import React, { useEffect, useState } from 'react'
import { useWorkspaceState } from '../../context/Workspace'
import { getStartingData, submitOp } from '../../functions/helpers'
import useSaveDocument from '../../hooks/useSaveDocument'
import Loading from '../Loading'

import SoundAddSection from './partials/SoundAddSection'
import SoundBoardEntry from './partials/SoundBoardEntry'
import TopToolBar from './partials/TopToolBar'
import './SoundboardViewer.css'

const defaultSoundData = { volume: 0.5, playing: false, repeat: false, time: 0 }

const SoundboardViewer = ({
    id,
    initialValue,
    windowIndex,
    saving,
    isFullScreen,
    broadcasted,
    broadcasting,
}) => {
    const [data, setData] = useState(getStartingData(null, 'soundboard'))
    const { doc, initialLoadComplete } = useSaveDocument(id, initialValue, setData, 'soundboard', windowIndex)

    const [soundSearch, setSoundSearch] = useState('')
    const [soundSearchBuffer, setSoundSearchBuffer] = useState('')
    const [editMode, setEditMode] = useState('Play Sounds')

    const { musicPlaying } = useWorkspaceState()

    const addSoundToBoard = (sound) => {
        submitOp(doc, { p: ['sounds', data.sounds.length], li: sound })
    }

    const removeSoundFromBoard = (sound) => {
        const index = data.sounds.findIndex(searchSound => searchSound.id === sound.id)
        submitOp(doc, { p: ['sounds', index], ld: sound })
    }

    const [filteredSounds, setFilteredSounds] = useState(data.sounds)

    useEffect(() => {
        const filtered = data.sounds.filter(sound => {
            return (
                sound?.title.toLowerCase().includes(soundSearch.toLowerCase()) ||
                sound?.tags.filter(tag => tag.toLowerCase().includes(soundSearch.toLowerCase()) > 0)?.length
            )
        })

        setFilteredSounds(filtered)
    }, [data.sounds, soundSearch])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSoundSearch(soundSearchBuffer)
        }, 300)

        return () => clearTimeout(timeout)
    }, [soundSearchBuffer])

    return (
        <>
            {(!initialLoadComplete) ? (
                <Loading opaque />
            ) : (
                <div className="row full-width full-height margin-top-2">
                    <TopToolBar
                        id={id}
                        windowIndex={windowIndex}
                        saving={saving}
                        isFullScreen={isFullScreen}
                        broadcasted={broadcasted}
                        broadcasting={broadcasting}
                        search={soundSearchBuffer}
                        setSearch={setSoundSearchBuffer}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        searchLoading={soundSearch !== soundSearchBuffer}
                    />
                    {editMode === "Add Sounds" ? (
                        <SoundAddSection windowIndex={windowIndex} search={soundSearch} currentList={data.sounds} onAddClick={addSoundToBoard} />
                    ) : (
                        <div className={`full-width col padding-1 margin-top-1`}>
                            {filteredSounds.length ? filteredSounds.map((sound, i) => (
                                <SoundBoardEntry key={`sound-${i}`} sound={{ ...defaultSoundData, ...sound, ...musicPlaying[sound.id] }} onRemoveClick={removeSoundFromBoard} />
                            )) : <p className='full-height full-width centered t-center'>No Sounds added yet, add a sound to play it.</p>}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default SoundboardViewer