import { useEffect, useRef, useState } from 'react'
import { useWorkspaceFunctions, useWorkspaceState } from '../../../../context/Workspace'
import { usePrevious } from '../../../../hooks/usePrevious'
import { getSoundData, setSoundData } from './functions'

const useMusic = (sound) => {
    const { globalVolume } = useWorkspaceState()
    const soundRef = useRef(getSoundData(sound.id) || null)
    const [loading, setLoading] = useState(false)
    const [duration, setDuration] = useState(0)
    let currentTimeTimeout = useRef(null)
    const [id] = useState(sound.id)

    const { playing, repeat, volume, url, currentTime } = sound
    useEffect(() => {
        if (soundRef.current) {
            soundRef.current.loop = repeat
            setSoundData(id, soundRef.current)
        }
    }, [id, repeat])

    const previousPlaying = usePrevious(sound.playing)
    const setCurrentDuration = () => {
        setDuration(soundRef.current.duration)
    }

    const { workspaceDispatch } = useWorkspaceFunctions()

    useEffect(() => {
        if (previousPlaying !== playing) {
            if (soundRef.current) {
                if (playing) {
                    soundRef.current.loop = repeat
                    soundRef.current.play().then(() => {
                        setLoading(false)
                    }).catch(err => {
                        console.log('error playing sound', err)
                        soundRef.current.muted = true
                        setSoundData(id, soundRef.current)
                        workspaceDispatch({ type: 'SET_MUSIC_MUTED', value: true })
                    })
                    if (!getSoundData(id) && currentTime) soundRef.current.currentTime = currentTime
                    setSoundData(id, soundRef.current)
                    setLoading(true)
                } else {
                    soundRef.current.pause()
                    setSoundData(id, soundRef.current)
                    setLoading(false)
                    clearInterval(currentTimeTimeout.current)
                }
            } else {
                soundRef.current = new Audio()
                soundRef.current.src = url
                soundRef.current.load()
                soundRef.current.loop = repeat
                soundRef.current.addEventListener('durationchange', setCurrentDuration)
                if (!getSoundData(id) && currentTime) soundRef.current.currentTime = currentTime

                if (playing) {
                    soundRef.current.loop = repeat
                    soundRef.current.play().then(() => {
                        setLoading(false)
                    }).catch(err => {
                        soundRef.current.muted = true
                        setSoundData(id, soundRef.current)
                        workspaceDispatch({ type: 'SET_MUSIC_MUTED', value: true })
                        soundRef.current.play()
                    })
                    setLoading(true)
                }
                setSoundData(id, soundRef.current)
            }
        }

        return () => {
            if (soundRef.current) {
                soundRef.current.removeEventListener('durationChange', setCurrentDuration)
            }
        }
    }, [currentTime, currentTimeTimeout, id, loading, playing, previousPlaying, repeat, sound, url, volume, workspaceDispatch])

    useEffect(() => {
        if (soundRef.current) {
            const netVolume = volume === undefined ? 1 : volume
            const newVolume = netVolume * globalVolume

            soundRef.current.volume = newVolume
        }
    }, [globalVolume, volume])

    const previousLoading = usePrevious(loading)
    useEffect(() => {
        if (previousLoading !== loading) {
            workspaceDispatch({ type: 'UPDATE_PLAYING_SONG', value: { ...sound, loading } })
        }
    }, [loading, previousLoading, sound, workspaceDispatch])

    const previousDuration = usePrevious(duration)
    useEffect(() => {
        if ((previousDuration !== duration && duration !== 0)) {
            workspaceDispatch({ type: 'UPDATE_PLAYING_SONG', value: { ...sound, duration } })
        }
    }, [duration, loading, previousDuration, sound, workspaceDispatch])

    useEffect(() => {
        return () => {
            const sd = getSoundData(sound.id)
            sd && sd.pause()
            setSoundData(sound.id, undefined)
        }
    }, [sound.id])
}

export default useMusic
