import React from 'react'
import './UserCount.css'
const UserCount = ({ userCount, openPremiumSignup }) => {
    return (
        <button
            className="row full-width padding-horizontal-2 margin-top-1 chest-inner-user-count-button"
            onClick={openPremiumSignup}
        >
            <p className="chest-inner-user-count t-left sans-serif">
                <span className={`t-bold ${(userCount === 5) ? 'c-red' : ''}`}>
                    {userCount}/5
                        </span>
                <span>&nbsp;Users</span>
            </p>
            <p className="chest-inner-user-count-message margin-left-1 t-left">Upgrade to premium to unlock 5 more user slots per campaign.</p>
        </button>
    )
}

export default UserCount
