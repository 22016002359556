export default function updateRollingDice() {
    this.rolledDice.forEach((die, i) => {
        die.position.copy(die.body.position)
        die.quaternion.copy(die.body.quaternion)
        if (die.finishingTimer === null) {
            if (die.finishingTimerMax === null)
                die.finishingTimerMax = setTimeout(() => this.finishDice(die), 5000)

            const rotX = die.body.angularVelocity.x.toFixed(2)
            const rotY = die.body.angularVelocity.x.toFixed(2)
            const rotZ = die.body.angularVelocity.x.toFixed(2)

            const xDone = rotX > -0.01 && rotX < 0.01
            const yDone = rotY > -0.01 && rotY < 0.01
            const zDone = rotZ > -0.01 && rotZ < 0.01

            if (xDone && yDone && zDone) {
                die.finishingTimer = setTimeout(() => this.finishDice(die), 1500)
            }
        }
    })
}