import { Media, Settings, Confirm } from './'
import AddTokens from './AddTokens'

import {
    NewCampaign,
    CampaignCreated,
    ViewCampaign,
    WaitingForInvite,
    UsersAwaitingInvite,
    KickedFromCampaign,
    DocumentUnshared
} from './campaign'

import {
    NewNote,
    Note,
    PDF,
    Map,
    Picture,
    Soundboard
} from './Documents'

import PremiumSignup from './PremiumSignup'

import Complete from './settings/SubscriptionSettings/Complete'

const windowObjects = {
    Map: {
        settings: {
            color: 'green',
            title: '',
            customNameWindow: true,
            showSave: true,
            width: 650,
            height: 650,
            icon: 'map',
            canMax: true,
            innerProps: {
                className: 'notes-window-inner'
            }
        },
        view: Map
    },
    picture: {
        settings: {
            color: 'blue',
            title: '',
            customNameWindow: true,
            showSave: true,
            width: 650,
            height: 650,
            icon: 'media',
            canMax: true,
            innerProps: {
                className: 'notes-window-inner'
            }
        },
        view: Picture
    },
    soundboard: {
        settings: {
            color: 'blue',
            title: '',
            customNameWindow: true,
            showSave: true,
            width: 650,
            height: 650,
            icon: 'soundboard',
            canMax: true,
            innerProps: {
                className: 'notes-window-inner'
            }
        },
        view: Soundboard
    },
    media: {
        settings: {
            color: 'blue',
            title: 'New Media',
            width: 400,
            height: 300
        },
        view: Media
    },
    newNote: {
        settings: {
            color: 'blue',
            title: 'New Document',
            width: 650,
            height: 425,
            showCloseOnTab: true
        },
        view: NewNote
    },
    note: {
        settings: {
            color: 'yellow',
            title: '',
            customNameWindow: true,
            showSave: true,
            width: 650,
            height: 325,
            icon: 'note',
            canMax: true,
            innerProps: {
                className: 'notes-window-inner'
            }
        },
        view: Note
    },
    PDF: {
        settings: {
            color: 'yellow',
            title: '',
            customNameWindow: true,
            showSave: true,
            width: 650,
            height: 325,
            icon: 'parchment',
            canMax: true,
            innerProps: {
                className: 'notes-window-inner'
            }
        },
        view: PDF
    },
    AddTokens: {
        settings: {
            color: 'green',
            title: 'Add Tokens',
            width: 700,
            height: 500,
            uniqueIndex: 'add-tokens',
            showCloseOnTab: true
        },
        view: AddTokens
    },
    newCampaign: {
        settings: {
            color: 'white',
            title: 'New Campaign',
            width: 630,
            height: 530,
            fullScreenMobile: true,
            showCloseOnTab: true
        },
        view: NewCampaign
    },
    campaignCreated: {
        settings: {
            color: 'blue',
            title: 'Campaign Created',
            width: 630,
            height: 530,
            fullScreenMobile: true,
            showCloseOnTab: true
        },
        view: CampaignCreated
    },
    viewCampaign: {
        settings: {
            color: 'white',
            width: 630,
            height: 530,
            fullScreenMobile: true,
            showCloseOnTab: true
        },
        view: ViewCampaign
    },
    waitingForInvite: {
        settings: {
            uniqueIndex: 'waiting-for-index',
            color: 'white',
            title: '',
            width: 400,
            height: 325,
            disableWindowNav: true,
            disableMobileDefault: true,
        },
        view: WaitingForInvite
    },
    usersAwaitingInvite: {
        settings: {
            uniqueIndex: 'users-waiting-for-index',
            color: 'white',
            title: '',
            width: 400,
            height: 300,
            disableMobileDefault: true,
        },
        view: UsersAwaitingInvite
    },
    settings: {
        settings: {
            uniqueIndex: 'settings',
            color: 'white',
            width: 756,
            height: 650,
            disableScaling: true,
            fullScreenMobile: true,
            showCloseOnTab: true,
            disableBroadcast: true,
        },
        view: Settings
    },
    premiumSignup: {
        settings: {
            uniqueIndex: 'premium',
            color: 'white',
            width: 775,
            height: 600,
            disableScaling: true,
            fullScreenMobile: true
        },
        view: PremiumSignup
    },
    paymentSuccess: {
        settings: {
            width: 756,
            height: 450,
            color: 'full-green'
        },
        view: Complete
    },
    kickedFromCampaign: {
        settings: {
            uniqueIndex: 'user-kicked',
            color: 'red',
            title: 'Youve been Kicked',
            width: 340,
            height: 325,
            disableWindowNav: true,
            disableMobileDefault: true,
        },
        view: KickedFromCampaign
    },
    documentUnshared: {
        settings: {
            uniqueIndex: 'document-unshared',
            color: 'red',
            title: 'Document has been removed',
            width: 340,
            height: 325,
            disableWindowNav: true,
            disableMobileDefault: true,
        },
        view: DocumentUnshared
    },
    confirm: {
        settings: {
            color: 'red',
            title: 'Are you sure?',
            disableWindowNav: true,
            disableScaling: true,
            disableMobileDefault: true,
            width: 300,
            height: 250
        },
        view: Confirm
    }
}

export default windowObjects
