import React, { useEffect } from 'react'
import { usePrevious } from '../../hooks/usePrevious'
import useRenameDocument from '../../hooks/useRenameDocument'
const CustomTitleWindowInput = ({ title, documentId, kind }) => {
    const { text, setText, onChange } = useRenameDocument(title, documentId, kind)
    const prevTitle = usePrevious(title)
    useEffect(() => {
        if (title !== prevTitle) {
            setText(title)
        }
    })
    return (
        <input className="window-title-input c-main" placeholder="Untitled..." value={text || ''} onChange={(e) => onChange(e.target.value)} />
    )
}

export default CustomTitleWindowInput
