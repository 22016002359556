import { useState, useCallback, useEffect } from 'react'
import { PDFDocument, TextAlignment } from 'pdf-lib'

const useLoadPDF = (url, page, windowWidth, windowScaling, isFullScreen, setLoading, canvasRef) => {
    const [numPages, setNumPages] = useState(null)
    const [pageWidth, setPageWidth] = useState(0)
    const [pageHeight, setPageHeight] = useState(0)
    const [file, setFile] = useState(null)
    const [fields, setFields] = useState(null)

    // PDF LOADING SECTION

    const loadPDF = useCallback(async (pageToLoad) => {
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const page = pdfDoc.getPage(pageToLoad - 1)
        setPageHeight(page?.getHeight() || 0)
        setPageWidth(page?.getWidth() || 0)
        setFile(existingPdfBytes)
    }, [url])

    useEffect(() => {
        setFields(null)
        if (url && !file) {
            setLoading(true)
            loadPDF(page)
        } else if (!url) {
            setLoading(false)
        }
    }, [url, loadPDF, page, setLoading, file])

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumPages(numPages)
    }, [])

    const setFieldData = useCallback((rawFields) => {
        setFields(rawFields.map((rawField) => {
            return {
                id: rawField.id,
                name: rawField.fieldName,
                initialValue: rawField.fieldValue,
                type: getPdfFieldType(rawField.fieldType),
                alignment: getPDFTextFieldAlignment(rawField.textAlignment),
                isMultiline: rawField.multiLine,
                x: rawField.rect[0],
                y: rawField.rect[3],
                width: rawField.rect[2] - rawField.rect[0],
                height: rawField.rect[3] - rawField.rect[1],
            }
        }))
    }, [])

    // PDF SCALING SETTINGS
    const [scale, setScale] = useState(1.0)
    const [scaleMult, setScaleMult] = useState(1)
    useEffect(() => {
        if (windowScaling === false || isFullScreen) {
            const ww = isFullScreen ? window.innerWidth - 52 : windowWidth - 52
            setScale((ww / pageWidth) * scaleMult)
        }
    }, [isFullScreen, pageWidth, scaleMult, windowScaling, windowWidth])

    const [changePDFDarkMode, setChangePDFDarkMode] = useState(false)
    const inDarkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) || localStorage.getItem('darkModeSetting') === 'On'

    useEffect(() => {
        setChangePDFDarkMode(true)
    }, [inDarkMode])

    useEffect(() => {
        if (changePDFDarkMode) {
            if (canvasRef.current) {
                const canvas = canvasRef.current
                const ctx = canvas.getContext("2d")
                ctx.globalCompositeOperation = 'difference'
                ctx.fillStyle = 'white'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
            }

            setChangePDFDarkMode(false)
        }
    }, [canvasRef, changePDFDarkMode, inDarkMode])

    return {
        numPages,
        pageHeight,
        file,
        fields,
        onDocumentLoadSuccess,
        scale,
        scaleMult,
        setScaleMult,
        setFieldData
    }
}

export default useLoadPDF

const getPdfFieldType = (field) => {
    switch (field) {
        case 'Btn': return 'checkbox'
        case 'Tx': return 'text'
        default: return 'none'
    }
}

const getPDFTextFieldAlignment = (alignment) => {
    if (alignment === TextAlignment.Left) return 'left'
    if (alignment === TextAlignment.Center) return 'center'
    if (alignment === TextAlignment.Right) return 'right'
}