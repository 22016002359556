import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three'

export function loadGlbMesh(path, textureKey) {
    const loader = new GLTFLoader()

    let texture, d100texture
    if (textureKey) {
        const texturePath = `/dice/dice-${textureKey}.png`
        const texturePath100 = `/dice/dice-${textureKey}-d100.png`

        texture = new THREE.TextureLoader().load(texturePath)
        texture.flipY = false

        d100texture = new THREE.TextureLoader().load(texturePath100)
        d100texture.flipY = false
    }

    return new Promise((resolve, reject) => {
        loader.load(path, (gltf) => {
            const meshChildren = []
            gltf.scene.traverse((child) => {
                if (child.isMesh) {
                    meshChildren.push(child)
                    if (!(texture && d100texture)) return
                    if (child.name === 'D100') {
                        child.material.map = d100texture
                        child.material.side = THREE.FrontSide
                    } else {
                        child.material.map = texture
                        child.material.side = THREE.FrontSide
                    }
                }
            })
            resolve(meshChildren)
        },
            undefined,
            (err) => {
                reject(err)
            })
    })
}