import { getMarkersBounds } from "./getMarkersBounds"

export function drawMarker(ctx, xx, yy, currentDrawing, isCurrentDrawing, width, height) {
    ctx.lineCap = 'round'
    ctx.lineWidth = currentDrawing.thickness
    ctx.strokeStyle = currentDrawing.color
    ctx.fillStyle = currentDrawing.color

    ctx.clearRect(0, 0, width, height)

    let brushPoints = [...currentDrawing.points]

    if (isCurrentDrawing) {
        brushPoints.push(xx, yy)
    }

    drawSplines(ctx, brushPoints)

    const { top, left, bottom, right } = getMarkersBounds(currentDrawing)
    ctx.beginPath()
    ctx.rect(left, top, right - left, bottom - top)
    ctx.stroke()
}

function drawSplines(ctx, pts) {
    let cps = [] // There will be two control points for each "middle" point, 1 ... len-2e
    for (var i = 0; i < pts.length - 2; i += 1) {
        cps = cps.concat(ctlpts(pts[2 * i], pts[2 * i + 1], pts[2 * i + 2], pts[2 * i + 3], pts[2 * i + 4], pts[2 * i + 5]))
    }

    drawCurvedPath(ctx, cps, pts)
}

function ctlpts(x1, y1, x2, y2, x3, y3) {
    var t = 0.3;
    var v = va(arguments, 0, 2);
    var d01 = dista(arguments, 0, 1);
    var d12 = dista(arguments, 1, 2);
    var d012 = d01 + d12;
    return [x2 - v[0] * t * d01 / d012, y2 - v[1] * t * d01 / d012,
    x2 + v[0] * t * d12 / d012, y2 + v[1] * t * d12 / d012];
}

// given an array of x,y's, return distance between any two,
// note that i and j are indexes to the points, not directly into the array.
function dista(arr, i, j) {
    return Math.sqrt(Math.pow(arr[2 * i] - arr[2 * j], 2) + Math.pow(arr[2 * i + 1] - arr[2 * j + 1], 2));
}

function va(arr, i, j) {
    return [arr[2 * j] - arr[2 * i], arr[2 * j + 1] - arr[2 * i + 1]]
}

function drawCurvedPath(ctx, cps, pts) {
    var len = pts.length / 2; // number of points
    if (len < 2) return;
    if (len === 2) {
        ctx.beginPath();
        ctx.moveTo(pts[0], pts[1]);
        ctx.lineTo(pts[2], pts[3]);
        ctx.stroke();
    }
    else {
        ctx.beginPath();
        ctx.moveTo(pts[0], pts[1]);
        // from point 0 to point 1 is a quadratic
        ctx.quadraticCurveTo(cps[0], cps[1], pts[2], pts[3]);
        // for all middle points, connect with bezier
        for (var i = 2; i < len - 1; i += 1) {
            // console.log("to", pts[2*i], pts[2*i+1]);
            ctx.bezierCurveTo(cps[(2 * (i - 1) - 1) * 2], cps[(2 * (i - 1) - 1) * 2 + 1],
                cps[(2 * (i - 1)) * 2], cps[(2 * (i - 1)) * 2 + 1],
                pts[i * 2], pts[i * 2 + 1]);
        }
        ctx.quadraticCurveTo(cps[(2 * (i - 1) - 1) * 2], cps[(2 * (i - 1) - 1) * 2 + 1],
            pts[i * 2], pts[i * 2 + 1]);
        ctx.stroke();

    }
}