import React from 'react'
import Folder from './Folder'
import { Container } from 'react-smooth-dnd'
import { useOnDrop } from './useOnInventoryDrop'

const FoldersList = ({ folders, className, draggable, openDocumentWindow, minimal, setLoading }) => {
    const onDrop = useOnDrop()

    const foldersMap = folders.filter(folder => !!folder).map((folder, index) => (
        <Folder
            key={folder._id}
            index={index}
            kind={folder.kind}
            id={folder._id}
            name={folder.name}
            owner={folder.owner}
            documents={folder.documents}
            minimal={minimal}
            draggable={draggable}
            defaultFolder={folder.isDefaultFolder}
            setLoading={setLoading}
            openDocumentWindow={openDocumentWindow}
            sharedCampaigns={folder.sharedCampaigns}
            sharedUsers={folder.sharedUsers}
        />
    ))

    return <>
        {draggable ? (
            <div className='full-width foldersListContainer'>
                <Container
                    type="FOLDERS"
                    autoScrollEnabled={false}
                    dropPlaceholder={{
                        animationDuration: 150,
                        showOnTop: true,
                        className: 'folder-drop-preview bg-shade-2 bc-main'
                    }}
                    onDrop={(payload) => {
                        onDrop('FOLDERS', payload)
                    }}
                    getChildPayload={i => ({ ...folders[i], isFolder: true })}
                    lockAxis="y"
                >
                    {foldersMap}
                </Container>
            </div>
        ) : (
            foldersMap
        )}
    </>
}

export default FoldersList
