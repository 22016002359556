import React from 'react'
import { ButtonParent } from '../..'
import './Button.css'

/**
 * @param {{
 * children, className?, onClick, onMouseDown?, onMouseDown?, disabled?, onContextMenu?, href?
 * }}
 */


const Button = ({ children, className, onClick, onMouseDown, onMouseOver, disabled, onContextMenu, href }) => {
    return (
        <ButtonParent
            className={`default-button ${className}`}
            onClick={disabled ? null : onClick}
            onMouseDown={disabled ? null : onMouseDown}
            href={href}
            onMouseOver={disabled ? null : onMouseOver}
            onContextMenu={onContextMenu}
            disabled={disabled}
        >
            {children}
        </ButtonParent>
    )
}

export default Button
