import { diff, patch } from 'jsondiffpatch'
const choose = function () {
    return arguments[Math.floor(Math.random() * arguments.length)]
}

const iRandom = function (max) {
    return Math.floor(Math.random() * max)
}

const randomRange = function (min, max) {
    return Math.random() * (max - min) + min;
}

const pushOrSet = function pushOrSet(array, newItem) {
    const newItemIndex = array.findIndex(item => {
        return item === newItem
    })
    if (newItemIndex < 0) {
        array.push(newItem)
    } else {
        array[newItem] = newItem
    }

    return array
}

const pushOrSetIndex = function pushOrSetIndex(array, newItem, index) {
    const newItemIndex = array.findIndex(item => {
        const newItemId = newItem[index]
        const itemId = item[index]
        return itemId === newItemId
    })

    if (newItemIndex < 0) {
        array.push(newItem)
    } else {
        array[newItemIndex] = newItem
    }

    return [...array]
}

const lerp = function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end
}

const getDiff = (oldData, newData) => {
    let delta = diff(oldData, newData)
    if (delta) {
        const patched = patch(oldData, delta)
        return patched
    } else {
        return newData
    }
}

const grid = (value, grid) => {
    return Math.floor(value / grid) * grid
}

const getStartingData = (initialValue, kind) => {
    switch (kind) {
        case 'note':
            return initialValue || null

        case 'picture':
            return initialValue || { picture: null }

        case 'PDF':
            return { file: null, fields: {}, defaultDiceSelection: 'D20', ...initialValue }

        case 'soundboard':
            return initialValue || { sounds: [], playing: [] }

        case 'Map':
            return {
                image: null,
                tokens: [],
                paint: [],
                walls: [],
                fogCuts: [],
                fogEnabled: false,
                showGrid: true,
                gridLeft: 0,
                gridTop: 0,
                gridSize: 64,
                gridXCells: 30,
                gridYCells: 30,
                ...initialValue
            }


        default: return initialValue
    }
}

const getPercentageValueInRange = (input, inputStart, inputEnd, outputStart, outputEnd) => {
    return outputStart + ((outputEnd - outputStart) * (input - inputStart)) / (inputEnd - inputStart)
}

const submitOp = (doc, op) => {
    try {
        if (doc && doc.type) {
            doc.submitOp(op)
        }
    } catch (err) {
        console.log('error submitting op', err)
    }
}

const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max)
}

const getIcon = (kind) => {
    switch (kind) {
        case 'note': return 'note'
        case 'PDF': return 'parchment'
        case 'soundboard': return 'lute'
        case 'Map': return 'map'
        case 'picture': return 'media'
        default: return ''
    }
}


const getKindFromIcon = (kind) => {
    switch (kind) {
        case 'note': return 'note'
        case 'parchment': return 'PDF'
        case 'lute': return 'soundboard'
        case 'soundboard': return 'soundboard'
        case 'map': return 'Map'
        case 'media': return 'picture'
        default: return ''
    }
}

export const getIconImg = (kind) => {
    switch (kind) {
        case 'note':
            return "/note.svg"
        case 'PDF':
        case 'parchment':
            return "/sheet.svg"
        case 'soundboard':
            return "/lute.svg"
        case 'Map':
        case 'map':
            return "/map.svg"
        case 'Picture':
        case 'picture':
            return "/media.svg"
        default: return null
    }
}

const getIconColors = (kind) => {
    switch (kind) {
        case 'note':
            return `bg-light-yellow bc-yellow`
        case 'PDF':
        case 'parchment':
            return 'bg-yellow bc-dark-yellow'
        case 'soundboard':
            return 'bg-dark-cyan bc-dark-blue'
        case 'Map':
        case 'map':
            return 'bg-light-green bc-dark-green'
        case 'Picture':
        case 'picture':
        case 'media':
            return 'bg-light-blue bc-blue'
        default: return ''
    }
}

const getBGHexFromKind = (kind) => {
    switch (kind) {
        case 'note':
            return `#ffedd6`
        case 'PDF':
        case 'parchment':
            return '#FFDCAD'
        case 'soundboard':
            return '#90D3DF'
        case 'Map':
        case 'map':
            return '#BCE0B8'
        case 'Picture':
        case 'picture':
        case 'media':
            return '#b4e1e9'
        default: return 'white'
    }
}

const getIconColorsInCanvas = (kind) => {
    switch (kind) {
        case 'note':
            return {
                bg: 'lightyellow',
                bc: 'yellow'
            }
        case 'PDF':
        case 'parchment':
            return {
                bg: 'yellow',
                bc: 'darkyellow'
            }
        case 'soundboard':
            return {
                bg: 'darkcyan',
                bc: 'darkblue'
            }
        case 'Map':
        case 'map':
            return {
                bg: 'lightgreen',
                bc: 'green'
            }
        case 'Picture':
        case 'picture':
            return {
                bg: 'lightblue',
                bc: 'blue'
            }
        default: return {
            bg: 'white',
            bc: 'shade1'
        }
    }
}

const getHexFromUserColor = (userColor) => {
    switch (userColor) {
        case 'red': return '#e17e7e'
        case 'yellow': return '#ffedd6'
        case 'blue': return '#6EADC6'
        case 'green': return '#9bd095'
        default: return '#6EADC6'
    }
}

const getFolderColors = (sharedToCampaign, sharedToUser) => {
    if (sharedToCampaign) {
        return 'bg-light-blue bc-dark-blue c-dark-blue'
    } else if (sharedToUser && !sharedToCampaign) {
        return 'bg-light-yellow bc-yellow c-yellow'
    } else {
        return 'bg-shade-5-neutral bc-main c-main'
    }
}

const getHexFromMapColor = (mapColor) => {
    switch (mapColor) {
        case 'ice': return '#f2f9fd'
        case 'light-blue': return '#b4e1e9'
        case 'blue': return '#6EADC6'
        case 'dark-blue': return '#7faabc'
        case 'midnight-blue': return '#404d55'
        case 'red': return '#e17e7e'
        case 'light-red': return '#EC9898'
        case 'dark-red': return '#DF5353'
        case 'green': return '#9bd095'
        case 'light-green': return '#BCE0B8'
        case 'dark-green': return '#87C77F'
        case 'yellow': return '#ffedd6'
        case 'light-yellow': return '#FFF6EB'
        case 'dark-yellow': return '#FFDCAD'
        case 'cyan': return '#b4e1e9'
        case 'light-cyan': return '#f2f9fd'
        case 'dark-cyan': return '#90D3DF'
        case 'main': return '#647682'
        case 'purple': return '#B18CD9'
        case 'pink': return '#FFC8E5'
        case 'orange': return '#FFB864'
        default: return '#e17e7e'
    }
}

const getControlStart = (e) => e.touches ? e.touches[0] : e
const getControlEnd = (e) => e.changedTouches ? e.changedTouches[0] : e

const getCenterOfVariablePoints = (points, offset) => {
    const x = points.reduce((sum, point) => point.x + offset + sum, 0) / points.length
    const y = points.reduce((sum, point) => point.y + offset + sum, 0) / points.length
    return { x, y }
}

const cancellablePromise = promise => {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
            error => reject({ isCanceled, error }),
        );
    });

    return {
        promise: wrappedPromise,
        cancel: () => (isCanceled = true),
    };
};

const noop = () => { };

const delay = n => new Promise(resolve => setTimeout(resolve, n));

const foldersEqual = (folders1, folders2) => {
    let folder1Docs = []
    let folder2Docs = []
    let equal = true
    folders1.forEach(folder => {
        const folderDocs = folder?.documents?.left?.concat?.(folder?.documents?.right || []) || []
        folder1Docs = folder1Docs.concat(folderDocs)
    })
    folders2.forEach(folder => {
        const folderDocs = folder?.documents?.left?.concat?.(folder?.documents?.right || []) || []
        folder2Docs = folder2Docs.concat(folderDocs)
    })

    if (folder1Docs.length !== folder2Docs.length) equal = false
    else {
        folder1Docs.forEach((folder1Doc, i) => {
            if (folder1Doc?._id !== folder2Docs?.[i]?._id)
                equal = false
        })
    }

    return equal
}

function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

function rectanglesIntersect(
    aLeftRaw, aTopRaw, aRightRaw, aBottomRaw,
    bLeftRaw, bTopRaw, bRightRaw, bBottomRaw) {

    const aLeft = Math.min(aLeftRaw, aRightRaw)
    const aRight = Math.max(aLeftRaw, aRightRaw)
    const aTop = Math.min(aTopRaw, aBottomRaw)
    const aBottom = Math.max(aTopRaw, aBottomRaw)

    const bLeft = Math.min(bLeftRaw, bRightRaw)
    const bRight = Math.max(bLeftRaw, bRightRaw)
    const bTop = Math.min(bTopRaw, bBottomRaw)
    const bBottom = Math.max(bTopRaw, bBottomRaw)

    const aLeftOfB = aRight < bLeft;
    const aRightOfB = aLeft > bRight;
    const aAboveB = aTop > bBottom;
    const aBelowB = aBottom < bTop;

    return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
}

export {
    choose, pushOrSet, pushOrSetIndex, randomRange,
    lerp, iRandom, getDiff, grid, getStartingData, submitOp, getHexFromMapColor,
    getIcon, getKindFromIcon, getIconColors, getFolderColors, getHexFromUserColor, getIconColorsInCanvas,
    getControlStart, getControlEnd, getCenterOfVariablePoints, getBGHexFromKind, cancellablePromise, noop, delay, foldersEqual,
    debounce, rectanglesIntersect, getPercentageValueInRange, clamp
}