import React, { useCallback, useEffect } from 'react'
import Loading from '../../../../components/Loading'
import { TextButton } from '../../../../components/Buttons'
import { useCampaignFunctions, useCampaignState } from '../../../../context/Campaign'
import { useAuthState } from '../../../../context/Auth'
import { useErrorFunctions } from '../../../../context/Error'
import { useHistory } from 'react-router-dom'
import { useWorkspaceFunctions } from '../../../../context/Workspace'
import { InviteRejected } from './'
import { useLoadingFunctions } from '../../../../context/Loading'

const WaitingForInvite = ({ _index }) => {
    const { auth, user } = useAuthState()
    const { socket, waitingForInviteId, inviteAccepted } = useCampaignState()
    const { cancelInviteRequest, requestToEnterCampaign } = useCampaignFunctions()

    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()
    const history = useHistory()
    const { closeWindow } = useWorkspaceFunctions()

    const cancelAndClose = useCallback(() => {
        cancelInviteRequest(auth, user._id, waitingForInviteId, socket, setError)
        history.push('/')
        closeWindow(_index)
    }, [_index, waitingForInviteId, auth, cancelInviteRequest, closeWindow, history, setError, socket, user])

    const enterAndClose = useCallback(() => {
        history.push('/')
        closeWindow(_index)
        requestToEnterCampaign(auth, user, waitingForInviteId, setLoading, setError)
    }, [_index, auth, closeWindow, history, setLoading, setError, user, waitingForInviteId, requestToEnterCampaign])

    const acceptRejection = useCallback(() => {
        cancelInviteRequest(auth, user._id, waitingForInviteId, socket, setError)
        history.push('/')
        closeWindow(_index)
    }, [auth, user._id, waitingForInviteId, socket, setError, cancelInviteRequest, history, closeWindow, _index])

    useEffect(() => {
        if (inviteAccepted === true) enterAndClose()
    }, [enterAndClose, inviteAccepted])

    if (inviteAccepted === false) return <InviteRejected acceptRejection={acceptRejection} />

    return (
        <div className="col centered margin-horizontal-1 full-height">
            <div className="section row waiting-for-invite-loading">
                <Loading small />
            </div>
            <h1 className="waiting-for-invite-title">Request Sent</h1>
            <p>If the campaign owner is logged in, they will see your invite.</p>
            <TextButton onClick={cancelAndClose}>Close</TextButton>
        </div>
    )
}

export default WaitingForInvite
