import { getSoundEffectVolume } from "../../../getSoundEffectVolume"
import get2DPositionFrom3d from "../../functions/get2dPositionFrom3d"

export function explodeDiceEffect(oldDie) {
    const dieScreenPos = get2DPositionFrom3d(oldDie, this.camera)
    this.UI.triggerExplodeEffect(dieScreenPos, oldDie.textureKey)
    oldDie.exploded = true

    window._ADVK_DICE_EXPLODED_AUDIO.currentTime = 0
    window._ADVK_DICE_EXPLODED_AUDIO.play()

    window._ADVK_DICE_DROP_AUDIO_1.volume = getSoundEffectVolume()
    window._ADVK_DICE_DROP_AUDIO_1.currentTime = 0
    window._ADVK_DICE_DROP_AUDIO_1.play()
}

export function explodeDice(oldDie) {
    const newDie = this.createDiceObject(oldDie.type, oldDie.position.clone())
    const { dist, direction } = this.getDiceRollingDirection(true)
    this.addDiceToRollingGroup(oldDie.rollingGroup, newDie, direction, dist)
    oldDie.rollingGroup.exploding = true
    oldDie.rollingGroup.explosionCount++

    newDie.explosionParent = oldDie.rollingId
    newDie.explosionAncestor = oldDie.explosionParent ? oldDie.explosionAncestor : oldDie.rollingId

    const currentDice = oldDie.rollingGroup.dice.map(die => {
        const { type, diceValue: value, rollingId: id, keep, exploded, explosionParent, explosionAncestor } = die
        return { type, value, id, keep, exploded, explosionParent, explosionAncestor }
    })

    const dataToSend = {
        ...oldDie.rollingGroup.networkObject,
        message: 'DICE_EXPLODED',
        userId: this.user._id,
        name: this.user.name,
        velocity: dist,
        skin: oldDie.rollingGroup.skin,
        dice: [...currentDice]
    }

    this.sendDiceRoll(dataToSend)
}