import React, { useState } from 'react'
import { TextButton } from '../Buttons'
import CopyTextInput from '../CopyTextInput'

const InviteToggle = ({ text, url, inputClassName, className }) => {
    const [toggle, setToggle] = useState(false)
    return (
        toggle ? (
            <CopyTextInput className={className} inputClassName={inputClassName} value={url} />
        ) : (
                <TextButton className={`full-width blue ${className}`} onClick={() => { setToggle(true) }}>{text}</TextButton>
            )
    )
}

export default InviteToggle