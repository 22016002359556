import { handleGetUserData, getAuthFromLocalStorage } from './'
export function getUser(auth, user, initialLoad, loading, setLoading, setError) {
    const { dispatch } = this
    if (loading) return
    if (auth === null || user === null) {
        const localStorageAuth = getAuthFromLocalStorage()
        if (!auth && !localStorageAuth) {
            if (initialLoad) dispatch({ type: "SET_INITIAL_LOAD", value: false })
            return null
        }
        handleGetUserData.call(this, localStorageAuth, setLoading, setError)
    }
}