import React from 'react'

const Inner = React.forwardRef((props, ref) => {
    const innerProps = {
        ...props,
        id: `window-inner-${props.index}`,
        className: `${props.className} windowInner ${props.hasWindowButton && 'hasWindowButton'} c-shade-6`
    }
    return (
        <div ref={ref} {...innerProps} {...props.style}>
            {props.children}
        </div>
    )
})

export default Inner
