export const colorMap = {
    'ice': '#f2f9fd',
    'cyan': '#b4e1e9',
    'blue': '#6EADC6',
    'red': '#e17e7e',
    'green': '#9bd095',
    'dark-yellow': '#FFDCAD',
    'purple': '#B18CD9',
    'pink': '#FFC8E5',
    'orange': '#FFB864',
    'midnight-blue': '#404d55',
}