import React from 'react'
import { useCampaignState } from '../../context/Campaign'
import ChatUserIcon from './ChatUserIcon'

const LoggedInUserMap = ({ user, open, setDialog, removeHovered }) => {
    const { connectedUsers, activeCampaign } = useCampaignState()

    return (
        <div className={`chat-user-list bg-shade-1 ${open && 'chat-open'} ${connectedUsers.length > 0 && 'display-voice'}`}>
            <div className="chat-user-list-inner" onMouseLeave={() => removeHovered('user-icon')}>
                {connectedUsers.map((connectedUser, i) => {
                    const isCurrentUser = connectedUser._id === user._id
                    return (
                        <ChatUserIcon
                            key={i}
                            id={connectedUser._id}
                            name={connectedUser.name}
                            color={isCurrentUser ? user.color : connectedUser.color}
                            isCampaignOwner={activeCampaign?.owner?._id === connectedUser._id}
                            isCurrentUser={isCurrentUser}
                            setDialog={setDialog}
                        />
                    )
                })}
            </div>
        </div >
    )
}

export default LoggedInUserMap