import React, { useEffect, useRef, useState } from 'react'
import { PlayFill, PauseFill, StopFill } from '@styled-icons/bootstrap/'
import { RepeatOutline } from '@styled-icons/evaicons-outline/RepeatOutline'

import { IconButton } from '../../../../components/Buttons'
import { useWorkspaceFunctions } from '../../../../context/Workspace'
import VolumeIcon from '../../../../components/SoundboardViewer/partials/VolumeIcon'
import { Slider } from '../../../../components/Slider'
import Icon from '../../../../components/Icon'
import { convertAudioTime, getSoundData, setSoundData } from './functions';
import useMusic from './useMusic';
import { useCampaignState } from '../../../../context/Campaign';
import Loading from '../../../../components/Loading'

const SoundEntry = ({ sound }) => {
    const [time, setTime] = useState(0)
    const currentTimeTimeout = useRef(null)

    const {
        id,
        playing,
        duration,
        repeat,
        volume,
        currentTime,
        loading
    } = sound

    const { workspaceDispatch } = useWorkspaceFunctions()
    const { socket, activeCampaign } = useCampaignState()
    const updateSoundData = (update) => {
        const newSoundData = { ...sound, ...update }
        workspaceDispatch({ type: 'UPDATE_PLAYING_SONG', value: newSoundData })
        if (socket && activeCampaign._id) {
            socket.emit('UPDATE_PLAYING_SONG', JSON.stringify(newSoundData))
        }
    }

    useEffect(() => {
        if (playing) {
            currentTimeTimeout.current = setInterval(() => setTime(getSoundData(id)?.currentTime), 400)
        } else {
            clearTimeout(currentTimeTimeout.current)
        }

        return () => clearTimeout(currentTimeTimeout.current)
    }, [id, playing])

    useEffect(() => {
        setTime(currentTime)
    }, [currentTime])

    const handleRepeatClick = (e) => {
        e.stopPropagation()
        updateSoundData({ repeat: !repeat })
    }

    const handleRemoveClick = (e) => {
        e.stopPropagation()
        workspaceDispatch({ type: 'REMOVE_PLAYING_SONG', value: { ...sound } })
        const sd = getSoundData(sound.id)
        sd && sd.pause()
        setSoundData(sound.id, undefined)

        if (socket && activeCampaign._id) {
            socket.emit('REMOVE_PLAYING_SONG', JSON.stringify({ id: sound.id }))
        }
    }

    useMusic((sound))

    return (
        <div
            className={`soundEntry ${playing && 'playing'} row full-width border rounded padding-none overflow-hidden cursor-pointer margin-top-half margin-bottom-1`}
            onClick={(e) => {
                e.stopPropagation()
                updateSoundData({ playing: !playing })
            }}
        >
            <div className='col full-width'>
                <div className='row padding-1'>
                    <div className='full-width'>
                        <div className='col'>
                            <div className='row centered-vertically space-between'>
                                <div className='row centered-vertically'>
                                    <Icon className="min-size-2 size-2" value={sound.category} />
                                    {loading ? <Loading className="size-1dot5 padding-quarter" /> : playing ? <PauseFill className='min-size-1dot5 size-1dot5' /> : <PlayFill className='min-size-1dot5 size-1dot5' />}
                                    <IconButton className="padding-0" onClick={handleRemoveClick}>
                                        <StopFill className='min-size-1dot5 size-1dot5 c-shade-6' />
                                    </IconButton>
                                    <p style={{ width: "150px" }} className='whitespace-nowrap t-overflow-elipses'>{sound.title || 'Untitled'}</p>

                                    <IconButton className='stroke-none grow-hover' onClick={handleRepeatClick}>
                                        <RepeatOutline className={`min-size-1 size-1 bc-none ${repeat ? 'c-dark-cyan' : 'c-shade-4'}`} />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="row centered-vertically space-between">
                                <div className="row centered-vertically">
                                    <VolumeIcon />
                                    <Slider
                                        min={0}
                                        max={100}
                                        value={volume * 100}
                                        className="full-width margin-0 margin-left-1 height-quarter"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                        onChange={(e) => {
                                            updateSoundData({ volume: e.target.value / 100 })
                                        }}
                                    />
                                </div>
                                <p className="soundEntryTime t-small">{convertAudioTime(duration - time)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Slider
                    min={0}
                    max={duration || 1}
                    value={((time / duration) * duration) || 0}
                    className="full-width hideKnob margin-0 square height-half"
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={(e) => {
                        updateSoundData({ currentTime: e.target.value })
                        setTime(e.target.value)
                        const sd = getSoundData(id)
                        sd.currentTime = e.target.value
                        setSoundData(id, sd)
                        if (socket && activeCampaign?._id) {
                            socket.emit('UPDATE_SONG_TRACKING', JSON.stringify({ id, currentTime: e.target.value }))
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default SoundEntry
