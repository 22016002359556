import React from 'react'
import Blobs from '../Blobs'

const Dice = ({ className, clutter }) => {
    return (
        <div className={`landing-feature landing-dice ${className}`} data-scroll>

            <div className="feature-img-container">
                <img src="/examples/dice-roller-example@2x.png" alt="Example of live dice rolling" />
                <Blobs />
            </div>

            {clutter.map((item, i) => (
                <img className="clutter-item" key={i} src={item.url} alt='' />
            ))}

            <div className="feature-text-container">
                <div className="feature-text-inner">
                    <h2>Live dice roller</h2>
                    <p>
                        Easily Roll dice on a shared table with every other Player in the game.
                        No more asking if everyone
                        Has rolled initiative yet.
                    </p>
                    <a className="landing-cta" href="/login">Join For Free!</a>
                </div>
            </div>
        </div>
    )
}

export default Dice
