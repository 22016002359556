import {
    addConnectedUser,
    addMessage,
    addNotification,
    addRollToHistory,
    addUserToCampaign,
    addWaitingUser,
    cancelInviteRequest,
    cancelWaitingUser,
    changeUserDiceSkin,
    checkForCampaign,
    createCampaign,
    enterCampaign,
    handleReRoll,
    logOutOfCampaign,
    recievedMessage,
    recieveNetworkRoll,
    removeConnectedUser,
    removeNotification,
    removeUserFromCampaign,
    removeUserFromDeniedList,
    requestToEnterCampaign,
    sendOnline,
    sendDiceRoll,
    updateRollInHistory,
} from './functions'

export default function buildFunctions(dispatch) {
    return {
        addConnectedUser: addConnectedUser.bind({ dispatch }),
        addMessage: addMessage.bind({ dispatch }),
        addNotification: addNotification.bind({ dispatch }),
        addRollToHistory: addRollToHistory.bind({ dispatch }),
        addUserToCampaign: addUserToCampaign.bind({ dispatch }),
        addWaitingUser: addWaitingUser.bind({ dispatch }),
        cancelInviteRequest: cancelInviteRequest.bind({ dispatch }),
        cancelWaitingUser: cancelWaitingUser.bind({ dispatch }),
        changeUserDiceSkin: changeUserDiceSkin.bind({ dispatch }),
        checkForCampaign: checkForCampaign.bind({ dispatch }),
        createCampaign: createCampaign.bind({ dispatch }),
        enterCampaign: enterCampaign.bind({ dispatch }),
        handleReRoll: handleReRoll.bind({ dispatch }),
        logOutOfCampaign: logOutOfCampaign.bind({ dispatch }),
        recievedMessage: recievedMessage.bind({ dispatch }),
        recieveNetworkRoll: recieveNetworkRoll.bind({ dispatch }),
        removeConnectedUser: removeConnectedUser.bind({ dispatch }),
        removeNotification: removeNotification.bind({ dispatch }),
        removeUserFromCampaign: removeUserFromCampaign.bind({ dispatch }),
        removeUserFromDeniedList: removeUserFromDeniedList.bind({ dispatch }),
        requestToEnterCampaign: requestToEnterCampaign.bind({ dispatch }),
        sendDiceRoll: sendDiceRoll.bind({ dispatch }),
        sendOnline: sendOnline.bind({ dispatch }),
        updateRollInHistory: updateRollInHistory.bind({ dispatch }),
        campaignDispatch: dispatch
    }
}