import React from 'react'
import { IconButton } from '../Buttons'
import './SocialIcons.css'

const SocialIcons = ({ className }) => {
    return (
        <div className={`social-media-icons ${className}`}>
            <IconButton icon="discord" href="https://discord.gg/WBVvMGenVH" />
            <IconButton icon="twitter" href="https://twitter.com/AdventuringKit" />
            <IconButton icon="instagram" href="https://www.instagram.com/adventuringkit/" />
        </div>
    )
}

export default SocialIcons
