import React from 'react'

const Media = () => {
    return (
        <svg id="media-icon" data-name="media-icon" xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
            <g>
                <path d="M91.684,98.411H42.547a6.727,6.727,0,0,1-6.727-6.727h0V42.547a6.727,6.727,0,0,1,6.727-6.727H91.684a6.727,6.727,0,0,1,6.727,6.727h0V91.684A6.727,6.727,0,0,1,91.684,98.411Z" transform="translate(-22.113 -22.113)" fill="#b4e1e9" />
                <path d="M42.541,123.29H91.677a6.727,6.727,0,0,0,6.727-6.727h0V89.084a44.839,44.839,0,0,0-31.3-12.964c-20.958,0-31.3,12.827-31.3,12.827v27.612a6.731,6.731,0,0,0,6.723,6.731Z" transform="translate(-22.107 -46.992)" fill="#6eadc6" />
                <path d="M132.737,153.761a6.727,6.727,0,0,0,6.727-6.727h0v-2.4L121.1,126.826a4.772,4.772,0,0,0-6.647,0l-12.13,11.728v15.214Z" transform="translate(-63.167 -77.464)" fill="#9bd095" />
                <path d="M139.3,155.707a30.489,30.489,0,0,1-14.089-3.031c-6.233-3.245-11.3-11.151-13.848-15.746l-9,8.7v15.214h30.4a6.731,6.731,0,0,0,6.536-5.139Z" transform="translate(-63.198 -84.533)" fill="#88b780" />
                <path d="M76.737,145.318,56.655,115.127a7.446,7.446,0,0,0-10.332-2.074,7.727,7.727,0,0,0-.983.765l-9.52,9v15.773a6.727,6.727,0,0,0,6.727,6.727Z" transform="translate(-22.113 -69.021)" fill="#9bd095" />
                <path d="M147.673,71.956a6.693,6.693,0,1,1-1.96-4.736A6.7,6.7,0,0,1,147.673,71.956Z" transform="translate(-82.897 -40.288)" fill="#ffedd6" />
                <path d="M89.572,32.38H40.436A8.036,8.036,0,0,0,32.4,40.416V89.56A8.036,8.036,0,0,0,40.436,97.6H89.572a8.059,8.059,0,0,0,8.036-8.036V40.423a8.055,8.055,0,0,0-8.036-8.043ZM40.436,35.016H89.572a5.411,5.411,0,0,1,5.407,5.407V84.046L78.866,68.407a6.073,6.073,0,0,0-8.483,0L62.641,75.9,55.646,65.365a8.767,8.767,0,0,0-12.153-2.441,8.885,8.885,0,0,0-1.148.918l-7.309,6.888V40.423a5.415,5.415,0,0,1,5.4-5.407Zm49.136,59.95H40.436a5.415,5.415,0,0,1-5.411-5.407v-15.2l9.122-8.6a6.122,6.122,0,0,1,8.659.26,5.927,5.927,0,0,1,.639.807l16.565,24.9a1.318,1.318,0,1,0,2.2-1.458h0L64.122,78.119l8.093-7.825a3.444,3.444,0,0,1,4.818,0L94.979,87.719V89.56a5.411,5.411,0,0,1-5.407,5.407Z" transform="translate(-20.002 -19.99)" fill="#21262c" />
                <path d="M138.859,77.846a8.013,8.013,0,1,0-8.009-8.011h0A8.036,8.036,0,0,0,138.859,77.846Zm0-13.393a5.376,5.376,0,1,1-5.357,5.376h0A5.38,5.38,0,0,1,138.859,64.453Z" transform="translate(-80.78 -38.164)" fill="#21262c" />
                <rect width="90" height="90" fill="none" />
            </g>
        </svg>
    )
}

export default Media
