import React from 'react'
import { Button } from '../../components/Buttons'
import './ErrorPage.css'

const ErrorPage = () => {
    return (
        <div className="workspace centered">
            <div className="max-width-20 centered col">
                <img className="size-10" src="/MapTokens/skull.svg" alt="" />
                <h1 className="c-shade-9 t-capitalize">Critical Fail!</h1>
                <p className="c-shade-9 margin-bottom-1">We hecked up something, sorry about that.</p>
                <Button onClick={() => window.location.reload()}>Refresh and try again</Button>
            </div>
        </div>
    )
}

export default ErrorPage
