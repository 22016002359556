export default function addDiceToHand(key) {
    if (this.selectedDice.length >= 30 || this.dice['local'] === {}) return
    const newDie = this.createDiceObject(key)

    newDie.position.z = 3
    this.selectedDice.push(newDie)
    const addOrSubValue = this.addOrSub !== '-' ? parseInt(this.addOrSub) : 0
    this.UI.addDice(newDie, addOrSubValue)
    this.setDiceInHand(true)

    this.startScene()
}