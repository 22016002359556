import React, { useState } from 'react'
import { TextButton } from '../Buttons'
import Icon from '../Icon'

const HiddenList = ({ defaultOpen, title, titleClassName, children }) => {
    const [listOpen, setListOpen] = useState(defaultOpen)
    return (
        <div className={`hidden-list padding-vertical-half ${listOpen && 'open'}`}>
            <div className={`hidden-list-header ${titleClassName}`}>
                <TextButton className="hidden-list-toggle" onClick={() => setListOpen(!listOpen)}>
                    <Icon value="expand-list" />
                    <p className="margin-left-half">{title}</p>
                </TextButton>
            </div>
            <div className="hidden-list-body">
                {listOpen ? (
                    children
                ) : null}
            </div>
        </div>
    )
}

export default HiddenList
