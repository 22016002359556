import React, { useState } from 'react'
import { Button } from '../Buttons'
import Icon from '../../components/Icon'

const Notification = ({ message, id }) => {
    const [quickClose, setQuickCLose] = useState(false)
    const close = () => { setQuickCLose(true) }

    return (
        <div id={id} key={`notification-${id}`} className={`notification shadow ${quickClose && 'quickClose'}`}>
            <p>{message}</p>
            <Button onClick={close}><Icon value="close" /></Button>
        </div>
    )
}

export default Notification
