import React, { useReducer, useContext, useMemo } from 'react'
import reducer from './reducer'
import buildFunctions from './buildFunctions'

const LoadingStateContext = React.createContext()
const LoadingFunctionsContext = React.createContext()

function LoadingProvider({ children }) {
    const initialState = {
        loading: null
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <LoadingStateContext.Provider value={state}>
            <LoadingFunctionsContext.Provider value={dispatch}>
                {children}
            </LoadingFunctionsContext.Provider>
        </LoadingStateContext.Provider>
    )
}

function useLoadingState() {
    const LoadingState = useContext(LoadingStateContext)
    if (typeof LoadingState === undefined) {
        throw new Error('useLoadingState must be used within an LoadingProvider')
    }

    return LoadingState
}


function useLoadingFunctions() {
    const dispatch = useContext(LoadingFunctionsContext)
    if (typeof dispatch === undefined) {
        throw new Error('useLoadingUpdater must be used within an LoadingProvider')
    }
    const functions = useMemo(() => buildFunctions(dispatch), [dispatch])

    return functions
}

export {
    LoadingProvider,
    useLoadingState,
    useLoadingFunctions
}
