import React from 'react'
import Icon from '../../../../components/Icon'
import { Button } from '../../../../components/Buttons'
import { useCampaignFunctions } from '../../../../context/Campaign'
import { useWorkspaceFunctions } from '../../../../context/Workspace'
import { useAuthState } from '../../../../context/Auth'
import { useErrorFunctions } from '../../../../context/Error'
import CopyTextInput from '../../../../components/CopyTextInput'
import { useLoadingFunctions } from '../../../../context/Loading'
const CampaignCreated = ({ _index, newCampaign }) => {
    const { auth, user } = useAuthState()

    const { requestToEnterCampaign } = useCampaignFunctions()
    const { closeWindow } = useWorkspaceFunctions()

    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()

    const handleEnterCampaign = () => {
        requestToEnterCampaign(auth, user, newCampaign.shortId, setLoading, setError)
        window.history.pushState("", "", `/campaign/${newCampaign.shortId}`);
        closeWindow(_index)
    }

    return (
        <div className="campaign-created-window">
            <div className="campaign-created-icon"><Icon value="tome" /></div>
            <h1>{newCampaign.title}</h1>
            <div className="campaign-created-subtitle">
                <p>Has been created!</p>
                <p>Share this link with your friends so they can join the adventure.</p>
            </div>
            <CopyTextInput containerClassName="campaign-created-share-link" text={'+ Get Campaign Invite Link'} value={`https://adventuring-kit.com/campaign/${newCampaign.shortId}`} />
            <Button class="blue" onClick={handleEnterCampaign}>Enter Campaign</Button>
        </div>
    )
}

export default CampaignCreated
