import React, { useState, useMemo, useEffect } from 'react'
import './Chat.css'

import { CircleButton, IconButton } from '../Buttons'
import TextInput from '../TextInput'
import { useAuthState } from '../../context/Auth'
import LoggedInUsers from './LoggedInUsers'
import UsersAreTyping from './UsersAreTyping'
import ListMessages from './ListMessages'
import { useMessaging } from './hooks/'
import { useCampaignState, useCampaignFunctions } from '../../context/Campaign'
import useDialogue from './hooks/useDialogue'
import ChatBadge from './ChatBadge'
import useLoadMessages from './hooks/useLoadMessages'
import Loading from '../Loading'
import { getSoundEffectVolume } from '../../functions/getSoundEffectVolume'

window._ADVK_NEW_MESSAGE_AUDIO = new Audio()
window._ADVK_NEW_MESSAGE_AUDIO.src = "/new-message.wav"
window._ADVK_NEW_MESSAGE_AUDIO.volume = getSoundEffectVolume()

const Chat = () => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")

    const { user, auth } = useAuthState()
    const { messages, usersTyping, unreads } = useCampaignState()
    const { campaignDispatch } = useCampaignFunctions()
    const { messagesLoading } = useLoadMessages(auth)
    const createMessage = useMessaging(message, setMessage)

    useEffect(() => {
        if (open === true) campaignDispatch({ type: "CLEAR_UNREADS" })
    }, [open, messages, campaignDispatch])

    const { dialog, setDialog, removeHovered } = useDialogue()

    const loggedInUsers = useMemo(() => (<LoggedInUsers user={user} open={open} setDialog={setDialog} removeHovered={removeHovered} campaignDispatch={campaignDispatch} />), [user, open, setDialog, removeHovered, campaignDispatch])
    const messagesDisplay = useMemo(() => <ListMessages messages={messages} messageClassName="chat-new" />, [messages])
    return useMemo(() => {
        return (
            <>
                <div className={`chat ${open && 'open'}`}>
                    <CircleButton
                        className="chat-button padding-dot75 bg-white bc-main shadow-dark border-thick"
                        onClick={() => setOpen(!open)}
                        icon="chat-icon"
                    />

                    <div className="chat-container bg-white bc-main shadow">
                        {loggedInUsers}
                        <div className="chat-inner bg-white">
                            <div className={`chat-header c-shade-9 bc-shade-2`}>
                                <p>Message Log</p>

                                <IconButton
                                    className="small-close-button"
                                    onClick={() => setOpen(false)}
                                    icon="close"
                                />
                            </div>
                            <div className="chat-messages-scroll-box bg-white">
                                <div className="chat-messages-container">
                                    {messagesLoading ? <Loading /> : messagesDisplay}
                                </div>
                            </div>
                            {usersTyping.length > 0 && <UsersAreTyping usersTyping={usersTyping} />}
                            <form className={`chat-input-container row centered-vertically`} autoComplete="off" onSubmit={(e) => { e.preventDefault(); createMessage() }}>
                                <TextInput id="chat-input" className="chat-text-input" label="Chat Input" placeholder="Type a message..." value={message} onChange={(e) => setMessage(e.target.value)} />
                            </form>
                        </div>
                    </div>
                    <ChatBadge count={unreads} />
                </div>
                {dialog}
            </>
        )
    }, [createMessage, dialog, loggedInUsers, message, messagesDisplay, messagesLoading, open, unreads, usersTyping])
}

export default Chat