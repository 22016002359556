import React, { useRef, useState, useCallback, useMemo } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import Loading from '../Loading'
import useLoadPDF from './hooks/useLoadPDF'
import PDFField from './partials/PDFField'
import TopToolBar from './partials/TopToolBar'
import DropZone from '../DropZone'
import useUploadPDF from './hooks/useUploadPDF'
import { useCampaignState } from '../../context/Campaign'
import { useWorkspaceFunctions, useWorkspaceState } from '../../context/Workspace'
import { getStartingData, submitOp } from '../../functions/helpers'
import useSaveDocument from '../../hooks/useSaveDocument'
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"
import './PDFRenderer.css'
import './AnnotationLayer.css'
import './TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
const PDFRenderer = ({
    initialValue,
    id,
    name,
    windowScaling,
    windowDragging,
    windowWidth,
    windowIndex,
    saving,
    isFullScreen,
    broadcasted,
    broadcasting
}) => {
    const [data, setData] = useState(getStartingData(null, 'PDF'))
    const [loading, setLoading] = useState(true)

    const { addDice } = useCampaignState()
    const { workspaceDispatch } = useWorkspaceFunctions()
    const { tempDiceOptions } = useWorkspaceState()
    const [page, setPage] = useState(1)
    const changePage = (page) => {
        setPage(page)
    }
    const [editMode, setEditMode] = useState('Roll')

    const { doc, initialLoadComplete } = useSaveDocument(id, initialValue, setData, 'PDF', windowIndex)

    const setFieldValue = useCallback((id, val) => {
        workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
        submitOp(doc, { p: ['fields', id], od: data.fields[id], oi: val })
    }, [data.fields, doc, windowIndex, workspaceDispatch])

    const setDefaultDiceSelection = useCallback((val) => {
        submitOp(doc, { p: ['defaultDiceSelection'], od: data.defaultDiceSelection, oi: val })
    }, [data.defaultDiceSelection, doc])

    const uploadPDF = useUploadPDF(setLoading, doc, data, id)
    const canvasRef = useRef(null)

    const {
        file, onDocumentLoadSuccess,
        fields, pageHeight,
        setFieldData, numPages,
        scale, scaleMult, setScaleMult
    } = useLoadPDF(data.file, page, windowWidth, windowScaling, isFullScreen, setLoading, canvasRef)

    const onRollableClick = useCallback((rollable) => {
        rollable.dice.forEach(die => {
            for (let i = 0; i < die.quantity; i++) {
                addDice(die.key)
            }
        })

        workspaceDispatch({
            type: 'SET_TEMP_DICE_OPTIONS',
            value: {
                addOrSub: tempDiceOptions.addOrSub + rollable.addOrSub,
                succeedsOn: rollable.succeedsOn,
                succeedsOnGreaterOrLess: rollable.succeedsOnGreaterOrLess,
                name,
                label: rollable.label
            }
        })
    }, [addDice, name, tempDiceOptions.addOrSub, workspaceDispatch])

    const pageMemo = useMemo(() => {
        return (
            <Page
                pageNumber={page}
                renderAnnotationLayer={true}
                renderInteractiveForms={false}
                renderMode="svg"
                scale={scale}
                renderTextLayer={false}
                onGetAnnotationsSuccess={anno => {
                    setFieldData(anno)
                    setLoading(false)
                }}
            />
        )
    }, [page, scale, setFieldData])

    const fieldsMemo = useMemo(() => {
        return (
            (!windowDragging && !windowScaling) && fields && fields.map(field => {
                return (
                    <PDFField
                        key={field.id}
                        data={field}
                        pageHeight={pageHeight}
                        scale={scale}
                        initialValue={field.initialValue}
                        value={data.fields[field.id]}
                        name={field.name}
                        setValue={(val) => setFieldValue(field.id, val)}
                        editMode={editMode}
                        onRollableClick={onRollableClick}
                        defaultDiceValue={data.defaultDiceSelection || 'D20'}
                    />
                )
            })
        )
    }, [data.defaultDiceSelection, data.fields, editMode, fields, onRollableClick, pageHeight, scale, setFieldValue, windowDragging, windowScaling])

    const documentsMemo = useMemo(() => (
        <Document
            className="PDFRenderer-document"
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(err) => console.log(err)}
            noData={null}
            loading={null}
            options={{ verbosity: 0 }}
        >
            {pageMemo}
            {fieldsMemo}
        </Document>
    ), [fieldsMemo, file, onDocumentLoadSuccess, pageMemo])

    return (
        <>
            <TopToolBar
                id={id}
                numPages={numPages}
                page={page}
                setPage={changePage}
                scaleMult={scaleMult}
                setScale={setScaleMult}
                setLoading={setLoading}
                setEditMode={setEditMode}
                defaultDiceSelection={data.defaultDiceSelection}
                setDefaultDiceSelection={setDefaultDiceSelection}
                windowIndex={windowIndex}
                saving={saving}
                isFullScreen={isFullScreen}
                broadcasted={broadcasted}
                broadcasting={broadcasting}
            />
            <div className='position-relative overflow-hidden full-height full-width padding-bottom-2'>
                {(loading || !initialLoadComplete) && <Loading opaque />}
                {data.file ? (
                    <div className={`PDFRenderer`}>
                        {documentsMemo}
                    </div>
                ) : (
                    <div className="PDFRenderer--noFile full-width full-height row">
                        <div className="full-width border-right-thin padding-1">
                            <h3>Upload New PDF</h3>
                            <DropZone
                                className="margin-top-1 full-width height-6 bg-shade-3 rounded"
                                onSave={uploadPDF}
                                DropOrClickMessage={"Drag PDF here or click to select."}
                                showClickIcon
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default PDFRenderer
