import React, { useReducer, useContext, useMemo } from 'react'
import reducer from './reducer'
import buildFunctions from './buildFunctions'

const ErrorStateContext = React.createContext()
const ErrorFunctionsContext = React.createContext()

function ErrorProvider({ children }) {
    const initialState = {
        error: ''
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <ErrorStateContext.Provider value={state}>
            <ErrorFunctionsContext.Provider value={dispatch}>
                {children}
            </ErrorFunctionsContext.Provider>
        </ErrorStateContext.Provider>
    )
}

function useErrorState() {
    const errorState = useContext(ErrorStateContext)
    if (typeof errorState === undefined) {
        throw new Error('useErrorState must be used within an ErrorProvider')
    }

    return errorState
}


function useErrorFunctions() {
    const dispatch = useContext(ErrorFunctionsContext)
    if (typeof dispatch === undefined) {
        throw new Error('useErrorUpdater must be used within an ErrorProvider')
    }
    const functions = useMemo(() => buildFunctions(dispatch), [dispatch])

    return functions
}

export {
    ErrorProvider,
    useErrorState,
    useErrorFunctions
}
