import React, { useEffect, useState, useCallback } from 'react'
import { useErrorState } from '../../context/Error'
import Loading from '../Loading'
import './Error.css'


let errorTimeout
const Error = () => {
    let { error } = useErrorState()
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (error && !error.persistent) {
            setShow(true)
            errorTimeout = setTimeout(() => setShow(false), 3000)
        }
        return () => clearTimeout(errorTimeout)
    }, [error])

    const handleClick = useCallback(() => {
        if (error.type === 'reload') window.location.reload()
    }, [error.type])


    return (
        <div className={`error ${(show || error.persistent) && "error-show"} ${error.type}`} onClick={handleClick}>
            <div className="error-inner row centered">
                <p>
                    {
                        typeof error === 'string' ? (
                            error
                        ) : (
                            typeof error.value === 'string' || error.value instanceof String ? (
                                error.value
                            ) : (
                                error.value === undefined ? 'Something went wrong.' : error.value.toString()
                            )
                        )
                    }
                </p>
                {error.type === 'pending' && <Loading className='size-1 margin-left-1 margin-bottom-quarter' />}
            </div>
        </div>
    )
}

export default Error