import API from '../../../functions/API'

export async function deleteDocument(authToken, id, folderId) {
    this.dispatch({ type: 'REMOVE', value: { id, folderId } })

    await API.sendGraphSecure(authToken, {
        query: `mutation {
            deleteDocument(documentId: "${id}", folderId: "${folderId}")
        }`
    })
}