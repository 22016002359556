import React from 'react'

const TextTypeList = () => {

    return (
        <select className="select-list bg-white c-shade-8 ql-size max-width-6">
            <option value="small">Small</option>
            <option value="large">Normal</option>
            <option value="huge">Large</option>
        </select>
    )
}

export default TextTypeList
