const DefaultMapTokens = {
    classic: [
        { src: '/MapTokens/sword.svg', name: 'sword' },
        { src: '/MapTokens/shield.svg', name: 'shield' },
        { src: '/MapTokens/spear.svg', name: 'spear' },
        { src: '/MapTokens/axe.svg', name: 'axe' },
        { src: '/MapTokens/arrows.svg', name: 'arrows' },
        { src: '/MapTokens/crossbow.svg', name: 'crossbow' },
        { src: '/MapTokens/dagger.svg', name: 'dagger' },
        { src: '/MapTokens/flail.svg', name: 'flail' },
        { src: '/MapTokens/lute.svg', name: 'lute' },
        { src: '/MapTokens/mace.svg', name: 'mace' },
        { src: '/MapTokens/tome.svg', name: 'tome' },
        { src: '/MapTokens/torch.svg', name: 'torch' },
        { src: '/MapTokens/fire-sword.svg', name: 'fire sword' },
        { src: '/MapTokens/fireball.svg', name: 'fireball' },
        { src: '/MapTokens/guantlet.svg', name: 'guantlet' },
        { src: '/MapTokens/helmet.svg', name: 'helmet' },
        { src: '/MapTokens/knight.svg', name: 'knight' },
        { src: '/MapTokens/potion.svg', name: 'potion' },
        { src: '/MapTokens/skull.svg', name: 'skull' },
        { src: '/MapTokens/target.svg', name: 'target' },
        { src: '/MapTokens/bag.svg', name: 'bag' },
        { src: '/MapTokens/boot.svg', name: 'boot' },
        { src: '/MapTokens/cart.svg', name: 'cart' },
        { src: '/MapTokens/catapult.svg', name: 'catapult' },
        { src: '/MapTokens/chest.svg', name: 'chest' },
        { src: '/MapTokens/coins.svg', name: 'coins' },
        { src: '/MapTokens/crown.svg', name: 'crown' },
        { src: '/MapTokens/eye.svg', name: 'eye' },
        { src: '/MapTokens/flag.svg', name: 'flag' },
        { src: '/MapTokens/gems.svg', name: 'gems' },
        { src: '/MapTokens/meat.svg', name: 'meat' },
        { src: '/MapTokens/ice.svg', name: 'ice' },
        { src: '/MapTokens/key.svg', name: 'key' },
        { src: '/MapTokens/leaf.svg', name: 'leaf' },
        { src: '/MapTokens/lock.svg', name: 'lock' },
        { src: '/MapTokens/logs.svg', name: 'logs' },
        { src: '/MapTokens/power.svg', name: 'power' },
        { src: '/MapTokens/stone.svg', name: 'stone' },
        { src: '/MapTokens/wave.svg', name: 'wave' },
    ]
}

export default DefaultMapTokens