import React, { useEffect, useRef } from 'react'
import { useCampaignFunctions, useCampaignState } from '../../../../context/Campaign/index'
import { getPos } from '../../../../functions/CanvasHelpers'
import './pings.css'

const Pings = ({ camera, docId, pings, addPing, addRemotePing, removePing }) => {
    const { pingQueue } = useCampaignState()
    const { campaignDispatch } = useCampaignFunctions()

    useEffect(() => {
        pingQueue.forEach(pingData => {
            if (pingData.docId === docId) {
                addRemotePing(pingData.ping)
                campaignDispatch({ type: 'REMOVE_PING_FROM_QUEUE', value: pingData.id })
            }
        })
    }, [addRemotePing, campaignDispatch, docId, pingQueue])


    return <div className="pings">
        {pings && pings.map(ping => {
            return (
                <Ping key={ping.id} camera={camera} ping={ping} removePing={removePing} />
            )
        })}
    </div>
}

export default Pings


const Ping = ({ camera, ping, removePing }) => {
    const docH = window.innerHeight || document.documentElement.clientHeight
    const docW = window.innerWidth || document.documentElement.clientWidth

    const maxX = getPos(docW, camera.x, camera.zoom)
    const minX = getPos(0, camera.x, camera.zoom)

    const maxY = getPos(docH, camera.y, camera.zoom)
    const minY = getPos(0, camera.y, camera.zoom)

    const xx = Math.max(minX, Math.min(ping.x, maxX))
    const yy = Math.max(minY, Math.min(ping.y, maxY))

    const timeout = useRef(null)
    useEffect(() => {
        timeout.current = setTimeout(() => {
            removePing(ping.id)
        }, 1000)

        return () => {
            clearTimeout(timeout.current)
        }
    }, [ping.id, removePing])

    return (
        <div
            id={`ping-${ping.id}`}
            className="ping"
            style={{
                left: `${xx}px`,
                top: `${yy}px`,
                color: ping.color
            }}
        ></div>
    )
}