import { getDiceMessage } from "../../../../context/Campaign/functions/addRollToHistory";
import countDieTypes from "../../functions/countDieTypes";

export function updateRollingGroup(oldRoll) {
    const roll = {
        ...oldRoll,
        totalValue: oldRoll.addOrSub,
        dice: oldRoll.dice.map(die => ({ ...die, keep: undefined, type: die.rawType })),
        failureCount: 0,
        successCount: 0,
    }
    const keepBest = parseInt(roll.keepBest)

    if (keepBest !== 0) {
        const sortValue = keepBest > 0 ? -1 : 1
        let keepDice = [...roll.dice]
        keepDice = keepDice.sort((a, b) => {
            return a.value > b.value ? sortValue : sortValue * -1
        })

        keepDice.splice(Math.abs(keepBest))

        roll.dice = roll.dice.map(die => {
            if (keepDice.find(keepDie => keepDie.id === die.id)) {
                const succeedsOnDiceOrRoll = roll.succeedsOnDiceOrRoll
                if (roll.succeedsOn !== 0) {
                    const succeedsOnGreaterOrLess = roll.succeedsOnGreaterOrLess
                    if (
                        succeedsOnDiceOrRoll === 'Dice' &&
                        (succeedsOnGreaterOrLess === 'greater' ?
                            die.diceValue >= die.rollingGroup.succeedsOn :
                            die.diceValue <= die.rollingGroup.succeedsOn))
                        roll.successCount++
                    else if (succeedsOnDiceOrRoll === 'Roll') {
                        roll.totalValue += die.value
                    }
                } else {
                    die.rollingGroup.totalValue += die.diceValue
                }
                die.keep = true
            }

            return die
        })

        roll.dice.forEach((die, i) => {
            if (!!die.keep) {
                die.rollingGroupIndex = i
            }
        })
    }

    if (roll.succeedsOn > 0) {
        roll.dice.forEach(dieInGroup => {
            if (roll.keepBest === 0 || dieInGroup.keep) {
                const succeedsOnDiceOrRoll = roll.succeedsOnDiceOrRoll
                const succeedsOnGreaterOrLess = roll.succeedsOnGreaterOrLess

                if (
                    succeedsOnDiceOrRoll === 'Dice') {
                    if (succeedsOnGreaterOrLess === 'greater' ?
                        dieInGroup.value >= roll.succeedsOn :
                        dieInGroup.value <= roll.succeedsOn) {
                        roll.successCount++
                        dieInGroup.failed = false

                    } else {
                        roll.failureCount++
                        dieInGroup.failed = true
                    }
                }
                else if (succeedsOnDiceOrRoll === 'Roll') {
                    roll.totalValue += dieInGroup.value
                }
            }
        })
    }

    roll.totalValue += roll.successCount

    roll.dice.forEach(die => {
        if (parseInt(keepBest) === 0 && roll.succeedsOn === 0) {
            if (roll.percentileRoll && die.type === 'D10' && die.diceValue === 10) roll.totalValue += 0
            else if (roll.percentileRoll && die.type === 'D100' && die.diceValue === 100) roll.totalValue += 0
            else roll.totalValue += die.value
        }
    })

    const { diceCounts, dice } = countDieTypes(roll.dice, false)
    roll.message = getDiceMessage(roll)

    roll.finished = true
    if (roll.percentileRoll && roll.totalValue === 0)
        roll.totalValue = 100

    return {
        ...roll,
        dice: roll.dice,
        diceCounts,
        countedDice: dice
    }
}