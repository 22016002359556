import React from 'react'
import { colorMap } from "../functions/colorMap"

const PingSettings = ({ pingColor, setPingColor }) => {
    return (
        <div className="pingSettings col padding-1">
            <p>Ping Color</p>
            <div className="row centered wrap margin-top-1">
                {Object.keys(colorMap).map((key) => {
                    return (
                        <button
                            key={key}
                            className={`round size-3 margin-quarter bg-${key} ${pingColor === key ? 'border-thick bc-main' : 'border-thin bc-main'}`}
                            onClick={() => setPingColor(key)}
                        ></button>
                    )
                })}
            </div>
        </div>
    )
}

export default PingSettings
