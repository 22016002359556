import React, { useEffect, useState } from 'react'
import './styles.css'
import { CircleButton, IconButton } from '../../../../components/Buttons'
import useOpenWindow from '../../../../hooks/useOpenWindow'
import { useWorkspaceFunctions, useWorkspaceState } from '../../../../context/Workspace'
import Icon from '../../../../components/Icon'
import { getIconColors, getKindFromIcon } from '../../../../functions/helpers'
import { Broadcast } from '@styled-icons/bootstrap/Broadcast'
import { CameraVideoFill } from '@styled-icons/bootstrap/'
import SoundControls from '../SoundControls/SoundControls'
import DocumentPreview from '../../../../components/DocumentPreview/DocumentPreview'

let tabHoverTimeout = null

const AddItems = () => {
    const openWindow = useOpenWindow()
    const { tabs, selectedTab, minimized } = useWorkspaceState()
    const { selectTab, workspaceDispatch } = useWorkspaceFunctions()
    const [minimizeHovered, setMinimizeHovered] = useState(null)
    const [tabHovered, setTabHovered] = useState(null)

    const [selectedTabIsMap, setSelectedTabIsMap] = useState()

    useEffect(() => {
        if (selectedTab) {
            const selectedTabData = tabs.find(tab => tab.index === selectedTab)
            if (selectedTabData?.settings?.icon === 'map') setSelectedTabIsMap(true)
            else setSelectedTabIsMap(false)
        } else setSelectedTabIsMap(false)
    }, [selectedTab, tabs])

    const handleMinimizeHover = (minimizeItem, i) => {
        clearTimeout(tabHoverTimeout)
        tabHoverTimeout = setTimeout(() => {
            if (minimizeItem?.documentId) setMinimizeHovered({ ...minimizeItem, hoverIndex: i })
        }, 300)
    }

    const handleMinimizeLeave = () => {
        clearTimeout(tabHoverTimeout)
        setMinimizeHovered(null)
    }

    const handleTabHover = (minimizeItem, i) => {
        clearTimeout(tabHoverTimeout)
        tabHoverTimeout = setTimeout(() => {
            if (minimizeItem?.documentId) setTabHovered({ ...minimizeItem, hoverIndex: i })
        }, 300)
    }

    const handleTabLeave = () => {
        clearTimeout(tabHoverTimeout)
        setTabHovered(null)
    }

    useEffect(() => {
        clearTimeout(tabHoverTimeout)
        setTabHovered(null)
        setMinimizeHovered(null)
    }, [tabs?.length, minimized?.length])


    return (
        <div className={`AddItems`}>
            <CircleButton
                onClick={() => openWindow('newNote')}
                icon="plus"
                className={`AddItems__button shadow-dark ${selectedTabIsMap && 'selectedTabIsMap'} padding-dot75 bg-white border-thick bc-shade-6`}
            />


            <div className="tabs-container row align-end">
                <div className='position-relative row-reverse'>
                    {minimizeHovered && (
                        <div className="fade-in hoveredPreview c-shade-9 width-10 height-10" style={{ right: minimizeHovered.hoverIndex * (112 + 16) + 16 }}>
                            <DocumentPreview
                                id={minimizeHovered?.documentId}
                                ownerId={minimizeHovered.documentOwnerId}
                                kind={getKindFromIcon(minimizeHovered.type)}
                                expanded
                                windowIndex={`minimized-hovered-${minimizeHovered.index}`}
                                className="padding-top-1 height-10"
                            />
                        </div>
                    )}
                    {minimized.map((minimizeItem, i) => (
                        <IconButton
                            key={minimizeItem.index}
                            className={`minimizeItem height-2 width-7 margin-right-1 grow-hover ${getIconColors(minimizeItem.type)} shadow-2`}
                            onClick={() => workspaceDispatch({ type: 'UNMINIMIZE_WINDOW', value: minimizeItem.index })}
                            onMouseOver={() => handleMinimizeHover(minimizeItem, i)}
                            onMouseLeave={handleMinimizeLeave}
                        >
                            <Icon className="size-1 min-width-1 margin-right-half" value={minimizeItem.type} />
                            <p className="height-1 width-3 t-small t-clear-transform whitespace-nowrap c-main overflow-hidden">{minimizeItem.title || 'Untitled'}</p>
                        </IconButton>
                    ))}
                </div>
                {minimized.length > 0 && tabs.length > 0 && <div className="border-right margin-right-1"></div>}
                <div className='row-reverse'>
                    {tabHovered && (
                        <div className="fade-in hoveredPreview c-shade-9 width-10 height-10" style={{ right: tabHovered.hoverIndex * 40 + 8 }}>
                            <DocumentPreview id={tabHovered?.documentId} kind={getKindFromIcon(tabHovered.type)} expanded windowIndex={`minimized-hovered-${tabHovered.index}`} className="padding-top-1 height-10" />
                        </div>
                    )}
                    {
                        tabs.map((tab, i) => (
                            <div className="tab-button-wrapper padding-right-half" key={tab.index}>
                                <IconButton
                                    onClick={() => selectTab(tab.index)}
                                    className={`tab-button flex-start border-thick ${selectedTab === tab.index && 'selected'} ${getIconColors(tab.settings.icon)} shadow-2`}
                                    onMouseOver={() => handleTabHover({ documentId: tab.propsPassedFromWindow.documentId, type: tab.settings.icon }, i)}
                                    onMouseLeave={handleTabLeave}
                                >
                                    <Icon className="size-1 min-width-1" value={tab.settings.icon} />
                                    <p className="t-small t-clear-transform whitespace-nowrap tab-button-p c-main overflow-hidden">{tab.settings.title || 'Untitled'}</p>
                                    {tab.settings.broadcasted && <div className="tab-broadcast-icon bg-white c-red border-thin bc-red round"><Broadcast /></div>}
                                    {tab.settings.broadcasting && <div className="tab-broadcast-icon bg-white c-red border-thin bc-red round"><CameraVideoFill /></div>}
                                </IconButton>
                            </div>
                        ))
                    }
                </div>
                <SoundControls />
            </div>
        </div>
    )
}
export default AddItems