import React from 'react'

const UsersAreTyping = ({ usersTyping }) => {
    if (usersTyping.length === 0) return null

    const slicedUsers = usersTyping.slice(0, 3)
    return (
        <div className="usersAreTyping">
            {usersTyping.length > 1 ? (
                <p>
                    {slicedUsers.map((user, i) => {
                        return (
                            <span key={i}>
                                {user}
                                {i < slicedUsers.length - 1 ? ', ' : ' '}
                                {i === slicedUsers.length - 2 && (usersTyping.length === 2 || usersTyping.length === 3) && 'and '}
                            </span>
                        )
                    })}
                    {usersTyping.length > 3 && `and ${usersTyping.length - 3} other${usersTyping.length - 3 > 1 ? 's' : ''}`}
                    {` ${(usersTyping.length - 3 > 1) || usersTyping.length < 4 ? 'are' : 'is'} typing`}
                </p>
            ) : (
                    <p>{usersTyping[0]} is typing</p>
                )}

            <TypingDots />
        </div>
    )
}

export default UsersAreTyping


const TypingDots = () => (
    <div className="typing-dots">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
    </div>
)