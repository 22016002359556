import React, { useEffect, useState } from 'react'
import { useWorkspaceFunctions, useWorkspaceState } from '../../../../context/Workspace'
import './SoundControls.css'
import SoundEntry from './SoundEntry'
import { Button } from '../../../../components/Buttons'
import { Music } from "@styled-icons/boxicons-solid/Music"
import { Slider } from '../../../../components/Slider'
import { useCampaignState } from '../../../../context/Campaign'
import { usePrevious } from '../../../../hooks/usePrevious'

const SoundControls = () => {
    const [open, setOpen] = useState(false)
    const { musicPlaying, muteSounds, globalVolume } = useWorkspaceState()
    const { workspaceDispatch } = useWorkspaceFunctions()
    const { activeCampaign } = useCampaignState()
    const sounds = Object.values(musicPlaying)

    const prevActiveCampaignId = usePrevious(activeCampaign?._id)
    useEffect(() => {
        if (activeCampaign?._id !== prevActiveCampaignId) {
            sounds.forEach(sound => {
                workspaceDispatch({ type: 'REMOVE_PLAYING_SONG', value: sound })
            })
        }
    })

    const handleRemoveClick = (music) => {
        workspaceDispatch({ type: 'REMOVE_PLAYING_SONG', value: music })
    }

    const handleSetUnmute = () => {
        workspaceDispatch({ type: 'SET_MUSIC_MUTED', value: false })
    }

    const setGlobalVolume = (vol) => {
        workspaceDispatch({ type: 'SET_GLOBAL_VOLUME', value: vol })
    }



    return sounds.length ? (
        <div className={`soundControls ${muteSounds && 'muted'} ${open ? 'open bc-shade-2 bg-white c-shade-6' : 'c-main bg-dark-cyan bc-dark-blue'}`} onClick={() => setOpen(!open)}>
            {muteSounds ? (
                <div className="full-width full-height col centered">
                    <p className='t-center margin-vertical-1'>Someone is playing sounds for the campaign.</p>
                    <Button className="red margin-bottom-1" onClick={handleSetUnmute}>Play Sounds</Button>
                </div>
            ) : (
                <>
                    <div className='soundControlsTitleContainer row space-between centered margin-bottom-half'>
                        <p className="t-normal t-bold">{sounds.length} Sounds Playing</p>
                        <Music className="music-icon size-1" />
                    </div>
                    <div className="soundControlsList full-width col border-top-thin bc-shade-2 padding-top-half padding-horizontal-1">
                        <p className="t-small">Local Volume</p>
                        <Slider
                            min={0}
                            max={100}
                            value={globalVolume * 100}
                            className="full-width margin-0 height-quarter margin-vertical-1"
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            onChange={(e) => {
                                setGlobalVolume(e.target.value / 100)
                            }}
                        />

                    </div>
                    <div className='height-12 border-top margin-top-half padding-top-half bc-shade-2 padding-horizontal-1 overflow-auto'>
                        <p className="t-small margin-bottom-half">Shared Controls</p>
                        {sounds.map(music => (
                            <SoundEntry key={music.id} sound={music} onRemoveClick={handleRemoveClick} />
                        ))}
                    </div>
                    <div className='soundControlsListFade'></div>
                </>
            )}
        </div>
    ) : null
}

export default SoundControls
