import React from 'react'
import DocumentToolbar from '../../DocumentToolbar'
import { ToolBarSeparator } from '../../DocumentToolbar'
import Toggle from '../../Toggle/Toggle'
import { MusicNoteBeamed } from '@styled-icons/bootstrap/MusicNoteBeamed'
import { EditAlt } from '@styled-icons/boxicons-regular/EditAlt'
import TextInput from '../../TextInput'
import Loading from '../../Loading'

const TopToolBar = ({ id, searchLoading, windowIndex, setEditMode, search, setSearch, saving, isFullScreen, broadcasted, broadcasting }) => {
    return (
        <DocumentToolbar
            windowIndex={windowIndex}
            saving={saving}
            isFullScreen={isFullScreen}
            documentId={id}
            broadcasted={broadcasted}
            broadcasting={broadcasting}
        >
            <div className="row full-width height-2 hidden-phone centered-vertically">
                <Toggle
                    entries={['Play Sounds', 'Add Sounds']}
                    entryIcons={[
                        <MusicNoteBeamed className="size-1" />,
                        <EditAlt className="size-1" />,
                    ]}
                    setValue={setEditMode}
                />
                <ToolBarSeparator />
                <div className='row centered'>
                    <TextInput
                        className="soundboard-search full-width"
                        inputClassName="padding-quarter padding-left-half"
                        placeholder="Search..."
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    {searchLoading && <Loading className="size-1 margin-left-1" />}
                </div>
            </div>
        </DocumentToolbar>
    )
}

export default TopToolBar
