import React, { useEffect, useState, useRef } from 'react'
import { getHexFromUserColor, getStartingData } from '../../functions/helpers'
import TopToolBar from './partials/TopToolBar'
import Quill from 'quill'
import QuillCursors from 'quill-cursors'
import ShareDB from '../../functions/ShareDB'
import './NoteEditor.css'
import 'quill/dist/quill.snow.css'
import { useWorkspaceFunctions } from '../../context/Workspace'
import { useCampaignState } from '../../context/Campaign'
import { useAuthState } from '../../context/Auth'

Quill.register('modules/cursors', QuillCursors)

const NoteEditor = ({
    windowIndex,
    saving,
    isFullScreen,
    id,
    initialValue,
    broadcasted,
    broadcasting,
    hideToolbar,
}) => {
    const startingData = getStartingData(initialValue, 'note')
    const cursorsRef = useRef(null)
    const { workspaceDispatch } = useWorkspaceFunctions()
    const { usersInDocuments } = useCampaignState()
    const { user } = useAuthState()
    const [cursors, setCursors] = useState()


    useEffect(() => {
        const connection = ShareDB.connect()
        const doc = connection.get('sharedDocData', id)
        const presence = connection.getDocPresence('sharedDocData', id)
        let localPresence
        let quill

        const onTextChange = (delta, oldDelta, source) => {
            if (source !== 'user') return
            workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
            doc.submitOp(delta, { source: quill })
        }

        const onSelectionChange = (range, oldRange, source) => {
            if (localPresence) localPresence.submit(range)
        }

        const onOp = function (op, source) {
            workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saved' } })
            if (source === quill) {
                return
            }
            quill.updateContents(op)
        }

        const onRecievePresence = (id, value) => {
            if (cursorsRef.current) cursorsRef.current.moveCursor(id, value)
        }

        const setupQuill = (oldData) => {
            quill = new Quill(`#quill-editor-${windowIndex}`, {
                modules: {
                    toolbar: hideToolbar ? null : `#quill-toolbar-${windowIndex}`,
                    cursors: true,
                },
                placeholder: 'Compose an epic...',
            })

            try {
                cursorsRef.current = quill.getModule('cursors')
                setCursors(cursorsRef.current)
            } catch (e) {
                console.log('quill error', e)
            }

            workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saved' } })
            if (!oldData) quill.setContents(doc.data)

            quill.on('text-change', onTextChange)
            quill.on('selection-change', onSelectionChange)
            doc.on('op', onOp)

            if (oldData) doc.submitOp(oldData)
        }

        presence.subscribe(err => {
            localPresence = presence.create(user._id)
        })

        presence.on('receive', onRecievePresence)
        presence.on('error', err => console.log(err))

        doc.subscribe((err) => {
            if (err) console.log(err)

            if (!doc.type) {
                doc.create({}, 'rich-text', (error) => {
                    if (error) console.error(error)
                    setupQuill(startingData)
                })
            } else {
                setupQuill()
            }
        })

        return () => {
            doc.unsubscribe()
            doc.off('op', onOp)
            presence.off('recieve', onRecievePresence)

            if (quill) {
                quill.off('text-change', onTextChange)
                quill.off('selection-change', onSelectionChange)
            }
        }
    }, [hideToolbar, id, startingData, user._id, windowIndex, workspaceDispatch])

    useEffect(() => {
        const usersInThisDoc = usersInDocuments[id]
        if (usersInThisDoc?.length) {
            if (cursors) {
                cursors.clearCursors()
                usersInThisDoc.forEach(user => {
                    cursors.createCursor(user._id, user.name, getHexFromUserColor(user.color), null)
                })
            }
        }
    }, [broadcasted, broadcasting, cursors, id, usersInDocuments])

    return <div className={`noteEditor full-width col full-height overflow-y-auto`}>
        {hideToolbar ? null : <TopToolBar
            id={id}
            toolbarId={`quill-toolbar-${windowIndex}`}
            isFullScreen={isFullScreen}
            saving={saving}
            windowIndex={windowIndex}
            broadcasting={broadcasting}
            broadcasted={broadcasted}
        />}
        <div id={`quill-editor-${windowIndex}`}></div>
    </div>
}

export default NoteEditor