import { addNotification } from "./addNotification"

export function enterCampaign(campaign, userId, openWaitingUsersWindow) {
    this.dispatch({ type: "ENTER_CAMPAIGN", value: { campaign, userId } })

    if (campaign.usersAwaitingInvite.length > 0 && campaign.owner._id === userId) {
        openWaitingUsersWindow()
    }

    addNotification.call(this, `Welcome to ${campaign.title}`, 'entered-campaign')
}