import shortid from "shortid"

export default function createDiceObject(key, position) {
    const dice = this.dice['local'][key]
    if (dice) {
        const newDie = dice.clone()
        newDie.textureKey = dice.textureKey
        newDie.traverse((node) => {
            if (node.isMesh) {
                node.material = node.material.clone()
            }
        })

        newDie.type = key

        newDie.rollingId = shortid.generate()
        newDie.name = newDie.rollingId
        newDie.finishingTimer = null
        newDie.finishingTimerMax = null

        if (position) {
            newDie.position.x = position.x
            newDie.position.y = position.y
        } else {
            newDie.position.x = this.originX
            newDie.position.y = this.originY
        }

        newDie.rotationOriginX = Math.random() * 180
        newDie.rotationOriginY = Math.random() * 180
        newDie.rotationOriginZ = Math.random() * 180

        this.scene.add(newDie)

        return newDie
    }
}