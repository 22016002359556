import React from 'react'

const Auras = ({ scale, labels, ww, hh, gridSize, centered }) => {
    return labels?.map((label, i) => {
        const diffFromGridSize = (((scale / 4) * gridSize) - gridSize) || 0
        return (
            <Ring key={i} {...{ i, ww, hh, diffFromGridSize, centered, auraWidth: (label.thickness * gridSize) + diffFromGridSize, color: label.color, zIndex: labels.length }} />
        )
    }) || null
}

export default Auras


const Ring = ({ auraWidth, centered, color, ww, hh, diffFromGridSize }) => {
    return (
        <div className="aura" style={{
            position: 'absolute',
            top: -auraWidth / 2 + (centered ? 0 : diffFromGridSize / 2),
            left: -auraWidth / 2 + (centered ? 0 : diffFromGridSize / 2),
            width: ww + auraWidth,
            height: hh + auraWidth,
            backgroundColor: color,
        }}></div>
    )
}