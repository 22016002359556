import React from 'react'
const PDFCheckbox = ({ style, value, initialValue, setValue }) => {
    return (
        <input
            className="PDFField PDFCheckbox"
            type="radio"
            style={style}
            checked={(initialValue && initialValue !== "Off") || !!value}
            onClick={() => setValue(value ? false : true)}
            onChange={((e) => setValue(e.target.checked))}
        />
    )
}

export default PDFCheckbox
