import React from 'react'
import NothingToSee from '../../../components/NothingToSee'

const Media = () => (
  <div className="centered full-height">
    <NothingToSee message="The media component is coming very soon!" />
  </div>
)

export default Media
