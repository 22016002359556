import React, { useReducer, useContext, useMemo } from 'react'
import reducer from './reducer'
import buildFunctions from './buildFunctions'

const CampaignStateContext = React.createContext()
const CampaignFunctionsContext = React.createContext()

const CampaignProvider = ({ children }) => {
    const initialState = {
        activeCampaign: null,
        waitingForInviteId: null,
        inviteAccepted: null,
        messages: [],
        connectedUsers: [],
        invalidCampaignId: null,

        socket: null,
        notifications: [],
        usersTyping: [],
        recentChatHistory: [],
        iceServers: [],

        dice: null,
        diceReady: false,
        defaultDie: 20,
        addDice: null,
        popDice: null,
        removeDice: null,
        unreads: 0,
        pastRolls: [],
        inProgressRolls: [],
        usersInDocuments: {},
        recentlyDisconnectedUsers: [],
        pingQueue: [],
        modalState: {}
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <CampaignStateContext.Provider value={state}>
            <CampaignFunctionsContext.Provider value={dispatch}>
                {children}
            </CampaignFunctionsContext.Provider>
        </CampaignStateContext.Provider>
    )
}

function useCampaignState() {
    const state = useContext(CampaignStateContext)
    if (typeof state === undefined) {
        throw new Error('useCampaignState must be used within an CampaignProvider')
    }

    return state
}


function useCampaignFunctions() {
    const dispatch = useContext(CampaignFunctionsContext)
    if (typeof dispatch === undefined) {
        throw new Error('useCampaignUpdater must be used within an CampaignProvider')
    }
    const functions = useMemo(() => buildFunctions(dispatch), [dispatch])

    return functions
}

export {
    CampaignProvider,
    useCampaignState,
    useCampaignFunctions
}