export default function get2DPositionFrom3d(object, camera) {
    const width = window.innerWidth, height = window.innerHeight
    const widthHalf = width / 2, heightHalf = height / 2

    const pos = object.position.clone()
    pos.project(camera)
    pos.x = (pos.x * widthHalf) + widthHalf
    pos.y = - (pos.y * heightHalf) + heightHalf

    return { x: pos.x, y: pos.y }
}