export default function removeGroup(group) {
    if (!group) return

    const groupIndex = this.rollingGroups.findIndex(searchGroup => searchGroup.id === group.id)

    group.dice.forEach(die => {
        const dieIndex = this.finishedDice.findIndex(finishedDie => finishedDie.rollingId === die.rollingId)
        this.finishedDice.splice(dieIndex, 1)
        this.removeDiceFromWorld(die)
    })

    this.rollingGroups.splice(groupIndex, 1)

    clearTimeout(group.deathTimer)
    group = null
    if (this.rollingGroups.length === 0 && this.selectedDice.length === 0 && this.finishedDice.length === 0 && this.rolledDice.length === 0) {
        this.stopScene()
    }
}