import React, { useCallback, useEffect, useState } from 'react'

import { useInventoryState } from '../../context/Inventory'
import { TextButton } from '../Buttons'
import TextInput from '../TextInput'
import useCreateFolder from '../../hooks/useCreateFolder'
import FoldersList from './FoldersList'
import Loading from '../Loading'

const Items = ({ openChestWindow }) => {
    const { folders } = useInventoryState()

    const openAddItemWindow = useCallback(() => {
        openChestWindow('newNote', null)
    }, [openChestWindow])

    const openDocumentWindow = useCallback((doc) => {
        const { name, kind, _id, owner, data } = doc
        openChestWindow(kind, { title: name, uniqueIndex: `document-${_id}` }, {
            documentId: _id,
            documentOwnerId: owner?._id || owner,
            documentName: name,
            oldData: data,
            kind
        })
    }, [openChestWindow])

    const [loading, setLoading] = useState(false)
    const createFolder = useCreateFolder(setLoading)


    const [inventoryReady, setInventoryReady] = useState(false)
    useEffect(() => {
        if (!inventoryReady) {
            const defaultFolder = folders.find(folder => folder.isDefaultFolder)
            if (defaultFolder?.documents) {
                setInventoryReady(true)
            }
        }
    }, [folders, inventoryReady])

    return (
        <div className="chest-inner-content chest-inner-items col flex-start">
            {loading && <Loading overlay />}
            <div className={`full-width padding-left-1 padding-right-1 header row full-width space-between padding-top-1 padding-bottom-1 ${!inventoryReady && 'disabled'}`}>
                <TextInput className="chestItemSearch full-width" inputClassName="padding-half" placeholder="Search Documents" />
                <TextButton className="blue margin-left-1" onClick={createFolder}>+&nbsp;Folder</TextButton>
                <TextButton className="blue margin-left-1" onClick={openAddItemWindow}>+&nbsp;Item</TextButton>
            </div>
            <FoldersList
                openDocumentWindow={openDocumentWindow}
                folders={folders}
                setLoading={setLoading}
                draggable
            />
        </div>
    )
}

export default Items