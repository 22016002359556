import React, { useEffect, useState } from 'react'
import { usePrevious } from '../../hooks/usePrevious'

const ChatBadge = ({ count }) => {
    const lastCount = usePrevious(count)
    const [display, setDisplay] = useState(count)
    useEffect(() => {
        if (count < 1) setDisplay(lastCount)
        else if (count > 9) setDisplay(9)
        else {
            setDisplay(count)
        }
    }, [count, lastCount, display, setDisplay])

    return (
        <div className={`chat-badge ${count > 0 && 'active'}`}>
            {display}{count > 9 ? "+" : ""}
        </div>
    )
}

export default ChatBadge
