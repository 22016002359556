import React from 'react'
import PDFCheckbox from './PDFCheckbox'
import PDFTextField from "./PDFTextField"

const PDFField = ({ data, pageHeight, scale, name, value, initialValue, editMode, setValue, onRollableClick, defaultDiceValue }) => {

    const props = {
        isMultiline: data.isMultiline,
        style: {
            position: 'absolute',
            left: data.x * scale,
            top: (pageHeight - data.y) * scale,
            width: data.width * scale,
            height: data.height * scale,
            fontSize: `${10 * scale}px`,
            textAlign: data.alignment
        },
        name,
        value,
        initialValue,
        setValue,
        editMode,
        onRollableClick,
        defaultDiceValue
    }

    switch (data.type) {
        case 'text': return <PDFTextField {...props} />
        case 'checkbox': return data.width > 50 ? null : <PDFCheckbox {...props} />
        default: return null
    }
}

export default PDFField