import React from 'react'
import Blobs from '../Blobs'
const Notes = ({ className, clutter }) => {
    return (
        <div className={`landing-feature landing-notes ${className}`} data-scroll>

            <div className="feature-img-container">
                <Blobs />
                <img src="/examples/notes-example@2x.png" alt="example of notes editor" />
            </div>

            {clutter.map((item, i) => (
                <img className="clutter-item" key={i} src={item.url} alt='' />
            ))}

            <div className="feature-text-container">
                <div className="feature-text-inner">

                    <h2>Notes</h2>
                    <p>
                        You and your players can keep track of anything while keeping your eye on the action.
                    </p>
                    <a className="landing-cta" href="/login">Join For Free!</a>
                </div>
            </div>
        </div>
    )
}

export default Notes
