import { randomRange } from '../../../helpers'
import * as THREE from 'three'

export default function getDiceRolingDirection(exploding) {
    let direction, dist
    if (exploding) {
        direction = new THREE.Vector3(randomRange(-1, 1), randomRange(-1, 1), 0)
        dist = randomRange(1, 3)
    } else {
        dist = (this.dragStartPos.distanceTo(this.mousePos) / 16) + 0.5
        if (dist < 0.7 || this.diceTray.active) {
            dist = randomRange(0.5, 1)
            direction = direction || new THREE.Vector3(randomRange(-0.1, -0.8), 0.5, 0)
        } else {
            direction = new THREE.Vector3()
            direction.subVectors(this.mousePos, this.dragStartPos).normalize()
        }
    }

    return { direction, dist }
}