import * as THREE from 'three'

export function getScreenPosIn3dSpace(camera, screenX, screenY, width, height) {
    camera.updateMatrixWorld()
    camera.updateProjectionMatrix()

    const vec = new THREE.Vector3()

    vec.set(
        (screenX / width) * 2 - 1,
        -(screenY / height) * 2 + 1,
        0
    )

    vec.unproject(camera)
    return vec
}