import React, { useEffect, useRef } from 'react'
import { IconButton } from '../../../Buttons/index'
import { drawMarker } from './MarkerFunctions/drawMarker'
import { Close } from '@styled-icons/evaicons-solid/Close'

const MarkerRenderer = ({ drawing, removeDrawing, startDrawingDrag }) => {
    const container = useRef(null)
    const instance = useRef(null)

    useEffect(() => {
        if (instance && drawing.markerBounds) {
            instance.current.width = drawing.markerBounds.width
            instance.current.height = drawing.markerBounds.height

            const ctx = instance.current.getContext("2d")
            drawMarker(ctx, 0, 0, drawing, false, drawing.markerBounds.width, drawing.markerBounds.height)
        }
    }, [drawing])

    return (
        <div
            ref={container}
            className='drawing'
            style={{
                top: `${drawing?.markerBounds?.top || 0}px`,
                left: `${drawing?.markerBounds?.left || 0}px`,
                width: `${drawing?.markerBounds?.width || 0}px`,
                height: `${drawing?.markerBounds?.height || 0}px`,
            }}
            onMouseDown={(e) => {
                if (e.button === 0)
                    startDrawingDrag(e, drawing, container)
            }}
        >
            <canvas ref={instance} className="marker-drawing">
            </canvas>
            <div className="drawing-controls">
                <IconButton className="round bg-shade-1 size-2" onClick={(e) => removeDrawing(drawing.id)}>
                    <Close className="size-2 c-shade-9" />
                </IconButton>
            </div>
        </div>
    )
}

export default MarkerRenderer