import {
    selectCampaign,
    openWindow,
    closeWindow,
    dragWindow,
    maximizeWindow,
    unmaximizeWindow,
    updateView,
    scaleWindow,
    selectWindow,
    selectTab,
    renameWindow,
    setAppDimensions,
    changeWindowColor,
    setDiceOptions,
} from './functions'

export default function buildFunctions(dispatch) {
    return {
        selectCampaign: selectCampaign.bind({ dispatch }),
        openWindow: openWindow.bind({ dispatch }),
        closeWindow: closeWindow.bind({ dispatch }),
        dragWindow: dragWindow.bind({ dispatch }),
        maximizeWindow: maximizeWindow.bind({ dispatch }),
        unmaximizeWindow: unmaximizeWindow.bind({ dispatch }),
        updateView: updateView.bind({ dispatch }),
        scaleWindow: scaleWindow.bind({ dispatch }),
        selectWindow: selectWindow.bind({ dispatch }),
        selectTab: selectTab.bind({ dispatch }),
        renameWindow: renameWindow.bind({ dispatch }),
        setAppDimensions: setAppDimensions.bind({ dispatch }),
        changeWindowColor: changeWindowColor.bind({ dispatch }),
        setDiceOptions: setDiceOptions.bind({ dispatch }),
        workspaceDispatch: dispatch
    }
}