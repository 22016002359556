import GlobalStorage from "../../functions/GlobalStorage"
import { foldersEqual } from "../../functions/helpers"

const reducer = (state, action) => {
    switch (action.type) {
        case 'ADD_CAMPAIGN': {
            const { item } = action.value
            state = { ...state }
            if (!state.campaigns.find(itemInInventory => item._id === itemInInventory._id)) {
                state.campaigns.push(item)
                return { ...state }
            }

            return { ...state }
        }

        case 'SET': {
            state = { ...action.value }
            return { ...state }
        }

        case 'ADD_FOLDER': {
            const newState = { ...state, folders: [...state.folders, action.value] }
            return newState
        }

        case 'SET_FOLDERS': {
            const newState = { ...state, folders: [...action.value] }
            return newState
        }

        case 'UPDATE': {
            let { id, data } = action.value

            const folder = state.folders.find(folder => {
                return folder?.documents?.left?.find?.(doc => doc?._id === id) || folder?.documents?.right?.find?.(doc => doc?._id === id)
            }) || state.folders.find(folder => folder.isDefaultFolder)

            if (folder.documents) {
                const indexLeft = folder?.documents?.left?.findIndex(item => item?._id === id)
                const indexRight = folder?.documents?.right?.findIndex(item => item?._id === id)

                const side = indexLeft > -1 ? 'left' : indexRight > -1 ? 'right' : null
                const index = indexLeft > -1 ? indexLeft : indexRight > -1 ? indexRight : -1

                if (side && index > -1) {
                    const oldData = folder.documents[side][index]
                    folder.documents[side][index] = { ...oldData, ...data }
                    folder.documents[side] = [...folder.documents[side]]
                } else {
                    const shorterSide = (
                        folder.documents.left.length === folder.documents.right.length
                        || folder.documents.left.length < folder.documents.right.length
                    ) ? 'left' : 'right'
                    folder.documents[shorterSide] = [...folder.documents[shorterSide], { _id: id, ...data }]
                }

                const allDocsIndex = state.allDocuments.findIndex(doc => doc?._id === id)
                if (allDocsIndex > -1) {
                    state.allDocuments = [...state.allDocuments]
                    state.allDocuments[allDocsIndex] = { _id: id, ...data }
                } else {
                    state.allDocuments.push({ _id: id, ...data })
                }

                return { ...state }
            } else return state
        }

        case 'REMOVE': {
            const { id, folderId } = action.value
            const folder = state.folders.find(folder => folder._id === folderId)

            if (folder) {
                const indexLeft = folder?.documents?.left?.findIndex(item => item._id === id)
                const indexRight = folder?.documents?.right?.findIndex(item => item._id === id)

                const side = indexLeft > -1 ? 'left' : indexRight > -1 ? 'right' : null
                const index = indexLeft > -1 ? indexLeft : indexRight > -1 ? indexRight : -1

                if (side && index > -1) {
                    folder.documents[side].splice(index, 1)
                }

                //sorting the folder
                const leftLength = folder.documents['left'].length
                const rightLength = folder.documents['right'].length

                if (leftLength > rightLength + 1) {
                    const moveTarget = folder.documents['left'].pop()
                    folder.documents['right'].push(moveTarget)
                }

                if (rightLength > leftLength + 1) {
                    const moveTarget = folder.documents['right'].pop()
                    folder.documents['left'].push(moveTarget)
                }
            }

            const allDocsIndex = state?.allDocuments?.findIndex(doc => doc?._id === id)
            if (allDocsIndex > -1) {
                state.allDocuments = [...state.allDocuments]
                state.allDocuments.splice(allDocsIndex, 1)
            }

            return { ...state }
        }

        case 'UPDATE_SHARED_FOLDER': {
            const folderIndex = state.folders.findIndex(folder => folder._id === action.value._id)
            if (folderIndex > -1) {
                state.folders[folderIndex] = {
                    ...state.folders[folderIndex],
                    sharedCampaigns: action.value.sharedCampaigns,
                    sharedUsers: action.value.sharedUsers,
                }

                if (action.value?.documents) state.folders[folderIndex].documents = action.value.documents
            }
            return { ...state, folders: [...state.folders] }
        }

        case 'APPLY_BATCHED_FOLDER_UPDATE': {
            return {
                ...state,
                folders: [...action.value.folders],
                sharedFoldersLoading: false,
                batchedFolderUpdates: null
            }
        }

        case 'SYNC_UPDATED_SHARED_FOLDER': {
            const draggingPayload = GlobalStorage.get('draggingInventoryPayload')
            const { updatedFolder, user } = action.value

            let newFolders = state.batchedFolderUpdates ? [...state.batchedFolderUpdates.folders] : [...state.folders]
            const folderIndex = newFolders.findIndex(folder => folder._id === updatedFolder._id)
            if (folderIndex > -1) {
                //update
                const oldFolder = newFolders[folderIndex]
                newFolders[folderIndex] = { ...oldFolder, ...updatedFolder }
            } else {
                //add new
                newFolders.push(updatedFolder)
            }

            newFolders = newFolders.filter(currentFolder => (
                currentFolder.owner._id === user._id
                || currentFolder.sharedUsers.find(sharedU => sharedU._id === user._id)
                || currentFolder.sharedCampaigns.find(sharedC => user.campaigns.find(userC => userC._id === sharedC._id))
            ))


            const foldersAreEqual = foldersEqual(newFolders, state.folders)
            if (draggingPayload && !foldersAreEqual) {
                return { ...state, batchedFolderUpdates: { folders: [...newFolders], sharedFoldersLoading: false } }
            } else {
                return { ...state, folders: [...newFolders], sharedFoldersLoading: false, batchedFolderUpdates: null }
            }
        }

        case 'SET_SHARED_FOLDERS_LOADING': {
            return { ...state, sharedFoldersLoading: action.value }
        }

        case 'RENAME_FOLDER': {
            const { folderId, newText } = action.value
            const folderIndex = state.folders.findIndex(folder => folder._id === folderId)
            if (folderIndex > -1) {
                state.folders[folderIndex].name = newText
            }
            return { ...state }
        }

        case 'DELETE_FOLDER': {
            const folderIndex = state.folders.findIndex(folder => folder._id === action.value)
            state.folders.splice(folderIndex, 1)
            return { ...state, folders: [...state.folders] }
        }

        case 'CAMPAIGN_DESTROYED': {
            state.campaigns = [...state.campaigns]

            const campaignIndex = state.campaigns.findIndex(campaign => campaign.shortId === action.value)
            if (campaignIndex > -1) {
                state.campaigns.splice(campaignIndex, 1)
            }

            return { ...state }
        }

        case 'REMOVE_CAMPAIGN_FROM_SHORTID': {
            state['campaigns'] = [...state['campaigns']]
            const itemIndex = state['campaigns'].findIndex(campaign => campaign.shortId === action.value)
            if (itemIndex > -1) {
                state['campaigns'].splice(itemIndex, 1)
            }

            return { ...state }
        }

        default: return state
    }
}

export default reducer
