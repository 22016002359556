import React from 'react'
import Card from '../../../../../../components/Card'
import * as dayjs from 'dayjs'

const UpcomingCharges = ({ nextBill, price }) => {
    return (
        <div className="upcomming-charges-container col margin-bottom-1 full-width">
            <label>Next Bill</label>
            <Card className="upcomming-charges-inner col">
                <p className="upcomming-charge-date margin-left-1">{dayjs(parseInt(nextBill)).format('MM/DD/YY')}</p>
                <p className="upcomming-charge-price margin-left-1">${price}<span>plus taxes</span></p>
            </Card>
        </div>
    )
}

export default UpcomingCharges
