import API from "../../../functions/API"
export async function deleteFolder(auth, folderId, setLoading, setError) {
    try {
        setLoading(true)
        const result = await API.sendGraphSecure(auth, {
            query: `mutation deleteFolder($folderId: ID!) {
                    deleteFolder(folderId: $folderId)
                }`,
            variables: {
                "folderId": folderId,
            }
        })

        this.dispatch({
            type: 'DELETE_FOLDER', value: folderId
        })

        setLoading(false)
        return result.data.deleteFolder
    } catch (err) {
        setError(err.message)
    }
}