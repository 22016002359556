import { useCallback } from 'react'

const useAddDocument = (selectedToken, modalState, setModalState, editSingleToken, editSelectedToken) => {
    const addDocument = useCallback((doc, multi) => {
        if (multi) {
            const newTokens = selectedToken.map(token => {
                const documents = token.documents.find(oldDoc => oldDoc._id === doc._id) ? (
                    token.documents
                ) : [...(token.documents || []), { _id: doc._id, kind: doc.kind, ownerId: doc.documentOwnerId }]

                return editSingleToken({
                    documents
                }, token)
            })
            setModalState({ ...modalState, selectedToken: newTokens })
        } else {
            const selectedTokenDocuments = selectedToken.documents || []
            const documents = selectedTokenDocuments.find(oldDoc => oldDoc._id === doc._id) ? (
                selectedToken.documents
            ) : [...selectedTokenDocuments, { _id: doc._id, kind: doc.kind, ownerId: doc.documentOwnerId }]

            editSelectedToken({ documents })
        }
    }, [editSelectedToken, editSingleToken, modalState, selectedToken, setModalState])

    return addDocument
}

export default useAddDocument