import React from 'react'

import DocumentListItem from '../../../DocumentListItem/DocumentListItem'
import HiddenList from '../../../List/HiddenList'
import NothingToSee from '../../../NothingToSee/NothingToSee'
import AddDocumentView from './AddDocumentView'


const Documents = ({ addDocument, selectedToken, documentId, removeDocument }) => {
    const { documents } = selectedToken

    return (
        <div className="col margin-top-1 margin-bottom-1 full-width overflow-none space-around">
            <HiddenList defaultOpen={selectedToken.documents} title="Items">
                <div className="col margin-top-1 padding-horizontal-half full-width">
                    <div className="col centered margin-bottom-1 full-width">
                        {selectedToken?.documents?.length ? (
                            selectedToken.documents?.map(document => {
                                return (
                                    <DocumentListItem
                                        key={document._id}
                                        id={document._id}
                                        kind={document.kind}
                                        ownerId={document.ownerId}
                                        onDelete={() => removeDocument(document._id)}
                                    />
                                )
                            })
                        ) : (
                            <NothingToSee className="full-width" />
                        )}
                    </div>
                </div>
            </HiddenList>
            <HiddenList title="Add Items">
                <AddDocumentView addDocument={addDocument} existingDocs={documents} thisDocId={documentId} />
            </HiddenList>
        </div>
    )
}

export default Documents