import React, { useState, useEffect } from 'react'
import Header from './Header'
import Main from './Main'
import features from './features/features'
import Footer from './Footer'
import GooFilter from './GooFilter'

import ScrollOut from 'scroll-out'

import './Landing.css'
import './Nav.css'
import './Features.css'
import './Blobs.css'

const Landing = () => {
    const [navMenuOpen, setNavMenuOpen] = useState(false)

    useEffect(() => {
        scrollOutWithErrors()
    }, [])

    return (
        <div className={`landing-container ${navMenuOpen && 'navMenuOpen'}`}>
            <Header navMenuOpen={navMenuOpen} setNavMenuOpen={setNavMenuOpen} />

            <Main />

            <div id="landing-features" className="landing-features">
                {features.map((feature, i) => {
                    return React.cloneElement(feature.view, {
                        className: `of-${features.length}`,
                        key: i, ...feature.props
                    })
                })}
            </div>

            <Footer />

            <GooFilter />
        </div>
    )
}

export default Landing


const scrollOutWithErrors = () => {
    try {
        ScrollOut()
    } catch (err) {
        console.log(err);
    }
}