import { useCallback } from 'react'
import { useAuthState } from '../../../context/Auth'
import { useErrorFunctions } from '../../../context/Error'
import { useWorkspaceFunctions } from '../../../context/Workspace'
import API from '../../../functions/API'
import { submitOp } from '../../../functions/helpers'
import ImageAPI from '../../../functions/ImageAPI'

const useUploadPDF = (setLoadingOverlay, doc, data, documentId) => {
    const { auth } = useAuthState()
    const { setError } = useErrorFunctions()
    const { workspaceDispatch } = useWorkspaceFunctions()

    const uploadPDF = useCallback(async (files) => {
        setLoadingOverlay(true)
        try {
            const uploadResult = await ImageAPI.uploadPDF(files)
            await API.sendGraphSecure(auth, {
                query: `mutation {
                    saveImageToUser(url: "${uploadResult.url}", size: ${uploadResult.size})
                }`
            })

            submitOp(doc, { p: ['file'], od: data.file, oi: uploadResult.url })
            workspaceDispatch({ type: 'RENAME_WINDOW_FROM_DOC_ID', value: { documentId, title: files[0].name } })
            setLoadingOverlay(false)
        } catch (err) {
            console.log('image upload err', err)
            setError('Error uploading PDF.')
            setLoadingOverlay(false)
        }
    }, [auth, data.file, doc, documentId, setError, setLoadingOverlay, workspaceDispatch])

    return uploadPDF
}

export default useUploadPDF
