export const formatCurrentDrawingLine = (currentDrawing, x2, y2) => {
    if (currentDrawing.tool === 'line') {
        let xA, xB, yA, yB
        let [x1, y1] = currentDrawing.points

        if (x1 > x2) {
            xA = x2
            xB = x1
        } else {
            xA = x1
            xB = x2
        }

        if (y1 > y2) {
            yA = y2
            yB = y1
        } else {
            yA = y1
            yB = y2
        }
        return {
            ...currentDrawing,
            points: [xA, yA, xB, yB]
        }
    } else return {
        ...currentDrawing,
        points: [...currentDrawing.points, x2, y2]
    }
}