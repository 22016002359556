import { checkVulgar } from './'
import { choose } from '../../../functions/helpers'

export function validateForm(fields, setError) {
    try {
        //pull out variables for easy access.
        let { page, email, password, passwordConfirm, name } = fields

        //make sure there are no blank fields on the login page.
        if (page === "login" && (email === "" || password === "")) {
            throw new Error('Fields cannot be blank')
        }


        if (page === "signup" && (email === "" || password === "" || passwordConfirm === "" || name === "")) {
            throw new Error('Fields cannot be blank')
        }


        if ((page === "signup" || (page === "update" && email.length > 0)) && email.match(/^\S+@\S+[.][0-9a-z]+$/) === null) {
            throw new Error('invalid email')
        }

        if (((page === "signup" || page === "update") && (password.length > 0 || passwordConfirm.length > 0)) && password !== passwordConfirm) {
            throw new Error('passwords dont match')
        }

        if ((page === "signup" || (page === "update" && password.length > 0)) && password.length < 4) {
            throw new Error('password too short')
        }

        if ((page === "signup" || (page === "update" && password.length > 0)) && password.match(/([0-9].*[a-zA-Z])|([a-zA-Z].*[0-9])/) === null) {
            throw new Error('password needs letters and numbers')
        }

        if ((page === "signup") && name.replace(" ", "").length === 0) {
            throw new Error('Invalid Name!')
        }

        if ((page === "signup" || (page === "update")) && checkVulgar(name)) {
            throw new Error(choose('That names gonna get you banned.', 'You kiss your mother with that name?', `Cant let you call yourself that sir.`))
        }

        if ((page === "update") && name.replace(" ", "").length === 0 && name.length > 0) {
            throw new Error('Invalid Name!')
        }

        //if no errors have been found return true to confinue processing the form.
        return true
    } catch (err) {
        setError(err.message)
        return false
    }
}