import React, { useEffect, useState } from 'react'
import defaultSounds from '../../../config/default-sounds'
import SoundSelection from './SoundSelection'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../Loading'

const SoundAddSection = ({ windowIndex, onAddClick, search, currentList }) => {
    const [index, setIndex] = useState(1)
    const [sounds, setSounds] = useState(getDefaultSoundData(search, currentList, index))
    const [hasMore, setHasMore] = useState(true)

    useEffect(() => {
        const timeout = setTimeout(() => {
            const newSounds = getDefaultSoundData(search, currentList, index)
            setSounds(newSounds)
            setHasMore(index * 20 <= newSounds.length)
        }, 300)

        return () => clearTimeout(timeout)
    }, [currentList, index, search])

    const fetchData = () => {
        const newIndex = index + 1
        setSounds(getDefaultSoundData(search, currentList, newIndex))
        setIndex(newIndex)
    }

    return (
        <div className="full-width col margin-top-1">
            <InfiniteScroll
                dataLength={sounds.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<Loading className='margin-bottom-1' />}
                endMessage={<p className='margin-bottom-1 t-center t-small'>Thats all folks!</p>}
                scrollableTarget={`window-inner-${windowIndex}`}
            >
                {sounds.map((sound, i) => (
                    <SoundSelection key={`sound-selection-${sound.id}`} sound={sound} onClick={onAddClick} />
                ))}
            </InfiniteScroll>
        </div >
    )
}

export default SoundAddSection

export const getDefaultSoundData = (search, currentList, index, limit = true) => {
    const newSoundsArray = defaultSounds.filter(sound => shouldShowInAdd(sound, search, currentList))
    if (limit) newSoundsArray.length = Math.min(newSoundsArray.length, (index) * 20)
    return newSoundsArray
}

export const shouldShowInAdd = (sound, search, currentList) => {
    if (search === "") return (!currentList.find(current => current.id === sound.id))

    return (
        (sound.title.toLowerCase().includes(search)
            || sound.tags.filter(tag => tag.toLowerCase().includes(search)).length > 0)
        && !currentList.find(current => current.id === sound.id)
    )
}