import React from 'react'
import { useAuthState } from '../../context/Auth'
import ReactMarkdown from "react-markdown"
import * as dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)

const ListMessages = ({ messages, messageClassName }) => {
    const { user } = useAuthState()

    return (
        <div className={`message-list-container ${messageClassName} ${messages.length > 0 && 'full'}`}>
            {messages.map((message, i) => {
                const senderId = message.sender._id || message.sender
                const senderName = message.senderName || message.sender.name
                const senderColor = (message.roll ? message.sender.diceSkin : message.sender.color) || message.color

                const initialsSplit = senderName.split(' ')
                const initials = initialsSplit.length > 1 ? initialsSplit[0][0] + initialsSplit[1][0] : senderName[0]

                return (
                    <div className={`chat-message-container ${senderId === user._id && 'your-message'}`} key={`chat-message-${messages.length - i}`}>
                        {message.roll ? null : (
                            senderId !== user._id ? <div className={`user-icon-circle ${senderColor} chat-message-author-icon`}>{initials}</div> : null
                        )}
                        <div className={`chat-message-inner ${message.roll && 'roll'} ${senderColor}`}>
                            {message.roll ? null : (
                                <div className="chat-message-author">
                                    {senderId === user._id ? "You" : senderName}
                                </div>
                            )}
                            <span className="chat-message-timestamp">{dayjs(message.date).fromNow()}</span>
                            <div className="chat-message c-shade-9">
                                <ReactMarkdown source={message.message} renderers={{ link: LinkRenderer }} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ListMessages


function LinkRenderer(props) {
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
}