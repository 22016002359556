import React, { useCallback, useEffect, useRef } from 'react'
import { globalTokenPlacer_Atom } from '../../../components/MapViewer/atoms'
import { useAtom } from 'jotai'

import './PlacingToken.css'
import GlobalStorage from '../../../functions/GlobalStorage'

const PlacingToken = () => {
    const [tokenPlacer] = useAtom(globalTokenPlacer_Atom)
    const ref = useRef(null)

    const displayScale = 32


    const onMouseMove = useCallback((e) => {
        const placer = ref.current
        if (placer) {
            ref.current.style.left = e.clientX - 16 + 'px'
            ref.current.style.top = e.clientY - 16 + 'px'
        }
    }, [])

    useEffect(() => {
        if (tokenPlacer) {
            if (tokenPlacer?.[0]?.mouseX && tokenPlacer?.[0]?.mouseY) {
                ref.current.style.left = tokenPlacer[0].mouseX - 16 + 'px'
                ref.current.style.top = tokenPlacer[0].mouseY - 16 + 'px'
            }
            window.addEventListener('mousemove', onMouseMove)
        }

        return () => {
            window.removeEventListener('mousemove', onMouseMove)
        }
    }, [onMouseMove, tokenPlacer])

    return tokenPlacer?.length > 0 ? (
        <div ref={ref} className="pasteIndicator rounded">
            {tokenPlacer.map((token, i) => {
                return (
                    <div
                        key={i}
                        ref={ref}
                        className="placingToken"
                        style={{
                            left: (token.x ?? 0) - (token?.center?.x ?? 0),
                            top: (token.y ?? 0) - (token?.center?.y ?? 0),
                            width: displayScale,
                            height: displayScale,
                            backgroundImage: `url(${token.src || GlobalStorage.get('TOKEN_ICON_MISSING').src})`,
                        }}
                    ></div>
                )
            })}
        </div>
    ) : null
}

export default PlacingToken