import React, { useEffect, useState } from 'react'
import DocumentToolbar, { ImageUploadButton, ToolBarSeparator } from '../../DocumentToolbar'
import API from '../../../functions/API'
import ImageAPI from '../../../functions/ImageAPI'

import { GridOn, GridOff } from '@styled-icons/material/'
import { CopperCoin } from '@styled-icons/remix-fill/CopperCoin'
import { Pointer } from '@styled-icons/boxicons-solid/Pointer'
import { ImageAdd } from '@styled-icons/boxicons-regular/ImageAdd'
import { CloudFill, CloudSlash } from '@styled-icons/bootstrap/'
import { ScissorsCut } from '@styled-icons/remix-fill/ScissorsCut'
// import { RoundedCorner } from '@styled-icons/material/RoundedCorner'
import { PaintBucket } from '@styled-icons/fluentui-system-filled/PaintBucket'
import { Ruler } from '@styled-icons/entypo/Ruler'
import { Target } from '@styled-icons/fluentui-system-filled/Target'
import { TrashBin } from '@styled-icons/ionicons-sharp/TrashBin'

import { IconButton } from '../../Buttons'
import { useAuthState } from '../../../context/Auth'
import { useErrorFunctions } from '../../../context/Error'
import { useInventoryFunctions } from '../../../context/Inventory'
import { submitOp } from '../../../functions/helpers'
import { useWorkspaceFunctions } from '../../../context/Workspace'
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown'

import { Marker } from '@styled-icons/fa-solid/Marker'
import { Circle, Square } from '@styled-icons/boxicons-regular/'
import { Slash } from '@styled-icons/fa-solid/Slash'
import { DocumentAdd } from '@styled-icons/fluentui-system-filled/DocumentAdd'
import { globalTokenPlacer_Atom } from '../atoms'
import { useAtom } from 'jotai'
import shortid from 'shortid'
import Toggle from '../../Toggle/Toggle'

const TopToolBar = ({
    id, isOwner, showGrid, fogEnabled, paint, image, hasFogCuts,
    doc, tool, setTool, modalState, toggleModal, drawToolSettings,
    pingColor, setLoading, isFullScreen, saving, windowIndex, broadcasted,
    broadcasting, setShareModalOpen, setCamera, setShowGridScaler, gridSize,
    windowPos, gridTop, gridLeft
}) => {
    const { auth, user } = useAuthState()
    const { setError } = useErrorFunctions()
    const { removeImagesFromUser } = useInventoryFunctions()
    const { workspaceDispatch } = useWorkspaceFunctions()
    const [, setTokenPlacer] = useAtom(globalTokenPlacer_Atom)

    const [usersPaints, setUsersPaints] = useState([])
    useEffect(() => {
        setUsersPaints(paint?.filter(paint => paint.userId === user._id || !paint.userId) || [])
    }, [paint, user._id])

    return (
        <DocumentToolbar
            windowIndex={windowIndex}
            saving={saving}
            isFullScreen={isFullScreen}
            broadcasted={broadcasted}
            broadcasting={broadcasting}
            documentId={id}
            setShareModalOpen={setShareModalOpen}
        >
            <div className="row full-width centered space-between height-2 hidden-phone">
                <div className="row centered">
                    <IconButton onClick={() => setTool('select')} alt="Select Tool (V)">
                        <Pointer className={`size-1 ${tool === 'select' ? 'c-dark-blue' : 'c-shade-6'}`} />
                    </IconButton>

                    <IconButton onClick={() => setTool('measure')} alt="Measure">
                        <Ruler className={`size-1 ${tool === 'measure' ? 'c-dark-blue' : 'c-shade-6'}`} />
                    </IconButton>

                    <div className={`row padding-vertical-half border-bottom bc-${drawToolSettings.color}`}>
                        <IconButton className="padding-right-0" onClick={() => setTool('draw')} alt="Draw">
                            <DrawIcon tool={tool} drawTool={drawToolSettings.tool} />
                        </IconButton>
                        <IconButton className="padding-left-0" onClick={() => { setTool('draw'); toggleModal('left', 'draw-settings', 256) }}>
                            <ChevronDown className={`size-1 c-shade-6`} />
                        </IconButton>
                    </div>

                    {
                        usersPaints.length ? (
                            <>
                                <IconButton onClick={() => {
                                    workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
                                    submitOp(doc, { p: ['paint'], od: paint, oi: paint.filter(paint => paint.userId !== undefined && paint.userId !== user._id) })
                                }} alt="Wipe Paint">
                                    <TrashBin className={`size-1 c-red`} />
                                </IconButton>
                                < ToolBarSeparator />
                            </>
                        ) : null
                    }

                    <div className={`row padding-vertical-half border-bottom bc-${pingColor}`}>
                        <IconButton className="padding-right-0" onClick={() => setTool('ping')} alt="Ping">
                            <Target className={`size-1 ${tool === 'ping' ? 'c-dark-blue' : 'c-shade-6'}`} />
                        </IconButton>
                        <IconButton className="padding-left-0" onClick={() => { setTool('ping'); toggleModal('left', 'ping-settings', 256) }}>
                            <ChevronDown className={`size-1 c-shade-6`} />
                        </IconButton>
                    </div>

                    <ToolBarSeparator />

                    <IconButton alt="Add Token" onClick={() => toggleModal('left', 'tokens', 256)}>
                        <CopperCoin className={`size-1 ${modalState.open && modalState.type === 'tokens' ? 'c-dark-blue' : 'c-shade-6'}`} />
                    </IconButton>

                    <IconButton alt="Add Document" onClick={() => setTokenPlacer([{ id: shortid.generate(), type: 'document' }])}>
                        <DocumentAdd className={`size-1 ${tool === 'ping' ? 'c-dark-blue' : 'c-shade-6'}`} />
                    </IconButton>
                    {isOwner && (
                        <>
                            <ImageUploadButton
                                id={id}
                                icon={<ImageAdd className="size-1 c-shade-6 margin-horizontal-quarter" />}
                                uploadImage={(e) => {
                                    workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
                                    const onFail = () => workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saved' } })
                                    addImage(e, auth, user._id, image, doc, removeImagesFromUser, setLoading, onFail, setError)
                                }}
                                alt="Add Image"
                            />

                            <ToolBarSeparator />

                            <Toggle
                                value={fogEnabled ? 1 : 0}
                                entries={['fog-off', 'fog-on']}
                                entryIcons={[
                                    <CloudSlash className="size-1" />,
                                    <CloudFill className="size-1" />,
                                ]}
                                setValue={(val) => {
                                    const newFogEnabled = val === 'fog-on'
                                    workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
                                    submitOp(doc, {
                                        p: ['fogEnabled'],
                                        od: fogEnabled,
                                        oi: newFogEnabled
                                    })

                                    if (tool === 'cut-fog' || tool === 'wall') setTool('select')
                                }}
                            />

                            {fogEnabled && (
                                <>
                                    <IconButton alt="Cut Fog Tool" onClick={() => setTool('cut-fog')}>
                                        <ScissorsCut className={`size-1 ${tool === 'cut-fog' ? 'c-dark-blue' : 'c-shade-6'}`} />
                                    </IconButton>

                                    {/* <IconButton alt="Wall Tool" onClick={() => setTool('wall')}>
                                        <RoundedCorner className={`size-1 ${tool === 'wall' ? 'c-dark-blue' : 'c-shade-6'}`} />
                                    </IconButton> */}

                                    {
                                        hasFogCuts && (
                                            <IconButton alt="Fill Fog" onClick={() => setTool('fog-fill')}>
                                                <PaintBucket className={`size-1 ${tool === 'fog-fill' ? 'c-dark-blue' : 'c-shade-6'}`} />
                                            </IconButton>
                                        )
                                    }
                                </>
                            )}
                        </>
                    )}
                </div>

                {isOwner && <div className="row centered">
                    <IconButton onClick={() => {
                        toggleModal('right', 'grid', 256)
                        setShowGridScaler(true)
                        setCamera((camera) => ({ ...camera, x: (windowPos.width - 275) / 2, y: windowPos.height / 2, zoom: 1 }))
                    }}>
                        {showGrid ? (<GridOn className="size-1 c-shade-6" />) : (<GridOff className="size-1 c-shade-6" />)}
                    </IconButton>
                </div>}
            </div>
        </DocumentToolbar >
    )
}

export default TopToolBar

const addImage = async (e, auth, userId, image, doc, removeImagesFromUser, setLoading, onFail, setError) => {
    try {
        setLoading(true)
        const imageResult = await ImageAPI.upload(e, null)
        await API.sendGraphSecure(auth, {
            query: `mutation {
                saveImageToUser(url: "${imageResult.url}", size: ${imageResult.size})
            }`
        })

        if (image) {
            removeImagesFromUser(auth, userId, [image], setError)
        }

        if (imageResult.url) {
            submitOp(doc, { p: ['image'], od: image, oi: imageResult.url })
        }
        setLoading(false)
    } catch (err) {
        setError(err)
        setLoading(false)
        onFail()
    }
}

const DrawIcon = ({ tool, drawTool }) => {
    switch (drawTool) {
        case 'marker':
            return <Marker className={`size-1 ${tool === 'draw' ? 'c-dark-blue' : 'c-shade-6'}`} />
        case 'square':
            return <Square className={`size-1 ${tool === 'draw' ? 'c-dark-blue' : 'c-shade-6'}`} />
        case 'circle':
            return <Circle className={`size-1 ${tool === 'draw' ? 'c-dark-blue' : 'c-shade-6'}`} />
        case 'line':
            return <Slash className={`size-1 ${tool === 'draw' ? 'c-dark-blue' : 'c-shade-6'}`} />
        default: return null
    }
}