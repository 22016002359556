import React from 'react'

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31.248" height="27.432" viewBox="0 0 31.248 27.432">
            <path id="Path_1407" data-name="Path 1407" d="M3903.234,2380.354l10.687,8.664,12.339-18.682" transform="matrix(0.978, 0.208, -0.208, 0.978, -3318.859, -3127.595)" fill="none" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CheckIcon
