import React from 'react'
import './SubscriptionSettings.css'

import { BillingHistory, UpcomingCharges } from './'
import YourSub from './partials/YourSub'
import PaymentInfo from './partials/PaymentInfo'
import { useAuthFunctions, useAuthState } from '../../../../../context/Auth'
import PremiumPromo from './partials/PremiumPromo'

import { useWorkspaceFunctions } from '../../../../../context/Workspace'
import stripeConfig from '../../../../../config/stripe'
import API from '../../../../../functions/API'
import { useLoadingFunctions, useLoadingState } from '../../../../../context/Loading'
import { loadStripe } from '@stripe/stripe-js'
import { useErrorFunctions } from '../../../../../context/Error'
import Loading from '../../../../../components/Loading'
import Card from '../../../../../components/Card'
import useOpenWindow from '../../../../../hooks/useOpenWindow'
import PremiumSignup from '../../PremiumSignup'

const stripeEnv = (window.location.hostname.includes('qa.') || window.location.hostname.includes('localhost')) ? 'qa' : 'production'
const stripePromise = loadStripe(stripeConfig.pk[stripeEnv])

const SubscriptionSettings = ({ windowIndex }) => {
    const { auth, user } = useAuthState()
    const { authDispatch } = useAuthFunctions()
    const { closeWindow } = useWorkspaceFunctions()
    const openWindow = useOpenWindow()
    const { setError } = useErrorFunctions()

    const { loading } = useLoadingState()
    const { setLoading } = useLoadingFunctions()

    const openSubSignupWindow = () => {
        openWindow('premiumSignup', null)
        closeWindow(windowIndex)
    }

    const onUpdatePayment = async () => {
        try {
            setLoading('premium-settings', true)
            const stripe = await stripePromise
            const response = await API.sendGraphSecure(auth, {
                query: `mutation {
                            createCheckout(subPath: "${window.location.pathname}", mode: "setup", customer: "${user.subscription.stripeCustomerId}")
                        }`
            })

            const sessionId = response.data.createCheckout
            await stripe.redirectToCheckout({
                sessionId: sessionId
            })
        } catch (err) {
            console.log(err)
            setLoading(false)
            setError('There was an error updating your payment.')
        }
    }

    const onCancelSub = () => {
        openWindow('confirm', null, {
            message: `Are you sure you want to cancel your premium?`,
            confirmCallback: async () => {
                try {
                    setLoading('premium-settings', true)
                    const response = await API.sendGraphSecure(auth, {
                        query: `mutation {
                                    cancelSubscription(customerId: "${user.subscription.stripeCustomerId}")
                                }`
                    })

                    authDispatch({ type: 'UPDATE_USER_SUBSCRIPTION_EXPIRATION', value: { expirationDate: response.data.cancelSubscription, nextBill: null } })
                    setLoading(false)
                } catch (err) {
                    console.log(err)
                    setLoading(false)
                    setError('There was an error canceling your subscription.')
                }
            }
        })
    }

    const onReactivateSub = async () => {
        try {
            setLoading('premium-settings', true)
            const response = await API.sendGraphSecure(auth, {
                query: `mutation {
                            reactivateSubscription(customerId: "${user.subscription.stripeCustomerId}")
                        }`
            })

            authDispatch({ type: 'UPDATE_USER_SUBSCRIPTION_EXPIRATION', value: { expirationDate: null, nextBill: response.data.reactivateSubscription } })
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
            setError('There was an error reactivating your subscription.')
        }
    }

    const subscription = user.subscription
    const subscriptionType = subscription?.price ? stripeConfig.priceIds[stripeEnv][subscription.price] : 'legacy'
    const price = subscriptionType === 'legacy' ? '0' : subscriptionType === 'premium-yearly' ? '95.99' : '9.99'
    const interval = subscriptionType === 'legacy' ? 'never' : subscriptionType === 'premium-yearly' ? 'year' : 'month'

    return !subscription?.active && !subscription?.billinghistory?.length ? (
        <PremiumSignup />
    ) : (
        <div className="subscription-settings full-height">
            {loading === 'premium-settings' && <Loading style={{ background: "#F5F9FA" }} overlay />}
            <div className="row col-reversed-mobile">
                <div className="col subscription-settings-left">
                    {subscription && subscription.active && subscription.nextBill && <UpcomingCharges nextBill={subscription.nextBill} price={price} />}
                    <BillingHistory subscription={subscription} />
                </div>

                <div className="col subscription-settings-right">
                    {subscription?.paymentFailed && (
                        <Card className="yellow padding-1 t-centered">
                            <p>There was a problem collecting payment,  your premium has been cancelled.</p>
                        </Card>
                    )}
                    {subscriptionType !== 'legacy' && <YourSub
                        windowIndex={windowIndex}
                        subscription={subscription}
                        openSubSignupWindow={openSubSignupWindow}
                        price={price}
                        interval={interval}
                        onCancelSub={onCancelSub}
                        onReactivateSub={onReactivateSub}
                    />}

                    {subscription?.active && subscriptionType === 'legacy' ?
                        <p>You have been given premium for being a founding user</p> : null}

                    {subscription?.coupon?.code ? (
                        <div className="margin-bottom-1">
                            <label>Active Coupon</label>
                            <Card className="row space-between padding-1">
                                <p>{subscription?.coupon?.code}</p>
                                <p>{subscription?.coupon?.description}</p>
                            </Card>
                        </div>
                    ) : null}
                    {subscription?.paymentInfo?.method ? <PaymentInfo info={subscription.paymentInfo} onUpdatePayment={onUpdatePayment} /> : null}
                    {subscription?.active ? null : <PremiumPromo onSubscribe={openSubSignupWindow} />}
                </div>
            </div>
        </div>
    )
}

export default SubscriptionSettings