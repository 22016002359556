import React, { useEffect, useRef } from 'react'
import './DiceLayer.css'
import Scene from '../../functions/dice/Scene'
import SceneUI from '../../functions/dice/SceneUI'
import { useCampaignFunctions, useCampaignState } from '../../context/Campaign'
import { useAuthState } from '../../context/Auth'
import { useWorkspaceFunctions, useWorkspaceState } from '../../context/Workspace'
import CancelDiceButton from '../CancelDiceButton/CancelDiceButton'

const DiceLayer = () => {
    const mount = useRef(null)
    const uiMount = useRef(null)
    const scene = useRef(null)
    const sceneUI = useRef(null)

    const { user } = useAuthState()
    const { connectedUsers, socket } = useCampaignState()
    const { campaignDispatch, sendDiceRoll, addRollToHistory } = useCampaignFunctions()
    const { diceOptions, tempDiceOptions } = useWorkspaceState()
    const { workspaceDispatch } = useWorkspaceFunctions()

    useEffect(() => {
        scene.current = new Scene(mount)
        sceneUI.current = new SceneUI(uiMount)
        scene.current.setUI(sceneUI.current)
        scene.current.setCloseDiceDrawer(() => workspaceDispatch({ type: 'SET_DICE_DRAWER_OPEN', value: false }))
        scene.current.setDiceInHand((value) => { workspaceDispatch({ type: 'SET_DICE_IN_HAND', value }) })
        scene.current.setRolling((value) => workspaceDispatch({ type: 'SET_DICE_ROLLING', value }))

        return () => scene.current.cleanup()
    }, [workspaceDispatch])

    useEffect(() => {
        scene.current.diceManager.loadDice(user.diceSkin, 'local')
            .then(() => {
                campaignDispatch({
                    type: 'DICE_READY',
                    value: {
                        addDice: scene.current.diceManager.addDiceToHand.bind(scene.current.diceManager),
                        popDice: scene.current.diceManager.popSelectedDice.bind(scene.current.diceManager),
                    }
                })
            })
    }, [campaignDispatch, user])

    useEffect(() => {
        connectedUsers.forEach(connectedUser => {
            if (connectedUser._id !== user._id) {
                scene.current.diceManager.loadDice(connectedUser.diceSkin, connectedUser._id)
            }
        })
    }, [user, connectedUsers, connectedUsers.length])

    useEffect(() => {
        scene.current.diceManager.addRollToHistory = addRollToHistory.bind(scene.current.diceManager)
    }, [addRollToHistory])

    useEffect(() => {
        scene.current.diceManager.setUser(user)
        scene.current.diceManager.setSendDiceRoll((rollData) => {
            if (!diceOptions.rollPrivately) {
                sendDiceRoll(user, rollData, socket)
            }
        })

        sceneUI.current.rollingPrivately = diceOptions.rollPrivately
    }, [user, connectedUsers, sendDiceRoll, socket, diceOptions.rollPrivately])

    useEffect(() => {
        scene.current.diceManager.rollingPrivately = diceOptions.rollPrivately
        scene.current.diceManager.disableAnimations = diceOptions.disableAnimations
        scene.current.setDiceTray({ active: diceOptions?.diceTrayActive, x: diceOptions?.diceTrayCoords?.x, y: diceOptions?.diceTrayCoords?.y })
        scene.current.diceManager.keepBest = diceOptions.keepBest
        scene.current.diceManager.addOrSub = diceOptions.addOrSub
        scene.current.diceManager.succeedsOn = diceOptions.succeedsOn
        scene.current.diceManager.succeedsOnDiceOrRoll = diceOptions.succeedsOnDiceOrRoll
        scene.current.diceManager.succeedsOnGreaterOrLess = diceOptions.succeedsOnGreaterOrLess
        scene.current.diceManager.explodesOn = diceOptions.explodesOn
        scene.current.diceManager.diceTrayActive = diceOptions.diceTrayActive
    }, [diceOptions])

    useEffect(() => {
        sceneUI.current.tempDiceOptions = tempDiceOptions
        scene.current.diceManager.tempDiceOptions = tempDiceOptions
    }, [tempDiceOptions])

    return (
        <>
            <div id="dice-layer-ui" ref={uiMount}></div>
            <div id="dice-layer" ref={mount}></div>
            <CancelDiceButton />
        </>
    )
}

export default DiceLayer