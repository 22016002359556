import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
// Load Styles for the Auth page.
import './Auth.css'

//Form components.
import Login from './Login'
import Signup from './Signup'
import PasswordResetRequest from './PasswordResetRequest'
import PasswordReset from './PasswordReset'

import { useAuthState, useAuthFunctions } from '../../context/Auth'
import Loading from '../../components/Loading'
import { useErrorFunctions } from '../../context/Error'
import { useLoadingState, useLoadingFunctions } from '../../context/Loading'
import VersionDisplay from '../../components/VersionDisplay/VersionDisplay'

function Auth() {
    const { page, auth, user, initialLoad, passwordResetToken } = useAuthState()
    const { getUser, changePage } = useAuthFunctions()

    const { loading } = useLoadingState()
    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()

    const history = useHistory()
    const location = useLocation()

    const [resetSuccess, setResetSuccess] = useState(false)

    useEffect(() => {
        if (location.search.includes("login=true") || localStorage.getItem("notNew")) changePage("login")
    }, [location, changePage])

    useEffect(() => {
        if (passwordResetToken !== "") {
            changePage("password-reset-form")
        }
    }, [changePage, passwordResetToken])

    useEffect(() => {
        if (user === null) {
            getUser(auth, user, initialLoad, loading, setLoading, setError)
        }
        else {
            const { from } = location.state || { from: { pathname: "/" } }
            history.replace(from)
        }
    }, [auth, getUser, initialLoad, loading, setLoading, setError, history, location.state, user])

    if (initialLoad || (loading === 'global')) return <Loading full />

    return (
        <div className="auth">
            <div className="auth-logo-container">
                <img src="/full-logo.svg" alt="adventuring' kit logo" className="auth-logo" />
            </div>
            <div className="auth-menu-container">
                <img className="mobile-logo" src="/logo.svg" alt="adventuring' kit logo" />
                {page === "login" && <Login resetSuccess={resetSuccess} />}
                {page === "signup" && <Signup />}
                {page === "password-reset-request-form" && <PasswordResetRequest />}
                {page === "password-reset-form" && <PasswordReset setResetSuccess={setResetSuccess} />}
            </div>
            <VersionDisplay />
        </div>
    )
}

export default Auth