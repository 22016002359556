import React, { useEffect, useRef, useState } from 'react';
import useRenameFolder from '../../../../hooks/useRenameFolder';

const FolderName = ({ id, name, owner, isOwner, setLoading, minimal, editingName, setEditingName }) => {
    const nameRef = useRef(null)
    const [newName, setNewName] = useState(name)
    useEffect(() => {
        if (editingName && nameRef.current) {
            nameRef.current.focus()
        }
    }, [editingName])
    const { text, onChange, setText } = useRenameFolder(newName, id, owner, setLoading)
    useEffect(() => {
        if (newName !== text) {
            const timeout = setTimeout(() => {
                onChange(newName)
            }, 300)

            return () => clearTimeout(timeout)
        }
    }, [text, newName, onChange, setLoading, editingName])

    useEffect(() => {
        if (!editingName && name !== newName) {
            setText(name)
            setNewName(name)
        }
    }, [editingName, name, newName, setText])

    return editingName ? (
        <input
            ref={nameRef}
            className="window-title-input t-small c-main padding-left-quarter folderName"
            placeholder="Untitled..."
            value={newName || ''}
            onChange={(e) => setNewName(e.target.value)}
            onBlur={() => setEditingName(false)}
            onKeyDown={(e) => {
                if (editingName && e.key === 'Enter') {
                    setEditingName(false)
                    if (newName !== text) onChange(newName)
                }
            }}
        />
    ) : (
        <p className={`${minimal ? 'whitespace-nowrap t-overflow-elipses' : 'window-title-display'} folderName t-small c-main padding-left-quarter ${isOwner && 'isOwner'}`}>
            {newName || 'Untitled...'}
        </p>
    )
};


export default FolderName;
