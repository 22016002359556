import { useEffect, useState } from 'react'
import { useAuthState } from '../../context/Auth'
import { useCampaignState } from '../../context/Campaign'
import { useWorkspaceFunctions, useWorkspaceState } from '../../context/Workspace'
import API from '../../functions/API'
import useOpenWindow from '../useOpenWindow'

const useRecieveBroadcast = () => {
    const [docToBroadcast, setDocToBroadcast] = useState(null)
    const [docToUnBroadcast, setDocToUnBroadcast] = useState(null)
    const { windows, tabs } = useWorkspaceState()
    const { maximizeWindow, selectTab, workspaceDispatch } = useWorkspaceFunctions()
    const { socket } = useCampaignState()
    const openWindow = useOpenWindow()
    const { user } = useAuthState()

    useEffect(() => {
        let tab, windowInstance
        const abort = new AbortController()
        if (docToBroadcast && docToBroadcast !== docToUnBroadcast) {

            tab = tabs.find(t => t?.propsPassedFromWindow?.documentId === docToBroadcast)
            if (tab && !tab.settings.broadcasted) {
                selectTab(tab.index)
                workspaceDispatch({ type: "UPDATE_WINDOW_SETTINGS", value: { index: tab.index, values: { wasTabBeforeBroadcast: true, broadcasted: true } } })
            }

            windowInstance = windows.find(w => w?.propsPassedFromWindow?.documentId === docToBroadcast)
            if (windowInstance) {
                maximizeWindow(windowInstance.index, { wasWindowBeforeBroadcast: true, broadcasted: true })
            }

            if (!tab && !windowInstance) {
                API.sendGraph({
                    query: `query {
                        document(documentId: "${docToBroadcast}") {
                            _id
                            name
                            kind
                            owner {
                                _id
                            }
                        }
                    }`
                }, { abort }).then((res) => {
                    const { kind, owner, name, _id } = res.data.document

                    openWindow(kind, {
                        title: name,
                        uniqueIndex: `document-${_id}`,
                        broadcasted: true
                    }, {
                        documentId: _id,
                        documentOwnerId: owner._id,
                        documentName: name,
                    })
                }).catch(err => {
                    console.log(err)
                })
            }
            setDocToBroadcast(false)
        }

        return () => {
            if (!docToBroadcast || docToBroadcast === docToUnBroadcast) {
                abort.abort()
            }
        }
    }, [docToBroadcast, docToUnBroadcast, maximizeWindow, openWindow, selectTab, tabs, windows, workspaceDispatch])

    useEffect(() => {
        if (docToUnBroadcast) {
            const closingTab = tabs.find(t => t?.propsPassedFromWindow?.documentId === docToUnBroadcast)
            const closeDocTimeout = setTimeout(() => {
                if (closingTab && (!closingTab.settings.wasTabBeforeBroadcast || !closingTab.settings.wasTabBeforeBroadcast)) {
                    socket.emit("USER_CLOSED_DOCUMENT", { documentId: docToUnBroadcast, userId: user._id })
                }
                workspaceDispatch({ type: 'UNBROADCAST_WINDOW', value: docToUnBroadcast })
                setDocToUnBroadcast(false)
            }, 100)

            return () => {
                clearTimeout(closeDocTimeout)
            }
        }
    }, [docToUnBroadcast, socket, tabs, user._id, workspaceDispatch])

    return {
        setDocToBroadcast,
        setDocToUnBroadcast
    }
}

export default useRecieveBroadcast
