import React, { useEffect, useState } from 'react'
import { IconButton } from '../../Buttons'
import DocumentToolbar, { ToolBarSeparator } from '../../DocumentToolbar'
import TextInput from '../../TextInput'
import { NavigateNext, NavigateBefore } from '@styled-icons/material/'
import GlobalStorage from '../../../functions/GlobalStorage'
import { usePrevious } from '../../../hooks/usePrevious'
import { SelectList } from '../../SelectList'
import { Dice } from '@styled-icons/ionicons-outline/Dice'
import { EditAlt } from '@styled-icons/boxicons-regular/EditAlt'
import Toggle from '../../Toggle/Toggle'

const TopToolBar = ({ id, numPages, defaultDiceSelection, setDefaultDiceSelection, scaleMult, setScale, page, setPage, setEditMode, setLoading, windowIndex, saving, isFullScreen, broadcasted, broadcasting }) => {
    const [pageNumberVal, setPageNumberVal] = useState(page)
    const prevPageNumberVal = usePrevious(pageNumberVal)

    const [localDefaultDiceSelection, setLocalDefaultDiceSelection] = useState(defaultDiceSelection || 'D20')

    useEffect(() => {
        setLocalDefaultDiceSelection(defaultDiceSelection)
    }, [defaultDiceSelection])

    useEffect(() => {
        if (prevPageNumberVal !== undefined && pageNumberVal !== prevPageNumberVal) {
            GlobalStorage.set('PDFPageValTimeout', setTimeout(() => {
                let intFirst = parseInt(pageNumberVal)
                if (isNaN(intFirst)) return
                const newPageNumber = Math.max(1, Math.min(intFirst, numPages))
                setPageNumberVal(newPageNumber)
                setPage(newPageNumber)
            }, 10))
        }

        return () => {
            clearTimeout(GlobalStorage.get('PDFPageValTimeout'))
        }
    }, [numPages, pageNumberVal, prevPageNumberVal, setLoading, setPage])

    const scaleSelections = {
        "25%": 0.25,
        "30%": 0.3,
        "45%": 0.45,
        "50%": 0.5,
        "55%": 0.55,
        "60%": 0.6,
        "65%": 0.65,
        "70%": 0.7,
        "75%": 0.75,
        "80%": 0.80,
        "85%": 0.85,
        "90%": 0.90,
        "95%": 0.95,
        "100%": 1,
    }


    return (
        <DocumentToolbar
            windowIndex={windowIndex}
            saving={saving}
            isFullScreen={isFullScreen}
            documentId={id}
            broadcasted={broadcasted}
            broadcasting={broadcasting}
        >
            <div className="row full-width height-2 hidden-phone centered-vertically">
                <Toggle
                    entries={['Roll', 'Edit']}
                    entryIcons={[
                        <Dice className="size-1" />,
                        <EditAlt className="size-1" />,
                    ]}
                    setValue={setEditMode}
                />
                <ToolBarSeparator />
                <p className="padding-horizontal-quarter c-shade-5 t-small">Page</p>
                {page > 1 && (
                    <IconButton className="width-2 c-shade-6" onClick={() => setPageNumberVal(pageNumberVal - 1)}>
                        <NavigateBefore />
                    </IconButton>
                )}
                {numPages > 1 && (
                    <>
                        <TextInput
                            value={pageNumberVal}
                            inputClassName="margin-horizontal-half square t-center width-3 border-none border-bottom-thin padding-half"
                            onChange={(e) => setPageNumberVal(e.target.value)}
                        />
                        <p className="whitespace-nowrap t-small">of {numPages}</p>
                    </>
                )}
                {page < numPages && (
                    <IconButton className="width-2 c-shade-6" onClick={() => setPageNumberVal(pageNumberVal + 1)}>
                        <NavigateNext />
                    </IconButton>
                )}
                <ToolBarSeparator />
                <p className="padding-horizontal-quarter c-shade-5 t-small">Zoom</p>
                <SelectList
                    className="width-5"
                    selected={Object.keys(scaleSelections).find(key => scaleSelections[key] === scaleMult)}
                    items={Object.keys(scaleSelections)}
                    onChange={(e) => {
                        setScale(scaleSelections[e.target.value])
                    }}
                />
                <ToolBarSeparator />
                <p className="padding-horizontal-quarter c-shade-5 t-small">Default Dice</p>
                <TextInput
                    className="width-6"
                    inputClassName="height-1"
                    value={localDefaultDiceSelection}
                    onChange={(e) => setLocalDefaultDiceSelection(e.target.value)}
                    onBlur={() => {
                        if (localDefaultDiceSelection.match(/((\d+|\+|)([+d](\d)+))+/gmi))
                            setDefaultDiceSelection(localDefaultDiceSelection)
                        else {
                            setDefaultDiceSelection('d20')
                        }
                    }}
                    placeholder={'3d6+d10'}
                />
            </div>
        </DocumentToolbar >
    )
}

export default TopToolBar
