export default function rotateDiceToValue(geoData, die, value) {
    try {
        const valueRotation = geoData.valueRotations.find(rot => rot.value === value)
        die.finalRotation = {
            x: valueRotation.x,
            y: valueRotation.y,
            z: valueRotation.z
        }
    } catch (e) {
        console.log(`error setting dice rotation to value: ${value}`)
    }
}