import React, { useCallback } from 'react'
import TextInput from '../../components/TextInput'
import { Button, IconButton } from '../../components/Buttons'

import { useAuthState, useAuthFunctions } from '../../context/Auth'
import { useErrorFunctions } from '../../context/Error'
import Loading from '../../components/Loading'
import { useLoadingFunctions, useLoadingState } from '../../context/Loading'

const PassswordReset = ({ setResetSuccess }) => {
    const { resetPassword, confirmResetPassword, passwordResetToken } = useAuthState()
    const { changeField, changePage, sendPasswordReset, clearForm } = useAuthFunctions()

    const { loading } = useLoadingState()
    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()

    const handleForm = useCallback(async (e) => {
        e.preventDefault()
        await sendPasswordReset(passwordResetToken, resetPassword, confirmResetPassword, setLoading, setError)
        setResetSuccess(true)
        clearForm()
        changePage("login")
    }, [sendPasswordReset, passwordResetToken, resetPassword, confirmResetPassword, setLoading, setError, setResetSuccess, clearForm, changePage])

    return (
        <div className="auth-login-container">
            {loading === 'password-reset-requests' ? <Loading overlay /> : null}
            <form className="auth-inner-container col" onSubmit={handleForm}>
                <div className="row margin-bottom-3">
                    <IconButton className="margin-right-1" icon="arrow-left" onClick={() => changePage("login")} />
                    <h1 className="c-shade-7">Set Password</h1>
                </div>
                <TextInput id="reset-password" className="auth-textInput" value={resetPassword} label="New Password" type="password" placeholder="****" onChange={(e) => changeField(e.target.value, "resetPassword")} />
                <TextInput id="reset-password-confirm" className="auth-textInput" value={confirmResetPassword} label="Confirm New Password" type="password" onChange={(e) => changeField(e.target.value, "confirmResetPassword")} />

                <Button className="auth-login-button" type="submit">Set New Password</Button>
            </form>
        </div>
    )
}

export default PassswordReset