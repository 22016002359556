import React from 'react'
import Blobs from '../Blobs'
const Sheets = ({ className, clutter }) => {
    return (
        <div className={`landing-feature landing-sheets ${className}`} data-scroll>

            <div className="feature-img-container">
                <Blobs />
                <img src="/examples/sheets-example@2x.png" alt="example of map designer" />
            </div>

            {clutter.map((item, i) => (
                <img className="clutter-item" key={i} src={item.url} alt='' />
            ))}

            <div className="feature-text-container">
                <div className="feature-text-inner">

                    <h2>Sheets <span className="coming-soon-span">(coming soon)</span></h2>
                    <p>
                        Want to track info on characters, items, or locations? Create your own forms for players in your campaign to use and view all of the data in one place.
                    </p>
                    <a className="landing-cta" href="/login">Join For Free!</a>
                </div>
            </div>
        </div>
    )
}

export default Sheets
