import React, { useCallback, useState } from 'react'
import { useAuthState } from '../../context/Auth'
import { useErrorFunctions } from '../../context/Error'
import { useWorkspaceFunctions } from '../../context/Workspace'
import API from '../../functions/API'
import { getStartingData, submitOp } from '../../functions/helpers'
import ImageAPI from '../../functions/ImageAPI'
import useSaveDocument from '../../hooks/useSaveDocument'
import DropZone from '../DropZone'
import Loading from '../Loading'
import TopToolBar from './partials/TopToolBar'

import './PictureViewer.css'

const PictureViewer = ({
    id,
    initialValue,
    windowIndex,
    saving,
    isFullScreen,
    broadcasted,
    broadcasting,
    hideToolbar
}) => {
    const [data, setData] = useState(getStartingData(null, 'picture'))
    const [loading, setLoading] = useState(false)
    const { auth } = useAuthState()
    const { workspaceDispatch } = useWorkspaceFunctions()
    const { setError } = useErrorFunctions()
    const { doc, initialLoadComplete } = useSaveDocument(id, initialValue, setData, 'picture', windowIndex)
    const [scaleMode, setScaleMode] = useState('contain')
    const [picturePosition, setPicturePosition] = useState('top')

    const uploadPicture = useCallback(async (files) => {
        setLoading(true)
        try {
            const uploadResult = await ImageAPI.upload(null, files)
            await API.sendGraphSecure(auth, {
                query: `mutation {
                    saveImageToUser(url: "${uploadResult.url}", size: ${uploadResult.size})
                }`
            })

            submitOp(doc, { p: ['picture'], od: data?.picture, oi: uploadResult.url })
            workspaceDispatch({ type: 'RENAME_WINDOW_FROM_DOC_ID', value: { documentId: id, title: files[0].name } })
            setLoading(false)
        } catch (err) {
            console.log('image upload err', err)
            setError('Error uploading picture.')
            setLoading(false)
        }
    }, [auth, data.picture, doc, id, setError, workspaceDispatch])

    return (
        <>
            {hideToolbar ? null : <TopToolBar
                id={id}
                windowIndex={windowIndex}
                saving={saving}
                isFullScreen={isFullScreen}
                broadcasted={broadcasted}
                broadcasting={broadcasting}
                picturePosition={picturePosition}
                setPicturePosition={setPicturePosition}
                scaleMode={scaleMode}
                setScaleMode={setScaleMode}
            />}
            {(loading || !initialLoadComplete) ? (
                <Loading opaque />
            ) : (
                data?.picture ? (
                    <div className="PictureViewer">
                        <img className={`pictureViewer__img ${scaleMode} ${picturePosition}`} src={data.picture} alt="" draggable={false} />
                    </div>
                ) : (
                    <div className="full-width full-height padding-1 centered">
                        <div className="half-width centered col">
                            <DropZone
                                className="padding-1 t-centered height-10 width-10 bg-shade-3 rounded"
                                onSave={uploadPicture}
                                DropOrClickMessage={"Drag Picture here or click to select."}
                                showClickIcon
                                accept='image/jpeg, image/png'
                            />
                        </div>
                    </div>
                )
            )}
        </>
    )
}

export default PictureViewer
