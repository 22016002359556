import React, { useState, useEffect } from 'react'

import Chest from '../../components/Chest'
import Window from '../../components/Window'
import Chat from '../../components/Chat'
import Notifications from '../../components/Notifications'

import './Workspace.css'

import { useWorkspaceState, useWorkspaceFunctions } from '../../context/Workspace'
import { useCampaignState } from '../../context/Campaign'

import Loading from '../../components/Loading'

import { useImportInventory, useCheckForCampaignInUrl, useSocketSetup } from '../../hooks'
import DiceLayer from '../../components/DiceLayer/DiceLayer'
import { useLoadingState } from '../../context/Loading'
import { useAuthState } from '../../context/Auth'
import AddItems from './partials/AddItems'
import useOpenWindow from '../../hooks/useOpenWindow'
import DiceTray from '../../components/DiceTray/DiceTray'
import { DiceHistory } from '../../components/DiceHistory'
import useOpenDocumentsCommunications from '../../hooks/useOpenDocumentsCommunications'
import Tab from '../../components/Window/Tab'
import ResizeBumpers from './partials/ResizeBumpers/ResizeBumpers'
import useAttemptedCoupon from '../../hooks/useAttemptedCoupon'
import useSubscribeToFolders from '../../hooks/useSubscribeToFolders'
import PlacingToken from './PlacingToken/index'

const Workspace = () => {
    const { setDiceOptions, workspaceDispatch } = useWorkspaceFunctions()
    const openWindow = useOpenWindow()
    const { user } = useAuthState()
    const { windows, tabs, selectedTab, diceOptions } = useWorkspaceState()
    const { activeCampaign } = useCampaignState()
    const [dragging, setDragging] = useState(null)
    const [draggingStartCoords, setDraggingStartCoords] = useState([0, 0])
    const [bumperActive, setBumperActive] = useState(null)
    const [historyReplaced, setHistoryReplaced] = useState(false)

    const { loading } = useLoadingState()

    useImportInventory()

    useCheckForCampaignInUrl()
    useSocketSetup()

    useOpenDocumentsCommunications()

    useAttemptedCoupon()

    useSubscribeToFolders()


    const startDrag = (e, target, countIndex) => {
        setDragging(target)
        const { x, y } = target.instance.current.getBoundingClientRect()
        const shiftX = e.clientX - x + 1,
            shiftY = e.clientY - y + 1
        setDraggingStartCoords([shiftX, shiftY])
        dragWindow(e)
    }

    const endDrag = (e) => {
        if (dragging) {
            if (bumperActive !== null) {
                workspaceDispatch({
                    type: 'MOVE_WINDOW', value: {
                        index: dragging.index,
                        x: bumperActive === 'left' ? 0 : window.innerWidth / 2,
                        y: 0,
                        width: window.innerWidth / 2,
                        height: window.innerHeight,
                        instance: dragging.instance.current
                    }
                })
            } else {
                workspaceDispatch({
                    type: 'MOVE_WINDOW', value: {
                        index: dragging.index,
                        x: dragging.instance.current.offsetLeft,
                        y: dragging.instance.current.offsetTop,
                        instance: dragging.instance.current
                    }
                })
            }
            setDragging(null)
        }
        setBumperActive(null)
    }

    const dragWindow = (e) => {
        if (!dragging) return
        const [shiftX, shiftY] = draggingStartCoords

        const x = (e.pageY - shiftY)
        const y = (e.pageX - shiftX)
        const top = x + "px"
        const left = y + "px"

        dragging.instance.current.style.top = top
        dragging.instance.current.style.left = left

        let bumper = null
        if (e.pageX >= window.innerWidth - 128) {
            bumper = 'right'
        }

        if (e.pageX <= 128) {
            bumper = 'left'
        }

        setBumperActive(bumper)

        if (dragging?.instance?.current?.id === 'dice-tray') {
            setDiceOptions('diceTrayCoords', { x, y })
        }
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        const paymentSuccess = query.get("payment-success")
        const paymentSetup = query.get("payment-setup")

        if (!historyReplaced) {
            window.history.replaceState({}, document.title, window.location.pathname)
            setHistoryReplaced(true)
        }
        if (paymentSuccess === 'true') {
            window.heap.track('USER_COMPLETED_CHECKOUT', { userId: user._id, email: user.email })
            openWindow('paymentSuccess')
        } else if (paymentSuccess === 'false' || paymentSetup === 'true') {
            openWindow('settings', null, { startingTab: 1 })
        }
    }, [historyReplaced, openWindow, user._id, user.email, windows])

    return (
        <React.Fragment>
            {loading === 'global' && <Loading full overlay />}
            <div
                id="workspace"
                className="workspace"
                onMouseMove={dragWindow}
                onMouseUp={endDrag}
            >
                <Chest />

                <div className="workspace-tools-container">
                    <AddItems />
                    {activeCampaign ? (
                        <>
                            <Chat />
                            <Notifications />
                        </>
                    ) : null}
                    <DiceHistory />
                </div>

                <ResizeBumpers bumperActive={bumperActive} />
                <PlacingToken />

                {windows.map((windowData, i) => {
                    const windowProps = { ...windowData, startDrag, windowDragging: dragging?.index === windowData.index, key: windowData.index, countIndex: i }
                    return <Window {...windowProps} />
                })}


                {diceOptions.diceTrayActive && <DiceTray startDrag={startDrag} color={user.diceSkin} coords={diceOptions.diceTrayCoords} />}
                <DiceLayer />

                {/* <DropZone /> */}
                {tabs.map((tabData, i) => (
                    <Tab key={tabData.index} tabIndex={i} {...tabData} selected={selectedTab === tabData.index} />
                ))}
            </div>
        </React.Fragment>
    )
}

export default Workspace