import { addNotification } from './'

export function addConnectedUser(userId, campaign, setError, isNewConnection) {
    try {
        const newUser = campaign.users.find(user => user._id === userId)
        if (newUser) {
            if (isNewConnection) {
                addNotification.call(this,
                    `${newUser.name} has joined the campaign!`,
                    `${newUser._id}-has-joined`
                )
            }

            this.dispatch({ type: "ADD_CONNECTED_USER", value: newUser })
        }

    } catch (err) {
        setError(err.message)
    }
}