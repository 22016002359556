import React, { useState } from 'react'
import { Checkbox } from '../../../../components/Checkbox'
import { SelectList } from '../../../../components/SelectList'
import { Slider } from '../../../../components/Slider'
import SocialIcons from '../../../../components/SocialIcons'

const General = () => {
    const [darkModeSetting, setDarkModeSetting] = useState(localStorage.getItem('darkModeSetting'))

    const setDarkMode = (val) => {
        localStorage.setItem('darkModeSetting', val)
        const appElement = document.getElementById('App')
        appElement.classList.remove('force-light-mode', 'force-dark-mode')
        if (val === 'On') {
            appElement.classList.add('force-dark-mode')
        }

        if (val === 'Off') {
            appElement.classList.add('force-light-mode')
        }

        setDarkModeSetting(val)
    }

    const [globalVolume, setGlobalVolume] = useState(() => {
        const volumeInStorage = localStorage.getItem('globalVolumeSetting')
        return (volumeInStorage ? parseFloat(volumeInStorage) : 0.5) * 100
    })
    const changeGlobalVolume = (val) => {
        localStorage.setItem('globalVolumeSetting', val / 100)
        setGlobalVolume(val)
    }


    const [globalMute, setGlobalMute] = useState(localStorage.getItem('globalMuteSetting') === 'true' || false)
    const changeGlobalMute = (val) => {
        localStorage.setItem('globalMuteSetting', val)
        setGlobalMute(val)
    }


    return (
        <div className="settings-container row centered padding-top-2">
            <div className="col centered full-width margin-bottom-2 flex-start">
                <div className="col full-width margin-right-1 margin-bottom-1">
                    <label className="c-main t-left c-shade-6" htmlFor="microphone-device-selector">Darkmode</label>
                    <SelectList
                        id="darkmode-selector"
                        className="padding-half"
                        items={['Auto', 'On', 'Off']}
                        selected={darkModeSetting}
                        onChange={(e) => setDarkMode(e.target.value)}
                    />
                </div>

                <div className="col full-width margin-right-1 margin-bottom-2">
                    <label className="c-main t-left c-shade-6" htmlFor="microphone-device-selector">Sound Effect Volume</label>
                    <div className="row full-width centered">
                        <Checkbox className="margin-top-half" checked={globalMute} onChange={(e) => changeGlobalMute(e.target.checked)} />
                        <Slider
                            className={`full-width margin-top-1 ${globalMute && 'disabled'}`}
                            min={0}
                            max={100}
                            value={globalVolume}
                            onChange={(e) => changeGlobalVolume(e.target.value)}
                        />
                    </div>
                </div>

                <div className="col full-width settings-social-icons">
                    <SocialIcons />
                </div>
            </div>
        </div>
    )
}

export default General
