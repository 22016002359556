import React, { useRef, useState } from 'react'
import { useWorkspaceFunctions } from '../../context/Workspace'

import { IconButton } from '../Buttons'
import ScaleControlls from './ScaleControlls'

import Inner from './Inner'

import './style.css'
import Loading from '../Loading'
import {
    CloudDone,
    CloudUpload,
    CloudQueue
} from '@styled-icons/material/'
import getSettings from './functions/getSettings'
import CustomTitleWindowInput from './CustomTitleWindowInput'
import { useCampaignState } from '../../context/Campaign'

import { CameraVideoFill } from '@styled-icons/bootstrap/'
import { BorderOutsideThick } from '@styled-icons/fluentui-system-filled/'
import { useAuthState } from '../../context/Auth'
import { WindowMinimize } from '@styled-icons/fa-regular/WindowMinimize'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

const Window = ({ index, view: ViewElement, settings, startDrag, windowDragging, propsPassedFromWindow, countIndex, closing, saving }) => {
    const {
        selectWindow,
        closeWindow,
        maximizeWindow,
        workspaceDispatch
    } = useWorkspaceFunctions()

    const { socket, activeCampaign } = useCampaignState()
    const { user } = useAuthState()

    const [scaling, setScaling] = useState(false)

    const newSettings = getSettings(settings, countIndex)
    const { canMax, minimized, selected, width, height, x, y, color, title, zIndex, disableWindowNav, disableBroadcast, disableScaling, fullScreenMobile, innerProps, customNameWindow, showSave } = newSettings
    const instance = useRef(null)
    const windowInnerRef = useRef(null)
    const viewProps = { _index: index, _settings: newSettings, saving, windowInnerRef, windowScaling: scaling, windowDragging, ...propsPassedFromWindow }

    return (
        <div
            className={`window bg-white ${minimized && 'minimized'} ${color} ${selected && 'selected'} ${fullScreenMobile && 'fullScreenMobile'}`}
            style={{ width: width, height: height, top: y, left: x, zIndex }}
            onMouseDown={() => (!selected) ? selectWindow(index) : null}
            ref={instance}
        >
            {closing && <Loading overlay label="Saving and Closing" />}
            <div
                className="topbar"
                onMouseDown={(e) => startDrag(e, { index, instance, countIndex })}
            >
                <div className={`row centered c-main`}>
                    {customNameWindow && propsPassedFromWindow?.documentId ? <CustomTitleWindowInput title={title} documentId={propsPassedFromWindow?.documentId} kind={propsPassedFromWindow?.kind} /> : <p>{title}</p>}
                    {showSave && <div className="row size-1">{saving === 'unsaved' ? <CloudQueue /> : saving === 'saved' ? <CloudDone /> : <CloudUpload />}</div>}
                </div>
                {
                    disableWindowNav || <div className="windowNav row">
                        {!disableBroadcast && activeCampaign?.owner?._id === user._id && <IconButton onClick={(e) => {
                            maximizeWindow(index, { broadcasting: true })
                            if (propsPassedFromWindow.documentId) {
                                socket.emit('BROADCAST_WINDOW', propsPassedFromWindow.documentId)
                                socket.emit('USER_OPENED_DOCUMENT', { documentId: propsPassedFromWindow.documentId, userId: user._id })
                            }
                        }}>
                            <CameraVideoFill className="size-1" />
                        </IconButton>}

                        <IconButton className="fill-main" onClick={() => { workspaceDispatch({ type: 'MINIMIZE_WINDOW', value: { index, title, type: settings.icon, documentId: propsPassedFromWindow?.documentId, documentOwnerId: propsPassedFromWindow.documentOwnerId } }) }}>
                            <WindowMinimize className='size-1' />
                        </IconButton>

                        {canMax && <IconButton onClick={(e) => { maximizeWindow(index) }}>
                            <BorderOutsideThick className="size-1" />
                        </IconButton>}

                        <IconButton className="padding-left-0" onClick={(e) => { closeWindow(index) }}>
                            <CloseOutline className="size-1dot5" />
                        </IconButton>
                    </div>
                }
            </div>
            <Inner ref={windowInnerRef} {...innerProps} index={index}>
                {ViewElement && <ViewElement {...viewProps} />}
            </Inner>
            {!disableScaling && <ScaleControlls scaling={scaling} setScaling={setScaling} windowData={newSettings} index={index} />}
        </div >
    )
}

export default Window