import { getSoundEffectVolume } from "../../../getSoundEffectVolume"
import { isMobile } from 'react-device-detect'

export default function releaseDice() {
    if (this.selectedDice.length === 0) return

    const { dist, direction } = this.getDiceRollingDirection()

    window._ADVK_DICE_DROP_AUDIO_1.volume = getSoundEffectVolume()
    window._ADVK_DICE_DROP_AUDIO_1.currentTime = 0
    window._ADVK_DICE_DROP_AUDIO_1.play()

    if (this.selectedDice.length > 1) {
        window._ADVK_DICE_DROP_AUDIO_2.volume = getSoundEffectVolume()
        window._ADVK_DICE_DROP_AUDIO_2.currentTime = 0
        window._ADVK_DICE_DROP_AUDIO_2.play()
    }

    if (this.selectedDice.length > 2) {
        window._ADVK_DICE_DROP_AUDIO_3.volume = getSoundEffectVolume()
        window._ADVK_DICE_DROP_AUDIO_3.currentTime = 0
        window._ADVK_DICE_DROP_AUDIO_3.play()
    }

    prepareRollRelease.call(this, dist, direction, this.selectedDice)
    this.setDiceInHand(false)
    this.setRolling(true)
    this.UI.stopRoll()

    if (isMobile)
        this.closeDiceDrawer()
}


function prepareRollRelease(dist, direction, diceGroup) {
    const group = this.createDiceGroup(diceGroup.length)
    const initialNetworkSend = {
        ...group.networkObject,
        message: 'ROLL_START',
        name: group.name || this.user.name,
        dice: []
    }

    while (diceGroup.length) {
        const newDie = diceGroup.pop()
        this.addDiceToRollingGroup(group, newDie, direction, dist)
        initialNetworkSend.dice.push({ type: newDie.type, id: newDie.rollingId })
    }

    if (group.dice.length === 2) {
        const firstDice = group.dice[0]
        const secondDice = group.dice[1]

        if ((firstDice.type === 'D100' && secondDice.type === 'D10') || (firstDice.type === 'D10' && secondDice.type === 'D100')) {
            group.percentileRoll = true
            initialNetworkSend.percentileRoll = true
        }
    }


    if (initialNetworkSend.dice.length) {
        initialNetworkSend.velocity = dist
        this.sendDiceRoll(initialNetworkSend)
    }

    if (group.disableAnimations) this.noAnimationRoll()
}