export const getRelativeMousePos = (e, isFullScreen, windowPos) => {
    const windowTop = isFullScreen ? 0 : windowPos.top + 74,
        windowLeft = isFullScreen ? 0 : windowPos.left

    const xx = e.clientX - windowLeft,
        yy = e.clientY - windowTop

    return {
        xx, yy
    }
}