import React, { useCallback } from 'react'

// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { Facebook as FacebookLogo } from '@styled-icons/boxicons-logos/Facebook'
// import GoogleLogin from 'react-google-login'

import TextInput from '../../components/TextInput'
import { Button, TextButton } from '../../components/Buttons'

import { useAuthState, useAuthFunctions } from '../../context/Auth'
import { useErrorFunctions } from '../../context/Error'
import Loading from '../../components/Loading'
import { useLoadingFunctions, useLoadingState } from '../../context/Loading'
import Icon from '../../components/Icon'

const Login = ({ resetSuccess }) => {
    const { loginEmail, loginPassword } = useAuthState()
    const { changeField, handleLogin, changePage } = useAuthFunctions()

    const { loading } = useLoadingState()
    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()

    const handleFormLogin = useCallback((e) => {
        e.preventDefault()
        handleLogin(loginEmail, loginPassword, setLoading, setError)
    }, [handleLogin, loginPassword, loginEmail, setLoading, setError])

    const attemptedCoupon = localStorage.getItem('attemptedCoupon')

    return (
        <div className="auth-login-container">
            {loading === 'login' ? <Loading overlay /> : null}
            <form className="auth-inner-container col" onSubmit={handleFormLogin}>
                <h1 className="c-shade-7">Log in</h1>
                {attemptedCoupon && <p className="t-small margin-bottom-half">Log in or signup to use coupon code: {attemptedCoupon}</p>}
                {resetSuccess && <p className="c-dark-green password-reset-success row centered"><Icon value="check" className="margin-right-1" /> Password successfully reset</p>}
                <TextInput id="login-email" className="auth-textInput" value={loginEmail} label="Email" placeholder="johndoe@example.com" onChange={(e) => changeField(e.target.value, "loginEmail")} />
                <TextInput id="login-password" className="auth-textInput" value={loginPassword} label="Password" type="password" onChange={(e) => changeField(e.target.value, "loginPassword")} />

                <Button className="auth-login-button black" type="submit">Log In</Button>
                {/* <div className="col padding-1">
                <FacebookLogin
                    appId={process.env.REACT_APP_FBAPPID}
                    autoLoad
                    type="button"
                    render={(renderProps) => (
                        <Button className="full-width bg-blue padding-half centered" onClick={renderProps.onClick}>
                            <FacebookLogo className="size-1and1half" /> <span>Login With Facebook</span>
                        </Button>
                    )}
                    scope="public_profile,email"
                    responseHandler={(data) => handleFacebookLogin(data)}
                    fields="id,name,email"
                />
            </div> */}

                {/* <GoogleLogin
                    clientId={process.env.REACT_APP_GAPPID}
                    onSuccess={(data) => HandleGoogleLogin(data)}
                    onFailure={(err) => HandleError(err.error, 2)}
                    render={renderProps => (
                        <Button value="Login With Google" primary loading={gLoginLoading} className="google-login-button" onClick={(e) => { HandleGoogleClick(); renderProps.onClick(e) }} />
                    )}
                /> */}
                <TextButton className="auth-signup-toggle t-clear-transform c-shade-7 padding-bottom-2 margin-top-2" onClick={() => changePage("signup")}>Dont have an account? Sign up.</TextButton>
                <TextButton className="auth-signup-toggle t-clear-transform c-shade-6" onClick={() => changePage("password-reset-request-form")}>Request password reset</TextButton>
            </form>
        </div>
    )
}

export default Login