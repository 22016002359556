import React from 'react'
import { KeyboardArrowLeft } from '@styled-icons/material-outlined/KeyboardArrowLeft'
import './MidCloseButton.css'

const MidCloseButton = ({ onClick, side, bg, bc }) => {
    return (
        <>
            <button
                className={`modal-mid-close-foreground ${bg} ${side}`}
                onClick={onClick}
            >
                <KeyboardArrowLeft className="size-1 c-main" />
            </button>

            <div className={`modal-mid-close ${bc} ${bg} ${side}`}></div>
        </>
    )
}

export default MidCloseButton
