import * as THREE from 'three'
import C from 'cannon'

import DiceManager from "./DiceManager"
import { CannonDebugRenderer } from './functions'
import { getScreenPosIn3dSpace } from './functions';
// import OrbitControls from 'three-orbitcontrols'


export default class Scene {
    constructor(mount) {
        this.inDarkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) || localStorage.getItem('darkModeSetting') === 'On'
        this.mount = mount.current
        this.WIDTH = this.mount.clientWidth
        this.HEIGHT = this.mount.clientHeight

        this.scene = new THREE.Scene()
        this.cameraFactor = 95

        this.cameraWidth = this.WIDTH / this.cameraFactor
        this.cameraHeight = this.HEIGHT / this.cameraFactor
        this.camera = new THREE.OrthographicCamera(this.cameraWidth, -this.cameraWidth, -this.cameraHeight, this.cameraHeight, 1, 100)

        this.camera.position.set(0, 0, 50)

        this.setWorld()
        this.setLights()
        this.render()
        this.adjustWorldBounds()
        // this.setupDebug()

        this.diceManager = new DiceManager(this)

        this.onWindowResize = this.onWindowResize.bind(this)
        this.refocusWindow = this.refocusWindow.bind(this)
        window.addEventListener('resize', this.onWindowResize)
        window.addEventListener('visibilitychange', this.refocusWindow)
    }

    setLights() {
        const ambient = new THREE.AmbientLight("white")
        ambient.intensity = 0.95
        this.scene.add(ambient)

        const backLight = new THREE.DirectionalLight("white", 0.35)
        backLight.position.set(25, -25, 100)
        backLight.castShadow = !this.inDarkMode

        backLight.shadow.camera.left = -this.cameraWidth
        backLight.shadow.camera.right = this.cameraWidth
        backLight.shadow.camera.top = this.cameraWidth
        backLight.shadow.camera.bottom = -this.cameraWidth
        backLight.shadow.camera.near = this.cameraWidth
        backLight.shadow.camera.far = 200
        backLight.shadow.mapSize.width = 256

        this.scene.add(backLight)
    }

    setWorld() {
        this.world = new C.World()
        this.world.gravity.set(0, 0, -60)
        this.world.broadphase = new C.NaiveBroadphase()
        this.world.solver.iterations = 8
        this.diceBodyMaterial = new C.Material({ friction: 0.01, restitution: 0.1 })
        this.floorBodyMaterial = new C.Material()
        this.barrierBodyMaterial = new C.Material()

        this.world.addContactMaterial(new C.ContactMaterial(this.floorBodyMaterial, this.diceBodyMaterial, { friction: 0.1, restitution: 0.5 }))
        this.world.addContactMaterial(new C.ContactMaterial(this.barrierBodyMaterial, this.diceBodyMaterial, { friction: 0, restitution: 1.0 }))
        this.world.addContactMaterial(new C.ContactMaterial(this.diceBodyMaterial, this.diceBodyMaterial, { friction: 0.5, restitution: 0.5 }))

        //Floor
        let floorBody = new C.Body({ mass: 0, shape: new C.Plane(), material: this.floorBodyMaterial })
        floorBody.quaternion.setFromAxisAngle(new C.Vec3(0, 0, 0), Math.PI / 2)

        const shadowMat = new THREE.ShadowMaterial()
        shadowMat.opacity = 0.05

        const geo = new THREE.PlaneBufferGeometry(this.WIDTH / 16, this.HEIGHT / 16)
        const plane = new THREE.Mesh(geo, shadowMat)
        // plane.rotateX(-Math.PI / 2)
        plane.receiveShadow = !this.inDarkMode
        this.world.add(floorBody)
        this.scene.add(plane)

        // Walls
        this.wallLeftBody = new C.Body({ mass: 0, shape: new C.Plane(), material: this.barrierBodyMaterial, position: new C.Vec3(0, 0, 5) })
        this.wallLeftBody.quaternion.setFromAxisAngle(new C.Vec3(0, -1, 0), Math.PI / 2)
        this.world.add(this.wallLeftBody)

        this.wallRightBody = new C.Body({ mass: 0, shape: new C.Plane(), material: this.barrierBodyMaterial, position: new C.Vec3(0, 0, 5) })
        this.wallRightBody.quaternion.setFromAxisAngle(new C.Vec3(0, 1, 0), Math.PI / 2)
        this.world.add(this.wallRightBody)

        this.wallTopBody = new C.Body({ mass: 0, shape: new C.Plane(), material: this.barrierBodyMaterial, position: new C.Vec3(0, 0, 5) })
        this.wallTopBody.quaternion.setFromAxisAngle(new C.Vec3(-1, 0, 0), Math.PI / 2)
        this.world.add(this.wallTopBody)

        this.wallBottomBody = new C.Body({ mass: 0, shape: new C.Plane(), material: this.barrierBodyMaterial, position: new C.Vec3(0, 0, 5) })
        this.wallBottomBody.quaternion.setFromAxisAngle(new C.Vec3(1, 0, 0), Math.PI / 2)
        this.world.add(this.wallBottomBody)
    }

    setDiceTray(diceTray) {
        if (diceTray.active) {
            this.adjustWorldBounds(diceTray.y, diceTray.x, 480, 320)
            diceTray.worldCoords = getScreenPosIn3dSpace(this.camera, diceTray.y + 64, diceTray.x + 64, this.WIDTH, this.HEIGHT)
        } else {
            this.adjustWorldBounds()
        }

        this.diceTray = diceTray
        this.diceManager.diceTray = diceTray
        this.UI.diceTray = diceTray
    }

    render() {
        this.renderer = new THREE.WebGLRenderer({ alpha: true })
        this.renderer.shadowMap.enabled = !this.inDarkMode
        // this.renderer.setPixelRatio(window.devicePixelRatio)
        // this.renderer.setSize(window.innerWidth, window.innerHeight)

        this.renderer.setSize(this.WIDTH, this.HEIGHT)
        this.mount.appendChild(this.renderer.domElement)
    }

    draw() {
        this.UI.draw()

        this.updatePhysics()
        this.renderer.render(this.scene, this.camera)
    }

    start() {
        this.renderer.setAnimationLoop(this.draw.bind(this))
    }

    stop() {
        this.renderer.setAnimationLoop(null)
    }

    updatePhysics() {
        if (this.dbr) this.dbr.update()
        if (this.controls) this.controls.update()

        this.diceManager.update()

        this.world.step(1 / 60)
    }

    adjustWorldBounds(x = 0, y = 0, width = this.WIDTH, height = this.HEIGHT) {
        const topLeftScreenIn3D = getScreenPosIn3dSpace(this.camera, x, y, this.WIDTH, this.HEIGHT)
        const bottomRightScreenIn3D = getScreenPosIn3dSpace(this.camera, x + width, y + height, this.WIDTH, this.HEIGHT)

        this.wallLeftBody.position.x = topLeftScreenIn3D.x
        this.wallRightBody.position.x = bottomRightScreenIn3D.x
        this.wallTopBody.position.y = topLeftScreenIn3D.y
        this.wallBottomBody.position.y = bottomRightScreenIn3D.y
    }

    setUI(UI) {
        this.UI = UI
        this.diceManager.UI = UI
    }

    setCloseDiceDrawer(func) {
        this.closeDiceDrawer = func
        this.diceManager.closeDiceDrawer = func
    }

    setDiceInHand(func) {
        this.diceManager.setDiceInHand = func
    }

    setRolling(func) {
        this.diceManager.setRolling = func
    }

    onWindowResize() {
        this.WIDTH = this.mount.clientWidth
        this.HEIGHT = this.mount.clientHeight

        this.cameraWidth = this.WIDTH / this.cameraFactor
        this.cameraHeight = this.HEIGHT / this.cameraFactor

        this.camera.left = this.cameraWidth
        this.camera.right = -this.cameraWidth
        this.camera.top = -this.cameraHeight
        this.camera.bottom = this.cameraHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(this.WIDTH, this.HEIGHT)
        if (this.helper) this.helper.update()

        this.adjustWorldBounds()
        this.diceManager.setBounds(this.WIDTH, this.HEIGHT)
    }

    refocusWindow(e) {
        if (!document.hidden)
            this.draw()
    }

    cleanup() {
        window.removeEventListener('resize', this.onWindowResize)
        window.removeEventListener('visibilitychange', this.refocusWindow)

        this.stop()
        this.UI.cleanup()
        this.diceManager.cleanup()
    }

    setupDebug() {
        this.helper = new THREE.CameraHelper(this.camera)
        this.scene.add(this.helper)

        this.dbr = new CannonDebugRenderer(this.scene, this.world)

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement)
        // this.controls.update()
    }
}