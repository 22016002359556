import shortid from 'shortid'

// add message to local view
export function addMessage(message, user, campaignShortId, roll) {
    const messageObject = {
        campaignShortId,
        messageId: shortid.generate(),
        sender: user._id,
        senderName: user.name,
        message,
        color: roll ? user.diceSkin : user.color,
        date: new Date(),
        roll
    }
    this.dispatch({ type: 'NEW_MESSAGE', value: messageObject })

    return messageObject
}