import React, { useEffect, useState } from 'react'
import { useWorkspaceFunctions } from '../../context/Workspace'

const Scale = ({ index, windowData, scaling, setScaling }) => {
    const { scaleWindow } = useWorkspaceFunctions()
    const [direction, setDirection] = useState(null)

    const scaleWindowHandler = (e) => {
        if (!scaling) return false
        switch (direction) {
            case 'bottom-right': {
                const { clientX, clientY } = e
                const { x, y } = windowData

                const width = clientX - x
                const height = clientY - y

                scaleWindow({ index, x, y, width, height })
                break
            }

            case 'top-left': {
                const { clientX, clientY } = e
                const { x, y, width, height } = windowData

                const ww = width - (clientX - x)
                const hh = height - (clientY - y)
                const yy = hh >= 128 ? clientY : y
                const xx = ww >= 128 ? clientX : x


                scaleWindow({ index, x: xx, y: yy, width: ww, height: hh })
                break
            }
            case 'top-right': {
                const { clientX, clientY } = e
                const { x, y, height } = windowData

                const width = clientX - x
                const hh = height - (clientY - y)
                const yy = hh >= 128 ? clientY : y
                scaleWindow({ index, x, y: yy, width, height: hh })
                break
            }

            case 'bottom-left': {
                const { clientX, clientY } = e
                const { x, y, width } = windowData

                const height = clientY - y
                const ww = width - (clientX - x)
                const xx = ww >= 128 ? clientX : x

                scaleWindow({ index, x: xx, y, width: ww, height })
                break
            }

            case 'left': {
                const { clientX } = e
                const { x, y, width, height } = windowData

                const ww = width - (clientX - x)
                const xx = ww >= 128 ? clientX : x
                scaleWindow({ index, x: xx, y, width: ww, height })
                break
            }

            case 'right': {
                const { clientX } = e
                const { x, y, height } = windowData

                const width = clientX - x

                scaleWindow({ index, x, y, width, height })
                break
            }

            case 'top': {
                const { clientY } = e
                const { x, y, width, height } = windowData

                const hh = height - (clientY - y)
                const yy = hh >= 128 ? clientY : y
                scaleWindow({ index, x, y: yy, width, height: hh })
                break
            }

            case 'bottom': {
                const { clientY } = e
                const { x, y, width } = windowData

                const height = clientY - y

                scaleWindow({ index, x, y, width, height })
                break
            }

            default: return false
        }
    }

    const stopScalingHandler = () => setScaling(false)

    useEffect(() => {
        window.addEventListener('mousemove', scaleWindowHandler)
        window.addEventListener('mouseup', stopScalingHandler)
        return () => {
            window.removeEventListener('mousemove', scaleWindowHandler)
            window.removeEventListener('mouseup', stopScalingHandler)
        }
    })

    return (
        <>
            <div
                className="scale scale-bottom-right"
                onMouseDown={() => {
                    setDirection('bottom-right')
                    setScaling(true)
                }}
            >
            </div>

            <div
                className="scale scale-top-left"
                onMouseDown={() => {
                    setDirection('top-left')
                    setScaling(true)
                }}
            >
            </div>

            <div
                className="scale scale-top-right"
                onMouseDown={() => {
                    setDirection('top-right')
                    setScaling(true)
                }}
            >
            </div>

            <div
                className="scale scale-bottom-left"
                onMouseDown={() => {
                    setDirection('bottom-left')
                    setScaling(true)
                }}
            >
            </div>

            <div
                className="scale scale-left"
                onMouseDown={() => {
                    setDirection('left')
                    setScaling(true)
                }}
            ></div>

            <div
                className="scale scale-right"
                onMouseDown={() => {
                    setDirection('right')
                    setScaling(true)
                }}
            ></div>

            <div
                className="scale scale-top"
                onMouseDown={() => {
                    setDirection('top')
                    setScaling(true)
                }}
            ></div>

            <div
                className="scale scale-bottom"
                onMouseDown={() => {
                    setDirection('bottom')
                    setScaling(true)
                }}
            ></div>
        </>
    )
}

export default Scale
