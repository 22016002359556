import React, { useEffect } from 'react'
import { useCampaignFunctions, useCampaignState } from '../../../../context/Campaign'
import { Button, TextButton } from '../../../../components/Buttons'
import { List, ListItem } from '../../../../components/List'
import { useErrorFunctions } from '../../../../context/Error'
import { useAuthState } from '../../../../context/Auth'
import { useWorkspaceFunctions } from '../../../../context/Workspace'
import useOpenWindow from '../../../../hooks/useOpenWindow'

const UsersAwaitingInvite = ({ _index }) => {
    const { auth, user } = useAuthState()
    const { activeCampaign, socket } = useCampaignState()
    const { addUserToCampaign, removeUserFromCampaign } = useCampaignFunctions()
    const { setError } = useErrorFunctions()

    const { closeWindow, changeWindowColor } = useWorkspaceFunctions()
    const openWindow = useOpenWindow()

    useEffect(() => {
        if (!activeCampaign?.usersAwaitingInvite?.length || activeCampaign?.usersAwaitingInvite?.length === 0) {
            closeWindow(_index)
        }

        if (activeCampaign?.users?.length >= 5 && !user.subscription.active) {
            changeWindowColor({ index: _index, color: 'yellow' })
        }
    }, [activeCampaign, _index, changeWindowColor, closeWindow, user])


    if (activeCampaign?.users?.length >= 5 && !user.subscription.active) {
        return (
            <div className="padding-1 col centered full-height">
                <p className="margin-bottom-2">{activeCampaign.usersAwaitingInvite[0]?.name} would like to join your campaign, But you are out of player slots.</p>
                <Button className="green" onClick={() => openWindow('premiumSignup')}>Upgrade to Premium</Button>
                <TextButton onClick={() => removeUserFromCampaign(auth, activeCampaign.usersAwaitingInvite[0]._id, activeCampaign.shortId, socket, setError)}>Deny</TextButton>
            </div>
        )
    }
    return (
        <div className="padding-1">
            <p className="margin-bottom-2">These users are waiting to be invited to your campaign.</p>
            <List>
                {activeCampaign && activeCampaign?.usersAwaitingInvite?.map((user, i) => (
                    <ListItem className="row centered-vertically space-between" key={i}>
                        <p>{user.name}</p>
                        <div className="row space-evenly">
                            <Button className="margin-horizontal-1 green" onClick={() => addUserToCampaign(auth, user._id, activeCampaign.shortId, socket, setError)}>Accept</Button>
                            <Button className="red" onClick={() => removeUserFromCampaign(auth, user._id, activeCampaign.shortId, socket, setError)}>Deny</Button>
                        </div>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default UsersAwaitingInvite
