import React, { useCallback, useState, useEffect } from 'react'
import ChatUserDialogue from '../ChatUserDialogue'
window.ADVK_REMOVE_HOVERED_TIMER = null

const useDialogue = () => {
    const [state, setState] = useState(null)
    const [hovered, setHovered] = useState([])

    const addHovered = useCallback((target) => {
        const hoveredIndex = hovered.findIndex(item => item === target)
        if (hoveredIndex < 0) {
            setHovered([...hovered, target])
        }
    }, [hovered, setHovered])

    const removeHovered = useCallback((target) => {
        const hoveredIndex = hovered.findIndex(item => item === target)
        if (hoveredIndex > -1) {
            hovered.splice(hoveredIndex, 1)
            setHovered([...hovered])
        }
    }, [hovered, setHovered])

    const setDialog = useCallback((target, id, name, isCurrentUser, x, y, voiceControls) => {
        if (id) {
            addHovered(target)
            setState({ id, name, isCurrentUser, x, y, voiceControls })
        }
    }, [addHovered, setState])

    useEffect(() => {
        clearTimeout(window.ADVK_REMOVE_HOVERED_TIMER)
        if (hovered.length === 0) {
            window.ADVK_REMOVE_HOVERED_TIMER = setTimeout(() => {
                setState(null)
            }, 100)
        }
    }, [hovered, setState])



    const dialog = state ? (
        <ChatUserDialogue
            id={state.id}
            name={state.name}
            isCurrentUser={state.isCurrentUser}
            x={state.x}
            y={state.y}
            addHovered={addHovered}
            removeHovered={removeHovered}
            voiceControls={state.voiceControls}
        />
    ) : null

    return { dialog, setDialog, addHovered, removeHovered }
}

export default useDialogue
