import { useState, useEffect, useCallback } from 'react'
import { useCampaignFunctions, useCampaignState } from '../../../context/Campaign'
import { useErrorFunctions } from '../../../context/Error'
import API from '../../../functions/API'

window.ADVK_LOADED_INITITAL_CHATS = false
const useLoadMessages = (auth) => {
    const [loading, setLoading] = useState(true)
    const { activeCampaign } = useCampaignState()
    const { campaignDispatch } = useCampaignFunctions()

    const { setError } = useErrorFunctions()

    const loadInitialMessages = useCallback(async () => {
        try {
            if (!window.ADVK_LOADED_INITITAL_CHATS && activeCampaign.shortId) {
                const response = await API.sendGraphSecure(auth, {
                    query: `query getMessages($shortId: String!) {
                        getMessages(shortId: $shortId) {
                                _id
                                sender {
                                    _id
                                    name
                                    color
                                    diceSkin
                                }
                                message
                                date
                                roll
                            }
                        }`,
                    variables: {
                        "shortId": activeCampaign.shortId
                    }
                })

                setLoading(false)
                campaignDispatch({ type: "NEW_MESSAGES", value: response.data.getMessages })
                window.ADVK_LOADED_INITITAL_CHATS = true
            }
        } catch (err) {
            setError(err)
        }
    }, [auth, activeCampaign, campaignDispatch, setLoading, setError])

    useEffect(() => {
        loadInitialMessages()
    }, [loadInitialMessages])

    return ({
        messagesLoading: loading
    })
}

export default useLoadMessages
