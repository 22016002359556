import React from 'react'
import { getIcon } from '../../../../functions/helpers'
import ItemIcon from '../ItemIcon'


const ItemDisplay = ({ folderId, isFolderOwner, documents, openDocumentWindow, draggable, minimal }) => {
    return (
        documents.map((doc, i) => {
            return doc ? (
                <ItemIcon
                    key={doc._id}
                    index={i}
                    id={doc._id}
                    icon={getIcon(doc.kind)}
                    kind={doc.kind}
                    folderId={folderId}
                    name={doc.name}
                    owner={doc?.owner?._id}
                    isFolderOwner={isFolderOwner}
                    openDocumentWindow={() => openDocumentWindow(doc)}
                    draggable={draggable}
                    minimal={minimal}
                />
            ) : null
        })
    )
}

export default ItemDisplay
