
import countDieTypes from "../../../functions/dice/functions/countDieTypes"

export function addRollToHistory(rollData) {
    this.dispatch({
        type: 'SAVE_DICE_ROLL',
        value: prepareData(rollData)
    })
}

export function updateRollInHistory(rollData) {
    this.dispatch({
        type: 'UPDATE_DICE_ROLL',
        value: {
            id: rollData.groupId,
            data: prepareData(rollData)
        }
    })
}



const prepareData = (rollData) => {
    let successCount = 0, failureCount = 0
    if (rollData.succeedsOn !== 0 && rollData.succeedsOnDiceOrRoll === 'Dice') {
        rollData.dice.forEach(die => {
            if (rollData.keepBest === 0 || die.keep)
                if (rollData.succeedsOnGreaterOrLess === 'greater' ? die.value < parseInt(rollData.succeedsOn) : die.value > parseInt(rollData.succeedsOn)) {
                    die.failed = true
                    failureCount++
                } else {
                    die.failed = false
                    successCount++
                }
        })
    }

    const explosions = getExplosions(rollData.dice)
    rollData.dice = rollData.dice.filter(die => !die.exploded && !die.explosionParent)
    const { diceCounts, dice } = countDieTypes(rollData.dice, rollData.inProgress)
    return {
        ...rollData,
        diceCounts,
        successCount,
        failureCount,
        explosions,
        countedDice: dice,
        message: getDiceMessage({ ...rollData }),
    }
}

export const getDiceMessage = (rollData) => {
    let message = ''
    const { dice, keepBest, name, label = '', inProgress } = rollData
    const rolledMessage = inProgress ? 'is rolling' : 'rolled'
    const keptMessage = inProgress ? 'keeping' : 'kept'
    if (dice.length === 1) {
        message = `${name} ${rolledMessage} ${label}`
    } else if (keepBest !== 0) {
        if (keepBest === 1) {
            message = `${name} ${rolledMessage} ${dice.length} dice, ${keptMessage} the best`
        } else if (keepBest > 1) {
            message = `${name} ${rolledMessage} ${dice.length} dice, ${keptMessage} the best ${keepBest}`
        } else if (keepBest < 1) {
            message = `${name} ${rolledMessage} ${dice.length} dice, ${keptMessage} the worst ${Math.abs(keepBest)}`
        }
    } else {
        message = `${name} ${rolledMessage} ${dice.length} dice`
    }

    if (rollData.succeedsOn !== 0) {
        if (rollData.succeedsOnDiceOrRoll === 'Dice') {
            if (rollData.succeedsOnGreaterOrLess === 'greater') {
                message = `${message}, checking for dice greater than ${rollData.succeedsOn}`
            }

            if (rollData.succeedsOnGreaterOrLess === 'less') {
                message = `${message}, checking for dice less than ${rollData.succeedsOn}`
            }
        }

        if (rollData.succeedsOnDiceOrRoll === 'Roll') {
            if (rollData.succeedsOnGreaterOrLess === 'greater') {
                message = `${message}, checking for a total greater than ${rollData.succeedsOn}`
            }

            if (rollData.succeedsOnGreaterOrLess === 'less') {
                message = `${message}, checking for a total less than ${rollData.succeedsOn}`
            }
        }
    }

    return message
}

const getExplosions = (dice) => {
    const explodedDice = dice.filter(die => die.exploded && !die.explosionParent)
    explodedDice.forEach(explodedDie => {
        const predecessors = dice.filter(die => die.explosionAncestor === explodedDie.id)
        let lastPred = explodedDie.id
        predecessors.sort(predecessor => {
            const sort = predecessor.explosionParent === lastPred ? -1 : 1
            lastPred = sort > 0 ? predecessor.id : lastPred

            return sort
        })

        explodedDie.predecessors = countDieTypes(predecessors)
    })

    return explodedDice
}