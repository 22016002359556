import adjustHostnameForDev from '../functions/adjustHostnameForDev'
const urlPrefix = (window.location.hostname.includes('qa.')) ? 'graph-qa' : 'graph'
const graphUrl = process.env.NODE_ENV === "production" ? `https://${urlPrefix}.adventuring-kit.com` : adjustHostnameForDev(process.env.REACT_APP_GRAPH_URL)

class API {
    static sendGraph(body, options) {
        let payload = { ...this.postData, body: JSON.stringify(body), signal: options?.abort?.signal }
        return this.handleResponse(fetch(graphUrl, payload), options)
    }

    static sendGraphSecure(auth, body, options) {
        let payload = { ...this.postData, body: JSON.stringify(body), signal: options?.abort?.signal }
        payload.headers.Authorization = 'Bearer ' + auth
        return this.handleResponse(fetch(graphUrl, payload))
    }

    static handleResponse(promise) {
        return promise
            .then(response => response.json())
            .then((body) => {
                if (body.errors) {
                    if (process.env.NODE_ENV) console.log(body.errors)
                    throw new Error(body.errors[0].message)
                }
                return body
            })
    }
}

API.postData = {
    method: "POST",
    headers: {
        "Content-type": "application/json"
    }
}

API.postFormData = {
    method: "POST",
    headers: {
        // accept: "multipart/form-data",
        // "Content-type": "multipart/form-data"
    }
}

API.subscriptions = [];

export default API;