import React, { useReducer, useContext, useMemo } from 'react'
import reducer from './reducer'
import buildFunctions from './buildFunctions'
import GS from '../../functions/GlobalStorage'

const WorkspaceStateContext = React.createContext()
const WorkspaceFunctionsContext = React.createContext()

const diceSettings = localStorage.getItem('diceSettings')
const diceOptions = JSON.parse(diceSettings) || {
    diceTrayActive: false,
    rollPrivately: false,
    disableAnimations: false,
    keepBest: 0,
    addOrSub: 0,
    succeedsOn: 0,
    succeedsOnDiceOrRoll: 'Dice',
    succeedsOnGreaterOrLess: 'greater',
    explodesOn: 0,
    diceTrayCoords: {},
    tokenPaste: null
}

GS.set('DICE_OPTIONS', diceOptions)

const WorkspaceProvider = ({ children }) => {
    const initialState = {
        windows: [],
        tabs: [],
        selectedTab: null,
        minimized: [],
        appDimensions: { width: 0, height: 0 },
        chestDrawerOpen: false,
        diceOptions,
        tempDiceOptions: {
            addOrSub: 0,
            succeedsOn: 0,
            succeedsOnGreaterOrLess: 'greater',
            name: ''
        },
        diceInHand: false,
        rolling: false,
        musicPlaying: [],
        muteSounds: false,
        globalVolume: 1,
        requestingSongSync: false,
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <WorkspaceStateContext.Provider value={state}>
            <WorkspaceFunctionsContext.Provider value={dispatch}>
                {children}
            </WorkspaceFunctionsContext.Provider>
        </WorkspaceStateContext.Provider>
    )
}

function useWorkspaceState() {
    const state = useContext(WorkspaceStateContext)
    if (typeof state === undefined) {
        throw new Error('useWorkspaceState must be used within an WorkspaceProvider')
    }

    return state
}


function useWorkspaceFunctions() {
    const dispatch = useContext(WorkspaceFunctionsContext)
    if (typeof dispatch === undefined) {
        throw new Error('useWorkspaceUpdater must be used within an WorkspaceProvider')
    }
    const functions = useMemo(() => buildFunctions(dispatch), [dispatch])

    return functions
}

export {
    WorkspaceProvider,
    useWorkspaceState,
    useWorkspaceFunctions
}
