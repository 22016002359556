import GS from "../GlobalStorage"
import { dashedLine, circle, lerp, distanceTo } from "../CanvasHelpers"
import { isMobile } from 'react-device-detect'
import { getControlStart } from "../helpers"

export default class SceneUI {
    constructor(mount) {
        this.mount = mount.current
        this.WIDTH = this.mount.clientWidth
        this.HEIGHT = this.mount.clientHeight
        this.mouseX = 0
        this.mouseY = 0
        this.dragStartX = 0
        this.dragStartY = 0
        this.mouseDown = false
        this.rolling = false

        this.lightDragColor = "#abb6bf"
        this.darkDragColor = "#647682"

        this.marchOffset = 0

        this.canvas = document.createElement("canvas")
        this.canvas.id = "scene-ui"
        this.canvas.width = this.WIDTH
        this.canvas.height = this.HEIGHT
        this.canvas.style = `width: ${this.WIDTH}px; height: ${this.HEIGHT}px;`
        this.mount.appendChild(this.canvas)
        this.ctx = this.canvas.getContext("2d")
        this.setCanvasStyles()

        this.helpTimeout = null
        this.shouldHelpUserRoll = isMobile
        this.rollingHelp = false
        this.addOrSubValue = 0
        this.helpTextAlpha = 0
        this.addedValueAlpha = 0

        this.explodingDice = []

        this.diceTray = { active: GS.get('DICE_OPTIONS').diceTrayActive }

        this.onWindowResize = this.onWindowResize.bind(this)
        this.onMouseMove = this.onMouseMove.bind(this)
        this.handleMouseDown = this.handleMouseDown.bind(this)
        this.handleMouseUp = this.handleMouseUp.bind(this)

        window.addEventListener('resize', this.onWindowResize)
        window.addEventListener('mousemove', this.onMouseMove)
        window.addEventListener('touchmove', this.onMouseMove)
        window.addEventListener('mousedown', this.handleMouseDown)
        window.addEventListener('touchstart', this.handleMouseDown)
        window.addEventListener('mouseup', this.handleMouseUp)
        window.addEventListener('touchend', this.handleMouseUp)
    }

    setCanvasStyles() {
        this.ctx.lineWidth = 2
        this.ctx.lineCap = "round"
        this.ctx.strokeStyle = this.lightDragColor

        this.ctx.font = 'bold 16px Open sans'
        this.ctx.textAlign = 'left'
        this.ctx.fillStyle = this.darkDragColor
        this.groupRightOffset = 16
    }

    march() {
        this.marchOffset--;
        if (this.marchOffset === 0) {
            this.marchOffset = 16;
        }
    }

    startRoll(networkObject) {
        this.rolling = true
        if (!networkObject) {
            this.marchTimeout = setInterval(this.march.bind(this), 30)
            if (this.shouldHelpUserRoll && this.helpTimeout === null) {
                this.helpTimeout = setTimeout(this.triggerRollingHelp.bind(this), 1000 * 3)
            }
        }
    }

    stopRoll() {
        this.rolling = false
        clearInterval(this.marchTimeout)

        this.shouldHelpUserRoll = false
        clearTimeout(this.helpTimeout)
        this.helpTimeout = null
    }

    addDice() {
        if (this.rolling === false) this.startRoll()
    }

    triggerRollingHelp() {
        this.rollingHelp = true
    }

    triggerExplodeEffect(position, skin) {
        const effectObj = {
            position,
            skin,
            frame: 0,
            alpha: 1
        }

        this.explodingDice.push(effectObj)
    }

    draw() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        if (this.mouseDown && this.rolling && !this.diceTray.active) {
            this.ctx.lineDashOffset = this.marchOffset
            const dist = distanceTo(this.mouseX, this.mouseY, this.dragStartX, this.dragStartY) / 16
            if (dist > 7.5) { this.ctx.strokeStyle = this.darkDragColor } else { this.ctx.strokeStyle = this.lightDragColor }

            const angle = Math.atan2(this.mouseY - this.dragStartY, this.mouseX - this.dragStartX)

            const offsetX = Math.cos(angle) * (dist * 2) + this.dragStartX
            const offsetY = Math.sin(angle) * (dist * 2) + this.dragStartY

            circle(this.ctx, this.dragStartX, this.dragStartY, dist)
            circle(this.ctx, this.dragStartX, this.dragStartY, dist * 0.75)
            circle(this.ctx, this.dragStartX, this.dragStartY, dist * 0.5)
            dashedLine(this.ctx, offsetX, offsetY, this.mouseX, this.mouseY)
        }

        if (this.rollingHelp) {
            this.helpTextAlpha = lerp(this.helpTextAlpha, 1, 0.1)
            this.ctx.globalAlpha = this.helpTextAlpha
            const textX = this.mouseX - 16
            const textY = this.mouseY - 32
            this.ctx.textAlign = "left"
            this.ctx.fillText(this.diceTray.active ? "Click in the dice tray to throw dice." : "Drag mouse and release, or click to throw dice.", textX + 8, textY + 16)
            this.ctx.globalAlpha = 1
        }

        if (this.rolling) {
            this.ctx.font = 'bold 24px Open sans'
            this.addedValueAlpha = lerp(this.addedValueAlpha, 1, 0.1)
            this.ctx.globalAlpha = this.addedValueAlpha
            const textX = this.mouseX + 16
            const textY = this.mouseY
            const diceOptions = GS.get('DICE_OPTIONS')
            const addOrSubValue = diceOptions.addOrSub !== '-' ? parseInt(diceOptions.addOrSub) : 0
            const succeedsOn = diceOptions.succeedsOn !== 0 ? parseInt(diceOptions.succeedsOn) : 0
            const succeedsOnGreaterOrLess = diceOptions.succeedsOnGreaterOrLess === 'greater' ? '>' : '<'
            const plusOrMinus = addOrSubValue <= 0 ? '' : '+'
            const finalAddOrSubValue = addOrSubValue !== 0 ? plusOrMinus + addOrSubValue : ''
            const finalSucceedsOnValue = succeedsOn > 0 ? succeedsOnGreaterOrLess + succeedsOn : ''


            const addOrSubValueTemp = this.tempDiceOptions.addOrSub !== '-' ? parseInt(this.tempDiceOptions.addOrSub) : 0
            const plusOrMinusTemp = addOrSubValueTemp <= 0 ? '' : '+'
            const finalAddOrSubValueTemp = addOrSubValueTemp !== 0 ? plusOrMinusTemp + addOrSubValueTemp : ''

            const succeedsOnValueTemp = this.tempDiceOptions.succeedsOn !== 0 ? parseInt(this.tempDiceOptions.succeedsOn) : 0
            const greaterOrLessTemp = succeedsOnValueTemp > 0 ? this.tempDiceOptions.succeedsOnGreaterOrLess === 'greater' ? '>' : '<' : ''
            const finalSucceedsOnValueTemp = succeedsOnValueTemp > 0 ? greaterOrLessTemp + succeedsOnValueTemp : ''

            this.ctx.textAlign = "left"
            if (addOrSubValue !== 0 || addOrSubValueTemp !== 0 || succeedsOn !== 0 || succeedsOnValueTemp !== 0) this.ctx.fillText(finalAddOrSubValue + ' ' + finalAddOrSubValueTemp + ' ' + finalSucceedsOnValueTemp || finalSucceedsOnValue || '', textX + 8, textY + 16)
            this.ctx.globalAlpha = 1
            this.ctx.font = 'bold 16px Open sans'

            this.ctx.textAlign = "center"
            const cancelHelpTextX = this.WIDTH / 2
            const cancelHelpTextY = this.HEIGHT - 32
            isMobile ? (
                this.ctx.fillText(`Drag anywhere on the screen to roll dice.`, cancelHelpTextX, cancelHelpTextY)
            ) : (
                this.ctx.fillText(`Press Escape to clear dice from hand or right click in the tray to remove one at a time.`, cancelHelpTextX, cancelHelpTextY)
            )
        }

        this.explodingDice.forEach((effectObj, i) => {
            this.ctx.strokeStyle = this.lightDragColor
            const rad = effectObj.frame + 32

            if (effectObj.frame >= 15) {
                effectObj.alpha = lerp(effectObj.alpha, 0, 0.1)
                this.ctx.globalAlpha = effectObj.alpha
                circle(this.ctx, effectObj.position.x, effectObj.position.y, rad - 15)
            }

            circle(this.ctx, effectObj.position.x, effectObj.position.y, rad)

            if (effectObj.frame >= 5) {
                circle(this.ctx, effectObj.position.x, effectObj.position.y, rad - 5)
            }

            if (effectObj.frame >= 10) {
                circle(this.ctx, effectObj.position.x, effectObj.position.y, rad - 10)
            }

            if (effectObj.frame >= 60) {
                this.explodingDice.splice(i, 1)
            }

            this.ctx.globalAlpha = 1

            effectObj.frame++
        })
    }

    onMouseMove(e) {
        const coords = getControlStart(e)
        this.mouseX = coords.clientX
        this.mouseY = coords.clientY
    }

    handleMouseDown(e) {
        const coords = getControlStart(e)
        this.mouseDown = true
        this.dragStartX = coords.clientX
        this.dragStartY = coords.clientY
        this.mouseX = coords.clientX
        this.mouseY = coords.clientY

        this.helpTextAlpha = 0
        this.rollingHelp = false
        clearTimeout(this.helpTimeout)
        this.helpTimeout = null
    }

    handleMouseUp() {
        this.mouseDown = false
        this.rollingHelp = false
        if (this.rolling && this.shouldHelpUserRoll && this.helpTimeout === null) {
            this.helpTimeout = setTimeout(this.triggerRollingHelp.bind(this), 1000 * 3)
        }
    }

    onWindowResize() {
        this.WIDTH = this.mount.clientWidth
        this.HEIGHT = this.mount.clientHeight

        this.canvas.width = this.WIDTH
        this.canvas.height = this.HEIGHT
        this.canvas.style = `width: ${this.WIDTH}px; height: ${this.HEIGHT}px;`

        this.setCanvasStyles()
    }

    cleanup() {
        window.removeEventListener('resize', this.onWindowResize)
        window.removeEventListener('mousemove', this.onMouseMove)
        window.removeEventListener('touchmove', this.onMouseMove)
        window.removeEventListener('mousedown', this.handleMouseDown)
        window.removeEventListener('touchstart', this.handleMouseDown)
        window.removeEventListener('mouseup', this.handleMouseUp)
        window.removeEventListener('touchend', this.handleMouseUp)
        clearInterval(this.marchTimeout)
        clearTimeout(this.helpTimeout)
    }
}