import * as THREE from 'three'
import { getGeoDataForDiceType } from "./getGeoDataForDiceType"
import rotateDiceToValue from './rotateDiceToValue'

export default function getUpsideValue(die, camera) {
    const shape = die.body.shapes[0]
    const geoData = getGeoDataForDiceType(die.type)

    let closest_angle = Math.PI * 2
    let closest_face = null

    const cameraPos = camera.position.clone()
    if (geoData.invertUpside) cameraPos.z = cameraPos.z * -1

    shape.faceNormals.forEach((normal, i) => {
        if (geoData.valueMax ? i < geoData.valueMax + 1 : true) {
            let angle = (new THREE.Vector3()).copy(normal).applyQuaternion(die.body.quaternion).angleTo(cameraPos)
            if (angle < closest_angle) {
                closest_angle = angle
                closest_face = i
            }
        }
    })

    const value = geoData.valueMap.find((value => value.index === closest_face))?.value
    rotateDiceToValue(geoData, die, value)

    return value
}