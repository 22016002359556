import React, { useEffect, useRef, useState } from 'react';
import useRenameDocument from '../../../../hooks/useRenameDocument';

const InventoryName = ({ id, name, kind, setLoading, editingName, minimal, setEditingName, isOwner }) => {
    const nameRef = useRef(null)
    const [newName, setNewName] = useState(name)
    const { text, onChange, setText } = useRenameDocument(newName, id, kind, setLoading)

    useEffect(() => {
        if (editingName && nameRef.current) {
            nameRef.current.focus()
        }
    }, [editingName])

    useEffect(() => {
        if (newName !== text) {
            const timeout = setTimeout(() => {
                onChange(newName)
            }, 300)

            return () => clearTimeout(timeout)
        }
    }, [text, newName, onChange, setLoading, editingName])

    useEffect(() => {
        if (!editingName && name !== newName) {
            setText(name)
            setNewName(name)
        }
    }, [editingName, name, newName, setText])

    return editingName ? (
        <input
            ref={nameRef}
            className="window-title-input t-small c-main padding-left-quarter"
            placeholder="Untitled..."
            value={newName || ''}
            onChange={(e) => setNewName(e.target.value)}
            onBlur={() => setEditingName(false)}
            onKeyDown={(e) => {
                if (editingName && e.key === 'Enter') {
                    setEditingName(false)
                    if (newName !== text) onChange(newName)
                }
            }}
        />
    ) : (
        <p
            className={`${minimal ? 'full-width whitespace-nowrap t-overflow-elipses' : 'window-title-display'} t-small c-main padding-left-quarter ${isOwner && 'isOwner'}`}
        >{newName || 'Untitled...'}</p>
    )
};


export default InventoryName;
