import userMinimum from './userMinimum'
import allUserTypesAtMinimum from './allUserTypesAtMinimum'

const fullCampaign = `
    _id
    shortId
    title
    description
    owner{
        ${userMinimum}
    }
    ${allUserTypesAtMinimum}
`

export default fullCampaign