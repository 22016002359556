import React, { useState, useEffect, useMemo } from 'react';
import { Container } from 'react-smooth-dnd'
import ItemDisplay from '../ItemDisplay/ItemDisplay';
import Loading from '../../../Loading'
import Icon from '../../../Icon';
import { Crown } from '@styled-icons/boxicons-solid/Crown'
import { Book } from '@styled-icons/fa-solid/Book'
import { User as UserIcon } from '@styled-icons/boxicons-solid/User'
import { useAuthState } from '../../../../context/Auth';
import FolderName from './FolderName';
import { Settings } from '@styled-icons/fluentui-system-filled/Settings'
import FolderEditMode from './FolderEditMode';
import { TextButton } from '../../../Buttons';
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft'
import { useInventoryState } from '../../../../context/Inventory';
import { getFolderColors } from '../../../../functions/helpers';
import './Folder.css'
import useOnInventoryDrop from '../../useOnInventoryDrop';
import NothingToSee from '../../../NothingToSee/NothingToSee';
import useClickPreventionOnDoubleClick from '../../../../hooks/useClickPreventionDoubleClick';

const FolderInner = ({ id, index, name, owner, documents, open, setOpen, setLoading, openDocumentWindow, draggable, minimal, defaultFolder, sharedCampaigns, sharedUsers }) => {
    const [nameLoading, setNameLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editingName, setEditingName] = useState(false)
    const { user } = useAuthState()
    const { campaigns } = useInventoryState()
    const { onDrop, onDragStart, onDragEnd, hoveredFolderId } = useOnInventoryDrop()

    const [sharedToUser, setSharedToUser] = useState(false)
    const [sharedToCampaign, setSharedToCampaign] = useState(false)

    useEffect(() => {
        if (user._id === owner._id) {
            setSharedToCampaign(sharedCampaigns.length > 0)
            setSharedToUser(sharedUsers.length > 0)
        }
        else {
            let isSharedInCampaign
            if (campaigns.length) {
                isSharedInCampaign = campaigns.find(campaign => sharedCampaigns.find(sharedC => sharedC._id === campaign._id))
                setSharedToCampaign(isSharedInCampaign)
            }

            const userIsDirectShared = sharedUsers.find(sharedU => sharedU._id === user._id)
            setSharedToUser(userIsDirectShared)
        }
    }, [campaigns, owner, sharedCampaigns, sharedUsers, user._id])

    useEffect(() => {
        let hoveredFolderTimeout
        hoveredFolderTimeout = setTimeout(() => {
            if (hoveredFolderId === id && !open) {
                setOpen(true)
            }
        }, 300)

        return () => clearTimeout(hoveredFolderTimeout)
    }, [hoveredFolderId, id, open, setOpen])

    const folderColors = useMemo(() => {
        return getFolderColors(sharedToCampaign, sharedToUser)
    }, [sharedToCampaign, sharedToUser])


    const onItemClick = () => {
        setOpen(!open)
        setEditMode(false)
    }

    const onItemDoubleClick = () => {
        if (!minimal && owner._id === user._id)
            setEditingName(true)
    }

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onItemClick, onItemDoubleClick);

    return <div
        style={{ "--animation-order": index }}
        className={`folder-inner overflow-hidden col full-width full-height rounded centered-vertically margin-bottom-half ${!defaultFolder && `border ${folderColors}`}`}
    >
        {!defaultFolder && (
            <div
                className="folder-topbar row full-width space-between centered-vertically padding-quarter"
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
            >
                <div className="centered-vertically full-width">
                    {nameLoading ? <Loading
                        className="size-1 min-size-1 margin-right-quarter"
                    /> : <Icon
                        className='margin-bottom-quarter min-size-1 size-1 margin-right-quarter'
                        value={sharedToCampaign ? 'tome' : sharedToUser ? 'character' : 'chest-closed'}
                    />}
                    <FolderName
                        id={id}
                        name={name}
                        minimal={minimal}
                        isOwner={owner._id === user._id}
                        owner={owner}
                        setLoading={setNameLoading}
                        editingName={editingName}
                        setEditingName={setEditingName}
                    />
                </div>
                {
                    <div className="row full-height centered-vertically">
                        {editMode ? (
                            <TextButton className='c-main t-capitalize whitespace-nowrap centered' onClick={(e) => { setEditMode(false); e.stopPropagation() }}>
                                <ArrowLeft className="size-1 padding-right-quarter" />
                                Back To Items
                            </TextButton>
                        ) : (
                            <div className='row centered c-main'>
                                {owner._id === user._id && <Crown className={`c-main min-size-75 size-75 `} />}
                                {(sharedToCampaign || sharedToUser) && (
                                    <div className={`border-right height-1 margin-left-half margin-right-half ${folderColors}`}></div>
                                )}
                                {sharedToCampaign && (
                                    <span className="row centered t-small t-bold">
                                        {sharedCampaigns.length}
                                        <Book style={{ transform: `scale(0.8)` }} className={`c-main opacity-75 min-size-75 size-75`} />
                                    </span>
                                )}
                                {sharedToCampaign && sharedToUser && (
                                    <div className={`border-right height-1 margin-left-half margin-right-half ${folderColors}`}></div>
                                )}
                                {sharedToUser && (
                                    <span className="row centered t-small t-bold">
                                        {sharedUsers.length}
                                        <UserIcon className={`c-main opacity-75 min-size-75 size-75`} />
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                }
                {(minimal || editMode) ? null : <div className={`folderHoverIconWrapper ${folderColors} centered-vertically`}>
                    <p className='c-main t-small margin-right-half'>Settings</p>
                    <Settings className='folderHoverIcon c-main size-1' onClick={(e) => {
                        e.stopPropagation()
                        setEditMode(!editMode)
                        setOpen(true)
                    }} />
                </div>}
            </div>
        )}
        {open && (editMode ? (
            <FolderEditMode
                folderId={id}
                sharedCampaigns={sharedCampaigns}
                sharedUsers={sharedUsers}
                isOwner={owner._id === user._id}
                owner={owner}
                sharedToCampaign={sharedToCampaign}
                sharedToUser={sharedToUser}
                setLoading={setLoading}
            />
        ) : (
            <div className='height-0 overflow-auto full-width'>
                <div className='folder--folderDocuments padding-horizontal-quarter row full-width flex-1'>
                    {!documents ? <Loading /> : (
                        <>

                            {
                                (documents?.left?.length === 0 && documents?.right?.length === 0) ? (
                                    <div className='full-width height-8 centered position-absolute pointer-events-none'>
                                        <NothingToSee
                                            hideDefaultMessage
                                            message={defaultFolder ? 'Create items or drag them here.' : 'Empty folder drag items here.'}
                                            titleClassName="c-main"
                                            className='padding-top-0 pointer-events-none'
                                        />
                                    </div>
                                ) : null
                            }
                            {draggable ?
                                (
                                    <>
                                        <div className="half-width flex-1 padding-left-quarter padding-right-quarter">
                                            <Container groupName="ITEMS"
                                                dragClass="dragging-item"
                                                autoScrollEnabled={false}
                                                onDragStart={onDragStart}
                                                onDragEnd={onDragEnd}
                                                onDrop={(payload) => {
                                                    onDrop('ITEMS', payload, id, 'left')
                                                }}
                                                getChildPayload={i => documents.left[i]}
                                                dropPlaceholder={{
                                                    animationDuration: 150,
                                                    showOnTop: true,
                                                    className: `items-drop-preview bg-shade-2 bc-main ${open && 'open'}`
                                                }}
                                            >
                                                <ItemList
                                                    id={id}
                                                    documents={documents.left}
                                                    openDocumentWindow={openDocumentWindow}
                                                    draggable={draggable}
                                                    minimal={minimal}
                                                    isFolderOwner={owner._id === user._id}
                                                />
                                            </Container>
                                        </div>
                                        <div
                                            className="half-width flex-1 padding-left-quarter padding-right-quarter"
                                        >
                                            <Container
                                                groupName="ITEMS"
                                                dragClass="dragging-item"
                                                autoScrollEnabled={false}
                                                onDragStart={onDragStart}
                                                onDragEnd={onDragEnd}
                                                onDrop={(payload) => {
                                                    onDrop('ITEMS', payload, id, 'right')
                                                }}
                                                getChildPayload={i => documents.right[i]}
                                                dropPlaceholder={{
                                                    animationDuration: 150,
                                                    showOnTop: true,
                                                    className: `items-drop-preview bg-shade-2 bc-main ${open && 'open'}`
                                                }}
                                            >
                                                <ItemList
                                                    id={id}
                                                    documents={documents.right}
                                                    openDocumentWindow={openDocumentWindow}
                                                    draggable={draggable}
                                                    minimal={minimal}
                                                    isFolderOwner={owner._id === user._id}
                                                />
                                            </Container>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="half-width flex-1 padding-quarter">
                                            <ItemList
                                                id={id}
                                                documents={documents.left}
                                                openDocumentWindow={openDocumentWindow}
                                                draggable={draggable}
                                                minimal={minimal}
                                            />
                                        </div>
                                        <div className="half-width flex-1 padding-quarter">
                                            <ItemList
                                                id={id}
                                                documents={documents.right}
                                                openDocumentWindow={openDocumentWindow}
                                                draggable={draggable}
                                                minimal={minimal}
                                            />
                                        </div>
                                    </>
                                )}
                        </>
                    )}
                </div >
            </div>
        ))}
    </div >;
};

export default FolderInner;

const ItemList = ({ id, isFolderOwner, documents, openDocumentWindow, draggable, minimal }) => {
    return <ItemDisplay
        folderId={id}
        documents={documents}
        openDocumentWindow={openDocumentWindow}
        draggable={draggable}
        minimal={minimal}
        isFolderOwner={isFolderOwner}
    />
}