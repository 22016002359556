import { useEffect } from 'react'

//if there are selected tokens and any tokens get an update, we need to cycle through any tokens that are selected and update them.
const useUpdateSelectedTokens = (setCurrentlySelectedTokens, tokens) => {
    useEffect(() => {
        setCurrentlySelectedTokens((lastSelectedTokens) => {
            const newSelected = lastSelectedTokens.map((selectedToken) => {
                const matchingToken = tokens.find(token => token.id === selectedToken.id)
                return ({
                    ...selectedToken,
                    ...matchingToken
                })
            })

            return newSelected
        })
    }, [setCurrentlySelectedTokens, tokens])
}

export default useUpdateSelectedTokens