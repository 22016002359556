import React, { useEffect, useRef, useState } from 'react'
import Icon from '../../Icon'
import { Slider } from '../../Slider'

import { PlayFill, PauseFill } from '@styled-icons/bootstrap/'
import { IconButton } from '../../Buttons'
import { RepeatOutline } from '@styled-icons/evaicons-outline/RepeatOutline'
import VolumeIcon from './VolumeIcon'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { useWorkspaceFunctions } from '../../../context/Workspace'
import { convertAudioTime, getSoundData, setSoundData } from '../../../pages/Workspace/partials/SoundControls/functions'
import { useCampaignState } from '../../../context/Campaign'
import Loading from '../../Loading'

const SoundBoardEntry = ({ sound, onRemoveClick, minified }) => {
    const [time, setTime] = useState(0)
    const currentTimeTimeout = useRef(null)

    const {
        id,
        playing,
        duration,
        repeat,
        volume,
        currentTime,
        loading
    } = sound

    const { workspaceDispatch } = useWorkspaceFunctions()
    const { socket, activeCampaign } = useCampaignState()
    const updateSoundData = (update) => {
        const newSoundData = { ...sound, ...update }
        workspaceDispatch({ type: 'UPDATE_PLAYING_SONG', value: newSoundData })
        if (socket && activeCampaign?._id) {
            socket.emit('UPDATE_PLAYING_SONG', JSON.stringify(newSoundData))
        }
    }

    useEffect(() => {
        setTime(currentTime)
    }, [currentTime])

    const handleRepeatClick = (e) => {
        e.stopPropagation()
        updateSoundData({ repeat: !repeat })
    }

    const handleRemoveClick = (e) => {
        e.stopPropagation()
        onRemoveClick(sound)
    }

    useEffect(() => {
        if (playing) {
            currentTimeTimeout.current = setInterval(() => setTime(getSoundData(id)?.currentTime), 200)
        } else {
            clearTimeout(currentTimeTimeout.current)
        }

        return () => clearTimeout(currentTimeTimeout.current)
    }, [id, playing])

    return (
        <div
            className={`soundEntry ${minified && 'minified'} ${playing && 'playing'} row full-width border rounded padding-none overflow-hidden cursor-pointer margin-top-half margin-bottom-half`}
            onClick={() => updateSoundData({ playing: !playing })}
        >
            <div className='col full-width'>
                <div className={`row ${minified ? 'padding-quarter' : 'padding-1'}`}>
                    <div className='full-width'>
                        <div className='col'>
                            <div className='row centered-vertically space-between'>
                                <div className='row centered-vertically'>
                                    {minified ? null : <Icon className="min-size-2 size-2" value={sound.category} />}
                                    {loading ? <Loading className="size-2 padding-quarter" /> : playing ? <PauseFill className='min-size-2 size-2' /> : <PlayFill className='min-size-2 size-2' />}
                                    <p style={{ maxWidth: "200px" }} className='whitespace-nowrap t-overflow-elipses'>{sound.title || 'Untitled'}</p>

                                    {minified ? null : <IconButton className='stroke-none grow-hover' onClick={handleRepeatClick}>
                                        <RepeatOutline className={`min-size-1 size-1 bc-none ${repeat ? 'c-dark-cyan' : 'c-shade-4'}`} />
                                    </IconButton>}
                                </div>
                                {minified ? null : <div className="row centered-vertically soundEntryWrapper">
                                    <IconButton className='soundEntryCloseButton stroke-none grow-hover' onClick={handleRemoveClick}>
                                        <CloseOutline className={`c-shade-6 size-2 bc-none`} />
                                    </IconButton>
                                </div>}
                            </div>
                            {minified ? null : <div className="row centered-vertically space-between">
                                <div className="row centered-vertically">
                                    <VolumeIcon />
                                    <Slider
                                        min={0}
                                        max={100}
                                        value={volume * 100}
                                        className="full-width margin-0 margin-left-1 height-quarter"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                        onChange={(e) => {
                                            updateSoundData({ volume: e.target.value / 100 })
                                        }}
                                    />
                                </div>
                                <p className="soundEntryTime t-small">{convertAudioTime(duration - (time || 0))}</p>
                            </div>}
                        </div>
                    </div>
                </div>
                {minified ? null : <Slider
                    min={0}
                    max={duration || 1}
                    value={((time / duration) * duration) || 0}
                    className="full-width hideKnob margin-0 square height-half"
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={(e) => {
                        updateSoundData({ currentTime: e.target.value })
                        setTime(e.target.value)
                        const sd = getSoundData(id)
                        if (sd) {
                            sd.currentTime = e.target.value
                        }
                        setSoundData(id, sd)
                        if (socket && activeCampaign?._id) {
                            socket.emit('UPDATE_SONG_TRACKING', JSON.stringify({ id, currentTime: e.target.value }))
                        }
                    }}
                />}
            </div>
        </div>
    )
}

export default SoundBoardEntry
