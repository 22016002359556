import React from 'react'

const Dots = () => {
    return (
        <svg id="dots-icon" data-name="dots-icon" xmlns="http://www.w3.org/2000/svg" width="7" height="39" viewBox="0 0 7 39">
            <g transform="translate(-203 -37)">
                <circle cx="3.5" cy="3.5" r="3.5" transform="translate(203 37)" />
                <circle cx="3.5" cy="3.5" r="3.5" transform="translate(203 53)" />
                <circle cx="3.5" cy="3.5" r="3.5" transform="translate(203 69)" />
            </g>
        </svg>

    )
}

export default Dots
