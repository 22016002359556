import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import TopToolBar from './partials/TopToolBar'
import Loading from '../Loading'
import './MapViewer.css'
import useKeyControls from './hooks/useKeyControls'
import MapModal from './partials/MapModal'
import { useCampaignFunctions } from '../../context/Campaign'
import { useAuthState } from '../../context/Auth'
import { getStartingData, submitOp } from '../../functions/helpers'
import useSaveDocument from '../../hooks/useSaveDocument'
import { useWorkspaceFunctions } from '../../context/Workspace'
import useRemoveToken from '../../hooks/useRemoveToken'
import MapTokens from './partials/MapTokens/index'


const MapViewer = ({
    id,
    initialValue,
    ownerId,
    windowIndex,
    windowPos,
    saving,
    isFullScreen,
    broadcasted,
    broadcasting
}) => {
    const [data, setData] = useState(getStartingData(null, 'Map'))
    const [loading, setLoading] = useState(false)

    const { user } = useAuthState()
    const { campaignDispatch } = useCampaignFunctions()
    const { workspaceDispatch } = useWorkspaceFunctions()

    const [tool, setTool] = useState('select')
    const [lastTool, setLastTool] = useState(tool)
    const [showNames, setShowNames] = useState(true)
    const [showGridScaler, setShowGridScaler] = useState(false)

    const scene = useRef(null)
    const [localModalState, setLocalModalState] = useState({})

    useEffect(() => {
        if (showGridScaler && (localModalState.open === false || localModalState.type !== 'grid')) setShowGridScaler(false)
    }, [localModalState.open, localModalState.type, showGridScaler])

    const assignTool = useCallback((tool = 'select') => {
        setTool(tool)
        if (tool !== 'draw' && localModalState.open && localModalState.type === 'draw-settings') setLocalModalState({ ...localModalState, open: false })
        if (tool !== 'ping' && localModalState.open && localModalState.type === 'ping-settings') setLocalModalState({ ...localModalState, open: false })
        if (tool !== 'draw' && tool !== 'ping') setLocalModalState({ ...localModalState, open: false })
    }, [localModalState])


    const [drawToolSettings, setDrawToolSettings] = useState(() => {
        const jsonData = localStorage.getItem('drawToolSettings')

        return jsonData ? JSON.parse(jsonData) : { tool: 'marker', thickness: 3, color: 'red' }
    })
    const [pingSettings, setPingSettings] = useState(localStorage.getItem('pingSettings') || 'red')

    const changeDrawToolSettings = (change) => {
        setDrawToolSettings(change)
        localStorage.setItem('drawToolSettings', JSON.stringify(change))
    }

    const changePingSettings = (change) => {
        setPingSettings(change)
        localStorage.setItem('pingSettings', change)
    }

    const { doc } = useSaveDocument(id, initialValue, setData, 'Map', windowIndex)

    const toggleModal = useCallback((side, type, width = 256) => {
        setLocalModalState({
            type,
            side,
            width,
            open: !type || localModalState.type === type ? !localModalState.open : true
        })
    }, [localModalState.open, localModalState.type])

    useEffect(() => {
        campaignDispatch({ type: 'UPDATE_MODAL_STATE', value: isFullScreen ? localModalState : {} })
    }, [campaignDispatch, isFullScreen, localModalState])

    useEffect(() => {
        if (data.fogCuts.length === 0 && tool === 'fog-fill') assignTool('cut-fog')
    }, [assignTool, data.fogCuts, tool])

    const updateDoc = useCallback((op) => {
        workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
        submitOp(doc, op)
    }, [doc, windowIndex, workspaceDispatch])

    const { mapKeyControls, releaseKeyControls } = useKeyControls(tool, assignTool, lastTool, setLastTool, setShowNames)

    const onRemoveToken = useRemoveToken(setLoading)
    const [camera, setCamera] = useState({ x: 0, y: 0, zoom: 1 })

    const topBar = useMemo(() => <TopToolBar
        showGrid={data.showGrid}
        fogEnabled={data.fogEnabled}
        paint={data.paint}
        image={data.image}
        hasFogCuts={data.fogCuts.length > 0}
        id={id}
        isOwner={user._id === ownerId}
        setLoading={setLoading}
        doc={doc}
        tool={tool}
        setTool={assignTool}
        modalState={localModalState}
        toggleModal={toggleModal}
        saving={saving}
        windowIndex={windowIndex}
        isFullScreen={isFullScreen}
        broadcasted={broadcasted}
        broadcasting={broadcasting}
        pingColor={pingSettings}
        drawToolSettings={drawToolSettings}
        setCamera={setCamera}
        setShowGridScaler={setShowGridScaler}
        gridSize={data.gridSize}
        gridTop={data.gridTop}
        gridLeft={data.gridLeft}
        windowPos={windowPos}
    />, [assignTool, broadcasted, broadcasting, data.fogCuts.length, data.fogEnabled, data.gridLeft, data.gridSize, data.gridTop, data.image, data.paint, data.showGrid, doc, drawToolSettings, id, isFullScreen, localModalState, ownerId, pingSettings, saving, toggleModal, tool, user._id, windowIndex, windowPos])

    const mapTokens = useMemo(() => <MapTokens
        docId={id}
        ownerId={ownerId}
        backgroundImageUrl={data.image}
        tool={tool}
        pingSettings={pingSettings}
        showGrid={data.showGrid}
        gridSize={data.gridSize}
        gridLeft={data.gridLeft}
        gridTop={data.gridTop}
        tokens={data.tokens}
        fogCuts={data.fogCuts}
        fogEnabled={data.fogEnabled}
        paint={data.paint}
        paintSettings={drawToolSettings}
        windowPos={windowPos}
        isFullScreen={isFullScreen}
        showNames={showNames}
        openTokenModal={(selectedTokens) => {
            const tokens = []
            selectedTokens.forEach(nst => {
                const tokenMatch = data.tokens.find(token => token.id === nst.id)
                if (tokenMatch) tokens.push(tokenMatch)
            })

            setLocalModalState({
                type: tokens.length > 1 ? 'multipleSelectedTokens' : 'selectedToken',
                side: 'right',
                width: 275,
                open: tokens.length > 0,
                selectedToken: tokens.length ? tokens.length > 1 ? tokens : tokens[0] : null
            })
        }}
        updateDoc={updateDoc}
        onRightClick={() => { if (scene.current) scene.current.altMouseButtonDown = true }}
        windowIndex={windowIndex}
        camera={camera}
        setCamera={setCamera}
        showGridScaler={showGridScaler}
    />, [camera, data.fogCuts, data.fogEnabled, data.gridLeft, data.gridSize, data.gridTop, data.image, data.paint, data.showGrid, data.tokens, drawToolSettings, id, isFullScreen, ownerId, pingSettings, showGridScaler, showNames, tool, updateDoc, windowIndex, windowPos])

    return (
        <div
            className="full-height"
            onKeyDown={mapKeyControls}
            onKeyUp={releaseKeyControls}
            tabIndex={0}
        >
            {topBar}
            {loading && <Loading overlay opaque />}
            <div className={`map-wrapper ${tool}`}>
                <MapModal
                    modalState={localModalState}
                    setModalState={setLocalModalState}
                    drawToolSettings={drawToolSettings}
                    setDrawToolSettings={changeDrawToolSettings}
                    pingColor={pingSettings}
                    setPingColor={changePingSettings}
                    data={data}
                    doc={doc}
                    removeTokenImage={onRemoveToken}
                    userImages={user.tokens}
                    windowIndex={windowIndex}
                    documentId={id}
                />
                {mapTokens}
            </div>
        </div>
    )
}

export default MapViewer