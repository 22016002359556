import React from 'react'

const Ink = () => {
    return (
        <svg id="ink" data-name="ink" xmlns="http://www.w3.org/2000/svg" width="97.094" height="97.094" viewBox="0 0 97.094 97.094">
            <g id="Group_1053" data-name="Group 1053" transform="translate(18.205 44.47)">
                <path id="Path_3630" data-name="Path 3630" d="M156.684,264.842v-7.585a9.482,9.482,0,0,0-9.482-9.482h-5.689a3.793,3.793,0,0,1-3.793-3.793V234.5H114.964v9.482a3.793,3.793,0,0,1-3.793,3.793h-5.689A9.482,9.482,0,0,0,96,257.256l30.342,10.9Z" transform="translate(-96 -234.5)" fill="#9bc5ff" />
            </g>
            <g id="Group_1054" data-name="Group 1054" transform="translate(18.205 44.47)">
                <path id="Path_3631" data-name="Path 3631" d="M114.964,257.256a9.482,9.482,0,0,1,9.482-9.482h17.067a3.793,3.793,0,0,1-3.793-3.793V234.5H114.964v9.482a3.793,3.793,0,0,1-3.793,3.793h-5.689A9.482,9.482,0,0,0,96,257.256l18.964,6.815Z" transform="translate(-96 -234.5)" fill="#69a8ff" />
            </g>
            <g id="Group_1055" data-name="Group 1055" transform="translate(25.791 38.781)">
                <path id="Path_3632" data-name="Path 3632" d="M175.824,215.878H141.689a5.689,5.689,0,0,1,0-11.378h34.135a5.689,5.689,0,0,1,0,11.378Z" transform="translate(-136 -204.5)" fill="#cde2ff" />
            </g>
            <g id="Group_1056" data-name="Group 1056" transform="translate(25.791 38.781)">
                <path id="Path_3633" data-name="Path 3633" d="M151.171,210.189a5.689,5.689,0,0,1,5.689-5.689H141.689a5.689,5.689,0,0,0,0,11.378H156.86A5.689,5.689,0,0,1,151.171,210.189Z" transform="translate(-136 -204.5)" fill="#9bc5ff" />
            </g>
            <g id="Group_1057" data-name="Group 1057" transform="translate(18.205 67.226)">
                <path id="Path_3634" data-name="Path 3634" d="M105.482,382.945H147.2a9.482,9.482,0,0,0,9.482-9.482V362.085c-30.342,0-30.342-7.585-60.684-7.585v18.964A9.482,9.482,0,0,0,105.482,382.945Z" transform="translate(-96 -354.5)" fill="#7e8596" />
            </g>
            <g id="Group_1058" data-name="Group 1058" transform="translate(18.205 67.226)">
                <path id="Path_3635" data-name="Path 3635" d="M105.482,382.945h18.964a9.482,9.482,0,0,1-9.482-9.482V355.833A117.382,117.382,0,0,0,96,354.5v18.964A9.482,9.482,0,0,0,105.482,382.945Z" transform="translate(-96 -354.5)" fill="#636978" />
            </g>
            <g id="Group_1059" data-name="Group 1059" transform="translate(16.593 1.422)">
                <path id="Path_3636" data-name="Path 3636" d="M134.3,7.5h17.393a14.886,14.886,0,1,1,0,29.773H119.128a3.793,3.793,0,0,1-2.682-1.111l-3.793-3.793a3.793,3.793,0,0,0-5.364,0L103.6,36.064a3.791,3.791,0,0,1-3.174,1.08A14.887,14.887,0,0,1,102.386,7.5h13.6a3.793,3.793,0,0,1,2.682,1.111l3.793,3.793a3.793,3.793,0,0,0,5.364,0l3.793-3.793A3.793,3.793,0,0,1,134.3,7.5Z" transform="translate(-87.5 -7.5)" fill="#ff8870" />
            </g>
            <g id="Group_1061" data-name="Group 1061" transform="translate(16.593 1.422)">
                <g id="Group_1060" data-name="Group 1060">
                    <path id="Path_3637" data-name="Path 3637" d="M119.454,22.386a14.823,14.823,0,0,1,3.493-9.577,3.814,3.814,0,0,1-.486-.406l-3.793-3.793A3.793,3.793,0,0,0,115.986,7.5h-13.6a14.887,14.887,0,0,0-1.965,29.644,3.792,3.792,0,0,0,3.174-1.08l3.695-3.695a3.793,3.793,0,0,1,5.364,0l3.793,3.793a3.793,3.793,0,0,0,2.682,1.111H134.34a14.887,14.887,0,0,1-14.886-14.886Z" transform="translate(-87.5 -7.5)" fill="#ff583e" />
                </g>
            </g>
            <g id="Group_1062" data-name="Group 1062" transform="translate(1.422 13.464)">
                <path id="Path_3638" data-name="Path 3638" d="M7.5,73.845a2.845,2.845,0,0,0,2.845,2.845h51.2a2.845,2.845,0,1,0,0-5.689h-51.2A2.845,2.845,0,0,0,7.5,73.845Z" transform="translate(-7.5 -71)" fill="#fff8ab" />
            </g>
            <g id="Group_1063" data-name="Group 1063" transform="translate(1.422 13.464)">
                <path id="Path_3639" data-name="Path 3639" d="M35.946,73.845a2.845,2.845,0,0,0,2.845,2.845H10.345a2.845,2.845,0,0,1,0-5.689H38.79A2.845,2.845,0,0,0,35.946,73.845Z" transform="translate(-7.5 -71)" fill="#ffee6e" />
            </g>
            <g id="Group_1071" data-name="Group 1071" transform="translate(0)">
                <path id="Path_3643" data-name="Path 3643" d="M141.124,215.964h-5.689a2.373,2.373,0,0,1-2.37-2.37v-2.37h4.267a7.111,7.111,0,1,0,0-14.223H103.2a7.111,7.111,0,1,0,0,14.223h4.267v2.37a2.373,2.373,0,0,1-2.37,2.37H99.4a10.916,10.916,0,0,0-10.9,10.9v18.964a10.916,10.916,0,0,0,10.9,10.9h41.72a10.916,10.916,0,0,0,10.9-10.9V226.868A10.916,10.916,0,0,0,141.124,215.964ZM98.93,204.111a4.272,4.272,0,0,1,4.267-4.267h34.135a4.267,4.267,0,1,1,0,8.534H103.2A4.272,4.272,0,0,1,98.93,204.111Zm50.254,28.914a122.047,122.047,0,0,1-14.057-.845,1.422,1.422,0,1,0-.35,2.823,124.918,124.918,0,0,0,14.408.867v9.962a8.069,8.069,0,0,1-8.06,8.06H99.4a8.069,8.069,0,0,1-8.06-8.06V228.3a105.694,105.694,0,0,1,28.574,3.744c2.835.709,5.767,1.442,9.079,2.063a1.422,1.422,0,1,0,.525-2.8c-3.229-.606-6.119-1.329-8.914-2.027a108.027,108.027,0,0,0-29.138-3.828,8.071,8.071,0,0,1,7.933-6.644h5.689a5.221,5.221,0,0,0,5.215-5.215v-2.37H130.22v2.37a5.221,5.221,0,0,0,5.215,5.215h5.689a8.069,8.069,0,0,1,8.06,8.06Z" transform="translate(-71.717 -159.642)" />
                <path id="Path_3644" data-name="Path 3644" d="M80.785,0H63.393a5.18,5.18,0,0,0-3.687,1.528L55.912,5.32a2.373,2.373,0,0,1-3.352,0L48.767,1.528A5.18,5.18,0,0,0,45.08,0H31.48A16.381,16.381,0,0,0,15.738,12.042H4.267a4.267,4.267,0,0,0,0,8.534H15.738a16.309,16.309,0,0,0,13.591,11.9,5.242,5.242,0,0,0,4.366-1.485L37.389,27.3a2.373,2.373,0,0,1,3.352,0l3.793,3.793a5.181,5.181,0,0,0,3.688,1.528H80.785A16.309,16.309,0,0,0,80.785,0Zm0,29.773H48.222a2.355,2.355,0,0,1-1.676-.694l-3.793-3.793a5.221,5.221,0,0,0-7.375,0l-3.695,3.695a2.383,2.383,0,0,1-1.982.676,13.463,13.463,0,0,1-10.995-9.081H36.221a1.422,1.422,0,1,0,0-2.845H4.267a1.422,1.422,0,1,1,0-2.845h51.2a1.422,1.422,0,1,1,0,2.845h-13.6a1.422,1.422,0,0,0,0,2.845h13.6a4.267,4.267,0,1,0,0-8.534H18.709a13.529,13.529,0,0,1,12.771-9.2h13.6a2.355,2.355,0,0,1,1.676.694l3.793,3.793a5.221,5.221,0,0,0,7.375,0l3.793-3.793a2.355,2.355,0,0,1,1.676-.694H80.785a13.464,13.464,0,0,1,0,26.928Z" transform="translate(0)" />
            </g>
        </svg>

    )
}

export default Ink
