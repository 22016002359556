import React, { useState } from 'react'
import { Draggable } from 'react-smooth-dnd'
import ItemIconInner from './ItemIconInner'
import './ItemIcon.css'

const ItemIcon = ({ icon, name, kind, id, folderId, isFolderOwner, openDocumentWindow, draggable = false, owner, minimal }) => {
    const [open, setOpen] = useState(false)
    return draggable ? (
        <Draggable key={`chest-item-draggable-${id}`}>
            <div className={`item col ${open && 'open'} full-width`}>
                <ItemIconInner {...{ id, icon, kind, folderId, name, owner, isFolderOwner, open, setOpen, openDocumentWindow, minimal }} />
            </div>
        </Draggable>
    ) : (
        <div className={`item col ${open && 'open'}`}>
            <ItemIconInner {...{ id, icon, kind, folderId, name, owner, isFolderOwner, open, setOpen, openDocumentWindow, minimal }} />
        </div>
    )
}

export default ItemIcon