import React from 'react'

const ExpandListToggle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6.086" height="12.722" viewBox="0 0 6.086 12.722">
            <path id="Path_1380" data-name="Path 1380" d="M2705.873-80.321l-3.685,5.071,3.685,4.853" transform="translate(2707.274 -68.995) rotate(180)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </svg>
    )
}

export default ExpandListToggle
