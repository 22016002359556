import ReconnectingWebSocket from 'reconnecting-websocket'
import db from 'sharedb/lib/client'
import adjustHostnameForDev from './adjustHostnameForDev'
import richText from 'rich-text'
import GlobalStorage from './GlobalStorage'
import * as Sentry from "@sentry/react";

// Registering the rich text type to make sharedb work
// with our quill editor

class ShareDB {
    static connect() {
        const existingConnection = GlobalStorage.get('ShareDB')
        if (existingConnection) {
            return existingConnection
        }
        else {
            db.types.register(richText.type)

            const urlPrefix = (window.location.hostname.includes('qa.')) ? 'socket-qa' : 'socket'
            const socketUrl = process.env.NODE_ENV === "production" ? `wss://${urlPrefix}.adventuring-kit.com` : adjustHostnameForDev(process.env.REACT_APP_WEB_SOCKET_URL)
            const socket = new ReconnectingWebSocket(socketUrl)
            socket.addEventListener('error', (e) => {
                console.log('sharedb socket error', e)
                Sentry.captureException('sharedb socket error', e);
            })

            const connection = new db.Connection(socket)

            GlobalStorage.set('ShareDB', connection)
            return connection
        }
    }
}

export default ShareDB