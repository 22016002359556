import React, { useRef, useState, useEffect } from 'react'
import { useCampaignState } from '../../context/Campaign'
import { useWorkspaceState } from '../../context/Workspace'
import GS from '../../functions/GlobalStorage'
import { usePrevious } from '../../hooks/usePrevious'
import './DiceHistory.css'
import DiceHistoryEntry from './DiceHistoryEntry'

const DiceHistory = () => {
    const { pastRolls, inProgressRolls, notifications, modalState } = useCampaignState()
    const { selectedTab } = useWorkspaceState()
    const lastRoll = useRef()

    const [active, setActive] = useState(false)
    const [hovered, setHovered] = useState(false)

    const setOnHover = (hover) => {
        if (!hover && hovered) setHovered(false)
        else if (hover && !hovered) setHovered(true)
    }

    const prevPastRollsLength = usePrevious(pastRolls.length)

    useEffect(() => {
        if (!active && pastRolls.length > prevPastRollsLength) {
            setActive(true)
        }
    }, [active, pastRolls, prevPastRollsLength])

    useEffect(() => {
        if (active && !hovered) {
            GS.set('DICE_HISTORY_TIMER', setTimeout(() => {
                setActive(false)
            }, 3000))
        }

        if (hovered) {
            if (!active) setActive(true)
            clearTimeout(GS.get('DICE_HISTORY_TIMER'))
            GS.set('DICE_HISTORY_TIMER', null)
        }
        return () => {
            clearTimeout(GS.get('DICE_HISTORY_TIMER'))
            GS.set('DICE_HISTORY_TIMER', null)
        }
    }, [active, hovered])

    return (
        <div
            className={`diceHistory__container ${selectedTab && 'inFullScreen'}`}
            style={{ right: modalState.open && modalState.side === "right" ? modalState.width : 0 }}
        >
            <div
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
                onMouseMove={() => setOnHover(true)}
                className={`diceHistory col-reversed ${(active || hovered) && 'active'}`}
                style={{ top: (notifications * 64) || 0, maxHeight: hovered ? null : lastRoll?.current?.clientHeight + 32 || 0 }}
            >
                <div className="diceHistory__scrollBox col-reversed padding-bottom-half">
                    <div className="col-reversed">
                        {pastRolls.map((roll, i) => (
                            <DiceHistoryEntry
                                roll={roll}
                                key={roll.groupId}
                                ref={i === 0 ? lastRoll : null}
                            />
                        )
                        )}
                    </div>
                </div>
            </div>
            <div className="diceHistory__separator"></div>
            {
                inProgressRolls.map((roll, i) => (
                    <DiceHistoryEntry
                        roll={roll}
                        index={i}
                        key={i}
                        ref={i === 0 ? lastRoll : null}
                    />
                ))
            }
        </div>
    )
}

export default DiceHistory