import React from 'react'
import ReactLoading from 'react-loading'

import './style.css'

const Loading = ({ className, label, full, small, overlay, opaque, type, style }) => {
    return (
        <div style={style} className={`col loading-dialog ${full && 'full'} ${small && 'small'} ${overlay && 'overlay'} ${opaque && 'opaque'} ${className}`}>
            <ReactLoading color="#647682" className="loading-dialog-spinner" type={type || "spin"} width="100%" height="100%" />
            <p>{label}</p>
        </div>
    )
}

export default Loading