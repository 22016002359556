import React from 'react'

const Scale = () => (
  <svg id="tome-closed" data-name="tome-closed" xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
    <g>
      <path d="M110.31,182.746H58.92v-13.6h51.39s-3.827,2.828-3.827,6.8S110.31,182.746,110.31,182.746Z" transform="translate(-36.374 -104.424)" fill="#6eadc6" />
      <path d="M51.025,14.47A12.945,12.945,0,0,0,38.08,27.411h0V81.174L51.2,71.355l47.74.233V23.558a9.088,9.088,0,0,0-9.088-9.088Z" transform="translate(-23.509 -8.933)" fill="#dd8b85" />
      <path d="M51.025,14.47A12.945,12.945,0,0,0,38.08,27.411h0V81.174L51.2,71.355Z" transform="translate(-23.509 -8.933)" fill="#dd8b85" />
      <path d="M51.148,161.244h43.71a4.08,4.08,0,1,0,.306-8.154H51.025a12.944,12.944,0,1,0,0,25.888l43.833,0a4.08,4.08,0,0,0,.306-8.154H51.025a4.8,4.8,0,0,1-4.791-4.707A4.894,4.894,0,0,1,51.148,161.244Z" transform="translate(-23.509 -94.51)" fill="#a85858" />
      <path d="M83.91,174.4v11.036a1.76,1.76,0,0,0,2.8,1.439l3.536-2.6,3.532,2.6a1.76,1.76,0,0,0,2.8-1.42V174.4Z" transform="translate(-51.801 -107.665)" fill="#e17e7e" />
      <path d="M123.461,59.919l-2.858,2.4.9,3.62a2.361,2.361,0,0,1-1.718,2.858,2.123,2.123,0,0,1-.409.065l-3.723.26-1.4,3.444a2.357,2.357,0,0,1-3.061,1.309,2.68,2.68,0,0,1-.383-.191L107.65,71.7l-3.165,1.982a2.357,2.357,0,0,1-3.249-.746,2.25,2.25,0,0,1-.187-.383l-1.393-3.444-3.723-.26a2.353,2.353,0,0,1-2.189-2.514,2.123,2.123,0,0,1,.065-.409l.9-3.62-2.858-2.4a2.361,2.361,0,0,1-.291-3.325,2.574,2.574,0,0,1,.291-.287l2.858-2.4-.9-3.62a2.353,2.353,0,0,1,1.714-2.858,2.124,2.124,0,0,1,.409-.065l3.723-.26,1.4-3.444a2.357,2.357,0,0,1,3.061-1.309,2.614,2.614,0,0,1,.383.191l3.165,1.982,3.165-1.982a2.353,2.353,0,0,1,3.249.746,2.268,2.268,0,0,1,.191.383l1.4,3.444,3.723.26a2.361,2.361,0,0,1,2.193,2.514,2.946,2.946,0,0,1-.065.409l-.9,3.62,2.858,2.4a2.353,2.353,0,0,1,.283,3.321,2.317,2.317,0,0,1-.291.291Z" transform="translate(-56.183 -26.031)" fill="#f7b26d" />
      <path d="M129.728,71.234a7.814,7.814,0,1,1-7.814-7.814h0A7.814,7.814,0,0,1,129.728,71.234Z" transform="translate(-70.439 -39.152)" fill="#b4e1e9" />
      <path d="M96.491,68.167l1.075,2.679a3.731,3.731,0,0,0,4.852,2.066,4.209,4.209,0,0,0,.585-.3l2.434-1.531,2.438,1.531a4.466,4.466,0,0,0,3.13.383,3.566,3.566,0,0,0,2.3-2.154l1.075-2.679,2.866-.2a3.727,3.727,0,0,0,3.444-3.98,3.351,3.351,0,0,0-.1-.643l-.7-2.79,2.2-1.848a3.731,3.731,0,0,0,.459-5.258,3.445,3.445,0,0,0-.459-.455l-2.2-1.848.7-2.793a3.731,3.731,0,0,0-2.717-4.519,3.681,3.681,0,0,0-.643-.1l-2.87-.2-1.071-2.679a3.731,3.731,0,0,0-4.852-2.062,3.372,3.372,0,0,0-.585.3l-2.437,1.531-2.434-1.531a3.731,3.731,0,0,0-5.143,1.179,4.209,4.209,0,0,0-.3.585l-1.071,2.679-2.87.2a3.727,3.727,0,0,0-3.444,3.98,3.639,3.639,0,0,0,.1.639l.7,2.793-2.2,1.848a3.731,3.731,0,0,0-.459,5.254,4.336,4.336,0,0,0,.459.459l2.2,1.848-.7,2.79a3.731,3.731,0,0,0,2.717,4.519,3.631,3.631,0,0,0,.643.1Zm-3.409-3.341a.964.964,0,0,1-.161-.819l.9-3.62a1.37,1.37,0,0,0-.448-1.37l-2.858-2.4a.987.987,0,0,1-.122-1.393.926.926,0,0,1,.122-.122l2.858-2.4a1.374,1.374,0,0,0,.448-1.374l-.9-3.624a.987.987,0,0,1,.723-1.2,1.189,1.189,0,0,1,.168-.027l3.727-.26a1.366,1.366,0,0,0,1.194-.861l1.393-3.444a.991.991,0,0,1,1.286-.562,1.006,1.006,0,0,1,.157.08l3.165,1.978a1.366,1.366,0,0,0,1.454,0l3.165-1.978a.983.983,0,0,1,1.358.31.869.869,0,0,1,.08.157l1.4,3.444a1.362,1.362,0,0,0,1.175.853l3.723.26a.991.991,0,0,1,.922,1.056,1.189,1.189,0,0,1-.027.168l-.922,3.647a1.366,1.366,0,0,0,.448,1.378l2.858,2.4a.991.991,0,0,1,.122,1.393c-.038.042-.08.084-.122.122l-2.858,2.4a1.37,1.37,0,0,0-.448,1.381l.9,3.62a.991.991,0,0,1-.719,1.2.888.888,0,0,1-.176.027l-3.723.26a1.362,1.362,0,0,0-1.175.853l-1.4,3.444a.987.987,0,0,1-1.286.547.964.964,0,0,1-.153-.08L106.168,68.3a1.366,1.366,0,0,0-1.454,0l-3.165,1.978a.987.987,0,0,1-1.362-.314.964.964,0,0,1-.08-.153l-1.393-3.444a1.374,1.374,0,0,0-1.179-.853l-3.723-.26a.972.972,0,0,1-.731-.406Z" transform="translate(-53.963 -23.78)" fill="#21262c" />
      <path d="M119.7,78.2a9.184,9.184,0,1,0-9.184-9.184h0A9.184,9.184,0,0,0,119.7,78.2Zm0-15.624a6.444,6.444,0,1,1-6.44,6.443,6.444,6.444,0,0,1,6.44-6.443Z" transform="translate(-68.229 -36.936)" fill="#21262c" />
      <path d="M94.087,74.632a5.46,5.46,0,0,0,4.01-5.254V21.348A10.466,10.466,0,0,0,87.639,10.89H48.815A14.33,14.33,0,0,0,34.5,25.2V78.96h0a14.334,14.334,0,0,0,14.3,13.6H92.648a5.445,5.445,0,0,0,1.439-10.714,5.694,5.694,0,0,1,0-7.228Zm-39.31,4.542a1.37,1.37,0,1,0-2.74,0v2.491H48.815a3.444,3.444,0,0,1-3.409-3.36,3.318,3.318,0,0,1,3.138-3.482,2.922,2.922,0,0,1,.409,0H64.718v9.666a.383.383,0,0,1-.612.306L60.57,82.2a1.366,1.366,0,0,0-1.622,0L55.416,84.8a.391.391,0,0,1-.62-.314Zm12.67-4.339h23.38a7.929,7.929,0,0,0,0,6.838H67.446ZM37.244,25.2a11.587,11.587,0,0,1,10.205-11.48l.165,50.273a14.3,14.3,0,0,0-10.37,5.851ZM95.357,64.657a5.414,5.414,0,0,0-2.705-.723h-14.3a1.37,1.37,0,0,0,0,2.74H92.648a2.709,2.709,0,1,1,0,5.415H48.941c-5.548,0-6.31,4.638-6.287,6.264a6.191,6.191,0,0,0,6.161,6.05h3.222v.088a3.115,3.115,0,0,0,1.718,2.793,3.191,3.191,0,0,0,3.264-.272l2.724-2,2.724,2a3.126,3.126,0,0,0,4.974-2.522V84.4H92.648a2.709,2.709,0,0,1,0,5.415H48.815a11.574,11.574,0,1,1,0-23.149l23.958,0a1.374,1.374,0,0,0,0-2.744H50.353l-.165-50.3h37.45a7.726,7.726,0,0,1,7.718,7.718Z" transform="translate(-21.298 -6.723)" fill="#21262c" />
      <rect width="90" height="90" fill="none" />
    </g>
  </svg>
)

export default Scale
