import React from 'react'
import { distanceTo } from '../../../../functions/CanvasHelpers'

import './measure.css'

const Measure = ({ measure, camera, gridSize }) => {
    if (measure) {
        const dist = distanceTo(measure.x1, measure.y1, measure.x2, measure.y2) / 2 || 0
        const angle = Math.atan2(measure.y2 - measure.y1, measure.x2 - measure.x1) || 0
        const distDisplay = (Math.round(((dist * 2) / camera.zoom) / gridSize)) * 5
        return <div className="measure" style={{
            left: (measure.x1 || 0),
            top: (measure.y1 || 0),
            width: dist * 2 || 0,
            transform: `rotate(${angle}rad)`
        }
        }>
            {distDisplay > 0 && <div className="measureDisplay" style={{
                left: dist - 32,
                transform: `rotate(${-angle}rad)`
            }}>
                {distDisplay} ft
            </div>}
        </div >
    } else return null
}

export default Measure