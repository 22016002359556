import React, {
    useEffect, useMemo, useRef, useState
} from 'react'
import GlobalStorage from '../../../../functions/GlobalStorage'
import { getIconImg, rectanglesIntersect } from '../../../../functions/helpers'
import DocumentIcons from './DocumentIcons'
import Name from './Name'
import Rings from './Rings'


const UnmemoizedToken = React.memo(({
    selected, selectable, id, index, src, x, y, name, rotation, scale, flip, layer, alignment, locked, labels, hidden, gridSize, gridLeft, gridTop,
    onRightClick, tokenOwner, showNames, documents, boundingBox, isFullScreen, diceInHand, clientIsMapOwner,
    addToSelection, removeFromSelection, startTokenDrag, showGrid, setMeasureFromToken, notUnderFog
}) => {
    const hoveredTimeout = useRef()

    const centered = alignment !== 'corner'
    const diffFromGridSize = (((scale / 4) * gridSize) - gridSize) || 0

    const displayScale = (scale / 4 || 1)
    const xx = x || 0
    const yy = y || 0

    const ww = gridSize * displayScale / 2
    const hh = gridSize * displayScale / 2

    const instance = useRef(null)

    const data = useMemo(() => ({ id, index, x, y, locked, alignment }), [id, alignment, index, locked, x, y])
    useEffect(() => {
        if (boundingBox && (tokenOwner || clientIsMapOwner || (!hidden && notUnderFog))) {
            if (rectanglesIntersect(
                boundingBox.left,
                boundingBox.top,
                boundingBox.left + boundingBox.width,
                boundingBox.top + boundingBox.height,
                ((xx + gridLeft) - (gridSize * displayScale) / 2) + 8,
                ((yy + gridTop) - (gridSize * displayScale) / 2) + 8,
                xx + gridLeft + ww + 16,
                yy + gridTop + hh + 16
            )) {
                addToSelection({ ...data, instance })
            } else {
                removeFromSelection(index)
            }
        }
    }, [addToSelection, boundingBox, clientIsMapOwner, data, displayScale, gridLeft, gridSize, gridTop, hh, hidden, index, isFullScreen, notUnderFog, removeFromSelection, tokenOwner, ww, xx, yy])

    const [hovered, setHovered] = useState(false)
    const render = useMemo(() => (
        <div
            ref={instance}
            className={`token ${selectable && 'selectable'} ${hovered && 'hovered'} ${selected && 'selected'} ${locked && 'locked'} ${!!labels?.length && 'has-rings'} ${layer && `layer-${layer}`}`}
            onContextMenu={onRightClick}
            onClick={() => {
                if (tokenOwner || (notUnderFog && !hidden)) {
                    addToSelection({ ...data, instance })
                }
            }}
            onMouseDown={(e) => {
                if (diceInHand) return
                if (e.button === 0 && e?.target?.className?.includes?.('token')) {
                    const isSelected = instance?.current?.className?.includes?.('selected')
                    if (tokenOwner || (notUnderFog && !hidden)) {
                        addToSelection({ ...data, instance }, !e.shiftKey && !isSelected)
                        if (!locked) startTokenDrag(e)
                        if (showGrid && !locked) {
                            setMeasureFromToken({
                                xx: xx + gridLeft + (centered ? 0 : diffFromGridSize / 2),
                                yy: yy + gridTop + (centered ? 0 : diffFromGridSize / 2)
                            })
                        }
                        setHovered(false)
                    }
                }
            }}
            onMouseEnter={() => {
                if (diceInHand) return
                if (tokenOwner || (notUnderFog && !hidden)) {
                    setHovered(true)
                    clearTimeout(hoveredTimeout.current)
                }
            }}
            onMouseLeave={() => {
                if (diceInHand) return
                hoveredTimeout.current = setTimeout(() => setHovered(false), 150)
            }}
            style={{
                top: yy,
                left: xx,
                width: gridSize + 1,
                height: gridSize + 1,
                transform: `scaleX(${displayScale}) scaleY(${displayScale})`,
                opacity: hidden ? tokenOwner ? 0.5 : 0 : 1,
                pointerEvents: hidden ? tokenOwner ? 'all' : 'none' : 'all',
                transformOrigin: alignment === 'corner' ? 'top left' : 'center'
            }}
        >
            <div className="tokenBackground" style={{
                backgroundImage: `url("${src || (documents?.length ? getIconImg(documents?.[0]?.kind) : GlobalStorage.get('TOKEN_ICON_MISSING').src)}")`,
                transform: `rotate(${rotation || 0}deg) scaleX(${flip ? -1 : 1})`
            }}></div>
            {documents && <DocumentIcons src={src} documents={documents} hovered={hovered} scale={scale} />}
            <Rings {...{ labels, ww: gridSize + 1, hh: gridSize + 1 }} />
            <Name name={name} labels={labels} show={showNames} scale={scale} />
        </div>
    ), [selectable, hovered, selected, locked, labels, layer, onRightClick, yy, xx, gridSize, displayScale, hidden, tokenOwner, alignment, src, documents, rotation, flip, scale, name, showNames, notUnderFog, addToSelection, data, diceInHand, startTokenDrag, showGrid, setMeasureFromToken, gridLeft, centered, diffFromGridSize, gridTop])

    return render
})

const Token = React.memo(UnmemoizedToken)
export default Token