import React from 'react'
import SocialIcons from '../../components/SocialIcons/SocialIcons'

const Footer = () => {
    return (
        <div className="landing-footer">
            <img src="/logo.svg" alt="Adventuring Kit Logo" className="landing-footer-logo" />
            <h1>Adventuring Kit</h1>
            <a className="landing-cta red" href="/login">Sign Up Now!</a>
            <SocialIcons className="margin-top-2" />

            <footer>
                <p>©  Adventuring Kit 2020</p>
                {/* <div className="landing-footer-right">
                    <a href="/terms">Terms of Service</a>
                    <a href="/privacy-policy">Privacy Policy</a>
                </div> */}
            </footer>
        </div>
    )
}

export default Footer
