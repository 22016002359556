import { useCallback, useState } from "react";
import { useAuthState } from "../context/Auth";
import { useCampaignState } from "../context/Campaign";
import { useErrorFunctions } from "../context/Error";
import { useInventoryFunctions } from "../context/Inventory";
import API from "../functions/API";

const useRenameFolder = (initialText, folderId, owner, setLoading) => {
    const [text, setText] = useState(initialText)

    const { auth } = useAuthState()
    const { socket } = useCampaignState()
    const { inventoryDispatch } = useInventoryFunctions()
    const { setError } = useErrorFunctions()

    const onChange = useCallback((newText) => {
        if (setLoading) setLoading(true)
        inventoryDispatch({ type: 'RENAME_FOLDER', value: { folderId, newText } })

        API.sendGraphSecure(auth, {
            query: `mutation {
                    renameFolder(folderId: "${folderId}", owner: "${owner}", name: "${newText}") {
                        name
                    }
                }`
        }).then(() => {
            if (socket) socket.emit("FOLDER_CHANGED_NAME", { folderId, name: newText })
            if (setLoading) setLoading(false)
        }).catch((err) => {
            console.log(err)
            setError('Error changing folder name')
        })

        setText(newText)
    }, [auth, folderId, inventoryDispatch, owner, setError, setLoading, socket])

    return {
        text, setText, onChange
    }
};

export default useRenameFolder;
