import React from 'react'

const Chat = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44.461" height="44.461" viewBox="0 0 44.461 44.461">
            <path id="messenger" d="M22.23,0A22.233,22.233,0,0,0,2.948,33.3L.084,42.192a1.737,1.737,0,0,0,2.186,2.186l8.895-2.864A22.231,22.231,0,1,0,22.23,0Zm0,40.987A18.7,18.7,0,0,1,12.3,38.146a1.738,1.738,0,0,0-1.453-.18L4.428,40.033,6.5,33.612a1.738,1.738,0,0,0-.181-1.453A18.758,18.758,0,1,1,22.23,40.987ZM24.4,22.23A2.171,2.171,0,1,1,22.23,20.06,2.171,2.171,0,0,1,24.4,22.23Zm8.684,0a2.171,2.171,0,1,1-2.171-2.171A2.171,2.171,0,0,1,33.085,22.23Zm-17.368,0a2.171,2.171,0,1,1-2.171-2.171A2.171,2.171,0,0,1,15.718,22.23Zm0,0" fill="#647682" />
        </svg>
    )
}

export default Chat
