import shortid from 'shortid'
import { getSoundEffectVolume } from '../../functions/getSoundEffectVolume'
import { pushOrSet } from '../../functions/helpers'

const reducer = (state, action) => {
    switch (action.type) {
        case 'ENTER_CAMPAIGN': {
            const { campaign, userId } = action.value

            state.activeCampaign = campaign
            state.messages = campaign.messages || []

            const userData = campaign.users.find(user => user._id === userId)
            state.connectedUsers = [userData]
            state.waitingForInviteId = null

            return { ...state }
        }

        case "WAITING_FOR_INVITE": {
            const shortId = action.value

            state.waitingForInviteId = shortId
            return { ...state }
        }

        case 'LOGOUT_CAMPAIGN': {
            state.activeCampaign = null
            state.socket = null
            state.connectedUsers = []
            state.messages = []
            state.waitingForInviteId = null
            state.inviteAccepted = null
            state.notifications = []
            state.usersTyping = []
            state.recentChatHistory = []
            state.undreads = 0

            return { ...state }
        }

        case "SET_INVALID_CAMPAIGN_ID": {
            state.invalidCampaignId = action.value
            return { ...state }
        }

        case "NEW_MESSAGES": {
            state.messages = [...action.value, ...state.messages]
            return { ...state }
        }

        case "INCOMMING_MESSAGE": {
            const { message, userId } = action.value
            state.messages = [message, ...state.messages]
            if (userId !== message.sender && message.roll !== true) {
                state.unreads++
                window._ADVK_NEW_MESSAGE_AUDIO.volume = getSoundEffectVolume()
                window._ADVK_NEW_MESSAGE_AUDIO.currentTime = 0
                window._ADVK_NEW_MESSAGE_AUDIO.play()
            }
            return { ...state }
        }

        case "CLEAR_UNREADS": {
            state.unreads = 0
            return { ...state }
        }

        case "UPDATE_CAMPAIGN_USERS": {
            const { users, usersAwaitingInvite, deniedUsers } = action.value

            const activeCampaign = { ...state.activeCampaign, users, usersAwaitingInvite, deniedUsers }
            return { ...state, activeCampaign }
        }

        case "ADD_CONNECTED_USER": {
            const newConnectedUser = action.value
            const connectedUsers = [...state.connectedUsers]
            const newUserIndex = connectedUsers.findIndex(oldUser => {
                return oldUser._id === newConnectedUser._id
            })

            if (newUserIndex > -1) {
                connectedUsers[newUserIndex] = { ...newConnectedUser, ...connectedUsers[newUserIndex] }
            } else {
                connectedUsers.push(newConnectedUser)
            }

            return { ...state, connectedUsers }
        }

        case "REMOVE_CONNECTED_USER": {
            const newConnectedUserId = action.value
            const newUserIndex = state.connectedUsers.findIndex(oldUser => oldUser._id === newConnectedUserId)
            if (newUserIndex > -1) {
                state.recentlyDisconnectedUsers = [...state.recentlyDisconnectedUsers, state.connectedUsers[newUserIndex]]
                state.connectedUsers.splice(newUserIndex, 1)
            }

            state.inProgressRolls = state.inProgressRolls.filter(roll => roll.user !== newConnectedUserId)
            return { ...state, connectedUsers: [...state.connectedUsers] }
        }

        case "REMOVE_USER_FROM_WAIT_LIST": {
            const userId = action.value
            const usersWaiting = state.activeCampaign.usersAwaitingInvite
            const removeUserIndex = usersWaiting.findIndex(waitingUser => waitingUser._id === userId)
            usersWaiting.splice(removeUserIndex, 1)
            state.activeCampaign.usersAwaitingInvite = [...usersWaiting]

            return { ...state }
        }

        case "ADD_WAITING_USER": {
            if (!state.activeCampaign.usersAwaitingInvite.find(waitingUser => waitingUser._id === action.value._id))
                state.activeCampaign.usersAwaitingInvite = [...state.activeCampaign.usersAwaitingInvite, action.value]

            return { ...state }
        }

        case "CANCEL_WAITING_USER": {
            const cancelledUserIndex = state.activeCampaign.usersAwaitingInvite.findIndex(waitingUser => waitingUser._id === action.value)
            state.activeCampaign.usersAwaitingInvite.splice(cancelledUserIndex, 1)
            return { ...state }
        }

        case "INVITE_REJECTED": {
            state.inviteAccepted = false

            return { ...state }
        }

        case "INVITE_ACCEPTED": {
            state.inviteAccepted = true

            return { ...state }
        }

        case "ADD_NOTIFICATION": {
            if (!state.notifications.find(notification => notification.id === action.value.id))
                state.notifications = [...state.notifications, action.value]
            return { ...state }
        }

        case "REMOVE_NOTIFICATION": {
            const index = state.notifications.findIndex(notification => notification.id === action.value)
            if (index > -1) state.notifications.splice(index, 1)
            return { ...state }
        }

        case "SET_SOCKET": {
            state.socket = action.value
            state.inProgressRolls = []
            return { ...state }
        }

        case "ADD_USER_TYPING": {
            pushOrSet(state.usersTyping, action.value)
            const usersTyping = [...state.usersTyping]
            return { ...state, usersTyping }
        }

        case "REMOVE_USER_TYPING": {
            const userTypingIndex = state.usersTyping.findIndex(userTyping => userTyping === action.value)
            if (userTypingIndex > -1) {
                state.usersTyping.splice(userTypingIndex, 1)
                const usersTyping = [...state.usersTyping]
                return { ...state, usersTyping }
            }
            return state
        }

        case "DICE_READY": {
            const { addDice, popDice, recieveNetworkRoll, explodeNetworkRoll } = action.value
            state.diceReady = true
            state.addDice = addDice
            state.popDice = popDice
            state.recieveNetworkRoll = recieveNetworkRoll
            state.explodeNetworkRoll = explodeNetworkRoll
            return { ...state }
        }

        case "CHANGE_USERS_DICE_SKIN": {
            const { changingUserId, skin, color } = action.value
            const connectedUsers = state.connectedUsers
            const connectedUserIndex = connectedUsers.findIndex(connectedUser => connectedUser._id === changingUserId)
            if (connectedUserIndex > -1) {
                connectedUsers[connectedUserIndex].color = color
                connectedUsers[connectedUserIndex].diceSkin = skin
            }

            return { ...state, connectedUsers: [...connectedUsers] }
        }


        case 'SAVE_DICE_ROLL': {
            if (action.value.inProgress)
                state.inProgressRolls = [action.value, ...state.inProgressRolls]
            else
                state.pastRolls = [action.value, ...state.pastRolls]

            return { ...state }
        }

        case 'UPDATE_DICE_ROLL': {
            const { id, data } = action.value
            if (data.inProgress) {
                state.inProgressRolls = state.inProgressRolls.map(roll => {
                    if (id === roll.groupId) {
                        return { ...data }
                    } else return roll
                })
            } else {
                state.inProgressRolls = state.inProgressRolls.filter(roll => roll.groupId !== id)
                state.pastRolls = [data, ...state.pastRolls]
            }

            return { ...state }
        }

        case "USER_OPENED_DOCUMENT": {
            const newState = { ...state }
            const { documentId, userId } = action.value
            const connectedUser = newState.connectedUsers.find(user => user._id === userId)
            if (connectedUser) {
                newState.usersInDocuments = { ...newState.usersInDocuments }
                const newUsersInDocument = newState.usersInDocuments[documentId] || []
                if (!newUsersInDocument.find(userInDoc => userInDoc._id === connectedUser._id))
                    newUsersInDocument.push(connectedUser)
                newState.usersInDocuments[documentId] = newUsersInDocument
            }

            return newState
        }

        case "USER_CLOSED_DOCUMENT": {
            const newState = { ...state }
            const { documentId, userId } = action.value
            const usersInDocuments = { ...newState.usersInDocuments }
            const docId = usersInDocuments?.[documentId]
            if (!docId) return newState
            const userIndex = docId.findIndex(search => userId === search._id)
            docId.splice(userIndex, 1)

            newState.usersInDocuments = {
                ...newState.usersInDocuments,
                [documentId]: docId
            }

            return newState
        }

        case 'CLEAR_RECENTLY_DISCONNECTED_USERS': {
            const newState = { ...state, recentlyDisconnectedUsers: [] }
            return newState
        }

        case 'ADD_MAP_PING': {
            const newPingQueue = [...state.pingQueue, { ...action.value, id: shortid.generate() }]
            const newState = { ...state, pingQueue: newPingQueue }
            return newState
        }

        case 'REMOVE_PING_FROM_QUEUE': {
            const newState = { ...state, pingQueue: [...state.pingQueue] }
            const deleteIndex = newState.pingQueue.findIndex(pingData => pingData._id === action.value)
            newState.pingQueue.splice(deleteIndex, 1)

            return newState
        }

        case 'UPDATE_MODAL_STATE': {
            return { ...state, modalState: action.value }
        }

        case 'UPDATE_PAST_ROLL': {
            const { roll } = action.value
            const newPastRolls = [...state.pastRolls]
            const newRollIndex = newPastRolls.findIndex(findRoll => findRoll.groupId === roll.groupId)
            newPastRolls[newRollIndex] = { ...roll }

            return { ...state, pastRolls: newPastRolls }
        }

        default: return { ...state }
    }

}

export default reducer