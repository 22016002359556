import React from 'react'
import { CircleButton } from '../../Buttons'

import './ChestButton.css'

const ChestButton = ({ open, onClick }) => (
    <>
        <CircleButton
            className="ChestButton centered"
            onClick={onClick}
            icon={open ? 'chest-open' : 'chest-closed'}
        />
        <CircleButton
            className="TomeButton centered"
            onClick={onClick}
            icon={open ? 'tome-open' : 'tome'}
        />
    </>
)

export default ChestButton
