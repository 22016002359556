import React, { useEffect, useState } from 'react'
import './Toggle.css'

const Toggle = ({ entries, entryIcons, value, setValue, fullWidthButtons }) => {
    const [selected, setSelected] = useState(value || 0)
    const onClick = (index) => {
        setValue(entries[index])
        setSelected(index)
    }

    useEffect(() => {
        if (value !== undefined) {
            if (value !== selected) setSelected(value)
        }
    }, [selected, value])

    return (
        <div className="toggle row">
            {entries.map((entry, i) => (
                <button key={i} className={`toggleEntry ${fullWidthButtons && 'fullWidthButtons'} ${selected === i && 'active'}`} onClick={(e) => onClick(i)}>
                    {entryIcons ? entryIcons[i] : entry}
                </button>
            ))}
        </div>
    )
}

export default Toggle
