import { lerp } from '../../../helpers'

export default function updateFinishedDice() {
    this.finishedDice.forEach((die, i) => {
        if (die.rollingGroup.keepBest === 0 || (die.rollingGroup.keepBest > 0 && die.keep)) {
            die.position.x = die.rollingGroup.disableAnimations ? this.finishedDicePosition.x : lerp(die.position.x, this.finishedDicePosition.x, 0.1)
            die.position.y = die.rollingGroup.disableAnimations ? this.finishedDicePosition.y : lerp(die.position.y, this.finishedDicePosition.y, 0.1)
        } else {
            die.position.y += 0.25
        }

        die.position.z = 3
    })
}