import adjustHostnameForDev from '../functions/adjustHostnameForDev'
import io from 'socket.io-client'

const urlPrefix = (window.location.hostname.includes('qa.')) ? 'socket-qa' : 'socket'
const socketUrl = process.env.NODE_ENV === "production" ? `//${urlPrefix}.adventuring-kit.com` : adjustHostnameForDev(process.env.REACT_APP_SOCKET_URL)

class SocketController {
    // Connects the socket, and sets up handlers for any possible incomming socket messages, then returns the socket for later use (ie using to send messages).
    static getSocket = async (userId, campaignShortId, setError) => {
        return new Promise((resolve, reject) => {

            try {
                const socket = io(socketUrl, {
                    reconnectionAttempts: 5,
                    rejectUnauthorized: false,
                    transports: ['websocket'],
                    query: {
                        campaignShortId,
                        userId
                    }
                })

                socket.on('connect', () => {
                    socket.emit('ATTACH_USER_ID', userId)
                    resolve(socket)
                })

            } catch (err) {
                setError('There is a connection error, please refresh the page.', true)
            }
        })
    }

    static assignSocket = async (user, campaignShortId, reassign) => {
        let socket
        if (user.socket && !reassign) {
            socket = user.socket
            window._ADVENTURINGKITSOCKET = socket
        } else {
            if (window._ADVENTURINGKITSOCKET) {
                window._ADVENTURINGKITSOCKET.off()
                window._ADVENTURINGKITSOCKET.destroy()
            }
            window._ADVENTURINGKITSOCKET = await this.getSocket(user._id, campaignShortId)
            socket = window._ADVENTURINGKITSOCKET
        }

        return socket
    }
}

export default SocketController