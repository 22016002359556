import userMinimum from "../../../config/graph-request-types/userMinimum"
import API from "../../../functions/API"

export async function checkForInventoryUpdate(auth, userId) {
    try {
        this.dispatch({ type: 'SET_SHARED_FOLDERS_LOADING', value: true })

        const result = await API.sendGraphSecure(auth, {
            query: `mutation checkForInventoryUpdate($userId: ID!) {
                        checkForInventoryUpdate(userId: $userId) {
                            folders {
                                _id
                                name
                                isDefaultFolder
                                owner {
                                    ${userMinimum}
                                }
                                sharedCampaigns {
                                    _id
                                    title
                                }
                                sharedUsers {
                                    _id
                                    name
                                }
                            }
                        }
                    }`,
            variables: {
                "userId": userId,
            }
        })

        this.dispatch({ type: 'SET_SHARED_FOLDERS_LOADING', value: false })
        this.dispatch({
            type: 'SET_FOLDERS', value: result.data.checkForInventoryUpdate.folders
        })
    } catch (err) {
        console.log(err)
    }
}