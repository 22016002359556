import React from 'react'
import { Button } from '../../../../../../components/Buttons'
import Card from '../../../../../../components/Card'
import PriceDisplay from '../../../../../../components/PriceDisplay'

const PremiumPromo = ({ price, interval, className, onSubscribe }) => {
    return (
        <Card className={`${className} premium-promo-container green col t-centered t-bold margin-bottom-1`} emphasis>
            <PriceDisplay price={price || 9.99} interval={interval || 'month'} green />
            <p>10 players per campaign</p>
            <p>Unlimited Maps, Notes, Pictures & PDFs</p>
            <p>Premium dice skins</p>
            <p>6GB upload storage</p>
            <div className="coming-soon">
                <label>Coming Soon</label>
                <p>Procedurally generate maps and characters</p>
                <p>Upload your own tilesets</p>
                <p>Procedurally generate maps and characters</p>
            </div>
            <Button className="bg-white c-main" emphasis onClick={onSubscribe}>Subscribe Now</Button>
        </Card>
    )
}

export default PremiumPromo
