import { getSoundEffectVolume } from "../../../functions/getSoundEffectVolume"
import { addRollToHistory, updateRollInHistory } from "./addRollToHistory"

export function recieveNetworkRoll(rollData, currentUserId) {
    if (rollData.message === 'ROLL_START') {
        rollData.inProgress = true
        addRollToHistory.call(this, rollData)

        if (currentUserId !== rollData.user) {
            window._ADVK_DICE_SHAKE_AUDIO.volume = getSoundEffectVolume()
            window._ADVK_DICE_SHAKE_AUDIO.currentTime = 0
            window._ADVK_DICE_SHAKE_AUDIO.play().catch(err => console.log('error playing sound', err))
        }
    }

    if (rollData.message === 'DICE_EXPLODED') {
        rollData.inProgress = true
        updateRollInHistory.call(this, rollData)

        if (currentUserId !== rollData.user) {
            window._ADVK_DICE_EXPLODED_AUDIO.volume = getSoundEffectVolume(-0.2)
            window._ADVK_DICE_EXPLODED_AUDIO.currentTime = 0
            window._ADVK_DICE_EXPLODED_AUDIO.play().catch(err => console.log('error playing sound', err))
        }
    }

    if (rollData.message === 'ROLL_DONE') {
        rollData.inProgress = false
        updateRollInHistory.call(this, rollData)

        if (currentUserId !== rollData.user) {
            window._ADVK_DICE_DROP_AUDIO_1.volume = getSoundEffectVolume()
            window._ADVK_DICE_DROP_AUDIO_1.currentTime = 0
            window._ADVK_DICE_DROP_AUDIO_1.play().catch(err => console.log('error playing sound', err))
        }
    }
}