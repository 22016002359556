import {
    getCenterOfVariablePoints,
} from '../../../../functions/helpers'
import { globalTokenPlacer_Atom } from '../../atoms'
import { useAtom } from 'jotai'

const useCopyTokens = () => {
    const [, setTokenPlacer] = useAtom(globalTokenPlacer_Atom)

    const copyTokens = (selectedTokens, gridSize) => {
        if (!selectedTokens.length) selectedTokens = [selectedTokens]
        const center = getCenterOfVariablePoints(selectedTokens, gridSize / 2)
        const tokens = selectedTokens.map(token => ({
            ...token,
            center,
        }))
        setTokenPlacer(tokens)
    }

    return copyTokens
}

export default useCopyTokens