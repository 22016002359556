import React from 'react'

const Settings = () => {
    return (
        <svg id="settings-icon" xmlns="http://www.w3.org/2000/svg" width="15.419" height="15.419" viewBox="0 0 15.419 15.419">
            <path d="M15.056,5.954,13.871,5.7a6.449,6.449,0,0,0-.381-.92l.656-1.021a.461.461,0,0,0-.062-.575L12.238,1.335a.461.461,0,0,0-.575-.062l-1.021.656a6.45,6.45,0,0,0-.92-.381L9.465.363A.461.461,0,0,0,9.015,0H6.4a.461.461,0,0,0-.45.363L5.7,1.548a6.45,6.45,0,0,0-.92.381L3.756,1.273a.461.461,0,0,0-.575.062L1.335,3.181a.461.461,0,0,0-.062.575l.656,1.021a6.45,6.45,0,0,0-.381.92L.363,5.954A.461.461,0,0,0,0,6.4V9.015a.461.461,0,0,0,.363.45l1.185.258a6.45,6.45,0,0,0,.381.92l-.656,1.021a.461.461,0,0,0,.062.575l1.846,1.846a.461.461,0,0,0,.575.062l1.021-.656a6.449,6.449,0,0,0,.92.381l.258,1.185a.461.461,0,0,0,.45.363H9.015a.461.461,0,0,0,.45-.363l.258-1.185a6.449,6.449,0,0,0,.92-.381l1.021.656a.461.461,0,0,0,.575-.062l1.846-1.846a.461.461,0,0,0,.062-.575l-.656-1.021a6.449,6.449,0,0,0,.381-.92l1.185-.258a.461.461,0,0,0,.363-.45V6.4A.461.461,0,0,0,15.056,5.954ZM10.474,7.709A2.764,2.764,0,1,1,7.709,4.945,2.768,2.768,0,0,1,10.474,7.709Zm0,0" />
        </svg>
    )
}

export default Settings
