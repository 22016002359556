import React from 'react'

import { Button, TextButton } from '../../../../components/Buttons'

import TextInput from '../../../../components/TextInput'

import { useAuthState, useAuthFunctions } from '../../../../context/Auth'
import { useErrorFunctions } from '../../../../context/Error'
import { useCampaignFunctions, useCampaignState } from '../../../../context/Campaign'
import { useLoadingFunctions } from '../../../../context/Loading'

const UserSettings = () => {
    const { auth, user, email, name, password, passwordConfirm } = useAuthState()
    const { changeField, handleUpdateUser, handleLogout } = useAuthFunctions()

    const { socket, activeCampaign, connectedUsers } = useCampaignState()
    const { logOutOfCampaign } = useCampaignFunctions()

    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()

    return (
        <div className="settings-container row centered padding-top-2">
            <div className="col full-width centered">
                <div className="row col-mobile full-width margin-bottom-1">
                    <TextInput className="full-width margin-right-1" value={email} label="Change Email" placeholder={user.email} onChange={(e) => changeField(e.target.value, "email")} />
                    <TextInput className="full-width" value={name} label="Change Name" placeholder={user.name} onChange={(e) => changeField(e.target.value, "name")} />
                </div>

                <div className="row col-mobile full-width margin-bottom-1">
                    <TextInput className="full-width margin-right-1" value={password} label="Change Password" placeholder="******" onChange={(e) => changeField(e.target.value, "password")} />
                    <TextInput className="full-width" value={passwordConfirm} label="Confirm Passwod" placeholder="******" onChange={(e) => changeField(e.target.value, "passwordConfirm")} />
                </div>

                <div className="row col-mobile full-width">
                    <Button
                        className="full-width margin-top-2 margin-bottom-1"
                        onClick={() => handleUpdateUser({ user, email, password, passwordConfirm, name, page: 'update' }, auth, user, setLoading, setError)}>
                        Save User
                    </Button>


                    <TextButton className="centered full-width" onClick={() => {
                        if (activeCampaign) logOutOfCampaign(socket, connectedUsers)
                        handleLogout()
                    }}>Log out</TextButton>
                </div>
            </div>
        </div>
    )
}

export default UserSettings
