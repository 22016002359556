import React from 'react'

const Volume = () => {
    return (
        <svg id="volume" xmlns="http://www.w3.org/2000/svg" width="16.262" height="15.178" viewBox="0 0 16.262 15.178">
            <g transform="translate(0 -15.99)">
                <path d="M9.45,16.044a.54.54,0,0,0-.573.065L3.6,20.327H1.084A1.086,1.086,0,0,0,0,21.412v4.336a1.085,1.085,0,0,0,1.084,1.084H3.6L8.876,31.05a.543.543,0,0,0,.575.064.541.541,0,0,0,.307-.488V16.533A.544.544,0,0,0,9.45,16.044Z" transform="translate(0 0)" />
                <path d="M342.675,122.409a.542.542,0,0,0-.762.772,4.324,4.324,0,0,1,0,6.123.542.542,0,1,0,.762.77,5.407,5.407,0,0,0,0-7.665Z" transform="translate(-330.174 -102.662)" />
                <path d="M387.792,77.282a.542.542,0,1,0-.764.77,6.469,6.469,0,0,1,0,9.181.542.542,0,0,0,.764.77,7.555,7.555,0,0,0,0-10.721Z" transform="translate(-373.761 -59.063)" />
            </g>
        </svg >
    )
}

export default Volume
