import React from 'react'
import Maps from "./Maps"
import Dice from "./Dice"
import Sheets from './Sheets'
import Notes from './Notes'

const features = [
    {
        view: <Dice />,
        props: {
            clutter: [
                { url: '/dice/icons/D4-green.png' },
                { url: '/dice/icons/D10-red.png' },
                { url: '/dice/icons/D6-blue.png' },
                { url: '/dice/icons/D20-green.png' },
                { url: '/dice/icons/D10-blue.png' },
            ],
        }
    },
    {
        view: <Notes />,
        props: {
            clutter: [
                { url: '/book-icon.svg' },
                { url: '/parchment-icon.svg' },
                { url: '/script-icon.svg' },
            ],
        }
    },
    {
        view: <Maps />,
        props: {
            clutter: [
                { url: '/mountain-icon.svg' },
                { url: '/loupe.svg' },
                { url: '/rocks.svg' },
                { url: '/rubber-stamp.svg' },
                { url: '/forest.svg' },
            ],
        }
    },
    {
        view: <Sheets />,
        props: {
            clutter: [
                { url: '/potion-icon.svg' },
                { url: '/sword-icon.svg' },
                { url: '/wizard-hat-icon.svg' },
                { url: '/king-icon.svg' },
            ],
        }
    }
]
export default features