import * as THREE from 'three'
import * as C from 'cannon'
import { getGeoDataForDiceType } from './'

export function attachPhysicsBodyToDice(die, material) {
    const geoData = getGeoDataForDiceType(die.type)

    // create cannon physics body from simple geometry
    die.geometry = createCannonShape(geoData)
    const vel = new C.Vec3()
    vel.x = 1
    vel.z = 0

    die.body = new C.Body({
        mass: geoData.mass,
        shape: die.geometry,
        material: material,
    })

    die.body.mesh = die
    die.body.position.copy(die.position)
    die.body.quaternion.copy(die.quaternion)
    die.finalScale = geoData.finalScale
}

function createCannonShape(geoData) {
    let radius = geoData.scaleFactor
    let faces = geoData.faces

    let vertices = new Array(geoData.vertices.length)
    for (let i = 0; i < geoData.vertices.length; ++i) {
        vertices[i] = (new THREE.Vector3()).fromArray(geoData.vertices[i]).normalize()
    }


    let cv = new Array(vertices.length), cf = new Array(faces.length)
    for (let i = 0; i < vertices.length; ++i) {
        let v = vertices[i]
        cv[i] = new C.Vec3(v.x * radius, v.y * radius, v.z * radius)
    }
    for (let i = 0; i < faces.length; ++i) {
        cf[i] = faces[i].slice(0, faces[i].length - 1)
    }
    return new C.ConvexPolyhedron(cv, cf)
}