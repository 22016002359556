import React, { useState, useCallback } from 'react'
import { useAuthFunctions, useAuthState } from '../../context/Auth'
import { useCampaignFunctions, useCampaignState } from '../../context/Campaign'
import API from '../../functions/API'
import useOpenWindow from '../../hooks/useOpenWindow'
import useRemoveToken from '../../hooks/useRemoveToken'
import { IconButton } from '../Buttons'
import Button from '../Buttons/ButtonParent'
import TokensList from '../MapViewer/partials/TokensList'

import './Dice.css'

const Dice = () => {
    const { user, auth } = useAuthState()
    const { authDispatch } = useAuthFunctions()
    const { socket, activeCampaign } = useCampaignState()
    const { changeUserDiceSkin } = useCampaignFunctions()
    const [selected, setSelected] = useState(user.diceSkin)
    const openWindow = useOpenWindow()
    const openPremiumSignup = () => openWindow('premiumSignup')

    const removeTokenImage = useRemoveToken()

    const selectSkin = useCallback((newSkin) => {
        if (socket) {
            socket.emit('CHANGE_DICE_SKIN', JSON.stringify({ auth, key: newSkin, campaignShortId: activeCampaign?.shortId }))
        } else {
            changeUserDiceSkin(user._id, newSkin, user.color, user, authDispatch)
            API.sendGraphSecure(auth, {
                query: `mutation {
                changeDiceSkin(key: "${newSkin}") {
                        _id
                        diceSkin
                        color
                    }
                }`
            })
        }

        setSelected(newSkin)
    }, [activeCampaign?.shortId, auth, authDispatch, changeUserDiceSkin, socket, user])

    const dice = [4, 6, 8, 12, 100, 10, 20]
    const skins = [
        {
            key: 'red',
            name: 'Red',
            path: '/dice/icons/D20-red.png'
        }, {
            key: 'blue',
            name: 'Blue',
            path: '/dice/icons/D20-blue.png'
        }, {
            key: 'green',
            name: 'Green',
            path: '/dice/icons/D20-green.png'
        }, {
            key: 'yellow',
            name: 'Yellow',
            path: '/dice/icons/D20-yellow.png'
        }, {
            key: 'lilac',
            name: 'Lilac',
            path: '/dice/icons/D20-lilac.png'
        }, {
            key: 'orange',
            name: 'Orange',
            path: '/dice/icons/D20-orange.png'
        }, {
            key: 'black',
            name: 'Black',
            path: '/dice/icons/D20-black.png'
        }, {
            key: 'white',
            name: 'White',
            path: '/dice/icons/D20-white.png'
        },
    ]

    const premiumSkins = [
        {
            key: 'stone',
            name: 'Stone',
            path: '/dice/icons/D20-stone.png'
        }
    ]


    return (
        <div className="chest-dice-tab full-height">
            <div className="full-height full-width col space-between">
                <div className="dice-display-container row margin-vertical-1 padding-1 bg-shade-2 centered">
                    {dice.map((die, i) => (
                        <img
                            key={i}
                            src={`/dice/icons/D${die}-${selected}.png`}
                            className="dice-icon"
                            alt={`D${die}`}
                        />
                    ))}
                </div>

                <h3 className="c-main">Basic Skins</h3>

                <div className="dice-select-container row centered margin-1 wrap padding-left-3 padding-right-3">
                    {skins.map((skin, i) => (
                        <IconButton
                            key={i}
                            className={`diceDrawer-dice-button ${skin.key === selected ? 'dice-tab-selected bg-shade-2' : ''}`}
                            img={skin.path}
                            alt={skin.name}
                            onClick={() => selectSkin(skin.key)}
                        />
                    ))}
                </div>

                {!user.subscription.active ? (
                    <Button className="padding-1 bg-dark-blue c-white" onClick={openPremiumSignup}>Unlock more with premium</Button>
                ) : <>
                    <h3 className="c-main">Premium Skins</h3>
                </>}

                <div className="dice-select-container row centered margin-1">
                    {premiumSkins.map((skin, i) => (
                        <IconButton
                            key={i}
                            className={`diceDrawer-dice-button ${user.subscription.active ? 'hi' : 'disabled'} ${skin.key === selected ? 'dice-tab-selected bg-shade-2' : ''}`}
                            img={skin.path}
                            alt={skin.name}
                            onClick={() => selectSkin(skin.key)}
                        />
                    ))}
                </div>

                <div className="margin-top-1 full-width">
                    <h3 className='c-main'>Tokens</h3>

                    <TokensList {...{ removeTokenImage, userImages: user.tokens }} />
                </div>
            </div>

        </div>
    )
}

export default Dice
