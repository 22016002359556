import React, { useState, useCallback, useRef } from 'react'
import TextInput from '../TextInput'
import './styles.css'

const CopyTextInput = ({ value, className, inputClassName, containerClassName }) => {
    const [copiedTooltip, setCopiedTooltip] = useState(false)
    const [copiedToolTipTimer, setCopiedToolTipTimer] = useState(null)
    const inputRef = useRef(null)

    const setCopiedTooltipAndReset = useCallback(() => {
        if (copiedToolTipTimer !== null) clearTimeout(copiedToolTipTimer)

        setCopiedTooltip(true)
        setCopiedToolTipTimer(setTimeout(() => setCopiedTooltip(false), 3000))


        return () => clearTimeout(copiedToolTipTimer)
    }, [copiedToolTipTimer])

    const copyTextToClipboard = () => {
        try {
            inputRef.current.focus()
            inputRef.current.select()
            document.execCommand("copy")
            setCopiedTooltipAndReset()
            inputRef.current.blur()
            window.getSelection().removeAllRanges()
        } catch (err) {
            console.log('problem copying text', err)
        }
    }

    return (
        <div className={`copyTextInput-container ${containerClassName}`} onClick={() => copyTextToClipboard()}>
            {copiedTooltip && <p className="copied-tooltip">Copied! Send to your friends!</p>}
            <TextInput defaultValue={value} className={`copyTextInput ${className}`} inputClassName={inputClassName} readOnly inputRef={inputRef} />
        </div>
    )
}

export default CopyTextInput