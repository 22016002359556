import React, { useEffect, useState } from 'react'


const Rings = ({ labels, ww, hh }) => {
  const max = labels?.length || 0
  return labels?.map((label, i) => {
    return (
      <Ring key={i} {...{ max, i, ww, hh, ringWidth: 3, color: label.color, zIndex: labels.length }} />
    )
  }) || null
}

export default Rings

const Ring = ({ max, ringWidth, color, i, ww, hh, zIndex }) => {
  const [visible, setVisisble] = useState(false)

  useEffect(() => {
    setTimeout(() => setVisisble(true), 0)
  }, [])

  return (
    <div className="ring" style={{
      top: ((i - max) * ringWidth),
      left: ((i - max) * ringWidth),
      width: (ww + (max - i) * ringWidth * 2),
      height: (hh + (max - i) * ringWidth * 2),
      borderWidth: visible ? `${ringWidth + 1}px ${ringWidth}px ${ringWidth + 1}px ${ringWidth + ringWidth / 2}px` : '0px',
      borderColor: color,
      animationDuration: `${(i + 1) * 10}s`,
      opacity: visible ? 1 : 0,
      transform: `scale(${visible ? 1 : 0.5})`,
      zIndex: `${zIndex - i}`
    }}></div>
  )
}