const fullUsersDocuments = `
    _id
    name
    kind
    data
    owner {
        _id
    }
    sharedUsers {
        _id
        name
    }
    sharedCampaigns {
        _id
        title
        owner {
            _id
            name
        }
    }
`

export default fullUsersDocuments