import React from 'react'
import * as dayjs from 'dayjs'
import Card from '../../../../../../components/Card'
import NothingToSee from '../../../../../../components/NothingToSee'

const BillingHistory = ({ subscription }) => {
    return (
        <div className="billing-history-container col">
            <label>Billing History</label>
            <Card className="billing-history-inner col space-between">
                <table className="billing-history-table">
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Activity</td>
                            <td>Amount</td>
                        </tr>
                    </thead>
                    {subscription?.billingHistory?.length ? (
                        <tbody>
                            {subscription.billingHistory.map((billingEntry, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{dayjs(parseInt(billingEntry.date) + 1000).format('M/DD/YY')}</td>
                                        <td>{billingEntry.activity}</td>
                                        <td>{billingEntry.amount}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    ) : null}
                </table>

                {subscription?.billingHistory?.length ? null : <NothingToSee />}
                <p className="billing-history-footer">Need Help? <a className="link t-bold" href="mailto:dreii@adventuring-kit.com">Contact Us</a></p>
            </Card>
        </div>
    )
}

export default BillingHistory
