import { useAuthFunctions, useAuthState } from "../../context/Auth"
import { useCampaignFunctions, useCampaignState } from "../../context/Campaign"

const useDiceCommunications = () => {
    const { user } = useAuthState()
    const { authDispatch } = useAuthFunctions()
    const { socket } = useCampaignState()
    const { changeUserDiceSkin, recieveNetworkRoll, campaignDispatch } = useCampaignFunctions()

    const diceCommunications = () => {
        socket.on('CHANGE_DICE_SKIN', (data) => {
            const { _id, diceSkin, color } = JSON.parse(data)
            changeUserDiceSkin(_id, diceSkin, color, user, authDispatch)
        })

        socket.on("DICE_ROLL", data => {
            recieveNetworkRoll(data, user._id)
        })

        socket.on("UPDATE_PAST_ROLL", data => {
            const { roll } = JSON.parse(data)
            campaignDispatch({ type: "UPDATE_PAST_ROLL", value: { roll } })
        })
    }

    return diceCommunications
}

export default useDiceCommunications