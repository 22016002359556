import React, { useState } from 'react'
import { useCampaignState } from '../../context/Campaign'
import { useWorkspaceFunctions, useWorkspaceState } from '../../context/Workspace'
import convertStringIntoDice from '../../functions/convertStringIntoDice'
import { Checkbox } from '../Checkbox'
import SelectList from '../SelectList/SelectList'
import TextInput from '../TextInput'

const DiceOptions = () => {

    const { diceOptions } = useWorkspaceState()
    const { setDiceOptions, workspaceDispatch } = useWorkspaceFunctions()
    const { addDice } = useCampaignState()

    const [complexRoll, setComplexRoll] = useState("")

    const {
        diceTrayActive,
        rollPrivately,
        disableAnimations,
        keepBest,
        addOrSub,
        succeedsOn,
        succeedsOnDiceOrRoll,
        succeedsOnGreaterOrLess,
        explodesOn
    } = diceOptions

    const changeOption = (key, value) => {
        switch (key) {
            case 'diceTrayActive':
            case 'rollPrivately':
            case 'disableAnimations':
                setDiceOptions(key, !!value)
                break

            case 'explodesOn':
                value = setValueNumber(key, value)
                setDiceOptions(key, value)
                break

            case 'succeedsOn':
            case 'addOrSub':
            case 'keepBest':
                value = setValueNumber(key, value, true)
                setDiceOptions(key, value)

                break

            case 'complexRoll':
                setComplexRoll(value)
                break

            case 'succeedsOnDiceOrRoll':
                setDiceOptions(key, value)
                break

            case 'succeedsOnGreaterOrLess':
                setDiceOptions(key, value)
                break

            default: throw new Error('Invalid dice option setting')
        }
    }

    const explosionLimitWarning = () => {
        if (diceOptions.explodesOn === 1 || diceOptions.explodesOn === "1") {
            setDiceOptions("explodesOn", "2")
            // setError("Cannot set dice explosions to 1, that would cause an infinite loop!")
        }
    }

    return (
        <div className="diceOptions row margin-top-1">
            <div className="col margin-right-2">
                <Checkbox
                    className="t-small hidden-tablet margin-bottom-1"
                    value={<span className={!diceTrayActive && 'disabled'}>Use dice tray</span>}
                    checked={diceTrayActive}
                    onChange={(e) => changeOption('diceTrayActive', e.target.checked)}
                />
                <Checkbox
                    className="t-small margin-bottom-1"
                    value={<span className={!rollPrivately && 'disabled'}>Roll privately</span>}
                    checked={rollPrivately}
                    onChange={(e) => changeOption('rollPrivately', e.target.checked)}
                />
                <Checkbox
                    className="t-small margin-bottom-1"
                    value={<span className={!disableAnimations && 'disabled'}>No animations</span>}
                    checked={disableAnimations}
                    onChange={(e) => changeOption('disableAnimations', e.target.checked)}
                />
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (complexRoll !== "") {
                        const complexDiceArr = convertStringIntoDice(complexRoll)
                        complexDiceArr.forEach(complexDice => {
                            complexDice.dice.forEach(die => {
                                for (let i = 0; i < die.quantity; i++) {
                                    addDice(die.key)
                                }
                            })

                            if (complexDice.addOrSub || complexDice.succeedsOn)
                                workspaceDispatch({
                                    type: 'SET_TEMP_DICE_OPTIONS', value: {
                                        addOrSub: complexDice.addOrSub,
                                        succeedsOn: complexDice.succeedsOn,
                                        succeedsOnGreaterOrLess: complexDice.succeedsOnGreaterOrLess,
                                    }
                                })
                        })

                        setComplexRoll("")
                    } else {

                    }
                }}>
                    <TextInput
                        inputClassName="padding-quarter padding-left-half hidden-tablet"
                        placeholder="Type out a complex roll"
                        value={complexRoll || ""}
                        onChange={(e) => changeOption('complexRoll', e.target.value.replace(/[^<>d\d+\-*]/gmi, ''))}
                    />
                </form>
            </div>

            <div className="col">
                <div className="row space-between">
                    <Checkbox
                        className="t-small margin-right-1 margin-bottom-1"
                        value={<span className={keepBest === 0 && 'disabled'}>Keep best</span>}
                        checked={keepBest !== 0}
                        onChange={(e) => changeOption('keepBest', e.target.checked)}
                    />
                    <TextInput
                        inputClassName="padding-quarter"
                        className="diceOptionsInput"
                        placeholder="0"
                        value={keepBest !== 0 ? keepBest : ''}
                        onChange={(e) => changeOption('keepBest', e.target.value)}
                    />
                </div>

                <div className="row space-between">
                    <Checkbox
                        className="t-small margin-right-1 margin-bottom-1"
                        value={<span className={addOrSub === 0 && 'disabled'}>Add or subtract</span>}
                        checked={addOrSub !== 0}
                        onChange={(e) => changeOption('addOrSub', e.target.checked)}
                    />
                    <TextInput
                        inputClassName="padding-quarter"
                        className="diceOptionsInput"
                        placeholder="0"
                        value={addOrSub !== 0 ? addOrSub : ''}
                        onChange={(e) => changeOption('addOrSub', e.target.value)}
                    />
                </div>

                <div className="row space-between">
                    <Checkbox
                        className="t-small"
                        valueClassName="padding-top-0"
                        checked={succeedsOn !== 0}
                        onChange={(e) => changeOption('succeedsOn', e.target.checked)}
                    />
                    <div className={`row centered t-small margin-right-1 c-shade-6 ${succeedsOn === 0 && 'disabled'}`}>
                        <SelectList
                            className="width-3dot5 margin-right-quarter"
                            items={['Dice', 'Roll']}
                            selected={succeedsOnDiceOrRoll}
                            onChange={e => changeOption('succeedsOnDiceOrRoll', e.target.value)}
                        />
                        <SelectList
                            className="width-5 margin-right-quarter"
                            items={['greater', 'less']}
                            selected={succeedsOnGreaterOrLess}
                            onChange={e => changeOption('succeedsOnGreaterOrLess', e.target.value)}
                        />
                        than
                    </div>
                    <TextInput
                        inputClassName="padding-quarter"
                        className="diceOptionsInput"
                        placeholder="0"
                        value={succeedsOn !== 0 ? succeedsOn : ''}
                        onChange={(e) => changeOption('succeedsOn', e.target.value)}
                    />
                </div>

                <div className="row space-between margin-top-half">
                    <Checkbox
                        className="t-small margin-right-1 margin-bottom-1"
                        value={<span className={explodesOn === 0 && 'disabled'}>Dice explodes on</span>}
                        checked={explodesOn !== 0}
                        onChange={(e) => changeOption('explodesOn', e.target.checked)}
                    />
                    <TextInput
                        inputClassName="padding-quarter"
                        className="diceOptionsInput"
                        placeholder="0"
                        value={explodesOn !== 0 ? explodesOn : ''}
                        onChange={(e) => changeOption('explodesOn', e.target.value)}
                        onBlur={(e) => explosionLimitWarning()}
                    />
                </div>
            </div>
        </div>
    )
}

export default DiceOptions


const setValueNumber = (key, value, allowNegative) => {
    const regex = allowNegative ? /[^\-0-9]+/ : /[^0-9]/

    if (value === true) value = key === 'explodesOn' ? 2 : 1
    else if (value === false) value = 0
    else value = value.replace(regex, '')
    while (value[0] === '0') value = value.substr(1)
    if (value === '') value = 0

    const extraDash = value.length > 1 && value.indexOf('-', 1)
    if (extraDash) {
        const frontValue = value.substr(0, extraDash)
        const backValue = value.substr(extraDash + 1)

        value = frontValue + backValue
    }

    if (typeof value === 'string') {
        if (value.includes('-')) {
            if (value.length > 5) value = value.substring(0, 5)
        } else {
            if (value.length > 4) value = value.substring(0, 4)
        }
    }

    return value
}