import React from 'react'

const Parchment = () => {
    return (
        <svg data-name="parchment" xmlns="http://www.w3.org/2000/svg" width="125.582" height="102.965" viewBox="0 0 125.582 102.965">
            <path d="M366.565,34.53a15.865,15.865,0,0,1-4.69-11.424C361.856,14.524,356.064,7.5,347.48,7.5H322.918V39.749H364.4A3.059,3.059,0,0,0,366.565,34.53Zm0,0" transform="translate(-243.714 -5.66)" fill="#e0c1ad" />
            <path d="M322.918,7.5V26.262h39.249a17.14,17.14,0,0,1-.292-3.155C361.856,14.524,356.064,7.5,347.48,7.5Zm0,0" transform="translate(-243.714 -5.66)" fill="#ccaa97" />
            <path d="M130.89,7.5a22.007,22.007,0,0,0-22.007,22.007v77.279H159.3A11.888,11.888,0,0,0,171.191,94.9V22.252A14.752,14.752,0,0,1,185.944,7.5Zm0,0" transform="translate(-82.176 -5.66)" fill="#efd4bf" />
            <path d="M136.81,339.576l-27.927.25V357.4H159.3a11.889,11.889,0,0,0,11.888-11.889v-5.952A184.346,184.346,0,0,1,136.81,339.576Zm0,0" transform="translate(-82.176 -256.27)" fill="#e0c1ad" />
            <path d="M70.917,301.172v-8.6a11.759,11.759,0,0,0-11.759-11.759H10.583a3.059,3.059,0,0,0-2.161,5.219,15.862,15.862,0,0,1,4.689,11.423,15.611,15.611,0,0,0,15.542,15.608H82.807A11.89,11.89,0,0,1,70.917,301.172Zm0,0" transform="translate(-5.681 -211.936)" fill="#fce6d4" />
            <path d="M55.883,337.2a4.472,4.472,0,0,0-4.472-4.473H28.449a16.854,16.854,0,0,1,.452,3.907,15.669,15.669,0,0,0,.84,5.038h21.67A4.472,4.472,0,0,0,55.883,337.2Zm0,0" transform="translate(-21.471 -251.12)" fill="#efd4bf" />
            <path d="M175.785,341.675h-3.6a4.472,4.472,0,0,1,0-8.945h3.6a4.472,4.472,0,0,1,0,8.945Zm0,0" transform="translate(-126.578 -251.12)" fill="#efd4bf" />
            <path d="M189.537,162.129H150.1a1.84,1.84,0,1,0,0,3.679h39.44a1.84,1.84,0,1,0,0-3.679Zm0,0" transform="translate(-111.894 -122.363)" />
            <path d="M189.537,199.168H150.1a1.84,1.84,0,1,0,0,3.679h39.44a1.84,1.84,0,0,0,0-3.679Zm0,0" transform="translate(-111.894 -150.317)" />
            <path d="M248.426,236.207h-20.3a1.84,1.84,0,0,0,0,3.679h20.3a1.84,1.84,0,0,0,0-3.679Zm0,0" transform="translate(-170.783 -178.271)" />
            <path d="M124.161,27.579A14.047,14.047,0,0,1,120,17.442C119.977,7.5,113,0,103.766,0H48.713A23.873,23.873,0,0,0,24.867,23.846V67.037H4.9a4.9,4.9,0,0,0-3.473,8.349A14.037,14.037,0,0,1,5.59,85.523a17.461,17.461,0,0,0,17.381,17.442H77.126A13.744,13.744,0,0,0,90.854,89.237V16.592A12.928,12.928,0,0,1,103.767,3.679c7.256,0,12.536,5.792,12.555,13.772a17.608,17.608,0,0,0,5.217,12.708,1.2,1.2,0,0,1,.266,1.352,1.168,1.168,0,0,1-1.116.738H96.365a1.84,1.84,0,1,0,0,3.679h24.324a4.815,4.815,0,0,0,4.51-3A4.911,4.911,0,0,0,124.161,27.579ZM87.175,16.592V89.237a10.049,10.049,0,1,1-20.1,0v-8.6a13.614,13.614,0,0,0-13.6-13.6H44.735a1.84,1.84,0,0,0,0,3.679h8.743A9.93,9.93,0,0,1,63.4,80.635v8.6a13.7,13.7,0,0,0,4.384,10.05H22.972A13.776,13.776,0,0,1,9.27,85.514,17.609,17.609,0,0,0,4.052,72.8a1.2,1.2,0,0,1-.265-1.351A1.165,1.165,0,0,1,4.9,70.716h31.93a1.84,1.84,0,0,0,0-3.679H28.546V23.846A20.19,20.19,0,0,1,48.713,3.679H93.359A16.568,16.568,0,0,0,87.175,16.592Zm0,0" transform="translate(0)" />
        </svg>

    )
}

export default Parchment
