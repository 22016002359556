import * as THREE from 'three'
import GS from '../../GlobalStorage'
import { getControlEnd, getControlStart } from '../../helpers'
import { getScreenPosIn3dSpace } from '../functions'
import {
    loadDice,
    addDiceToHand,
    popSelectedDice,
    releaseDice,
    createDiceGroup,
    updateSelectedDice,
    finishDice,
    noAnimationRoll,
    removeDiceFromWorld,
    removeGroup,
    updateRollingDice,
    updateFinishedDice,
    setDiceFinishedPosition,
    addDiceToRollingGroup,
    getDiceRollingDirection,
    createDiceObject,
    explodeDice,
    explodeDiceEffect,
} from './functions'

export default class DiceManager {

    constructor(sceneManager) {
        this.scene = sceneManager.scene
        this.world = sceneManager.world
        this.camera = sceneManager.camera
        this.diceBodyMaterial = sceneManager.diceBodyMaterial
        this.dice = {}
        this.ready = false
        this.WIDTH = sceneManager.WIDTH
        this.HEIGHT = sceneManager.HEIGHT
        this.startScene = sceneManager.start.bind(sceneManager)
        this.stopScene = sceneManager.stop.bind(sceneManager)
        this.closeDiceDrawer = this.scene.closeDiceDrawer

        this.mousePos = new THREE.Vector3(0, 0, 0)
        this.dragStartPos = new THREE.Vector3(0, 0, 0)
        this.dragStartPos2d = null

        this.originX = 12.4
        this.originY = -17

        this.primaryX = 0.5
        this.primaryY = 2
        this.offset = 2

        this.mobileOrigin = getScreenPosIn3dSpace(this.camera, (this.WIDTH / 5), this.HEIGHT * 0.3, this.WIDTH, this.HEIGHT)

        this.selectedDice = []
        this.rollingGroups = []
        this.rolledDice = []
        this.finishedDice = []

        this.mouse = new THREE.Vector2()
        this.raycaster = new THREE.Raycaster()

        this.finishedDicePosition = getScreenPosIn3dSpace(this.camera, this.WIDTH + 64, 64, this.WIDTH, this.HEIGHT)
        this.sendDiceRoll = null
        this.addRollToHistory = null

        this.diceTray = { active: GS.get('DICE_OPTIONS').diceTrayActive }

        this.onMouseMove = this.onMouseMove.bind(this)
        this.handleMouseDown = this.handleMouseDown.bind(this)
        this.handleMouseUp = this.handleMouseUp.bind(this)

        window.addEventListener('mousemove', this.onMouseMove)
        window.addEventListener('touchmove', this.onMouseMove)
        window.addEventListener('mousedown', this.handleMouseDown)
        window.addEventListener('touchstart', this.handleMouseDown)
        window.addEventListener('mouseup', this.handleMouseUp)
        window.addEventListener('touchend', this.handleMouseUp)

        this.loadDice = loadDice.bind(this)
        this.addDiceToHand = addDiceToHand.bind(this)
        this.popSelectedDice = popSelectedDice.bind(this)
        this.releaseDice = releaseDice.bind(this)
        this.createDiceObject = createDiceObject.bind(this)

        this.createDiceGroup = createDiceGroup.bind(this)
        this.addDiceToRollingGroup = addDiceToRollingGroup.bind(this)
        this.getDiceRollingDirection = getDiceRollingDirection.bind(this)
        this.explodeDiceEffect = explodeDiceEffect.bind(this)
        this.explodeDice = explodeDice.bind(this)

        this.updateSelectedDice = updateSelectedDice.bind(this)
        this.updateRollingDice = updateRollingDice.bind(this)
        this.updateFinishedDice = updateFinishedDice.bind(this)

        this.finishDice = finishDice.bind(this)
        this.setDiceFinishedPosition = setDiceFinishedPosition.bind(this)
        this.removeGroup = removeGroup.bind(this)
        this.removeDiceFromWorld = removeDiceFromWorld.bind(this)

        this.noAnimationRoll = noAnimationRoll.bind(this)
    }

    // INTERACTIONS
    handleMouseDown(e) {
        const coords = getControlStart(e)
        this.mouseDown = true
        this.mousePos = getScreenPosIn3dSpace(this.camera, coords.clientX, coords.clientY, this.WIDTH, this.HEIGHT)
        this.dragStartPos2d = { x: coords.clientX, y: coords.clientY }
        this.dragStartPos = this.mousePos

        const target = e.target || e.srcElement
        const id = target.id
        if (id === 'cancelDiceButton') return this.popSelectedDice()
        if (id === "dice-tray" || (this.disableAnimations && id === "workspace")) this.releaseDice()
    }

    handleMouseUp(e) {
        const coords = getControlEnd(e)
        this.mouseDown = false
        if (this.selectedDice.length) {
            const target = coords.target || coords.srcElement
            const id = target.id
            if (!e.shiftKey) {
                if (this.diceTrayActive) {
                    if (id === "dice-tray")
                        this.releaseDice()
                } else {
                    if (!this.hasSomeParentTheClass(coords.target || coords.srcElement, 'dice-drawer'))
                        this.releaseDice()
                }
            }
        }
    }

    // returns true if the element or one of its parents has the class classname
    hasSomeParentTheClass(element, classname) {
        if (element?.className?.split?.(' ')?.indexOf?.(classname) >= 0) return true
        return element?.parentNode && this.hasSomeParentTheClass(element.parentNode, classname)
    }

    onMouseMove(e) {
        const coords = getControlStart(e)
        if (!this.selectedDice.length) return
        this.mousePos = getScreenPosIn3dSpace(this.camera, coords.clientX, coords.clientY, this.WIDTH, this.HEIGHT)
    }
    // LIFECYCLE
    update() {
        this.updateSelectedDice()
        this.updateRollingDice()
        this.updateFinishedDice()
    }

    cleanup() {
        window.removeEventListener('mousemove', this.onMouseMove)
        window.removeEventListener('touchmove', this.onMouseMove)

        window.removeEventListener('mousedown', this.handleMouseDown)
        window.removeEventListener('touchstart', this.handleMouseDown)

        window.removeEventListener('mouseup', this.handleMouseUp)
        window.removeEventListener('touchend', this.handleMouseUp)

        this.rollingGroups.forEach(group => this.removeGroup(group))
    }

    // SETTERS
    setUser(user) { this.user = user }
    setSendDiceRoll(func) { this.sendDiceRoll = func }
    setAddRollToHistory(func) { this.addRollToHistory = func }
    setBounds(width, height) {
        this.WIDTH = width
        this.HEIGHT = height
        this.finishedDicePosition = getScreenPosIn3dSpace(this.camera, this.WIDTH + 64, 64, this.WIDTH, this.HEIGHT)
    }
}
