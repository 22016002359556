import API from "../../../functions/API"
export async function shareFolder(auth, targetId, folderId, isCampaign, setLoading, setError) {
    try {
        setLoading(true)
        if (isCampaign) {
            const result = await API.sendGraphSecure(auth, {
                query: `mutation shareFolderWithCampaign($folderId: ID!, $campaignId: String!) {
                        shareFolderWithCampaign(folderId: $folderId, campaignId: $campaignId) {
                            _id
                            sharedCampaigns {
                                _id
                            }
                            sharedUsers {
                                _id
                            }
                        }
                    }`,
                variables: {
                    "folderId": folderId,
                    "campaignId": targetId
                }
            })

            this.dispatch({
                type: 'UPDATE_SHARED_FOLDER', value: result.data.shareFolderWithCampaign
            })

            setLoading(false)
            return result.data.shareFolderWithCampaign
        } else {
            const result = await API.sendGraphSecure(auth, {
                query: `mutation shareFolderWithUser($folderId: ID!, $userId: String!) {
                        shareFolderWithUser(folderId: $folderId, userId: $userId) {
                            _id
                            sharedCampaigns {
                                _id
                            }
                            sharedUsers {
                                _id
                            }
                        }
                    }`,
                variables: {
                    "folderId": folderId,
                    "userId": targetId
                }
            })

            this.dispatch({
                type: 'UPDATE_SHARED_FOLDER', value: result.data.shareFolderWithUser
            })

            setLoading(false)
            return result.data.shareFolderWithUser
        }
    } catch (err) {
        setError(err.message)
    }
}