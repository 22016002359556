import Socket from '../../../functions/Socket'
export async function destroyCampaign(authToken, user, shortId, setLoading, setError, closeWindow, windowIndex) {
    const errorDestroying = setTimeout(() => {
        setLoading(false)
        setError('Error trying to destroy campaign.', true)
    }, 10000)

    setLoading(true)

    const socket = await Socket.assignSocket(user, shortId)
    socket.on('CANNOT_DELETE_CAMPAIGN', () => {
        setLoading(false)
        clearTimeout(errorDestroying)
        setError('Cannot destroy campaign while users are active within it.')
    })

    socket.on('CAMPAIGN_DESTROYED', (shortId) => {
        closeWindow(windowIndex)
        this.dispatch({ type: 'CAMPAIGN_DESTROYED', value: shortId })
        setLoading(false)
        clearTimeout(errorDestroying)
    })

    socket.emit("ATTEMPT_TO_DESTROY_CAMPAIGN", JSON.stringify({ authToken, shortId }))
}