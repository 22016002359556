import React, { useState } from 'react'
import Card from '../../../../../../components/Card'
import { Button, TextButton } from '../../../../../../components/Buttons'
import PriceDisplay from '../../../../../../components/PriceDisplay'
import * as dayjs from 'dayjs'

const YourSub = ({ subscription, onCancelSub, onReactivateSub, openSubSignupWindow, price, interval }) => {
    const [manage, setManage] = useState(false)
    return (
        <div className="your-sub-container col margin-bottom-1">
            <label>Your Subscription</label>
            <Card className={`your-sub-inner ${subscription && subscription.active ? 'col' : 'row space-between centered-vertically'} ${subscription.active ? (subscription.expirationDate ? 'red' : 'green border-thick') : ''}`} emphasis>
                {subscription && subscription.active ? (
                    subscription.expirationDate ? (
                        <>
                            <div className="row space-between centered-vertically full-width t-left">
                                <p>Premium will expire on <span className="whitespace-nowrap">{dayjs(parseInt(subscription.expirationDate)).format('MMM D, YYYY')}</span></p>
                                <Button className="white whitespace-nowrap emphasis-1" onClick={onReactivateSub}>Continue Subscription</Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row space-between centered-vertically full-width">
                                <PriceDisplay className="margin-left-1" green price={price} interval={interval} />
                                <TextButton className="c-main" onClick={() => setManage(!manage)}>Manage</TextButton>
                            </div>
                            {manage && (
                                <div className="row space-between">
                                    <TextButton className="c-red" onClick={onCancelSub}>Cancel Subscription</TextButton>
                                    {/* {subscription.billingHistory[0].amount === "9.99" && <Button className="white emphasis-1" onClick={onUpgradeToYearly}>Upgrade to Yearly</Button>} */}
                                </div>
                            )}
                        </>
                    )
                ) : (
                    <>
                        <p className="margin-left-1">Free Tier</p>
                        <Button emphasis onClick={openSubSignupWindow}>Upgrade Now</Button>
                    </>
                )}
            </Card>
        </div>
    )
}

export default YourSub
