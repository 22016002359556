import { updateRollingGroup } from "../../../functions/dice/DiceManager/functions/updateRollingGroup"
import { getRandomDiceValue } from "../../../functions/dice/functions/setRandomDiceValue"

export function handleReRoll(roll, die, socket) {
    const newRollsDice = [...roll.dice]
    const newDiceIndex = newRollsDice.findIndex(findDie => findDie.id === die.id)
    if (newDiceIndex > -1) {
        newRollsDice[newDiceIndex].value = getRandomDiceValue(newRollsDice[newDiceIndex].rawType)
        const oldRRCount = newRollsDice[newDiceIndex].rerollCount
        newRollsDice[newDiceIndex].rerollCount = oldRRCount ? oldRRCount + 1 : 1
    }

    const updatedRoll = updateRollingGroup(roll)
    this.dispatch({ type: "UPDATE_PAST_ROLL", value: { roll: updatedRoll } })

    if (socket) {
        const data = {
            roll: { ...updatedRoll, localRoll: false },
            user: roll.user
        }
        socket.emit('UPDATE_PAST_ROLL', JSON.stringify(data))
    }
}