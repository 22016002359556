import {
    addCampaignToInventory,
    importInventoryData,
    deleteDocument,
    removeImagesFromUser,
    leaveCampaign,
    destroyCampaign,
    shareFolder,
    unshareFolder,
    unshareDocument,
    deleteFolder,
    checkForInventoryUpdate,
    getFolderData
} from './functions'

export default function buildFunctions(dispatch) {
    return {
        addCampaignToInventory: addCampaignToInventory.bind({ dispatch }),
        importInventoryData: importInventoryData.bind({ dispatch }),
        deleteDocument: deleteDocument.bind({ dispatch }),
        removeImagesFromUser: removeImagesFromUser.bind({ dispatch }),
        leaveCampaign: leaveCampaign.bind({ dispatch }),
        destroyCampaign: destroyCampaign.bind({ dispatch }),
        shareFolder: shareFolder.bind({ dispatch }),
        unshareFolder: unshareFolder.bind({ dispatch }),
        unshareDocument: unshareDocument.bind({ dispatch }),
        deleteFolder: deleteFolder.bind({ dispatch }),
        checkForInventoryUpdate: checkForInventoryUpdate.bind({ dispatch }),
        getFolderData: getFolderData.bind({ dispatch }),
        inventoryDispatch: dispatch
    }
}