import React, { useCallback, useState } from 'react'

import TextInput from '../../components/TextInput'
import { Button, IconButton } from '../../components/Buttons'

import { useAuthState, useAuthFunctions } from '../../context/Auth'
import { useErrorFunctions } from '../../context/Error'
import Loading from '../../components/Loading'
import { useLoadingFunctions, useLoadingState } from '../../context/Loading'

const PasswordResetRequest = () => {
    const { resetEmail } = useAuthState()
    const { changeField, changePage, sendPasswordResetEmail } = useAuthFunctions()

    const { loading } = useLoadingState()
    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()

    const [success, setSuccess] = useState(false)

    const handleForm = useCallback(async (e) => {
        e.preventDefault()
        await sendPasswordResetEmail(resetEmail, setLoading, setError)
        setSuccess(true)

    }, [sendPasswordResetEmail, resetEmail, setLoading, setError])

    return (
        <div className="auth-login-container">
            {loading === 'password-reset-request' ? <Loading overlay /> : null}
            <form className="auth-inner-container" onSubmit={handleForm}>
                <div className="row margin-bottom-3">
                    <IconButton className="margin-right-1" icon="arrow-left" onClick={() => changePage("login")} />
                    <h1 className="c-shade-7">Reset password</h1>
                </div>
                {success ? (
                    <p className="c-shade-6">If <span className="c-blue t-bold">{resetEmail}</span> has an account, we will send you an email with a reset token.</p>
                ) : (
                        <TextInput id="reset-email" className="auth-textInput" value={resetEmail} label="Email" placeholder="johndoe@example.com" onChange={(e) => changeField(e.target.value, "resetEmail")} />
                    )}

                <Button className="auth-login-button margin-top-3" type="submit">Send Reset Request</Button>
            </form>
        </div>
    )
}

export default PasswordResetRequest