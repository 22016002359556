import React from 'react'
import './styles.css'
const TabNavigation = ({ tabs, activeTab, setTab, classNames, tabStyles }) => {
    return (
        <div className={`tab-nav-header ${classNames}`}>
            {tabs.map((tab, i) => (
                <button
                    className={`tab-nav-button ${activeTab === i && 'active'}`}
                    key={i}
                    onClick={() => setTab(i)}
                    style={{ ...tabStyles }}
                >
                    {tab}
                </button>
            ))}
        </div>
    )
}

export default TabNavigation
