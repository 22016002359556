import React from 'react'
import './List.css'
const List = ({ emptyMessage, children, className }) => {
    return (
        <ul className={`list ${className}`}>
            {children || <p className="list-empty-message">{emptyMessage}</p>}
        </ul>
    )
}

export default List
