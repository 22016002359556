import React, { useEffect } from 'react'
import DefaultMapTokens from '../../../config/DefaultMapTokens'
import { Button, CircleButton, IconButton } from '../../Buttons'
import LazyLoad from 'react-lazyload'
import { Plus } from '@styled-icons/bootstrap/Plus'
import HiddenList from '../../List/HiddenList'
import useOpenWindow from '../../../hooks/useOpenWindow'
import { globalTokenPlacer_Atom } from '../atoms'
import { useAtom } from 'jotai'
import { forceCheck } from 'react-lazyload';

const TokensList = ({ removeTokenImage = () => null, userImages = [], scrollRef }) => {
    const openWindow = useOpenWindow()

    const [, setTokenPlacer] = useAtom(globalTokenPlacer_Atom)

    useEffect(() => {
        setTimeout(() => forceCheck(), 100)
    }, [])

    return (
        <div className="col tokenListModal">
            <div className="row centered margin-bottom-1 flex-start">
                <Button type="blue" className="tokenList-header-button padding-horizontal-1 centered-vertically margin-top-1" onClick={() => openWindow('AddTokens')}>
                    <Plus className=" size-2" /> Add Tokens
                </Button>
            </div>
            <div className="col">
                <HiddenList titleClassName="padding-0" defaultOpen={userImages.length > 0} title={`Your Tokens (${userImages.length})`}>
                    <div className="position-relative row wrap centered margin-top-quarter">
                        {userImages.map((token, i) => {
                            return (
                                <div className="usersTokenListItem" key={token.src}>
                                    <IconButton draggable={false} onMouseDown={(e) => {
                                        setTokenPlacer([{ ...token, mouseX: e.clientX, mouseY: e.clientY }])
                                    }}>
                                        <LazyLoad height={48} width={48} offset={100} overflow scrollContainer={scrollRef}>
                                            <img draggable={false} className="userTokensListItem-img size-3 fade-in" src={token.src} alt={token.name || 'User token'} />
                                        </LazyLoad>
                                    </IconButton>
                                    {<CircleButton className="tokenDeleteButton" icon="close" onClick={() => removeTokenImage(token.src)} />}
                                </div>
                            )
                        })}
                    </div>
                </HiddenList>
                <HiddenList titleClassName="padding-0" defaultOpen={userImages.length < 5} title={`Classic (${DefaultMapTokens.classic.length})`}>
                    <div className="row wrap centered margin-top-quarter">
                        {DefaultMapTokens.classic.map((token, i) => (
                            <IconButton key={i} draggable={false} onMouseDown={() => setTokenPlacer([token])}>
                                <LazyLoad height={48} width={48} offset={100} overflow>
                                    <img draggable={false} className="userTokensListItem-img size-3 fade-in" src={token.src} alt={token.name} />
                                </LazyLoad>
                            </IconButton>
                        ))}
                    </div>
                </HiddenList>
            </div>
        </div>
    )
}

export default TokensList
