import React from 'react'
import { ButtonParent } from '../..'
import './CardButton.css'

const CardButton = ({ onClick, onMouseDown, onMouseOver, onMouseLeave, children, className, disabled }) => {
    return (
        <ButtonParent
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            disabled={disabled}
            className={`${className} card-button`}
        >
            {children}
        </ButtonParent>
    )
}

export default CardButton
