import { useCallback, useState } from 'react'
import shortid from 'shortid'
import { useCampaignState } from '../../../../context/Campaign/index'
import { getPos } from '../../../../functions/CanvasHelpers'
import { getHexFromMapColor } from '../../../../functions/helpers'
import { getRelativeMousePos } from '../getRelativeMousePos'

const usePing = (camera, isFullScreen, windowPos, pingSettings, docId) => {
    const [pings, setPings] = useState([])
    const { socket, activeCampaign } = useCampaignState()

    const addPing = useCallback((e) => {
        const color = getHexFromMapColor(pingSettings)
        const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
        const xFinal = getPos(xx, camera.x, camera.zoom)
        const yFinal = getPos(yy, camera.y, camera.zoom)

        const newPing = {
            id: shortid.generate(),
            x: xFinal,
            y: yFinal,
            color
        }

        setPings((pings) => [...pings, newPing])

        return newPing
    }, [camera.x, camera.y, camera.zoom, isFullScreen, pingSettings, windowPos])

    const addRemotePing = useCallback((remotePing) => {
        setPings((pings) => [...pings, remotePing])
    }, [])

    const sendRemotePing = useCallback((newPing) => {
        if (socket && activeCampaign?.shortId && docId) {
            socket.emit('MAP_PING', { ping: newPing, docId: docId })
        }
    }, [activeCampaign?.shortId, docId, socket])

    const removePing = useCallback((id) => {
        setPings((pings) => {
            const pingIndex = pings.findIndex(findPing => findPing.id === id)
            pings.splice(pingIndex, 1)
            return [...pings]
        })
    }, [])

    return {
        addPing,
        sendRemotePing,
        addRemotePing,
        removePing,
        pings,
    }

}

export default usePing