import { getScreenPosIn3dSpace } from "../../functions"

export default function setDiceFinishedPosition(die, forcePosition) {
    const finishedPosX = ((die.rollingGroupIndex % 4) * this.finishedDiceOffset) + (this.groupPadding * 2) + this.WIDTH / 2
    const finishedPosY = (Math.floor(die.rollingGroupIndex / 4) * this.finishedDiceOffset) + (this.groupPadding * 2) + this.HEIGHT / 2

    die.finishedPosition = getScreenPosIn3dSpace(this.camera, finishedPosX, finishedPosY, this.WIDTH, this.HEIGHT)

    if (forcePosition) {
        die.position.x = die.rollingGroup.pos.x + die.finishedPosition.x
        die.position.y = die.rollingGroup.pos.y + die.finishedPosition.y
    }
}