
import React, { useCallback, useState } from 'react'

//refer to Auth.js for documentation on what this component does.

import TextInput from '../../components/TextInput'
import { Button, TextButton } from '../../components/Buttons'
import { useAuthState, useAuthFunctions } from '../../context/Auth'
import { useErrorFunctions } from '../../context/Error'
import Loading from '../../components/Loading'
import { useLoadingFunctions, useLoadingState } from '../../context/Loading'
import { Checkbox } from '../../components/Checkbox'

const Signup = () => {
    const { email, password, passwordConfirm, name } = useAuthState()
    const { handleSignup, changePage, changeField } = useAuthFunctions()

    const { loading } = useLoadingState()
    const { setError } = useErrorFunctions()
    const { setLoading } = useLoadingFunctions()

    const [newsletter, setNewsLetter] = useState(true)

    const handleFormSignup = useCallback((e) => {
        e.preventDefault()
        handleSignup(email, password, passwordConfirm, name, newsletter, setLoading, setError)
    }, [handleSignup, email, password, passwordConfirm, name, newsletter, setLoading, setError])

    const attemptedCoupon = localStorage.getItem('attemptedCoupon')

    return (
        <form className="auth-signup-container" onSubmit={handleFormSignup}>
            {loading === 'signup' ? <Loading overlay /> : null}
            <div className="auth-inner-container col">
                <h1 className="c-shade-7">Sign Up</h1>
                {attemptedCoupon && <p className="t-small margin-bottom-half">Log in or signup to use coupon code: {attemptedCoupon}</p>}
                <TextInput id="signup-email" className="auth-textInput" value={email} placeholder="johndoe@example.com" label="Email" onChange={(e) => changeField(e.target.value, "email")} />
                <TextInput id="signup-password" className="auth-textInput" value={password} type="password" label="Password" onChange={(e) => changeField(e.target.value, "password")} />
                <TextInput id="signup-password-confirm" className="auth-textInput" value={passwordConfirm} type="password" label="Password Confirm" onChange={(e) => changeField(e.target.value, "passwordConfirm")} />
                <TextInput id="signup-name" className="auth-textInput" value={name} label="Name" placeholder="John Doe" onChange={(e) => changeField(e.target.value, "name")} />
                <Checkbox id="newsletter-signup" className="newsletter-signup margin-left-3 margin-bottom-2" onChange={() => setNewsLetter(!newsletter)} checked={newsletter} value="Send me updates about adventuring kit" />
                <Button className="auth-signup-button" primary type="submit">Sign up</Button>
                <TextButton className="auth-login-toggle t-clear-transform c-shade-7" onClick={() => changePage("login")}>Have an account? Log in</TextButton>
            </div>
        </form>
    )
}

export default Signup