import fullCampaign from "./fullCampaign"
import subscription from "./subscription"
import userMinimum from "./userMinimum"

const fullUser = `
    _id
    email
    name
    color
    diceSkin
    tokens {
        src
    }
    allDocuments {
        _id
        name
        owner
        kind
    }
    folders {
        _id
        name
        isDefaultFolder
        owner {
            ${userMinimum}
        }
        sharedCampaigns {
            _id
            title
        }
        sharedUsers {
            _id
            name
        }
    }
    campaigns {
        ${fullCampaign}
    }
    subscription {
        ${subscription}
    }
`

export default fullUser