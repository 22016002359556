import { useCallback } from 'react'
import { useWorkspaceFunctions, useWorkspaceState } from '../context/Workspace'
import { windowObjects } from '../pages/Workspace/windows'

const useOpenWindow = () => {
    const { openWindow } = useWorkspaceFunctions()
    const { windows } = useWorkspaceState()
    return useCallback((window, settings, props) => {
        openWindow(windows, windowObjects[window], settings, { kind: window, ...props })
    }, [openWindow, windows])
}

export default useOpenWindow
