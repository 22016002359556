import React from 'react'
import './DocumentToolbar.css'
import { useCampaignState } from '../../context/Campaign'
import { useWorkspaceFunctions, useWorkspaceState } from '../../context/Workspace'
import { IconButton } from '../Buttons'
import { isMobile } from 'react-device-detect'

import {
    CloudDone,
    CloudUpload,
    CloudQueue
} from '@styled-icons/material/'

import { Dismiss } from '@styled-icons/fluentui-system-filled/'
import ToolBarSeparator from './partials/ToolBarSeparator'

import { Windows as UnMaxIcon } from '@styled-icons/boxicons-regular/Windows'
import { CameraVideoFill, CameraVideoOffFill } from '@styled-icons/bootstrap/'
import { Broadcast } from '@styled-icons/bootstrap/Broadcast'
import { useAuthState } from '../../context/Auth'

const DocumentToolbar = ({ windowIndex, toolbarId, documentId, isFullScreen, saving, setShareModalOpen, broadcasting, broadcasted, children }) => {
    const { activeCampaign, socket } = useCampaignState()
    const { diceDrawerOpen } = useWorkspaceState()
    const { closeWindow, unmaximizeWindow, workspaceDispatch } = useWorkspaceFunctions()
    const { user } = useAuthState()

    return (
        <div className={`DocumentToolbar ${diceDrawerOpen && "diceDrawerOpen"} space-between row full-width padding-left-half padding-quarter bg-white bc-shade-3 border-bottom-thin`}>
            <div id={toolbarId} className="row full-width centered-vertically">
                {children}
            </div>

            {isFullScreen && (
                <div className="row centered">
                    <ToolBarSeparator />
                    <div className="row size-1 margin-right-1">{saving === 'unsaved' ? <CloudQueue /> : saving === 'saved' ? <CloudDone /> : <CloudUpload />}</div>
                    {!broadcasted ? (
                        <>
                            {
                                activeCampaign?.owner?._id === user._id && socket && (broadcasting ? (
                                    <IconButton onClick={(e) => {
                                        socket.emit('UNBROADCAST_WINDOW', documentId)
                                        workspaceDispatch({ type: "UPDATE_WINDOW_SETTINGS", value: { index: windowIndex, values: { broadcasting: false } } })
                                    }}>
                                        <CameraVideoOffFill className="size-1and1half c-red" />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={(e) => {
                                        socket.emit('BROADCAST_WINDOW', documentId)
                                        socket.emit('USER_OPENED_DOCUMENT', { documentId, userId: user._id })
                                        workspaceDispatch({ type: "UPDATE_WINDOW_SETTINGS", value: { index: windowIndex, values: { broadcasting: true } } })
                                    }}>
                                        <CameraVideoFill className="size-1 c-shade-6" />
                                    </IconButton>
                                ))}

                            {!broadcasting && (
                                <>
                                    {!isMobile && <IconButton onClick={(e) => { unmaximizeWindow(windowIndex) }}>
                                        <UnMaxIcon className="size-1 c-shade-6" />
                                    </IconButton>}
                                    <IconButton onClick={(e) => { closeWindow(windowIndex) }}>
                                        <Dismiss className="size-1 c-shade-6" />
                                    </IconButton>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="row centered">
                            <Broadcast className="size-1and1half c-red" />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default DocumentToolbar
