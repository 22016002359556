import React, { useState, useCallback } from 'react';
import { getIconColors } from '../../../../functions/helpers';
import Icon from '../../../Icon';
import Loading from '../../../Loading';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'
import { Crown } from '@styled-icons/boxicons-solid/Crown'
import { useAuthState } from '../../../../context/Auth';
import DocumentPreview from '../../../DocumentPreview/DocumentPreview';
import { useInventoryFunctions } from '../../../../context/Inventory';
import { useErrorFunctions } from '../../../../context/Error';
import useOpenWindow from '../../../../hooks/useOpenWindow';
import InventoryName from './InventoryName';
import { useWorkspaceFunctions, useWorkspaceState } from '../../../../context/Workspace';
import useClickPreventionOnDoubleClick from '../../../../hooks/useClickPreventionDoubleClick';

const ItemIconInner = ({ id, icon, kind, name, folderId, isFolderOwner, owner, open, setOpen, openDocumentWindow, minimal }) => {
    const { user, auth } = useAuthState()
    const [loading, setLoading] = useState(false)

    const iconColors = getIconColors(kind)
    const [editingName, setEditingName] = useState(false)
    const { deleteDocument, unshareDocument } = useInventoryFunctions()
    const { windows } = useWorkspaceState()
    const { closeWindow } = useWorkspaceFunctions()
    const { setError } = useErrorFunctions()
    const openWindow = useOpenWindow()
    const openDeleteDialogue = useCallback(() => {
        openWindow('confirm', null, {
            message: `Are you sure you want to delete ${name || 'Untitled'}?`,
            confirmCallback: () => {
                deleteDocument(auth, id, folderId).catch(err => setError(err))
                const windowIndex = windows.find(window => window?.propsPassedFromWindow?.documentId)?.index
                closeWindow(windowIndex)
            }
        })
    }, [auth, closeWindow, deleteDocument, folderId, id, name, openWindow, setError, windows])

    const openUnshareDialogue = useCallback(() => {
        openWindow('confirm', null, {
            message: `Are you sure you want to delete ${name || 'Untitled'}?`,
            confirmCallback: () => {
                unshareDocument(auth, folderId, id, setLoading, setError).catch(err => setError(err))
                const windowIndex = windows.find(window => window?.propsPassedFromWindow?.documentId)?.index
                closeWindow(windowIndex)
            }
        })
    }, [auth, closeWindow, folderId, id, name, openWindow, setError, unshareDocument, windows])

    const onItemClick = () => {
        if (minimal) {
            openDocumentWindow()
        } else {
            setOpen(!open)
        }
    }

    const onItemDoubleClick = () => {
        if ((user._id === owner && !minimal)) {
            setEditingName(true)
        }
    }

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onItemClick, onItemDoubleClick);

    return (
        <>
            <div
                className={`item-inner position-relative cursor-pointer ${iconColors} rounded border row padding-half centered-vertically`}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
            >
                {minimal ? null : (
                    loading ? <Loading
                        className="size-1 min-size-1 margin-right-quarter"
                    /> : <Icon
                        className='margin-bottom-quarter min-size-1 size-1 margin-right-quarter'
                        value={icon}
                    />
                )}
                <InventoryName
                    id={id}
                    name={name}
                    kind={kind}
                    minimal={minimal}
                    setLoading={setLoading}
                    editingName={editingName}
                    setEditingName={setEditingName}
                    isOwner={user._id === owner}
                />
                {minimal ? null : owner === user._id && (
                    <>
                        <div className={`${iconColors} border-right full-height margin-right-quarter margin-left-quarter`}></div>
                        <Crown className={`c-main min-size-75 size-75`} />
                    </>
                )}
                {minimal ? null : <div className={`itemHoverIconWrapper ${iconColors}`} onClick={(e) => {
                    e.stopPropagation()
                    openDocumentWindow()
                }}>
                    <ArrowRightShort className='itemHoverIcon c-main' />
                </div>}
            </div>
            <div className={`item--preview border ${iconColors}`}>
                {open && (
                    <DocumentPreview
                        id={id}
                        kind={kind}
                        ownerId={owner}
                        expanded
                        windowIndex={`chest-item-${id}`}
                        className="padding-top-1 height-10"
                        onDelete={owner === user._id ? openDeleteDialogue : null}
                        onUnshare={isFolderOwner ? openUnshareDialogue : null}
                    />
                )}
            </div>
        </>
    )
};

export default ItemIconInner;