import shortid from "shortid";
import { addMarkerPoint } from "./addMarkerPoint"
import { getMarkersBounds } from "./getMarkersBounds";

export function finishMarker(xx, yy, brushCycle, brushCycleReady, currentDrawing, setCurrentDrawing, addPaintToDoc) {
    if (currentDrawing) {
        addMarkerPoint(xx, yy, brushCycleReady, currentDrawing, setCurrentDrawing)

        const { top, left, width, height } = getMarkersBounds(currentDrawing)
        const updatedDrawing = { ...currentDrawing, id: shortid.generate() }
        updatedDrawing.markerBounds = { top, left, width, height }
        updatedDrawing.points = [...updatedDrawing.points].map((point, i) => {
            if (i % 2 === 0) {
                // is odd (x)
                return point - left
            } else {
                //is even (y)
                return point - top
            }
        })

        addPaintToDoc(updatedDrawing)
        window.clearInterval(brushCycle)
        setCurrentDrawing(null)
    }
}