
class GlobalStorage {
    static get(key) {
        return window.__ADVK_GLOBALS?.[key]
    }

    static set(key, value) {
        window.__ADVK_GLOBALS = window.__ADVK_GLOBALS || {}
        window.__ADVK_GLOBALS[key] = value
        return value
    }
}

export default GlobalStorage