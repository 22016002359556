import { useEffect, useState } from "react"
import { useAuthState } from "../context/Auth"
import { useInventoryFunctions, useInventoryState } from "../context/Inventory/index"
import { useAtom } from 'jotai'
import ShareDB from "../functions/ShareDB"
import { dragDestination_atom, dragSource_atom } from "../components/Chest/useOnInventoryDrop"
const connection = ShareDB.connect()

export default function useSubscribeToFolders() {
    const { auth, user } = useAuthState()
    const { folders } = useInventoryState()
    const { inventoryDispatch, checkForInventoryUpdate } = useInventoryFunctions()
    const [folderConnections, setFolderConnections] = useState({})

    const [, setDragSource] = useAtom(dragSource_atom)
    const [, setDragDest] = useAtom(dragDestination_atom)


    useEffect(() => {
        folders.forEach(folder => {
            if (!folderConnections[folder._id]) {
                const doc = connection.get('folderUpdateData', folder._id)
                doc.subscribe((err) => {
                    if (err) console.log(err)

                    if (!doc.type) {
                        doc.create({}, (error) => {
                            if (error) console.error(error)
                        })
                    }
                })

                const onOp = () => {
                    setDragSource(null)
                    setDragDest(null)
                    if (doc.data.lastUpdatedUser !== user._id) {
                        inventoryDispatch({ type: 'SYNC_UPDATED_SHARED_FOLDER', value: { updatedFolder: doc.data, user } })
                    }
                }
                doc.on('op', onOp)

                const onError = (error) => {
                    console.log('doc error', error)
                }
                doc.on('error', onError)

                folderConnections[folder._id] = doc

                return () => {
                    doc.unsubscribe()
                    doc.off('op', onOp)
                }
            }
        })

        subscribeToUsersInventory(auth, user._id, checkForInventoryUpdate, folderConnections, setFolderConnections)

        setFolderConnections(folderConnections)
    }, [auth, checkForInventoryUpdate, folderConnections, folders, inventoryDispatch, setDragDest, setDragSource, setFolderConnections, user])
}

const subscribeToUsersInventory = (auth, userId, checkForInventoryUpdate, folderConnections, setFolderConnections) => {
    if (!folderConnections[userId]) {
        const doc = connection.get('userHash', userId)
        doc.subscribe((err) => {
            if (err) console.log(err)

            if (!doc.type) {
                doc.create({}, (error) => {
                    if (error) console.error(error)
                })
            }
        })

        const onOp = () => {
            checkForInventoryUpdate(auth, userId)
        }
        doc.on('op', onOp)
        folderConnections[userId] = doc
        setFolderConnections(folderConnections)
    }

}