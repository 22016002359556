import { useAuthFunctions, useAuthState } from "../context/Auth"
import { useErrorFunctions } from "../context/Error"
import API from "../functions/API"
import ImageAPI from "../functions/ImageAPI"
import useOpenWindow from "./useOpenWindow"


const useRemoveToken = (setLoading = () => null) => {
    const { user, auth } = useAuthState()
    const { authDispatch } = useAuthFunctions()
    const openWindow = useOpenWindow()
    const { setError } = useErrorFunctions()

    const removeTokenImage = async (src) => {
        try {
            setLoading(true)
            await ImageAPI.deleteMany([src])
            await API.sendGraphSecure(auth, {
                query: `mutation($userId: String!, $srcList: String!) {
                    removeTokenImagesFromUser(userId: $userId, srcList: $srcList)
                }`,
                variables: {
                    userId: user._id,
                    srcList: JSON.stringify([src])
                }
            })

            const tokenIndex = user.tokens.findIndex(token => token.src === src)
            const newTokens = [...user.tokens]
            newTokens.splice(tokenIndex, 1)
            authDispatch({ type: 'UPDATE_USER_TOKENS', value: newTokens })

            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }
    }

    const onRemoveToken = (src) => {
        openWindow('confirm', null, {
            message: `Are you sure you want to delete this token image?`,
            confirmCallback: () => {
                removeTokenImage(src).catch(err => setError(err))
            }
        })
    }

    return onRemoveToken
}

export default useRemoveToken
