import React, { useRef, useState, useEffect } from 'react'
import DropZone from '../../../components/DropZone'
import { Button } from '../../../components/Buttons'
import { List, ListItem } from '../../../components/List'
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css";
import { getResizedCanvas } from '../../../functions/CanvasHelpers'
import Loading from '../../../components/Loading'
import { Check } from '@styled-icons/boxicons-regular/Check'
import ImageAPI from '../../../functions/ImageAPI'
import API from '../../../functions/API'
import { useErrorFunctions } from '../../../context/Error'
import { useAuthFunctions, useAuthState } from '../../../context/Auth'
import { useWorkspaceFunctions } from '../../../context/Workspace'
import { Checkbox } from '../../../components/Checkbox'
import './AddTokens.css'

const AddTokens = ({ _index }) => {
    const [loading, setLoading] = useState(false)
    const [finishedCount, setFinishedCount] = useState(0)
    const [images, setImages] = useState([])
    const [selectedImage, setSelectedImage] = useState(null)
    const [crops, setCrops] = useState([])
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [imageLoading, setImageLoading] = useState(true)
    const [isSquare, setIsSquare] = useState(false)

    const { setError } = useErrorFunctions()
    const { auth, user } = useAuthState()
    const { authDispatch } = useAuthFunctions()
    const { closeWindow } = useWorkspaceFunctions()

    const defaultCrop = {
        aspect: 1 / 1,
        width: 128,
    }

    const imageRef = useRef()

    const processImages = (files) => {
        const images = files.map(file => ({
            name: file.name,
            file,
            complete: false,
        }))

        setCrops(images.map(() => defaultCrop))
        setImages(images)
        selectImage(0, images)
    }

    const setCrop = (crop) => {
        const newCrops = [...crops]
        newCrops[selectedImageIndex] = crop
        setCrops(newCrops)
    }

    const selectImage = (index, files = images) => {
        const reader = new FileReader();
        if (index !== selectedImageIndex) {
            setImageLoading(true)
        }
        setSelectedImageIndex(index);
        reader.addEventListener("load", () =>
            setSelectedImage(reader.result)
        );
        if (files[index]) {
            reader.readAsDataURL(files[index].file);
        }
    }

    const makeClientCrop = (async (crop) => {
        if (imageRef && imageRef.current && crop.width && crop.height) {
            const { blob, fileUrl } = await getCroppedImg(
                imageRef.current,
                crop,
                images[selectedImageIndex]?.name
            );

            const newImages = [...images]
            newImages[selectedImageIndex] = {
                ...newImages[selectedImageIndex],
                complete: true,
                completedFile: blob,
                completedFileUrl: fileUrl
            }

            if (!images[selectedImageIndex].complete)
                setFinishedCount(finishedCount + 1)

            setImages(newImages)
        }
    })

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        // const pixelRatio = window.devicePixelRatio;
        const scaleX = (image.naturalWidth / image.width);
        const scaleY = (image.naturalHeight / image.height);
        const ctx = canvas.getContext("2d");
        canvas.width = crop.width;
        canvas.height = crop.height;

        // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        if (!isSquare) {
            ctx.globalCompositeOperation = 'destination-in'
            ctx.beginPath()
            ctx.arc(crop.width / 2, crop.height / 2, crop.height / 2, 0, Math.PI * 2)
            ctx.fill()

            // restore to default composite operation (is draw over current image)
            ctx.globalCompositeOperation = "source-over"
        }

        return new Promise((resolve, reject) => {
            const processedCanvas = getResizedCanvas(canvas, 256, 256)
            processedCanvas.toBlob((blob) => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                let fileUrl = selectedImage
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve({ fileUrl, blob });
            }, "image/png");
        });
    }

    const uploadImages = async () => {
        try {
            setLoading(true)
            const data = await ImageAPI.uploadMany(images.map(image => image.completedFile))
            await API.sendGraphSecure(auth, {
                query: `mutation saveTokenImages($files: String!){
                    saveTokenImagesToUser(files: $files)
                }`,
                variables: {
                    files: JSON.stringify(data.files)
                }
            })
            const newTokens = [...data.files.map(file => ({ src: file.url })), ...user.tokens]
            authDispatch({ type: 'UPDATE_USER_TOKENS', value: newTokens })

            setLoading(false)
            closeWindow(_index)
        } catch (err) {
            setLoading(false)
            setError('Error Uploading Tokens, please try again.')
            console.log(err)
        }
    }

    useEffect(() => {
        makeClientCrop(crops[selectedImageIndex])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSquare])

    const imagesLeft = images.length - finishedCount

    return (
        <div className="tokenUploadForm full-height border-left-thin centered col padding-1">
            {loading && <Loading overlay />}
            <div className="row full-width full-height">
                {images.length ? (
                    <>
                        <div className="token-upload-list half-width margin-right-1 ">
                            <List className="full-height bg-shade-1 c-shade-8">
                                {images.map((image, i) => (
                                    <ListItem key={i} className="t-small" selected={selectedImageIndex === i} onClick={() => selectImage(i)}>
                                        <div className="row full-width centered space-between">
                                            <div className="row token-upload-name">
                                                {image.complete && <Check className="size-1 margin-right-1" />}
                                                <p className="t-overflow-elipses whitespace-nowrap">{image.name}</p>
                                            </div>
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                            {finishedCount >= images.length ? (
                                <Button className="full-width" onClick={uploadImages}>Save</Button>
                            ) : (
                                <p className="full-width">Crop {imagesLeft} more image{imagesLeft.length > 1 ? 's' : ''}</p>
                            )}
                        </div>

                        <div className="relative half-width full-height">
                            {imageLoading && <Loading overlay opaque />}
                            <div className="full-width quarter-height centered">
                                <img src={images[selectedImageIndex]?.completedFileUrl} alt="token preview" className="object-fit-contain height-80" />
                                <Checkbox className="margin-left-1" value="Square" checked={isSquare} onChange={(e) => {
                                    setIsSquare(e.target.checked)
                                }} />
                            </div>
                            <ReactCrop
                                src={selectedImage}
                                crop={crops[selectedImageIndex]}
                                ruleOfThirds
                                onImageLoaded={(image) => {
                                    imageRef.current = image
                                    setImageLoading(false)
                                }}
                                onComplete={makeClientCrop}
                                onChange={(crop) => setCrop(crop)}
                                circularCrop={!isSquare}
                                keepSelection
                            />
                        </div>
                    </>
                ) : (
                    <div className="full-width full-height centered">
                        <DropZone
                            className="full-width full-height padding-1 t-centered height-10 width-10 bg-shade-3 rounded"
                            onSave={(files) => processImages(files)}
                            DropOrClickMessage={"Drag Images here or click to select."}
                            showClickIcon
                            accept='image/jpeg, image/png, image/gif, image/webp'
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default AddTokens
