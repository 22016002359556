const subscription = `active
price
nextBill
stripeCustomerId
expirationDate
paymentFailed
coupon{
    code
    description
}
billingHistory {
    date
    amount
    activity
},
paymentInfo {
    id
    method
    details
}`

export default subscription