import React, { useState } from 'react'
import TextInput from '../../TextInput'
import { Button } from '../../Buttons'
import { Checkbox } from '../../Checkbox'
import { useWorkspaceFunctions } from '../../../context/Workspace'
import { useCallback } from 'react'
import { submitOp } from '../../../functions/helpers'

const GridProperties = ({
    data,
    doc,
    modalState,
    setModalState,
    windowIndex,
}) => {
    const {
        showGrid,
        gridTop,
        gridLeft,
        gridSize
    } = data

    const { workspaceDispatch } = useWorkspaceFunctions()
    const [localState, setLocalState] = useState({
        showGrid,
        gridTop,
        gridLeft,
        gridSize
    })

    const update = useCallback((key, val) => {
        let intFirst = parseInt(val)
        if (isNaN(intFirst)) intFirst = 0
        let finalVal = val
        if (key !== 'showGrid') finalVal = intFirst
        workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
        setLocalState({ ...localState, [key]: val })
        submitOp(doc, { p: [key], od: data[key], oi: finalVal })
    }, [data, doc, localState, windowIndex, workspaceDispatch])


    return (
        <div className="col padding-1">
            <p>Grid Settings</p>
            <div className="row center margin-bottom-1">
                <TextInput disabled={!showGrid} className="margin-right-1" label="Top" value={gridTop} onChange={(e) => update('gridTop', e.target.value)} />
                <TextInput disabled={!showGrid} label="Left" value={gridLeft} onChange={(e) => update('gridLeft', e.target.value)} />
            </div>

            <TextInput disabled={!showGrid} className="margin-bottom-1" label="Size" value={gridSize} onChange={(e) => update('gridSize', e.target.value)} />

            <div className="margin-bottom-3">
                <Checkbox
                    value="Show Grid"
                    checked={localState.showGrid}
                    onChange={(e) => update('showGrid', e.target.checked)}
                />
            </div>

            <Button className="green" onClick={() => setModalState({ ...modalState, selectedToken: null, open: false })}>
                Done
            </Button>
        </div>
    )
}

export default GridProperties
