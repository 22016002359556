import React from 'react'
import { Image as ImageIcon } from '@styled-icons/material/Image'
const ImageUploadButton = ({ id, uploadImage, alt, className, icon }) => {

    return (
        <form>
            <label className={`centered image-upload-button cursor-pointer ${className}`} htmlFor={`image-upload-input-${id}`} title={alt}>
                {icon || <ImageIcon className="c-main" />}
                <input type="file" id={`image-upload-input-${id}`} onChange={async (e) => {
                    await uploadImage(e)

                    e.target.value = ''

                    if (!/safari/i.test(navigator.userAgent)) {
                        e.target.type = ''
                        e.target.type = 'file'
                    }
                }} />
            </label>
        </form>
    )
}

export default ImageUploadButton
