import React, { useState } from 'react'
import './campaign.css'

import TextInput from '../../../../components/TextInput'
import { Button } from '../../../../components/Buttons'

import { useCampaignFunctions } from '../../../../context/Campaign'
import CampaignCreated from './CampaignCreated'

import Loading from '../../../../components/Loading'
import { List, ListItem } from '../../../../components/List'
import { useAuthState } from '../../../../context/Auth'
import { useErrorFunctions } from '../../../../context/Error'
import { useLoadingState, useLoadingFunctions } from '../../../../context/Loading'
import { useInventoryFunctions, useInventoryState } from '../../../../context/Inventory'

const Campaign = (windowProps) => {
  const { auth } = useAuthState()
  const { setError } = useErrorFunctions()

  const { createCampaign } = useCampaignFunctions()

  const { campaigns } = useInventoryState()
  const { addCampaignToInventory, inventoryDispatch } = useInventoryFunctions()

  const { loading } = useLoadingState()
  const { setLoading } = useLoadingFunctions()

  const [newCampaign, setNewCampaign] = useState({
    title: '',
    description: '',
    items: []
  })

  const [campaignCreated, setCampaignCreated] = useState(null)

  const { title, description, items } = newCampaign

  const createCampaignAndShowView = async () => {
    try {
      const duplicateCampaign = campaigns.find((campaign) => campaign.title.toLowerCase() === title.toLowerCase())
      if (duplicateCampaign) throw new Error("You are already in a campaign with that name.")
      const { campaign, folder } = await createCampaign(title, auth, items, description, setLoading, setError)
      addCampaignToInventory(campaign)
      setCampaignCreated(campaign)
      inventoryDispatch({ type: 'ADD_FOLDER', value: folder })
    } catch (err) {
      setError(err)
      setCampaignCreated(false)
    }
  }

  return (
    <>
      {loading === 'creating-campaign' && <Loading overlay />}
      {campaignCreated ? (
        <CampaignCreated newCampaign={campaignCreated} {...windowProps} />
      ) : (
        <div className="newCampaign-window">
          <TextInput className="newCampaign-titleInput" value={title} label="Campaign Title" placeholder="My Glorious Campaign" onChange={(e) => setNewCampaign({ ...newCampaign, title: e.target.value })} />

          <List className="newCampaign-items" emptyMessage="Items are coming soon.">
            {items.length > 0 && items.map((item, i) => (
              <ListItem key={i}>
                Item
              </ListItem>
            ))}
          </List>
          <Button className="newCampaign-button blue" onClick={createCampaignAndShowView}>Create Campaign</Button>
        </div >
      )}
    </>
  )
}

export default Campaign