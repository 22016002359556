export function importInventoryData(user) {

    const value = {
        campaigns: user.campaigns,
        folders: user.folders,
        defaultFolder: user.folders.find(folder => folder.isDefaultFolder),
        allDocuments: user.allDocuments,
        batchedFolderUpdates: null
    }

    this.dispatch({
        type: 'SET', value
    })
}