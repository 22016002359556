import React, { useState, useEffect } from 'react'
import convertStringIntoDice from '../../../functions/convertStringIntoDice'
import { usePrevious } from '../../../hooks/usePrevious'

const PDFTextField = ({ defaultDiceValue, isMultiline, style, value, name, initialValue, editMode, setValue, onRollableClick }) => {
    let shownValue = value === undefined ? (initialValue || '') : value
    const [rollables, setRollables] = useState(null)

    const prevEditMode = usePrevious(editMode)
    const prevDefaultDiceValue = usePrevious(defaultDiceValue)
    useEffect(() => {
        if (editMode === 'Roll' && (editMode !== prevEditMode || defaultDiceValue !== prevDefaultDiceValue)) {
            const rollablesArray = convertStringIntoDice(shownValue, defaultDiceValue, name)
            rollablesArray.length > 0 && setRollables(rollablesArray)
        }
    }, [defaultDiceValue, editMode, name, prevEditMode, rollables, shownValue, prevDefaultDiceValue])

    return (
        <div className={`PDFField ${editMode === 'Roll' && 'rollMode'}`} name={name}>
            {rollables && <div
                className={`PDF-rollables ${isMultiline && 'isMultiLine'}`}
                style={{
                    top: style.top,
                    left: style.left,
                    width: isMultiline ? 'auto' : style.width,
                    height: isMultiline ? 'auto' : style.height,
                    fontSize: style.fontSize,
                    textAlign: style.textAlign
                }}
            >
                {rollables.map((rollable, i) => (
                    <span key={i} onClick={() => onRollableClick(rollable)}>
                        {rollable.value}
                    </span>
                ))}
            </div>}
            {isMultiline ? (
                <textarea
                    className={`PDFTextField c-shade-8 `}
                    style={style}
                    value={shownValue}
                    onChange={(e) => setValue(e.target.value)}
                    resize="false"
                    disabled={editMode === 'Roll'}
                    name={name}
                />
            ) : (
                <input
                    className={`PDFTextField c-shade-8`}
                    style={style}
                    value={shownValue}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={editMode === 'Roll'}
                />
            )}
        </div>
    )
}

export default PDFTextField
