import React from 'react'

export default function getBackground(skin) {
    let color = null, image = null

    switch (skin) {
        case 'red':
            color = '#E17E7E'
            break

        case 'blue':
            color = '#B4E1E9'
            break

        case 'green':
            color = '#9bd095'
            break

        case 'yellow':
            color = '#ffedd6'
            break

        case 'lilac':
            color = '#d396ff'
            break

        case 'orange':
            color = '#ffb95a'
            break

        case 'black':
            color = '#656565'
            break

        case 'white':
            color = '#e0e0e0'
            break
        case 'stone':
            color = '#8bb0ae'
            break

        case 'failure':
            color = '#ABB6BF'
            break
        default:
            image = <defs>
                <pattern id="img1" patternUnits="userSpaceOnUse" width="100" height="100">
                    <image href={image} x="0" y="0" width="100" height="100" />
                </pattern>
            </defs>
    }

    return { color, image }
}