import React from 'react'
import { ButtonParent } from '../..'

import './TextButton.css'
const TextButton = ({ className, children, onClick, onMouseDown }) => {
    return (
        <ButtonParent className={`text-button ${className}`} onClick={onClick} onMouseDown={onMouseDown}>
            {children}
        </ButtonParent>
    )
}

export default TextButton
