import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAuthState } from '../../../../context/Auth/index'
import { getDrawPos, getPos } from '../../../../functions/CanvasHelpers'
import { usePrevious } from '../../../../hooks/usePrevious'
import { getRelativeMousePos } from '../getRelativeMousePos'
import { addFogCutPoint, detectPointOnFogCut, drawFillTool, drawFog, drawFogCutTool, removeFogCut } from './fogFunctions'

const Fog = ({
    tool, width, height, fogCuts, fogEnabled, ownerId, updateDoc, camera,
    windowPos, isFullScreen, wrapperInstance, tokens,
    gridSize, setTokensNotInFog, boundingBox
}) => {
    const { user } = useAuthState()
    const instance = useRef(null)
    const ctx = useRef(null)

    const [currentFogCuts, setCurrentFogCuts] = useState(null)

    const [selectedFogCut, setSelectedFogCut] = useState(null)

    const clientIsOwner = user._id === ownerId

    useEffect(() => {
        if (instance) ctx.current = instance.current.getContext("2d")
    }, [])


    useEffect(() => {
        instance.current.width = width
        instance.current.height = height
        instance.current.style = `position: absolute; top: ${0}px; left:${0}px; width: ${width}px; height: ${height}px; pointer-events: none; z-index: 12;`
    }, [camera.x, camera.y, camera.zoom, height, width, windowPos.height, windowPos.width])

    useEffect(() => {
        if (fogEnabled && ctx.current) {
            drawFog(ctx.current, fogCuts, clientIsOwner, width, height)
            detectPointOnFogCut(0, 0, ctx.current, tool, fogCuts, setSelectedFogCut, camera)
        }
    }, [camera, clientIsOwner, fogCuts, fogEnabled, height, tool, width])


    useEffect(() => {
        if (ctx.current)
            drawFillTool(ctx.current, tool, selectedFogCut)
    }, [camera, selectedFogCut, tool])

    const onMouseMove = useCallback((e) => {
        const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
        const x = getPos(xx, camera.x, camera.zoom)
        const y = getPos(yy, camera.y, camera.zoom)
        detectPointOnFogCut(x, y, ctx.current, tool, fogCuts, setSelectedFogCut)

        if (fogEnabled && (tool === 'cut-fog' || tool === 'fog-fill')) {
            drawFog(ctx.current, fogCuts, clientIsOwner, width, height)

            drawFogCutTool(x, y, ctx.current, currentFogCuts)


            if (selectedFogCut && ctx.current)
                drawFillTool(ctx.current, tool, selectedFogCut)
        }
    }, [camera, clientIsOwner, currentFogCuts, fogCuts, fogEnabled, height, isFullScreen, selectedFogCut, tool, width, windowPos])


    const onMouseDown = useCallback((e) => {
        if (e.button === 0) {
            const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
            addFogCutPoint(xx, yy, tool, fogCuts, updateDoc, currentFogCuts, setCurrentFogCuts, camera)
            removeFogCut(tool, fogCuts, selectedFogCut, setSelectedFogCut, updateDoc)
        }
    }, [camera, currentFogCuts, fogCuts, isFullScreen, selectedFogCut, tool, updateDoc, windowPos])

    const onMouseUp = useCallback((e) => {
        if (fogEnabled && (tool === 'cut-fog' || tool === 'fog-fill')) {

            drawFog(ctx.current, fogCuts, clientIsOwner, width, height)

            const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
            detectPointOnFogCut(xx, yy, ctx.current, tool, fogCuts, setSelectedFogCut)
            if (selectedFogCut && ctx.current)
                drawFillTool(ctx.current, tool, selectedFogCut)
        }
    }, [clientIsOwner, fogCuts, fogEnabled, height, isFullScreen, selectedFogCut, tool, width, windowPos])


    const prevBoundingBox = usePrevious(boundingBox)
    const prevFogCutsLength = usePrevious(fogCuts.length)
    const prevFogEnabled = usePrevious(fogEnabled)
    useEffect(() => {
        let newTokensNotInFog = tokens.filter(token => {

            const centered = token.alignment !== 'corner'

            const scale = gridSize * (token.scale || 1) / 4
            const diffFromGridSize = gridSize - scale
            const centerY = (getDrawPos(token.y + (centered ? diffFromGridSize / 2 + scale / 2 : scale / 2), camera.y, camera.zoom) - camera.y) / camera.zoom
            const centerX = (getDrawPos(token.x + (centered ? diffFromGridSize / 2 + scale / 2 : scale / 2), camera.x, camera.zoom) - camera.x) / camera.zoom

            const x1 = centerX - scale / 2
            const y1 = centerY - scale / 2
            const pointInFog = detectPointOnFogCut(x1, y1, ctx.current, tool, fogCuts) ? 1 : 0

            const x2 = centerX + scale / 2
            const y2 = centerY - scale / 2
            const pointInFog2 = detectPointOnFogCut(x2, y2, ctx.current, tool, fogCuts) ? 1 : 0

            const x3 = centerX - scale / 2
            const y3 = centerY + scale / 2
            const pointInFog3 = detectPointOnFogCut(x3, y3, ctx.current, tool, fogCuts) ? 1 : 0

            const x4 = centerX + scale / 2
            const y4 = centerY + scale / 2
            const pointInFog4 = detectPointOnFogCut(x4, y4, ctx.current, tool, fogCuts) ? 1 : 0

            return pointInFog + pointInFog2 + pointInFog3 + pointInFog4 >= 2
        })

        setTokensNotInFog(newTokensNotInFog)
    }, [boundingBox, camera.x, camera.y, camera.zoom, fogCuts, fogEnabled, gridSize, isFullScreen, prevBoundingBox, prevFogCutsLength, prevFogEnabled, setTokensNotInFog, tokens, tool, windowPos])

    useEffect(() => {
        const wrapperDiv = wrapperInstance.current
        if (wrapperDiv) {
            wrapperDiv.addEventListener('mousedown', onMouseDown)
            wrapperDiv.addEventListener('mousemove', onMouseMove)
        }

        window.addEventListener('mouseup', onMouseUp)

        return () => {
            if (wrapperDiv) {
                wrapperDiv.removeEventListener('mousedown', onMouseDown)
                wrapperDiv.removeEventListener('mousemove', onMouseMove)
            }
            window.removeEventListener('mouseup', onMouseUp)
        }
    }, [onMouseDown, onMouseMove, onMouseUp, wrapperInstance])

    return (
        <canvas
            ref={instance}
            className="fog-renderer"
        ></canvas>
    )
}

export default Fog