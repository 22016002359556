import React from 'react'
import './Checkbox.css'

/**
 * @param {{id, style, className, onChange, checked, value, disabled, valueClassName }}
 */

const Checkbox = ({ id, style, className, onChange, checked, value, disabled, valueClassName }) => {
    return (
        <label style={style} className={`checkbox bc-main ${className} ${disabled && 'disabled'} row centered-vertically`}>
            <div className={`value c-shade-6 t-small ${valueClassName}`}>{value}</div>
            <input id={id} type="checkbox" className="checkbox-input border-thin bc-shade-4" checked={!!checked} onChange={onChange} />
            <div className="checkmark"></div>
        </label>
    )
}

export default Checkbox
