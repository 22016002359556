import React, { useRef, useState, useEffect } from 'react'
import Icon from '../Icon'
import { IconButton } from '../Buttons'
import './DiceTray.css'
import './DiceTray-stone.css'

import { LockAlt, LockOpenAlt } from '@styled-icons/boxicons-regular/'
import { useWorkspaceState } from '../../context/Workspace'

const DiceTray = ({ startDrag, color, coords }) => {
    const [locked, setLocked] = useState(false)
    const instance = useRef(null)
    const { rolling, diceInHand } = useWorkspaceState()
    useEffect(() => {
        if (diceInHand) {
            setLocked(true)
        }
    }, [diceInHand])

    return (
        <div
            id="dice-tray"
            style={
                coords?.x !== undefined ? {
                    top: Math.min(Math.max(0, coords.x), window.innerWidth - 480),
                    left: Math.min(Math.max(0, coords.y), window.innerHeight - 320)
                } : {}
            }
            className={`diceTray hidden-mobile ${color} ${locked && 'locked'}`}
            onMouseDown={e => locked ? null : startDrag(e, { instance })}
            ref={instance}
        >
            {rolling || <IconButton className={`dice-tray-lock-button ${color}`} onClick={() => setLocked(!locked)}>
                {locked ? (
                    <LockAlt className={`size-2`} />
                ) : (
                    <LockOpenAlt className={`size-2`} />
                )}
            </IconButton>}
            <Icon value="logo-outline" />
        </div >
    )
}

export default DiceTray
