import React from 'react'
import './styles.css'

const Button = ({ children, type, id, className, onClick, onMouseDown, onMouseOver, onMouseLeave, disabled, onContextMenu, emphasis, href, alt, draggable }) => {
  if (emphasis === true) emphasis = 1
  return (
    href ? (
      <a
        id={id}
        draggable={draggable}
        className={`button ${className} ${disabled && 'disabled'} ${emphasis && ('emphasis-' + emphasis)}`}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onMouseOver={disabled ? null : onMouseOver}
        onContextMenu={onContextMenu}
        type={type}
        alt={alt}
      >
        {children}
      </a>
    ) : (
      <button
        id={id}
        draggable={draggable}
        className={`button ${className} ${disabled && 'disabled'} ${emphasis && ('emphasis-' + emphasis)}`}
        onClick={disabled ? null : onClick}
        onMouseDown={disabled ? null : onMouseDown}
        onMouseOver={disabled ? null : onMouseOver}
        onMouseLeave={disabled ? null : onMouseLeave}
        onContextMenu={onContextMenu}
        type={type}
        title={alt}
      >
        {children}
      </button>
    )
  )
}

export default Button