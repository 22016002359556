import React, { useState } from 'react'
import Loading from '../../../../components/Loading'
import MapViewer from '../../../../components/MapViewer'
import useLoadDocument from '../../../../hooks/useLoadDocument'

const Map = ({ documentId, documentOwnerId, oldData, _index, _settings, saving, isFullScreen, onCreate }) => {
    const [loading, setLoading] = useState(true)

    const {
        id,
        data
    } = useLoadDocument(documentId, oldData, 'Map', _index, setLoading, onCreate)

    return (
        <div className="full-height">
            {loading ? (
                <Loading />
            ) : <MapViewer
                windowIndex={_index}
                saving={saving}
                isFullScreen={isFullScreen}
                windowPos={{
                    top: _settings.y,
                    left: _settings.x,
                    width: _settings.width,
                    height: _settings.height
                }}
                id={id}
                initialValue={data}
                ownerId={documentOwnerId}
                setLoading={setLoading}
                broadcasted={_settings.broadcasted}
                broadcasting={_settings.broadcasting}
            />}
        </div>
    )
}

export default Map