import fullUsersDocuments from "./fullUsersDocuments";
import userMinimum from "./userMinimum";

const fullFolder = `
    _id
    name
    isDefaultFolder
    owner {
        ${userMinimum}
    }
    sharedCampaigns {
        _id
        title
        users {
            _id
            name
        }
    }
    sharedUsers {
        _id
        name
    }
    documents {
        left {
            ${fullUsersDocuments}
        }
        right {
            ${fullUsersDocuments}
        }
    }
`

export default fullFolder