import React, { useCallback, useState } from 'react'
import './PremiumSignup.css'

import { useLoadingState } from '../../../../context/Loading'
import Loading from '../../../../components/Loading'
import Card from '../../../../components/Card'
import PremiumPromo from '../settings/SubscriptionSettings/partials/PremiumPromo'
import PriceDisplay from '../../../../components/PriceDisplay'
import useSendToCheckout from '../../../../hooks/useSendToCheckout'

const PremiumSignup = () => {
    const [interval, setIntervalToggle] = useState('monthly')
    const toggleInterval = useCallback((e) => {
        setIntervalToggle(e.target.checked ? 'yearly' : 'monthly')
    }, [setIntervalToggle])

    const { loading } = useLoadingState()

    const sendToCheckout = useSendToCheckout(interval)

    return (
        <div className="premium-signup-window">
            {loading === 'premium-signup' ? (
                <Loading overlay />
            ) : null}
            <div className="col">
                <h3>The Adventure Is Just Beginning!</h3>
                <p>Level up your account and enjoy these great features.</p>
                <label htmlFor="billing-interval-toggle-input" className="billing-interval-toggle row bg-white bc-shade-3">
                    <p className={`option ${interval === 'monthly' && 'active'}`}>Monthly</p>
                    <p className={`option ${interval === 'yearly' && 'active'} `}>Yearly</p>
                    <p className="billing-interval-sticker centered">Save 20%</p>
                </label>
                <input id="billing-interval-toggle-input" type="checkbox" onChange={toggleInterval} />
                <div className="row col-reversed-mobile">
                    <Card className="margin-right-1 full-width margin-bottom-1">
                        <PriceDisplay price={0} interval={interval === 'monthly' ? 'month' : 'year'} />
                        <p className="t-italic t-lighter c-shade-5 c-shade-5-hover margin-top-1 margin-bottom-1">5 players per campaign</p>
                        <p className="t-italic t-lighter c-shade-5 c-shade-5-hover margin-bottom-1">Pre made character sheets and notes</p>
                        <p className="t-italic t-lighter c-shade-5 c-shade-5-hover margin-bottom-1">Only 3 maps, notes, pictures or PDFs</p>
                        <p className="t-italic t-lighter c-shade-5 c-shade-5-hover margin-bottom-1">Basic map features</p>
                        <p className="t-italic t-lighter c-shade-5 c-shade-5-hover margin-bottom-1">Basic dice skins</p>
                        <p className="t-italic t-lighter c-shade-5 c-shade-5-hover margin-bottom-1">200MB upload storage</p>
                    </Card>
                    <PremiumPromo
                        className="full-width margin-bottom-1"
                        price={interval === 'monthly' ? 9.99 : 95.99}
                        interval={interval === 'monthly' ? 'month' : 'year'}
                        onSubscribe={sendToCheckout}
                    />
                </div>
            </div>
        </div>
    )
}

export default PremiumSignup
