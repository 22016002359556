import React from 'react'

import { TextButton } from '../../../../components/Buttons'
import Icon from '../../../../components/Icon'

const InviteRejected = ({ acceptRejection }) => {
    return (
        <div className="col centered margin-horizontal-1">
            <div className="section row waiting-for-invite-icon fail">
                <Icon value="close" />
            </div>
            <h1 className="waiting-for-invite-title">No legends here</h1>
            <p className="padding-bottom-1">It appears your invite was not accepted or the campaign no longer exsists.</p>
            <TextButton onClick={acceptRejection}>Okay</TextButton>
        </div>
    )
}

export default InviteRejected
