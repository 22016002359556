import { useCallback } from 'react'

const useRemoveDocument = (selectedToken, editSelectedToken, editSingleToken, modalState, setModalState) => {
    const removeDocument = useCallback((docId, multi) => {
        if (multi) {
            const newTokens = selectedToken.map(token => {
                const localDocIndex = token.documents.findIndex(doc => doc._id === docId)
                const newDocs = token.documents || []
                newDocs.splice(localDocIndex, 1)
                return editSingleToken({ documents: newDocs }, token)
            })
            setModalState({ ...modalState, selectedToken: newTokens })
        } else {
            const selectedTokenDocs = selectedToken.documents || []
            const localDocIndex = selectedToken.documents.findIndex(doc => doc._id === docId)
            selectedTokenDocs.splice(localDocIndex, 1)
            editSelectedToken({ documents: [...selectedTokenDocs] })
        }
    }, [editSelectedToken, editSingleToken, modalState, selectedToken, setModalState])

    return removeDocument
}

export default useRemoveDocument