import React from 'react'

const Scale = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.707" height="17.707" viewBox="0 0 17.707 17.707">
      <g id="Group_62" data-name="Group 62" transform="translate(-894.146 -944.146)">
        <line id="Line_30" data-name="Line 30" x1="7" y2="7" transform="translate(904.5 954.5)" fill="none" strokeWidth="1"/>
        <line id="Line_31" data-name="Line 31" x1="12" y2="12" transform="translate(899.5 949.5)" fill="none" strokeWidth="1"/>
        <line id="Line_32" data-name="Line 32" x1="17" y2="17" transform="translate(894.5 944.5)" fill="none" strokeWidth="1"/>
      </g>
    </svg>
)

export default Scale
