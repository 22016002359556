import React from 'react'

const HiddenTextInput = ({ value, onChange, className }) => {
    return <input
        className={`full-width window-title-input t-small c-main padding-left-quarter ${className}`}
        placeholder="Untitled..."
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
    />
}

export default HiddenTextInput