import { useWorkspaceFunctions } from '../../../../context/Workspace/index'

const useRemoveSelectedToken = (data, doc, selectedToken, windowIndex, submitOp, modalState, setModalState) => {
    const { workspaceDispatch } = useWorkspaceFunctions()

    const removeSelectedToken = (multi) => {
        if (multi) {
            removeMultiTokens()
        } else {
            removeSingleToken(selectedToken)
        }

        setModalState({ ...modalState, selectedToken: null, open: false })
    }

    const removeSingleToken = (targetToken) => {
        const tokenIndex = data.tokens.findIndex(token => token.id === targetToken.id)
        const newTokens = [...data.tokens]
        newTokens.splice(tokenIndex, 1)
        workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
        submitOp(doc, {
            p: ['tokens', tokenIndex],
            ld: data.tokens[tokenIndex]
        })
    }

    const removeMultiTokens = () => {
        const newTokens = data.tokens.filter(token => !selectedToken.find(findToken => findToken.id === token.id))
        workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
        submitOp(doc, {
            p: ['tokens'],
            od: data.tokens,
            oi: newTokens
        })
    }

    return removeSelectedToken
}

export default useRemoveSelectedToken