import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Upload } from '@styled-icons/entypo/Upload'

import './DropZone.css'

const DropZone = ({ className, onSave, DropHereMessage, DropOrClickMessage, showClickIcon, accept }) => {
    const onDrop = useCallback((acceptedFiles) => {
        onSave(acceptedFiles)
    }, [onSave])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept })

    return (
        <div className={`dropZone centered ${className}`} {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ? (
                    <p>{DropHereMessage}</p>
                ) : (
                    <div className="col centered">
                        {showClickIcon && <Upload width="32px" height="32px" />}
                        <p>{DropOrClickMessage}</p>
                    </div>
                )
            }
        </div>
    )
}

export default DropZone
