import React, { useCallback, useState, useEffect } from 'react'
import { IconButton } from '../Buttons'
import { useCampaignState } from '../../context/Campaign'
import Loading from '../Loading'

import './DiceDrawer.css'
import { useWorkspaceFunctions, useWorkspaceState } from '../../context/Workspace'
import { useErrorFunctions } from '../../context/Error'
import DiceOptions from './DiceOptions'
import { getSoundEffectVolume } from '../../functions/getSoundEffectVolume'

window._ADVK_DICE_SHAKE_AUDIO = new Audio()
window._ADVK_DICE_SHAKE_AUDIO.src = "/dice/sounds/dice-shaker-1.wav"
window._ADVK_DICE_SHAKE_AUDIO.volume = getSoundEffectVolume()
window._ADVK_DICE_DROP_AUDIO_1 = new Audio()
window._ADVK_DICE_DROP_AUDIO_1.src = "/dice/sounds/dice-1.wav"
window._ADVK_DICE_DROP_AUDIO_1.volume = getSoundEffectVolume()
window._ADVK_DICE_DROP_AUDIO_2 = new Audio()
window._ADVK_DICE_DROP_AUDIO_2.src = "/dice/sounds/dice-2.wav"
window._ADVK_DICE_DROP_AUDIO_2.volume = getSoundEffectVolume()
window._ADVK_DICE_DROP_AUDIO_3 = new Audio()
window._ADVK_DICE_DROP_AUDIO_3.src = "/dice/sounds/dice-4.wav"
window._ADVK_DICE_DROP_AUDIO_3.volume = getSoundEffectVolume()
window._ADVK_DICE_EXPLODED_AUDIO = new Audio()
window._ADVK_DICE_EXPLODED_AUDIO.src = "/dice/sounds/dice-exploded.wav"
window._ADVK_DICE_EXPLODED_AUDIO.volume = getSoundEffectVolume(-0.2)

const DiceDrawer = ({ skin, chestOpen }) => {
    const diceSkin = skin || 'blue'
    const dice = [4, 6, 8, 12, 100, 10, 20]

    const { diceDrawerOpen, diceOptions } = useWorkspaceState()
    const { workspaceDispatch } = useWorkspaceFunctions()

    const [optionsOpen, setOptionsOpen] = useState()

    const { diceReady, defaultDie, addDice, popDice } = useCampaignState()

    const { setError } = useErrorFunctions()

    useEffect(() => {
        if (diceDrawerOpen === false && optionsOpen !== false) setOptionsOpen(false)
    }, [diceDrawerOpen, optionsOpen])

    const onDiceLeftClick = useCallback((die) => {
        if (diceOptions.explodesOn > 0 && die === 'D100' && diceOptions.explodesOn < 10) setError('Your "Explodes on" value is too low, roll will explode forever.')
        else addDice(die)
    }, [addDice, diceOptions.explodesOn, setError])

    const onDiceRightClick = useCallback((e, die) => {
        e.preventDefault()
        if (popDice) popDice(`D${die}`)
    }, [popDice])

    const onOptionsClicked = useCallback(() => {
        if (!optionsOpen && !diceDrawerOpen) workspaceDispatch({ type: 'SET_DICE_DRAWER_OPEN', value: true })
        setOptionsOpen(!optionsOpen)
    }, [diceDrawerOpen, optionsOpen, workspaceDispatch])

    useEffect(() => {
        const popAll = (e) => {
            if (e.key === "Escape") {
                if (popDice) popDice()
            }
        }
        window.addEventListener('keydown', popAll)

        return () => window.removeEventListener('keydown', popAll)
    }, [popDice])

    return (
        <div id="dice-drawer" className={`dice-drawer bg-shade-1 bc-shade-3 shadow ${diceDrawerOpen && 'open'} ${optionsOpen && 'optionsOpen'} ${chestOpen && 'chest-open'}`}>
            {diceReady ? (
                <div className="diceDrawer-inner">
                    <div className="col-phone row-desktop centered-vertically diceDrawer-inner-top">
                        <IconButton
                            className={`diceDrawer-optionsButton hidden-tablet ${diceDrawerOpen && optionsOpen && 'open'}`}
                            icon="dots"
                            onClick={onOptionsClicked}
                            alt="More Dice Options"
                        />
                        {diceDrawerOpen ? (
                            <div className="diceDrawer-dice-container">
                                {dice.map(die => {
                                    return (
                                        <IconButton
                                            key={die}
                                            className="diceDrawer-dice-button"
                                            img={`/dice/icons/D${die}-${diceSkin}.png`}
                                            alt={`${die} Sided Dice`}
                                            onClick={() => onDiceLeftClick(`D${die}`)}
                                            onContextMenu={(e) => onDiceRightClick(e, die)}
                                        />
                                    )
                                })}
                            </div>
                        ) : (
                            <IconButton
                                className="diceDrawer-dice-button"
                                img={`/dice/icons/D${defaultDie}-${diceSkin}.png`}
                                alt={`${defaultDie} Sided Dice`}
                                onClick={() => onDiceLeftClick(`D${defaultDie}`)}
                                onContextMenu={(e) => onDiceRightClick(e, defaultDie)}
                            />
                        )}
                        <IconButton
                            className={`diceDrawer-expand-button hidden-tablet ${diceDrawerOpen && 'open'}`}
                            icon="expand-list"
                            alt="Open dice drawer."
                            onClick={() => workspaceDispatch({ type: 'SET_DICE_DRAWER_OPEN', value: !diceDrawerOpen })}
                        />

                        <IconButton
                            className={`diceDrawer-expand-button padding-1 hidden-desktop ${diceDrawerOpen && 'open'}`}
                            icon={diceDrawerOpen ? 'minimize' : 'plus'}
                            alt="Open dice drawer."
                            onClick={() => workspaceDispatch({ type: 'SET_DICE_DRAWER_OPEN', value: !diceDrawerOpen })}
                        />
                    </div>
                    <DiceOptions open={optionsOpen} />
                </div>
            ) : (
                <Loading className="" small />
            )
            }
        </div >
    )
}

export default DiceDrawer