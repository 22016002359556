import React from 'react'

const Maximize = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
      <g id="Rectangle_31" data-name="Rectangle 31" fill="none" strokeWidth="3">
        <rect width="27" height="27" rx="4" stroke="none"/>
        <rect x="1.5" y="1.5" width="24" height="24" rx="2.5" fill="none"/>
      </g>
    </svg>
)

export default Maximize
