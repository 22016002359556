import { useCallback, useEffect, useState } from 'react'
import { getDrawPos } from '../../../../functions/CanvasHelpers'
import { grid } from '../../../../functions/helpers'
import { getRelativeMousePos } from '../getRelativeMousePos'

const useMeasure = (camera, isFullScreen, windowPos, gridSize, gridLeft, gridTop) => {
    const [measure, setMeasure] = useState(null)
    const [measureFromToken, applyMeasureFromToken] = useState(null)

    useEffect(() => {
        if (measureFromToken) {
            setMeasure(measureFromToken)
            applyMeasureFromToken(null)
        }
    }, [measureFromToken])

    const startMeasure = (e) => {
        const { x, y } = getMeasurePoint(e, isFullScreen, windowPos, gridSize, gridLeft, gridTop, camera)

        setMeasure({
            x1: x,
            y1: y
        })
    }

    const updateMeasure = (e) => {
        if (measure) {
            const { x, y } = getMeasurePoint(e, isFullScreen, windowPos, gridSize, gridLeft, gridTop, camera)

            setMeasure((measure) => ({
                x1: measure.x1,
                y1: measure.y1,
                x2: x,
                y2: y
            }))
        }
    }

    const clearMeasure = () => {
        if (measure) {
            setMeasure(null)
        }
    }

    const setMeasureFromToken = useCallback(({ xx, yy }) => {
        const x1 = getDrawPos(xx + gridSize / 2, camera.x, camera.zoom)
        const y1 = getDrawPos(yy + gridSize / 2, camera.y, camera.zoom)

        applyMeasureFromToken({ x1, y1 })
    }, [camera.x, camera.y, camera.zoom, gridSize])

    return {
        measure,
        setMeasure,
        setMeasureFromToken,
        startMeasure,
        updateMeasure,
        clearMeasure
    }
}

export default useMeasure

export const getMeasurePoint = (e, isFullScreen, windowPos, gridSize, gridLeft, gridTop, camera, enableCellCentering) => {
    const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
    const zoomedGrid = gridSize * camera.zoom

    const cameraGridX = camera.x - grid(camera.x, zoomedGrid)
    const cameraGridY = camera.y - grid(camera.y, zoomedGrid)

    const glz = gridLeft * camera.zoom
    const gtz = gridTop * camera.zoom

    const gl = glz - grid(glz, zoomedGrid)
    const gt = gtz - grid(gtz, zoomedGrid)

    const x = grid(xx - cameraGridX - gl, zoomedGrid) + cameraGridX + gl + (enableCellCentering ? zoomedGrid / 2 : 0),
        y = grid(yy - cameraGridY - gt, zoomedGrid) + cameraGridY + gt + (enableCellCentering ? zoomedGrid / 2 : 0)

    return {
        x, y
    }
}