import {
    getIconImg, submitOp,
} from '../../../../functions/helpers'
import { useMemo, useState } from "react"
import Tab from "../../../TabNavigation/Tab"
import TabNavigation from "../../../TabNavigation/TabNavigation"
import Controls from "./Controls"
import Documents from "./Documents"
import Labels from "./Labels"
import TextInput from '../../../TextInput/TextInput'
import useEditSelectedToken from './useEditSelectedToken'
import { Crown } from '@styled-icons/boxicons-solid/Crown'
import GlobalStorage from '../../../../functions/GlobalStorage'
import { useAuthState } from '../../../../context/Auth/index'
import useRemoveSelectedToken from './useRemoveSelectedToken'
import useCopyTokens from './useCopyTokens'
import useAddDocument from './useAddDocument'
import useRemoveDocument from './useRemoveDocument'
import TokenPropertiesHeader from './TokenPropertiesHeader'


const TokenProperties = ({ selectedToken, data, doc, modalState, setModalState, windowIndex, multi, documentId }) => {
    const { user } = useAuthState()
    const tokenOwnedByUser = useMemo(() => selectedToken?.length ? selectedToken[0]?.ownerId === user._id : selectedToken?.ownerId === user._id, [selectedToken, user._id])

    const [activeTab, setActiveTab] = useState(0)
    const { editSelectedToken, editSingleToken } = useEditSelectedToken(data, doc, selectedToken, windowIndex, submitOp, modalState, setModalState)
    const removeSelectedToken = useRemoveSelectedToken(data, doc, selectedToken, windowIndex, submitOp, modalState, setModalState)
    const copyTokens = useCopyTokens()
    const addDocument = useAddDocument(selectedToken, modalState, setModalState, editSingleToken, editSelectedToken)
    const removeDocument = useRemoveDocument(selectedToken, editSelectedToken, editSingleToken, modalState, setModalState)

    const { gridSize } = data

    return selectedToken ? (
        <div className="col">
            <div className="col margin-bottom-1">
                <div className="position-relative row padding-top-1">
                    {multi ? <p>Multiple Tokens</p> : (
                        <>
                            {tokenOwnedByUser && <Crown className="tokenPropertiesCrown size-1 margin-right-half round bg-blue c-shade-2" alt="You own this token" title="You own this token" />}
                            <img className="border bc-shade-3 rounded padding-quarter size-3" src={selectedToken?.src || getIconImg(selectedToken?.documents?.[0]?.kind) || GlobalStorage.get('TOKEN_ICON_MISSING')?.src || ""} alt={selectedToken.name} />
                            <TextInput
                                className="margin-left-1 full-width"
                                inputClassName="full-height"
                                value={selectedToken?.name || ''}
                                placeholder="Token Name"
                                onChange={(e) => editSelectedToken({ name: e.target.value })}
                            />
                        </>
                    )}
                </div>

                <TokenPropertiesHeader {...{ multi, tokenOwnedByUser, selectedToken, editSelectedToken, removeSelectedToken }} />

                <TabNavigation
                    tabs={["Controls", "Styles", "Items"]}
                    activeTab={activeTab}
                    setTab={setActiveTab}
                    classNames="centered"
                    tabStyles={{ margin: '0 0.5rem' }}
                />

                <Tab active={activeTab === 0}>
                    <Controls
                        {...{
                            tokenOwnedByUser, selectedToken, editSelectedToken,
                            copyTokens, removeSelectedToken, gridSize, multi
                        }}
                    />
                </Tab>
                <Tab active={activeTab === 1}>
                    <Labels
                        {...{
                            multi, selectedToken, editSelectedToken, editSingleToken, modalState, setModalState
                        }}
                    />
                </Tab>
                <Tab active={activeTab === 2}>
                    <Documents
                        {...{ addDocument, selectedToken, documentId, removeDocument }}
                    />
                </Tab>
            </div>
        </div>
    ) : null
}

export default TokenProperties
