import React from 'react'
import { VolumeUpFill, VolumeDownFill, VolumeMuteFill } from '@styled-icons/bootstrap/'

const VolumeIcon = (volume) => {
    if (volume === 0) return <VolumeMuteFill className='size-1' />
    if (volume < 0 && volume >= 50) return <VolumeDownFill className='size-1' />
    return <VolumeUpFill className='size-1' />
}

export default VolumeIcon
