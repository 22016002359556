import { useEffect } from 'react'
import { useCampaignState, useCampaignFunctions } from '../context/Campaign'
import useCampaignConnections from './socket-functions/useCampaignConnections'
import useDiceCommunications from './socket-functions/useDiceCommunications'
import useDocumentCommunications from './socket-functions/useDocumentCommunications'
import useSocketErrors from './socket-functions/useSocketErrors'
import useUsersTypingCommunications from './socket-functions/useUsersTypingCommunications'
import useSoundboardCommunications from './socket-functions/useSoundboardCommunications'
import { usePrevious } from './usePrevious'
import { useWorkspaceState } from '../context/Workspace'

export function useSocketSetup() {
    const { socket, activeCampaign, waitingForInviteId } = useCampaignState()
    const { logOutOfCampaign } = useCampaignFunctions()
    const { tabs } = useWorkspaceState()

    const prevSocket = usePrevious(socket)
    const prevActiveCampaign = usePrevious(activeCampaign)
    const prevWaitingForInvite = usePrevious(waitingForInviteId)

    const campaignConnections = useCampaignConnections()
    const diceCommunications = useDiceCommunications()
    const documentCommunications = useDocumentCommunications()
    const socketErrors = useSocketErrors()
    const usersTypingCommunications = useUsersTypingCommunications()
    const soundboardCommunications = useSoundboardCommunications()

    window.onbeforeunload = () => {
        tabs.forEach(tab => {
            if (tab.settings.broadcasting) {
                socket.emit('UNBROADCAST_WINDOW', tab?.propsPassedFromWindow?.documentId)
            }
        })
        logOutOfCampaign(socket)
        return null
    }

    useEffect(() => {
        if (
            socket && (
                socket !== prevSocket ||
                activeCampaign !== prevActiveCampaign || waitingForInviteId !== prevWaitingForInvite
            )
        ) {
            if (prevSocket) prevSocket.off()

            campaignConnections()
            diceCommunications()
            documentCommunications()
            socketErrors()
            usersTypingCommunications()
            soundboardCommunications()
        }
    })
}