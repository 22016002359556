import C from 'cannon'
import { attachPhysicsBodyToDice } from '../../functions/attachPhysicsBodyToDice'

export default function addDiceToRollingGroup(group, die, direction, dist) {
    die.rollingGroup = group
    die.rollingGroupIndex = group.dice.length
    die.position.z = 3

    attachPhysicsBodyToDice(die, this.diceBodyMaterial)

    var axis = new C.Vec3(Math.random(), Math.random(), Math.random());
    var angle = Math.PI / (Math.random() * 3);
    die.body.quaternion.setFromAxisAngle(axis, angle);

    const newVel = new C.Vec3(direction.x * (dist * 25), direction.y * (dist * 25), 0)
    const aVel = new C.Vec3(direction.x * (dist * -10), direction.y * (dist * -10), direction.z * (dist * -10))

    die.body.velocity = newVel
    die.body.angularVelocity = aVel

    if (!group.disableAnimations) this.world.add(die.body)
    this.rolledDice.push(die)
    group.dice.push(die)
}