import React from 'react'
import { ButtonParent } from '../..'
import Icon from '../../../Icon'
import Loading from '../../../Loading'

import './CircleButton.css'
const CircleButton = ({ icon, className, onClick, onMouseDown, loading }) => {
    return (
        <ButtonParent onClick={onClick} onMouseDown={onMouseDown} className={`circleButton ${className}`}>
            {loading ? (
                <Loading />
            ) : (
                <Icon className="centered" value={icon} />
            )}
        </ButtonParent>
    )
}

export default CircleButton
