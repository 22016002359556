const useKeyControls = (tool, setTool, lastTool, setLastTool, setTokenNames) => {
    const mapKeyControls = (e) => {
        if (e?.target?.className?.includes?.('textInput')
            || e.target?.className?.includes?.('ql-editor')
            || e?.target?.className?.includes?.('window-title-input')) return

        switch (e.code) {
            case 'ShiftLeft': {
                setTokenNames((showNames) => !showNames)
                break
            }
            case 'Space': {
                if (tool !== 'pan') setLastTool(tool)
                if (tool !== 'pan') setTool('pan')
                break
            }

            case 'KeyV': setTool('select')
                break

            case 'KeyD': setTool('draw')
                break

            case 'KeyM': setTool('measure')
                break

            case 'KeyP': setTool('ping')
                break
            default: return null
        }
    }

    const releaseKeyControls = (e) => {
        if (e?.target?.className?.includes?.('textInput')
            || e?.target?.className?.includes?.('ql-editor')
            || e?.target?.className?.includes?.('window-title-input')) return

        switch (e.code) {
            case 'Space':
            case 'MetaLeft':
            case 'MetaRight':
                setTool(lastTool)
                break

            default: return null
        }
    }

    return {
        mapKeyControls,
        releaseKeyControls
    }
}

export default useKeyControls
