
function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end
}

const circle = (ctx, x, y, rad, fill, dash) => {
    if (rad < 0) return
    ctx.beginPath()
    dash ? ctx.setLineDash([16, 8]) : ctx.setLineDash([])
    ctx.arc(x, y, rad, 0, 2 * Math.PI)
    fill ? ctx.fill() : ctx.stroke()
    ctx.setLineDash([])
}

const drawSelectionCircle = (ctx, x, y, rad, cutHole) => {
    if (rad < 0) return
    ctx.beginPath()
    ctx.setLineDash([16, 8])
    cutHole ? ctx.arc(x, y, rad, (1.8 * Math.PI), (1.6 * Math.PI)) : ctx.arc(x, y, rad, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.setLineDash([])
}

const roundRect = (ctx, x, y, w, h, r, stroke) => {
    if (w < 2 * r) r = w / 2
    if (h < 2 * r) r = h / 2
    ctx.beginPath()
    ctx.moveTo(x + r, y)
    ctx.arcTo(x + w, y, x + w, y + h, r)
    ctx.arcTo(x + w, y + h, x, y + h, r)
    ctx.arcTo(x, y + h, x, y, r)
    ctx.arcTo(x, y, x + w, y, r)
    ctx.closePath()
    stroke ? ctx.stroke() : ctx.fill()
}

const line = (ctx, x1, y1, x2, y2) => {
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
}

const dashedLine = (ctx, x1, y1, x2, y2, dash) => {
    ctx.beginPath()
    ctx.setLineDash(dash || [5, 15])
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
}

const distanceTo = (x1, y1, x2, y2) => {
    const a = x1 - x2
    const b = y1 - y2

    return Math.sqrt(a * a + b * b)
}


function getPos(point, offset, zoom) {
    return (point - offset) / zoom
}

function getDrawPos(point, offset, zoom) {
    return (point * zoom) + offset
}

function getResizedCanvas(canvas, newWidth, newHeight) {
    var tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    var ctx = tmpCanvas.getContext('2d');
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

    return tmpCanvas;
}

export { lerp, circle, roundRect, line, dashedLine, distanceTo, getPos, getDrawPos, getResizedCanvas, drawSelectionCircle }