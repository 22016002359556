import React, { useState } from 'react'
import Loading from '../../../../components/Loading'
import SoundboardViewer from '../../../../components/SoundboardViewer'
import useLoadDocument from '../../../../hooks/useLoadDocument'

const Note = ({ documentId, oldData, _settings, _index, saving, isFullScreen, onCreate }) => {
  const [loading, setLoading] = useState(true)

  const {
    id,
    data,
  } = useLoadDocument(documentId, oldData, 'soundboard', _index, setLoading, onCreate)

  return (
    <div className="centered full-height col">
      {loading || !id ? (
        <Loading />
      ) : <SoundboardViewer
        windowIndex={_index}
        saving={saving}
        isFullScreen={isFullScreen}
        id={id}
        name={_settings.title}
        initialValue={data}
        broadcasted={_settings.broadcasted}
        broadcasting={_settings.broadcasting}
      />}
    </div>
  )
}

export default Note