import { useAuthState } from '../../context/Auth'
import { useCampaignFunctions, useCampaignState } from "../../context/Campaign"
import { useErrorFunctions } from "../../context/Error"
import Socket from "../../functions/Socket"

const useSocketErrors = () => {
    const { auth, user } = useAuthState()
    const { socket, activeCampaign } = useCampaignState()
    const { campaignDispatch } = useCampaignFunctions()
    const { setError } = useErrorFunctions()

    const usersTyping = () => {
        if (!activeCampaign?.shortId) return
        const shortId = activeCampaign.shortId

        socket.on('disconnect', async (err) => {
            console.log('socket close', err)
            setError('Connection error...', true, 'pending')

            if (err === "io server disconnect") {
                // the disconnection was initiated by the server, you need to reconnect manually
                const socket = await Socket.assignSocket(user, shortId, true)
                campaignDispatch({ type: "SET_SOCKET", value: socket })

                const connectionData = { auth, campaignShortId: shortId, userId: user._id }
                socket.emit("USER_REQUESTING_TO_ENTER_CAMPAIGN", JSON.stringify(connectionData))
            }
        })

        socket.on('error', async (err) => {
            console.log('socket close', err)
            setError('Connection error...', true, 'pending')

            const socket = await Socket.assignSocket(user, shortId, true)
            campaignDispatch({ type: "SET_SOCKET", value: socket })

            const connectionData = { auth, campaignShortId: shortId, userId: user._id }
            socket.emit("USER_REQUESTING_TO_ENTER_CAMPAIGN", JSON.stringify(connectionData))
        })


        socket.io.on('reconnect_attempt', () => {
            console.log('reconnecting')
            setError('Reconnecting...', true, 'pending')

        })

        socket.io.on('reconnect', async () => {
            console.log('reconnect')
            setError('Connected!', false, 'success')
            const socket = await Socket.assignSocket(user, shortId)
            campaignDispatch({ type: "SET_SOCKET", value: socket })

            const connectionData = { auth, campaignShortId: shortId, userId: user._id }
            socket.emit("USER_REQUESTING_TO_ENTER_CAMPAIGN", JSON.stringify(connectionData))
        })

        socket.io.on("reconnect_failed", () => {
            console.log('reconnection failed')
            setError('There was a connection error, please refresh the page.', true)
        })

        socket.io.on('connect_error', async (err) => {
            console.log('connect error', err)
            setError('Connection error...', true, 'pending')

            const socket = await Socket.assignSocket(user, shortId, true)
            campaignDispatch({ type: "SET_SOCKET", value: socket })

            const connectionData = { auth, campaignShortId: shortId, userId: user._id }
            socket.emit("USER_REQUESTING_TO_ENTER_CAMPAIGN", JSON.stringify(connectionData))
        })
    }

    return usersTyping
}

export default useSocketErrors