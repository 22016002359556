import React from 'react'

const River = () => (
    <svg id="river" xmlns="http://www.w3.org/2000/svg" width="152.064" height="151.992" viewBox="0 0 152.064 151.992">
        <path id="Path_6945" data-name="Path 6945" d="M67.17,61.4C56.226,55.3,48.23,44.271,48.23,30.9V7.622h29.8V26.185a8.985,8.985,0,0,0,8.99,8.98h36.7a47.864,47.864,0,0,1,33.738,81.876s-9.653,7.3-14.256,9.751c-5.751,3.061-12.545,4.121-19.482,4.121H118.2a8.132,8.132,0,0,0-8.136,8.127v16.19h-29.8V135.2a34.066,34.066,0,0,1,34.084-34.048h9.372a18.108,18.108,0,1,0,0-36.216H82.294C78.9,64.646,71.476,63.8,67.17,61.4Z" transform="translate(-33.906 -5.395)" fill="#a4e0ff" />
        <path id="Path_6946" data-name="Path 6946" d="M124.552,35.166h9.8a8.985,8.985,0,0,1-8.99-8.98V7.622h-9.8V26.185A8.985,8.985,0,0,0,124.552,35.166Z" transform="translate(-81.24 -5.395)" fill="#80d4ff" />
        <path id="Path_6947" data-name="Path 6947" d="M246.877,100.362h-9.8a47.864,47.864,0,0,1,33.738,81.876s-9.653,7.3-14.256,9.751c-5.751,3.061-12.545,4.121-19.482,4.121h-5.522a8.132,8.132,0,0,0-8.136,8.127v16.19h9.8v-16.19a8.132,8.132,0,0,1,8.136-8.127h5.522c6.937,0,13.731-1.06,19.482-4.121,4.6-2.45,14.256-9.751,14.256-9.751a47.864,47.864,0,0,0-33.738-81.876Z" transform="translate(-157.063 -70.591)" fill="#80d4ff" />
        <path id="Path_6948" data-name="Path 6948" d="M464.713,26.81a8.4,8.4,0,1,1-16.809,0c0-4.642,3.763-19.188,8.4-19.188S464.713,22.168,464.713,26.81Z" transform="translate(-314.876 -5.395)" fill="#c5e866" />
        <path id="Path_6949" data-name="Path 6949" d="M24.309,221.81a8.4,8.4,0,1,1-16.809,0c0-4.642,3.763-19.188,8.4-19.188S24.309,217.168,24.309,221.81Z" transform="translate(-5.272 -142.48)" fill="#c5e866" />
        <path id="Path_6950" data-name="Path 6950" d="M82.309,322.81a8.4,8.4,0,1,1-16.809,0c0-4.642,3.763-19.188,8.4-19.188s8.4,14.546,8.4,19.188Z" transform="translate(-46.046 -213.482)" fill="#9cc042" />
        <path id="Path_6951" data-name="Path 6951" d="M122.713,180.81a8.4,8.4,0,1,1-16.809,0c0-4.642,3.763-19.188,8.4-19.188S122.713,176.168,122.713,180.81Z" transform="translate(-74.45 -113.657)" fill="#9cc042" />
        <path id="Path_6952" data-name="Path 6952" d="M384.713,375.81a8.4,8.4,0,1,1-16.809,0c0-4.642,3.763-19.188,8.4-19.188S384.713,371.168,384.713,375.81Z" transform="translate(-258.636 -250.741)" fill="#c5e866" />
        <path id="Path_6953" data-name="Path 6953" d="M464.713,398.937a8.4,8.4,0,1,1-16.809,0c0-4.642,3.763-19.188,8.4-19.188S464.713,394.3,464.713,398.937Z" transform="translate(-314.876 -267)" fill="#9cc042" />
        <g id="Group_1892" data-name="Group 1892" transform="translate(0 0)">
            <path id="Path_6954" data-name="Path 6954" d="M152.279,32.292h36.7a45.827,45.827,0,0,1,28.772,10.181c2.214,1.794,5.047-1.644,2.805-3.461A50.3,50.3,0,0,0,188.98,27.838h-36.7a6.766,6.766,0,0,1-6.763-6.753V2.521c0-2.873-4.455-2.873-4.455,0V21.084a11.226,11.226,0,0,0,11.218,11.208Z" transform="translate(-99.166 -0.294)" fill="#060606" />
            <path id="Path_6955" data-name="Path 6955" d="M109.074,98.275h9.372a20.336,20.336,0,1,0,0-40.671H77.273c-.233-.765-.482-1.521-.744-2.247-2.232-6.188-4.936-9.325-8.038-9.325-2.758,0-5.2,2.481-7.277,7.379A31.609,31.609,0,0,1,45.184,25.8V2.521c0-2.873-4.455-2.873-4.455,0V25.8A36.242,36.242,0,0,0,59.69,57.663a38.929,38.929,0,0,0-1.832,9.784,10.65,10.65,0,0,0,8.473,10.411l.049,7.5c.019,2.846,4.474,2.861,4.455-.029l-.049-7.505a10.649,10.649,0,0,0,8.336-10.38,28.218,28.218,0,0,0-.7-5.388h40.024a15.881,15.881,0,1,1,0,31.761h-9.372a36.335,36.335,0,0,0-36.312,36.275v20.036c0,2.873,4.455,2.873,4.455,0V130.095a31.875,31.875,0,0,1,31.857-31.82ZM70.757,73.187,70.718,67.3c-.019-2.846-4.474-2.861-4.455.029l.039,5.888a6.182,6.182,0,0,1-3.988-5.769,38.689,38.689,0,0,1,2.314-10.536c1.887-5.252,3.552-6.417,3.863-6.424s1.975,1.17,3.862,6.423a38.7,38.7,0,0,1,2.315,10.538A6.183,6.183,0,0,1,70.757,73.187Z" transform="translate(-28.632 -0.294)" fill="#060606" />
            <path id="Path_6956" data-name="Path 6956" d="M448.879,31.949l.049,7.5c.019,2.846,4.474,2.861,4.455-.029l-.049-7.505a10.648,10.648,0,0,0,8.336-10.379,42.619,42.619,0,0,0-2.594-12.09C456.844,3.26,454.14.123,451.038.123S445.231,3.26,443,9.448a42.609,42.609,0,0,0-2.594,12.09,10.649,10.649,0,0,0,8.473,10.411ZM447.175,11c1.887-5.252,3.552-6.417,3.863-6.424S453.012,5.748,454.9,11a38.7,38.7,0,0,1,2.315,10.538,6.183,6.183,0,0,1-3.911,5.741l-.039-5.889a2.228,2.228,0,0,0-2.227-2.213h-.015a2.227,2.227,0,0,0-2.213,2.242l.039,5.888a6.182,6.182,0,0,1-3.988-5.769A38.692,38.692,0,0,1,447.175,11Z" transform="translate(-309.605 -0.123)" fill="#060606" />
            <path id="Path_6957" data-name="Path 6957" d="M21.264,216.538a42.619,42.619,0,0,0-2.594-12.09c-2.232-6.188-4.936-9.325-8.038-9.325s-5.807,3.137-8.039,9.325A42.615,42.615,0,0,0,0,216.538,10.65,10.65,0,0,0,8.473,226.95l.049,7.5c.019,2.846,4.474,2.861,4.455-.029l-.049-7.505A10.649,10.649,0,0,0,21.264,216.538Zm-16.809,0A38.7,38.7,0,0,1,6.77,206c1.887-5.252,3.552-6.416,3.862-6.423s1.975,1.172,3.863,6.424a38.689,38.689,0,0,1,2.314,10.536,6.183,6.183,0,0,1-3.911,5.741l-.039-5.889c-.019-2.846-4.474-2.861-4.455.029l.039,5.888a6.183,6.183,0,0,1-3.988-5.769Z" transform="translate(0 -137.208)" fill="#060606" />
            <path id="Path_6958" data-name="Path 6958" d="M75.884,310.279a2.227,2.227,0,0,0-1.713,2.644,25.379,25.379,0,0,1,.637,4.615,6.183,6.183,0,0,1-3.911,5.741l-.039-5.889c-.019-2.867-4.474-2.846-4.455.029l.039,5.888a6.183,6.183,0,0,1-3.988-5.769A38.7,38.7,0,0,1,64.77,307c1.887-5.252,3.552-6.416,3.862-6.423.009,0,1.034.216,2.582,3.36,1.27,2.577,5.266.609,4-1.968-1.911-3.88-4.124-5.847-6.579-5.847-3.1,0-5.807,3.137-8.038,9.325A42.606,42.606,0,0,0,58,317.538a10.65,10.65,0,0,0,8.473,10.411l.049,7.5c.019,2.846,4.474,2.861,4.455-.029l-.049-7.505a10.648,10.648,0,0,0,8.336-10.379,28.838,28.838,0,0,0-.736-5.546A2.228,2.228,0,0,0,75.884,310.279Z" transform="translate(-40.774 -208.211)" fill="#060606" />
            <path id="Path_6959" data-name="Path 6959" d="M300.607,222.186a50.061,50.061,0,0,0,3.914-65.677c-1.757-2.274-5.282.451-3.525,2.725a45.613,45.613,0,0,1-2.371,58.569c-1.771-3.115-3.768-4.689-5.964-4.689-3.1,0-5.806,3.137-8.038,9.325a51.949,51.949,0,0,0-1.885,6.661,45.4,45.4,0,0,1-17.935,3.653h-5.522a10.371,10.371,0,0,0-10.364,10.355V259.3c0,2.873,4.455,2.873,4.455,0v-16.19a5.911,5.911,0,0,1,5.909-5.9H264.8a49.877,49.877,0,0,0,17.234-3.04A10.763,10.763,0,0,0,290.5,244.94l.049,7.5c.019,2.846,4.474,2.861,4.455-.029l-.049-7.505a10.648,10.648,0,0,0,8.336-10.379,37.051,37.051,0,0,0-2.687-12.343Zm-5.679,18.083-.039-5.889c-.019-2.846-4.474-2.861-4.455.029l.039,5.888a6.182,6.182,0,0,1-3.988-5.769,38.689,38.689,0,0,1,2.314-10.536c1.887-5.252,3.552-6.417,3.863-6.424s1.975,1.17,3.862,6.423a38.7,38.7,0,0,1,2.315,10.538A6.183,6.183,0,0,1,294.928,240.27Z" transform="translate(-174.989 -109.461)" fill="#060606" />
            <path id="Path_6960" data-name="Path 6960" d="M451.036,372.25c-1.989,0-3.819,1.281-5.44,3.808-1.551,2.419,2.2,4.824,3.75,2.405.93-1.45,1.569-1.756,1.69-1.759.311.007,1.975,1.17,3.862,6.423a38.7,38.7,0,0,1,2.315,10.538,6.183,6.183,0,0,1-3.911,5.741l-.039-5.889a2.228,2.228,0,0,0-2.227-2.213h-.015a2.227,2.227,0,0,0-2.213,2.242l.039,5.888a6.182,6.182,0,0,1-3.988-5.769,27.071,27.071,0,0,1,.771-5.217c.638-2.8-3.705-3.792-4.344-.99a30.931,30.931,0,0,0-.882,6.207,10.65,10.65,0,0,0,8.473,10.411l.049,7.5c.019,2.846,4.474,2.861,4.455-.029l-.049-7.505a10.649,10.649,0,0,0,8.336-10.38,42.619,42.619,0,0,0-2.594-12.09C456.843,375.387,454.138,372.25,451.036,372.25Z" transform="translate(-309.604 -261.728)" fill="#060606" />
            <path id="Path_6961" data-name="Path 6961" d="M78.228,43.064a2.227,2.227,0,0,0,2.228-2.228V32.521c0-2.873-4.455-2.873-4.455,0v8.316A2.227,2.227,0,0,0,78.228,43.064Z" transform="translate(-53.428 -21.384)" fill="#060606" />
            <path id="Path_6962" data-name="Path 6962" d="M111.228,77.282a2.227,2.227,0,0,0,2.227-2.228V68.521c0-2.873-4.455-2.873-4.455,0v6.534A2.227,2.227,0,0,0,111.228,77.282Z" transform="translate(-76.627 -46.692)" fill="#060606" />
            <path id="Path_6963" data-name="Path 6963" d="M210.4,160.122c-2.873,0-2.873,4.455,0,4.455h8.91c2.873,0,2.873-4.455,0-4.455Z" transform="translate(-146.395 -112.602)" fill="#060606" />
            <path id="Path_6964" data-name="Path 6964" d="M260.4,126.122c-2.873,0-2.873,4.455,0,4.455h11.286c2.873,0,2.873-4.455,0-4.455Z" transform="translate(-181.545 -88.7)" fill="#060606" />
            <path id="Path_6965" data-name="Path 6965" d="M217.228,445.122A2.227,2.227,0,0,0,215,447.349v8.316c0,2.873,4.455,2.873,4.455,0v-8.316A2.227,2.227,0,0,0,217.228,445.122Z" transform="translate(-151.145 -312.957)" fill="#060606" />
            <path id="Path_6966" data-name="Path 6966" d="M185.228,416.122A2.227,2.227,0,0,0,183,418.349v7.722c0,2.873,4.455,2.873,4.455,0v-7.722A2.227,2.227,0,0,0,185.228,416.122Z" transform="translate(-128.649 -292.57)" fill="#060606" />
            <path id="Path_6967" data-name="Path 6967" d="M374.722,277.622a17.685,17.685,0,0,1-4.854,9.7c-2.116,1.945.9,5.211,3.01,3.284a21.945,21.945,0,0,0,6.26-12.4C379.516,275.361,375.1,274.773,374.722,277.622Z" transform="translate(-259.511 -193.893)" fill="#060606" />
            <path id="Path_6968" data-name="Path 6968" d="M418.891,243.638c-.508,2.8,3.869,3.626,4.383.8a24.747,24.747,0,0,0-1.023-12.83c-1.094-2.657-5.214-.961-4.12,1.7A20.25,20.25,0,0,1,418.891,243.638Z" transform="translate(-293.817 -161.916)" fill="#060606" />
        </g>
    </svg>
)


export default River
