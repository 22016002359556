// price_1HK24ZBhoZTuzI2zsUFu2nKY - test
// price_1HbUgfBhoZTuzI2zubks16RO - prod

const stripe = {
    pk: {
        production: 'pk_live_51HJV42BhoZTuzI2ziav0SMDK7y2UW5FKVkSx4DPtVDnULUPbdRjFXVNaUQDAHdkAM7Hij6qvqqFwy0pyDaeyLUIh00P4l2lMwL',
        qa: 'pk_test_51HJV42BhoZTuzI2zA5cyu5Ydj1i1ex1mAAQY7hbWeW9RJFQZK5bujO9KNOzc9Au02hNI7A6NpmEasu7uEE9mP1pT00crf9GMx8'
    },
    priceIds: {
        production: {
            "premium": 'price_1HbUgfBhoZTuzI2zubks16RO',
            "price_1HbUgfBhoZTuzI2zubks16RO": "premium",
            "premium-yearly": "price_1Hce3ABhoZTuzI2zWOi5AuUe",
            "price_1Hce3ABhoZTuzI2zWOi5AuUe": "premium-yearly"
        },
        qa: {
            "premium": 'price_1HK24ZBhoZTuzI2zsUFu2nKY',
            "price_1HK24ZBhoZTuzI2zsUFu2nKY": "premium",
            "premium-yearly": "price_1Hce1vBhoZTuzI2zyahrkiwl",
            "price_1Hce1vBhoZTuzI2zyahrkiwl": "premium-yearly"
        }
    },
    taxRates: {
        default: 8.25
    }
}

export default stripe
