
import React from 'react'
import shortid from 'shortid'
import { IconButton } from '../../../Buttons/index'
import Close from '../../../Icon/Close'
import { colorMap } from '../../functions/colorMap'

import HiddenTextInput from '../../../TextInput/HiddenTextInput'
import Slider from '../../../Slider/Slider'

const Labels = ({ multi, selectedToken, editSelectedToken, editSingleToken, modalState, setModalState }) => {
    const labels = (multi ? [...new Set(selectedToken?.reduce?.((acc, token) => acc.concat(token.labels), []))] : selectedToken?.labels) || []

    const addLabel = (label) => {
        if (multi) {
            const newTokens = selectedToken.map(token => editSingleToken({ labels: [...(token.labels || []), label] }, token))
            setModalState({ ...modalState, selectedToken: newTokens })
        } else {
            editSelectedToken({ labels: [...labels, label] })
        }
    }

    const removeLabel = (labelId) => {
        if (multi) {
            const newTokens = selectedToken.map(token => {
                const localLabelIndex = token.labels.findIndex(label => label.id === labelId)
                const newLabels = token.labels || []
                newLabels.splice(localLabelIndex, 1)
                return editSingleToken({ labels: newLabels }, token)
            })
            setModalState({ ...modalState, selectedToken: newTokens })
        } else {
            const labelIndex = labels.findIndex(label => label.id === labelId)
            const newLabels = labels || []
            newLabels.splice(labelIndex, 1)
            editSelectedToken({ labels: [...newLabels] })
        }
    }

    const onUpdateLabelText = (labelId, newText) => {
        if (multi) {
            const newTokens = selectedToken.map(token => {
                const localLabelIndex = token.labels.findIndex(label => label.id === labelId)
                const newLabels = [...token.labels] || []

                newLabels[localLabelIndex] = { ...newLabels[localLabelIndex], text: newText }
                return editSingleToken({ labels: newLabels }, token)
            })
            setModalState({ ...modalState, selectedToken: newTokens })
        } else {
            const labelIndex = labels.findIndex(label => label.id === labelId)
            const newLabels = labels || []

            newLabels[labelIndex] = { ...newLabels[labelIndex], text: newText }

            editSelectedToken({ labels: [...newLabels] })
        }
    }

    const onUpdateLabelThickness = (labelId, newText = '') => {
        const regex = /[^0-9]/
        const value = parseInt(newText.replace(regex, ''))

        if (multi) {
            const newTokens = selectedToken.map(token => {
                const localLabelIndex = token.labels.findIndex(label => label?.id === labelId)
                const newLabels = [...token.labels] || []

                newLabels[localLabelIndex] = { ...newLabels[localLabelIndex], thickness: value }
                return editSingleToken({ labels: newLabels }, token)
            })
            setModalState({ ...modalState, selectedToken: newTokens })
        } else {
            const labelIndex = labels.findIndex(label => label?.id === labelId)
            const newLabels = labels || []

            newLabels[labelIndex] = { ...newLabels[labelIndex], thickness: value }

            editSelectedToken({ labels: [...newLabels] })
        }
    }

    return (
        <div className='col padding-horizontal-half'>
            <div className="row space-between wrap margin-top-1 padding-horizontal-1">
                {Object.keys(colorMap).map((key, i) => {
                    const keyAdded = labels.find(label => label?.key === key)
                    return (
                        <button
                            key={i}
                            className={`round size-2 margin-quarter bg-${key} ${keyAdded ? 'border-thick bc-main' : 'border-thin bc-main'}`}
                            onClick={() => keyAdded ? removeLabel(keyAdded.id) : addLabel({
                                id: shortid.generate(),
                                color: colorMap[key],
                                key,
                                thickness: 0,
                                text: key
                            })}
                        ></button>
                    )
                })}
            </div>

            <div className="col margin-top-1">
                {labels.map((label, i) => {
                    if (!label) return null
                    return (
                        <div className='col border-bottom-thin padding-vertical-1' key={`${label?.id}-${i}`}>
                            <div className='row padding-bottom-1'>
                                <div className={`min-size-1 size-1 round margin-quarter bg-${label.key}`}></div>
                                <HiddenTextInput className="centered-vertically bg-hover-shade-2 c-shade-5-hover" value={label.text} onChange={(val) => onUpdateLabelText(label.id, val)} />
                                <IconButton className="size-1dot5" onClick={() => { removeLabel(label.id) }}>
                                    <Close />
                                </IconButton>
                            </div>
                            <p className='t-small'>Aura Radius</p>
                            <div className='row centered-vertically'>
                                <Slider className="height-quarter full-width" value={label.thickness} min={0} max={50} onChange={e => onUpdateLabelThickness(label.id, e.target.value)} />
                                <p className='margin-left-1 width-6'>{(label.thickness * 5) / 2 || '0'} ft.</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Labels
