import React, { useCallback, useEffect, useRef } from 'react'
import { getPos } from '../../../../functions/CanvasHelpers'
import { getRelativeMousePos } from '../getRelativeMousePos'
import { addMarkerPoint } from './MarkerFunctions/addMarkerPoint'
import { drawMarker } from './MarkerFunctions/drawMarker'
import { finishMarker } from './MarkerFunctions/finishMarker'


let brushCycle = null

const CurrentMarkerRenderer = ({ width, height, currentDrawing, setCurrentDrawing, isCurrent, addPaintToDoc, isFullScreen, windowPos, camera }) => {
    const instance = useRef(null)
    const ctx = useRef(null)
    const brushCycleReady = useRef(true)

    // Init ctx on first render
    useEffect(() => {
        if (instance) {
            ctx.current = instance.current.getContext("2d")
        }
        brushCycleReady.current = true
        window.clearInterval(brushCycle)
        brushCycle = window.setInterval(() => {
            brushCycleReady.current = true
        }, 100)
    }, [currentDrawing.color, currentDrawing.thickness])

    useEffect(() => {
        instance.current.width = width
        instance.current.height = height
        instance.current.style = `position: absolute; top: 0px; left:0px; width: ${width}px; height: ${height}px;`
    }, [height, width])

    const onMouseMove = useCallback((e) => {
        const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
        const x = getPos(xx, camera.x, camera.zoom)
        const y = getPos(yy, camera.y, camera.zoom)
        addMarkerPoint(x, y, brushCycleReady, currentDrawing, setCurrentDrawing)
        drawMarker(ctx.current, x, y, currentDrawing, true, width, height)
    }, [camera.x, camera.y, camera.zoom, currentDrawing, height, isFullScreen, setCurrentDrawing, width, windowPos])

    const onMouseUp = useCallback((e) => {
        const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
        const x = getPos(xx, camera.x, camera.zoom)
        const y = getPos(yy, camera.y, camera.zoom)
        finishMarker(x, y, brushCycle, brushCycleReady, currentDrawing, setCurrentDrawing, addPaintToDoc)
        drawMarker(ctx.current, xx, yy, currentDrawing, true, width, height)
    }, [addPaintToDoc, camera.x, camera.y, camera.zoom, currentDrawing, height, isFullScreen, setCurrentDrawing, width, windowPos])

    useEffect(() => {
        const div = instance.current
        if (div) {
            div.addEventListener('mousemove', onMouseMove)
        }
        window.addEventListener('mouseup', onMouseUp)


        return () => {
            if (div) {
                div.removeEventListener('mousemove', onMouseMove)
            }
            window.removeEventListener('mouseup', onMouseUp)
        }
    }, [onMouseMove, onMouseUp])

    return (
        <canvas
            ref={instance}
            className={isCurrent ? 'current-marker-drawing' : 'drawing marker-drawing'}
        ></canvas>
    )
}

export default CurrentMarkerRenderer