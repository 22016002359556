import fullCampaign from './fullCampaign'
import fullUser from './fullUser'
import userMinimum from './userMinimum'
import allUserTypesAtMinimum from './allUserTypesAtMinimum'
import fullFolder from './fullFolder'

const TYPES = {
    fullCampaign,
    fullUser,
    fullFolder,
    userMinimum,
    allUserTypesAtMinimum
}

export default TYPES