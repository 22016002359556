import { IconButton } from '../../../Buttons/index'
import { Close } from '@styled-icons/evaicons-solid/Close'
import CurrentMarkerRenderer from './CurrentMarkerRenderer'
import './Drawings.css'
import { useCallback, useRef } from 'react'
import MarkerRenderer from './MarkerRenderer'

const Drawings = ({
    width, height, currentDrawing, setCurrentDrawing, paint = [],
    showGrid, windowPos, isFullScreen, updateDoc, startDrawingDrag,
    camera, diceInHand, tool
}) => {
    const removeDrawing = useCallback((paintId) => {
        const paintIndex = paint.findIndex((drawing) => drawing.id === paintId)
        updateDoc({
            p: ['paint', paintIndex], ld: paint[paintIndex]
        })
    }, [paint, updateDoc])

    const addPaintToDoc = useCallback((newDrawing) => {
        updateDoc({
            p: ['paint', paint.length], li: newDrawing
        })
    }, [paint.length, updateDoc])

    return <div className={`drawings-container ${tool === 'select' && 'selectable'}`}>
        {currentDrawing && (
            currentDrawing.tool === 'marker' ?
                <CurrentMarkerRenderer {...{
                    width,
                    height,
                    currentDrawing,
                    setCurrentDrawing,
                    isCurrent: true,
                    removeDrawing,
                    windowPos,
                    isFullScreen,
                    addPaintToDoc,
                    camera
                }} />
                : <PaintRenderer drawing={currentDrawing} isCurrent showGrid={showGrid} />
        )}

        {paint.map((drawing) => (
            <PaintRenderer key={drawing.id} drawing={drawing} removeDrawing={removeDrawing} startDrawingDrag={startDrawingDrag} diceInHand={diceInHand} />
        ))}
    </div>
}

export default Drawings

const PaintRenderer = ({ drawing, isCurrent, showGrid, removeDrawing, startDrawingDrag, diceInHand }) => {
    if (drawing.tool === 'marker') {
        return <MarkerRenderer {...{ drawing, removeDrawing, startDrawingDrag, diceInHand }} />
    } else {
        return <BasicRenderer {...{ drawing, isCurrent, showGrid, removeDrawing, startDrawingDrag, diceInHand }} />
    }
}

const BasicRenderer = ({ drawing, isCurrent, showGrid, removeDrawing, startDrawingDrag, diceInHand }) => {
    const instance = useRef(null)
    const [x1, y1, x2, y2] = drawing.points

    const invertX = x1 > x2,
        invertY = y1 > y2

    const left = invertX ? x2 : x1,
        top = invertY ? y2 : y1,
        width = invertX ? x1 - x2 : x2 - x1,
        height = invertY ? y1 - y2 : y2 - y1

    const angle = Math.atan2(y2 - y1, x2 - x1) || 0

    let altStyles
    switch (drawing.tool) {
        case 'circle': {
            altStyles = { borderRadius: '100%' }
            break
        }
        case 'line': {
            altStyles = {
                top: (drawing.origin === 'center' ? (y1 - y2 / 2) * 2 : y1) - drawing.thickness / 2 || 0,
                left: (drawing.origin === 'center' ? (x1 - x2 / 2) * 2 : x1) - drawing.thickness / 2 || 0,
                height: `${drawing.thickness}px`,
                width: `${(drawing.dist * (drawing.origin === 'center' ? 4 : 2)) + drawing.thickness / 2 || 0}px`,
                transform: `rotate(${angle}rad)`,
                transformOrigin: `${drawing.thickness / 2}px ${drawing.thickness / 2}px`,
                border: `${drawing.thickness / 2}px solid ${drawing.color}`,
                backgroundColor: 'none',
                borderRadius: `${drawing.thickness}px`
            }
            break
        }
        case 'square':
        default: altStyles = {}
            break
    }

    const displayDistX = drawing.displayDistX || 0
    const displayDistY = drawing.displayDistY || 0

    return (
        <div
            ref={instance}
            className={`drawing ${isCurrent && 'current-drawing'} ${drawing.color === "#f2f9fd" && 'white-drawing'} ${diceInHand && 'diceInHand'} ${drawing.tool}`}
            style={{
                left: (drawing.origin === 'center' ? left - width + (invertX ? width : 0) : left) || 0,
                top: (drawing.origin === 'center' ? top - height + (invertY ? height : 0) : top) || 0,
                width: (drawing.origin === 'center' ? width * 2 : width || 0) + 1,
                height: (drawing.origin === 'center' ? height * 2 : height || 0) + 1,
                border: drawing.fill === 'outline' ? `${drawing.thickness}px solid ${drawing.color} ` : 'none',
                backgroundColor: drawing.fill === 'outline' ? 'none' : drawing.color,
                ...altStyles
            }}
            onMouseDown={(e) => {
                if (diceInHand) return
                if (e?.target?.className?.includes?.('drawing') && e.button === 0)
                    startDrawingDrag(e, drawing, instance)
            }}
        >
            {(displayDistX > 5 || displayDistY > 5) && (
                <>
                    {showGrid && <>
                        {/* middle to right side line */}
                        {drawing.tool !== 'line' && (
                            <>
                                <div className="drawingMeasure" style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '100%',
                                    height: '50%',
                                    borderBottom: `1px solid ${drawing.color === "#f2f9fd" ? '#9bd095' : 'white'} `,
                                }}>
                                </div>

                                {/* middle to top line */}
                                <div className="drawingMeasure" style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '50%',
                                    width: '100%',
                                    height: '100%',
                                    borderRight: `1px solid ${drawing.color === "#f2f9fd" ? '#9bd095' : 'white'} `,
                                }}>
                                </div>

                                {/* middle to right side measurement */}
                                <div className="measureDisplay" style={{
                                    top: '50%',
                                    right: '0',
                                    transform: `translate(50 %, -50 %)`
                                }}>
                                    {displayDistX || 0} ft
                                </div>
                            </>
                        )}

                        {/* middle to top side measurement */}
                        <div className="measureDisplay" style={{
                            top: '0%',
                            left: '50%',
                            transform: `translate(-50%, -50%) ${drawing.tool === 'line' ? `rotate(${-angle}rad)` : ''}`
                        }}>
                            {drawing.tool === 'line' ? drawing.displayDist : displayDistY || 0} ft
                        </div>
                    </>}
                </>
            )}

            <div className="drawing-controls">
                <IconButton className="round bg-shade-1 size-2" onClick={(e) => {
                    removeDrawing(drawing.id)
                }}>
                    <Close className="size-2 c-shade-9" />
                </IconButton>
            </div>
        </div>
    )
}