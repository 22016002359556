import React from 'react'

const Map = () => (
  <svg id="map-icon" data-name="map-icon" xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
    <g opacity="0.9">
      <g>
        <path d="M79.1,88.7c-17.112,10.852-34.224-4.542-51.333.643a4.837,4.837,0,0,1-6.015-3.26,4.745,4.745,0,0,1-.2-1.389V32.2a7,7,0,0,1,4.7-6.608c17.6-6.145,35.231,13.462,52.848,2.3Z" transform="translate(-13.304 -15.043)" fill="#ffedd6" />
        <path d="M88.689,31.5c1.764,6.723,4.561,20.124,2.495,31.427A283.943,283.943,0,0,0,87.74,94.1c10.749,2.3,21.49,5.824,32.239-.991V32.284C109.536,38.893,99.112,34.73,88.689,31.5Z" transform="translate(-54.166 -19.446)" fill="#ead6c1" />
        <path d="M151.78,13.321S154.543,6.162,164.9,3.64a3.226,3.226,0,0,1,3.9,2.372,3.192,3.192,0,0,1,.092.765v9.846Z" transform="translate(-93.701 -2.19)" fill="#ead6c1" />
        <path d="M151.78,200.778s2.763,7.163,13.121,9.685a3.226,3.226,0,0,0,3.9-2.372,3.193,3.193,0,0,0,.092-.765V197.48Z" transform="translate(-93.701 -121.914)" fill="#ead6c1" />
        <path d="M165.744,28.153c0-4.366-6.647-7.913-14.847-7.913s-14.847,3.543-14.847,7.913V86.837c0,4.366,6.647,7.913,14.847,7.913s14.847-3.543,14.847-7.913Z" transform="translate(-83.99 -12.495)" fill="#ffedd6" />
        <path d="M181.14,20.51a5.74,5.74,0,0,1,1.531,3.739V91.075a5.74,5.74,0,0,1-1.531,3.735c7.052-.612,12.436-3.876,12.436-7.806V28.32C193.58,24.39,188.192,21.126,181.14,20.51Z" transform="translate(-111.826 -12.662)" fill="#ead6c1" />
        <path d="M81.49,140.1l1.121-3.784a2.634,2.634,0,1,0-5.051-1.5h0L76.435,138.6l-3.788-1.121a2.633,2.633,0,1,0-1.492,5.051h0l3.788,1.136-1.121,3.784a2.636,2.636,0,0,0,5.055,1.5L80,145.167l3.784,1.117a2.634,2.634,0,1,0,1.5-5.051Z" transform="translate(-42.761 -82.064)" fill="#e67575" />
        <path d="M69.584,124.637c.49.547,1.427,1.439,1.462,1.473a1.247,1.247,0,0,0,1.76-.023,1.439,1.439,0,0,0,0-1.913l-.046-.042s-.853-.823-1.228-1.24a1.317,1.317,0,0,0-1.963,1.756Z" transform="translate(-42.741 -75.596)" fill="#21262c" />
        <path d="M60.569,110.616a19.173,19.173,0,0,1-1.026-1.63,1.317,1.317,0,0,0-2.3,1.29c.36.635.765,1.259,1.148,1.848a1.328,1.328,0,0,0,1.837.325,1.312,1.312,0,0,0,.321-1.829Z" transform="translate(-35.237 -66.867)" fill="#21262c" />
        <path d="M51.263,94.368a1.343,1.343,0,0,0,1.626.907,1.274,1.274,0,0,0,.926-1.554.4.4,0,0,0,0-.073c-.172-.616-.325-1.251-.448-1.89a1.317,1.317,0,0,0-2.587.5A18.51,18.51,0,0,0,51.263,94.368Z" transform="translate(-31.334 -55.988)" fill="#21262c" />
        <path d="M49.744,73.021a14.667,14.667,0,0,0-.241,2.242,1.255,1.255,0,0,0,1.148,1.358,1.347,1.347,0,0,0,.176,0,1.316,1.316,0,0,0,1.316-1.274,12.356,12.356,0,0,1,.2-1.841,1.316,1.316,0,1,0-2.587-.486Z" transform="translate(-30.557 -44.399)" fill="#21262c" />
        <path d="M55.935,59.812a1.4,1.4,0,0,0,1.848-.23,8.258,8.258,0,0,1,1.232-1.3,1.316,1.316,0,0,0-1.691-2.017A11.146,11.146,0,0,0,55.7,57.937a1.324,1.324,0,0,0,.23,1.875Z" transform="translate(-34.211 -34.556)" fill="#21262c" />
        <path d="M108.036,59.533c.57.3,1.121.673,1.718.983a1.316,1.316,0,1,0,1.4-2.227h-.031c-.612-.383-1.236-.731-1.852-1.06a1.316,1.316,0,1,0-1.24,2.323Z" transform="translate(-66.265 -35.234)" fill="#21262c" />
        <path d="M90.839,52.971c.616.134,1.247.3,1.871.5a1.205,1.205,0,0,0,1.584-.635,1.067,1.067,0,0,0,.069-.222,1.316,1.316,0,0,0-.857-1.653c-.7-.222-1.408-.413-2.1-.562a1.317,1.317,0,1,0-.566,2.571Z" transform="translate(-55.443 -31.095)" fill="#21262c" />
        <path d="M72.755,52.228a11.892,11.892,0,0,1,1.837-.279,1.32,1.32,0,0,0-.2-2.629,15.168,15.168,0,0,0-2.231.337,1.316,1.316,0,0,0-.983,1.58,1.228,1.228,0,0,0,1.393,1.022A1.025,1.025,0,0,0,72.755,52.228Z" transform="translate(-43.918 -30.447)" fill="#21262c" />
        <path d="M92.934,24.341a1.316,1.316,0,0,0,1.316-1.32h0v-7.3c0-3.107-2.556-5.8-6.555-7.473V4.654A4.546,4.546,0,0,0,83.145.1,4.423,4.423,0,0,0,82.07.23C75.565,1.814,71.842,5.12,69.825,7.749c-4.4,1.469-7.4,4.091-7.837,7.19-4.431-.459-8.977-1.913-13.393-3.295C40.5,9.077,32.141,6.421,23.689,9.368a8.323,8.323,0,0,0-5.579,7.848V69.7a6.122,6.122,0,0,0,7.909,5.912,24.674,24.674,0,0,1,6.207-1.01,1.32,1.32,0,0,0-.073-2.636h-.038A27.218,27.218,0,0,0,25.261,73.1a3.444,3.444,0,0,1-3.061-.54A3.509,3.509,0,0,1,20.746,69.7V17.216a5.682,5.682,0,0,1,3.827-5.357c7.611-2.656,15.2-.249,23.242,2.3A70.9,70.9,0,0,0,61.939,17.6V74.423a5.633,5.633,0,0,0,.352,1.963,70.27,70.27,0,0,1-13.776-2.173A97.043,97.043,0,0,0,37.694,72.2a1.32,1.32,0,0,0-.352,2.614h.069a95.444,95.444,0,0,1,10.5,1.952c4.906,1.11,9.949,2.25,15.038,2.25H64a14.61,14.61,0,0,0,5.82,3.379c2.024,2.629,8.591,7.653,13.335,7.653a4.546,4.546,0,0,0,4.554-4.565v-3.6c4-1.665,6.559-4.366,6.559-7.473V28.3a1.317,1.317,0,0,0-2.633-.069.206.206,0,0,0,0,.069V74.4c0,3.184-5.438,6.6-13.531,6.6S64.579,77.587,64.579,74.4V15.716c0-3.184,5.434-6.593,13.527-6.593s13.531,3.413,13.531,6.593v7.3a1.313,1.313,0,0,0,1.312,1.32Zm-7.875,58.42v2.713a1.913,1.913,0,0,1-1.913,1.913,2.028,2.028,0,0,1-.455-.054,20.407,20.407,0,0,1-8.2-3.922,28.893,28.893,0,0,0,3.593.222,27.287,27.287,0,0,0,6.972-.872ZM74.494,6.712a20.357,20.357,0,0,1,8.2-3.922,1.913,1.913,0,0,1,2.315,1.4,2.028,2.028,0,0,1,.054.455V7.363A27.279,27.279,0,0,0,78.09,6.49a28.91,28.91,0,0,0-3.6.222Z" transform="translate(-11.18 -0.062)" fill="#21262c" />
        <path d="M65.862,136.743a3.7,3.7,0,0,0,2.679,4.909l2.522.746-.746,2.526c-.3,1.01-.321,4.129,2.679,4.909s4.385-.8,4.913-2.679l.746-2.522,2.522.765a3.745,3.745,0,0,0,4.913-2.679,3.892,3.892,0,0,0-2.552-4.875l-.119-.034-2.522-.746.765-2.525a3.826,3.826,0,0,0-2.679-4.909,3.911,3.911,0,0,0-4.886,2.587.657.657,0,0,1-.027.08l-.746,2.526-2.522-.765a3.8,3.8,0,0,0-4.825,2.38A1.171,1.171,0,0,0,65.862,136.743Zm2.526.746a1.278,1.278,0,0,1,1.569-.907h.065L73.81,137.7a1.316,1.316,0,0,0,1.638-.892h0l1.121-3.784a1.228,1.228,0,0,1,1.473-.937.986.986,0,0,1,.161.046,1.221,1.221,0,0,1,.949,1.446,1.878,1.878,0,0,1-.057.2l-1.121,3.784a1.316,1.316,0,0,0,.888,1.638h0l3.784,1.121a1.317,1.317,0,0,1-.746,2.526l-3.788-1.121a1.316,1.316,0,0,0-1.634.907h0l-1.121,3.784a1.324,1.324,0,0,1-1.638.892,1.259,1.259,0,0,1-.922-1.531.728.728,0,0,1,.034-.111l1.121-3.758a1.316,1.316,0,0,0-.884-1.638h0l-3.788-1.121a1.3,1.3,0,0,1-.9-1.607v-.031Z" transform="translate(-40.508 -79.919)" fill="#21262c" />
        <rect width="90" height="90" fill="none" />
      </g>
    </g>
  </svg>
)

export default Map
