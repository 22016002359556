import { useEffect } from 'react'
import { useInventoryFunctions } from '../context/Inventory'
import { useAuthState } from '../context/Auth'
import { usePrevious } from './usePrevious'

export function useImportInventory() {
    const { user } = useAuthState()
    const { importInventoryData } = useInventoryFunctions()

    const prevUser = usePrevious(user)
    useEffect(() => {
        if (user && user !== prevUser) {
            importInventoryData(user)
        }
    })
}