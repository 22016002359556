import React, { useState, useMemo, useCallback } from 'react'
import { TextButton } from '../Buttons'
import { TabNavigation, Tab } from '../TabNavigation'
import InviteToggle from '../InviteToggle'

import Items from './Items'
import Campaigns from './Campaigns'
import Users from './Users'
import Dice from './Dice'
import DiceDrawer from '../DiceDrawer/DiceDrawer'

import { useCampaignFunctions, useCampaignState } from '../../context/Campaign'
import { useHistory } from 'react-router-dom'
import { useAuthState } from '../../context/Auth'
import ChestButton from './ChestButton/ChestButton'

import './styles.css'
import useOpenWindow from '../../hooks/useOpenWindow'
import { useWorkspaceFunctions } from '../../context/Workspace'
import MidCloseButton from '../MidCloseButton'

import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort'
import { Settings } from '@styled-icons/fluentui-system-filled/Settings'


const Chest = () => {
  const [open, setOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const { user } = useAuthState()

  const openWindow = useOpenWindow()

  const { socket, activeCampaign } = useCampaignState()
  const { logOutOfCampaign } = useCampaignFunctions()

  const { workspaceDispatch } = useWorkspaceFunctions()

  const history = useHistory()

  const openChestWindow = useCallback((window, settings, props) => {
    openWindow(window, settings, props)
    setOpen(false)
  }, [openWindow])

  return useMemo(() => {
    const logOutCloseAndRedirect = () => {
      logOutOfCampaign(socket)
      workspaceDispatch({ type: 'CLOSE_ALL' })
      setOpen(false)
      history.push('/')
    }

    return (
      <>
        <div className={`chest border-thick ${open && 'open border-right-thick bc-shade-2'} ${activeCampaign ? 'active-campaign bg-light-blue bc-dark-blue' : 'bg-light-yellow bc-yellow'}`}>
          <ChestButton open={open} onClick={() => setOpen(!open)} />
          {open && <div className="chest-inner bg-white">
            <div className="chest-header border-bottom-thin bc-shade-2">
              <h2 className="chest-title c-shade-6">{activeCampaign ? activeCampaign.title : 'Personal Chest'}</h2>
              <div className="chest-subtitle-section">
                {activeCampaign ? (
                  <InviteToggle className="chest-subtitle-button blue" inputClassName="invite-link-small" text={'+ Invite Players'} url={`https://adventuring-kit.com/campaign/${activeCampaign.shortId}`} />
                ) : (
                  <TextButton className="chest-subtitle-button blue" onClick={() => { openWindow('newCampaign'); setOpen(false) }}>+ Create Campaign</TextButton>
                )}
              </div>
              <TabNavigation tabs={["Items", `${activeCampaign ? "Users" : "Campaigns"}`, "Pieces"]} activeTab={activeTab} setTab={setActiveTab} />
            </div>
            {activeCampaign ? (
              <div className="chest-tabs-container bg-shade-1 bc-shade-3">
                <Tab active={activeTab === 0}><Items openChestWindow={openChestWindow} activeCampaign={activeCampaign} /></Tab>
                <Tab active={activeTab === 1}><Users openChestWindow={openChestWindow} activeCampaign={activeCampaign} /></Tab>
                <Tab active={activeTab === 2}><Dice openChestWindow={openChestWindow} /></Tab>
              </div>
            ) : (
              <div className="chest-tabs-container bg-shade-1 bc-shade-3">
                <Tab active={activeTab === 0}><Items openChestWindow={openChestWindow} /></Tab>
                <Tab active={activeTab === 1}><Campaigns openChestWindow={openChestWindow} setActiveTab={setActiveTab} /></Tab>
                <Tab active={activeTab === 2}><Dice openChestWindow={openChestWindow} /></Tab>
              </div>
            )}
            <div className="chest-footer-section border-top-thin bc-shade-2">
              {activeCampaign && (
                <TextButton
                  className="logoutButton blue half-width flex-start padding-left-1 centered"
                  onClick={logOutCloseAndRedirect}
                >
                  <ArrowLeftShort className="size-1" /> Singleplayer
                </TextButton>
              )}
              <TextButton className="row full-width flex-end centered-vertically" onClick={() => openChestWindow('settings')}>
                <p className="t-small">{user.name}</p>
                <Settings className="size-1 margin-left-half" />
              </TextButton>
            </div>
          </div>}
          {open && (
            <MidCloseButton bc="border-none shadow-light-3" bg="bg-shade-1" side="left" onClick={() => setOpen(false)} />
          )}
        </div>
        <DiceDrawer chestOpen={open} skin={user.diceSkin} />
      </>
    )
  }, [open, activeCampaign, activeTab, openChestWindow, user.name, user.diceSkin, logOutOfCampaign, socket, workspaceDispatch, history, openWindow])
}

export default Chest