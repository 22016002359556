import React from 'react'
import { Button } from '../../../../../components/Buttons'
import Icon from '../../../../../components/Icon'
import { useWorkspaceFunctions } from '../../../../../context/Workspace'

const Complete = ({ _index }) => {
    const { closeWindow } = useWorkspaceFunctions()

    return (
        <div className="col payment-complete-container centered">
            <h1 className="c-shade-9">Level Up!</h1>
            <p>Your payment was successfull</p>
            <div className="payment-complete-icon centered">
                <Icon value="chest-open" />
            </div>
            <Button className="white emphasis-1" onClick={() => {
                closeWindow(_index)
            }}>Start using your new rewards</Button>
        </div>
    )
}

export default Complete
