import React from 'react'
import './Notifications.css'
import { useCampaignState } from '../../context/Campaign'
import Notification from './Notification'
import { useWorkspaceState } from '../../context/Workspace'

const Notifications = () => {
    const { notifications } = useCampaignState()
    const { selectedTab } = useWorkspaceState()

    return (
        <div id="notifications-container" className={`notifications-container ${notifications.length > 5 && 'full'} ${selectedTab && 'inFullScreen'}`}>
            {notifications.map((notification, i) => (
                <Notification key={i} message={notification.message} id={notification.id} />
            ))}
        </div>
    )
}

export default Notifications
