import React, { useCallback, useState } from 'react'
import './grid.css'

const Grid = ({ showGrid, gridLeft = 0, gridTop = 0, gridSize = 1, camera, tool, updateDoc, showGridScaler, zoom }) => {

    const [dragTarget, setDragTarget] = useState(null)
    const [dragStartCoords, setDragStartCoords] = useState(null)
    const [dragStartGridPos, setDragStartGridPos] = useState(null)
    const [dragStartSize, setDragStartSize] = useState(gridSize)

    const update = useCallback((key, val) => {
        const data = { showGrid, gridLeft, gridTop, gridSize }
        let intFirst = parseInt(val)
        if (isNaN(intFirst)) intFirst = 0
        let finalVal = Math.max(val, 0)
        if (key !== 'showGrid') finalVal = intFirst
        updateDoc({
            p: [key], od: data[key], oi: finalVal
        })
    }, [gridLeft, gridSize, gridTop, showGrid, updateDoc])

    const onMouseDown = (e, target) => {
        setDragStartCoords({ x: e.clientX, y: e.clientY })
        setDragStartSize(gridSize)
        setDragTarget(target)
        setDragStartGridPos({ x: gridLeft, y: gridTop })
    }

    const onMouseMove = (e) => {
        if (dragTarget && dragStartCoords && dragStartGridPos) {
            switch (dragTarget) {
                case 'top-left':
                    update('gridLeft', dragStartGridPos.x + (e.clientX - dragStartCoords.x) / camera.zoom)
                    update('gridTop', dragStartGridPos.y + (e.clientY - dragStartCoords.y) / camera.zoom)
                    break
                case 'bottom-right':
                    update('gridSize', dragStartSize + (e.clientX - dragStartCoords.x) / camera.zoom)
                    break
                default:
            }
        }
    }

    const onMouseUp = () => {
        setDragStartCoords(null)
        setDragTarget(null)
        setDragStartGridPos(null)
    }

    return <div className={`grid ${showGridScaler && 'adjusting'}`} style={{
        backgroundPosition: `${gridLeft}px ${gridTop}px`,
        backgroundSize: `${gridSize}px ${gridSize}px`,
        color: tool === 'measure' ? '#abe4a5' : '#465058',
        opacity: tool === 'measure' ? 1 : showGrid ? 1 : 0
    }}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onWheel={(e) => {
            if (showGridScaler) {
                zoom(e)
            }
        }}
    >
        {showGridScaler ? (
            <div
                className="gridScaler bc-white centered"
                style={{ width: (gridSize) + 1, height: (gridSize) + 1, top: gridTop, left: gridLeft }}
            >
                <p className="t-center t-small c-shade-1" style={{ top: -32 }}>Scale&nbsp;and&nbsp;Move&nbsp;Grid</p>
                <p className="c-shade-9 t-center t-small" style={{ top: -33 }}>Scale&nbsp;and&nbsp;Move&nbsp;Grid</p>
                <div
                    className="topLeft bc-red border-thick"
                    style={{ width: gridSize * 0.75, height: gridSize * 0.75 }}
                    onMouseDown={(e) => onMouseDown(e, 'top-left')}
                />
                <div
                    className="bottomRight bc-red border-thick"
                    style={{ width: gridSize * 0.75, height: gridSize * 0.75 }}
                    onMouseDown={(e) => onMouseDown(e, 'bottom-right')}
                />
            </div>
        ) : null
        }
    </div >
}

export default Grid