const reducer = (state, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'SET_ERROR': {
            newState.error = action.value
            return newState
        }

        case 'CHANGE_FIELD': {
            const { field, string } = action.value

            newState[field] = string
            return newState
        }

        case 'CHANGE_PAGE': {
            newState.page = action.value
            return newState
        }

        case 'SET_AUTH': {
            const { token, user } = action.value
            newState.auth = token
            newState.user = user
            return newState
        }

        case 'CLEAR_FORM': {
            newState.name = ""
            newState.loginEmail = ""
            newState.loginPassword = ""
            newState.password = ""
            newState.passwordConfirm = ""
            newState.passwordResetToken = ""

            return newState
        }

        case 'SET_RESET_TOKEN': {
            newState.passwordResetToken = action.value
            return newState
        }

        case "SET_INITIAL_LOAD": {
            newState.initialLoad = action.value
            return newState
        }

        case 'LOG_OUT': {
            localStorage.removeItem("auth")
            newState.auth = null
            newState.user = null
            newState.page = "login"
            return newState
        }

        case 'CHANGE_DICE_SKIN': {
            const { color, skin } = action.value
            newState.user.color = color
            newState.user.diceSkin = skin

            return { ...newState, user: { ...newState.user } }
        }

        case 'UPDATE_SUBSCRIPTION': {
            const { newSubscription } = action.value
            const newSub = { ...newState.user.subscription, ...newSubscription }
            return { ...newState, user: { ...newState.user, subscription: newSub } }
        }

        case 'UPDATE_USER_SUBSCRIPTION_EXPIRATION': {
            const expirationDates = action.value
            const newSub = { ...newState.user.subscription, ...expirationDates }
            return { ...newState, user: { ...newState.user, subscription: newSub } }
        }

        case 'UPDATE_USER_TOKENS': {
            return { ...newState, user: { ...newState.user, tokens: [...action.value] } }
        }

        default: return state
    }
}

export default reducer
