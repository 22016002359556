import API from '../../../functions/API'

export async function sendPasswordReset(token, newPassword, newPasswordConfirm, setLoading, setError) {
    try {
        if (newPassword !== newPasswordConfirm) throw new Error('Passwords do not match')

        setLoading("password-reset-request")
        const response = await API.sendGraph({
            query: `mutation {
                    resetPassword(token: "${token}", newPassword: "${newPassword}") {
                            success
                        }
                    }`
        })

        if (response) {
            setLoading(false)
            return true
        }
    } catch (err) {
        setLoading(false)
        setError(err.message)
    }
}
