import shortid from 'shortid'

export function setError(error, persistent, type) {
    const { dispatch } = this
    console.trace(error)
    if (!type) {
        if (persistent) type = 'reload'
    }

    if (typeof error === 'object') {
        error = error.message
    }
    if (typeof error === 'string') error.replace('Error: ', '')

    if (error === 'Failed to fetch') error = 'Connection error please try again.'
    dispatch({
        type: 'SET_ERROR',
        value: { value: error, id: shortid.generate(), persistent, type }
    })
}
