import { useCallback, useState } from 'react'

const useSelectTokens = () => {
    const [currentlySelectedTokens, setCurrentlySelectedTokens] = useState([])

    const addToSelection = useCallback((auralessNewToken, clearTargets) => {
        const newToken = { ...auralessNewToken, auraInstance: document.getElementById(`token-auras-${auralessNewToken.id}`) }

        setCurrentlySelectedTokens((currentlySelectedTokens) => {
            if (clearTargets) clearTargetClasses(currentlySelectedTokens)
            if (currentlySelectedTokens.find(cst => cst.id === newToken.id))
                return clearTargets ? [newToken] : currentlySelectedTokens
            else
                return clearTargets ? [newToken] : [...currentlySelectedTokens, newToken]
        })
        newToken?.instance?.current?.classList?.add('selected')
    }, [])

    const removeFromSelection = useCallback((index) => {
        setCurrentlySelectedTokens((currentlySelectedTokens) => {
            const selectedIndex = currentlySelectedTokens.findIndex(token => token.index === index)
            if (selectedIndex !== -1) {
                console.log('removeFromSelection');
                currentlySelectedTokens[selectedIndex]?.instance?.current?.classList?.remove('selected')
                currentlySelectedTokens.splice(selectedIndex, 1)
            }
            return currentlySelectedTokens
        })
    }, [])

    const clearSelection = useCallback(() => {
        setCurrentlySelectedTokens((currentlySelectedTokens) => {
            clearTargetClasses(currentlySelectedTokens)
            return []
        })
    }, [])

    return {
        addToSelection,
        removeFromSelection,
        clearSelection,
        currentlySelectedTokens,
        setCurrentlySelectedTokens
    }
}

export default useSelectTokens


const clearTargetClasses = (currentlySelectedTokens) => {
    currentlySelectedTokens.forEach(token => {
        token?.instance?.current?.classList?.remove('selected')
    })
}