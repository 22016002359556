import React from 'react'
import { Close } from '@styled-icons/evaicons-solid/Close'
import { IconButton } from '../Buttons'
import { useCampaignFunctions } from '../../context/Campaign'
import './CancelDiceButton.css'
import { useWorkspaceState } from '../../context/Workspace'

const CancelDiceButton = () => {
    const { popDice } = useCampaignFunctions()
    const { diceInHand } = useWorkspaceState()

    return (
        <IconButton id="cancelDiceButton" className={`cancelDiceButton round border ${diceInHand && 'active'}`} onClick={() => { if (popDice) popDice(); return false }}>
            <Close id="cancelDiceButton-icon" className="size-3" />
        </IconButton>
    )
}

export default CancelDiceButton
