import React, { useState, useEffect } from 'react';
import { useAuthState } from '../../../../context/Auth';
import { useInventoryFunctions, useInventoryState } from '../../../../context/Inventory';
import { IconButton } from '../../../Buttons';
import Icon from '../../../Icon';
import { List, ListItem } from '../../../List';
import { User as UserIcon } from '@styled-icons/boxicons-solid/User';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { useErrorFunctions } from '../../../../context/Error';
import Loading from '../../../Loading';
import useOpenWindow from '../../../../hooks/useOpenWindow';
import { Crown } from '@styled-icons/boxicons-solid/Crown'
import { Book } from '@styled-icons/fa-solid/Book'
import { useCampaignState } from '../../../../context/Campaign/index';

const FolderEditMode = ({ setLoading, folderId, sharedCampaigns, sharedUsers, isOwner, owner, sharedToUser, sharedToCampaign }) => {
    const { activeCampaign } = useCampaignState()
    const { campaigns } = useInventoryState()
    const { deleteFolder, unshareFolder, inventoryDispatch } = useInventoryFunctions()
    const { user, auth } = useAuthState()
    const { setError } = useErrorFunctions()
    const [users, setUsers] = useState([])

    useEffect(() => {
        let usersSet = []
        usersSet.push(owner)
        campaigns.forEach(campaign => {
            let list = []
            if (campaign._id === activeCampaign?._id) {
                list = activeCampaign.users.filter(campaignUser => {
                    return (!isOwner || campaignUser._id !== user._id) && !usersSet.find(setUser => campaignUser._id === setUser._id)
                })
            } else {
                list = campaign.users.filter(campaignUser => {
                    return (!isOwner || campaignUser._id !== user._id) && !usersSet.find(setUser => campaignUser._id === setUser._id)
                })
            }
            usersSet = usersSet.concat(list)
        })

        sharedUsers.forEach(sharedU => {
            if (!usersSet.find(setUser => sharedU._id === setUser._id)) {
                usersSet.push(sharedU)
            }
        })

        setUsers(usersSet)
    }, [activeCampaign, campaigns, isOwner, owner, sharedUsers, user._id])

    const openWindow = useOpenWindow()

    const openDestroyFolderDialog = () => {
        openWindow('confirm', { x: window.innerWidth / 8 + 375 }, {
            message: <span className='t-center'>
                <span className='t-bold'>Delete folder?</span><br />
                <span className='t-small full-width'>You will lose all files within this folder.</span>
            </span>,
            confirmCallback: () => {
                deleteFolder(auth, folderId, setLoading, setError)
            }
        })
    }

    const openUnshareFolderDialog = (targetName, targetId, isCampaign) => {
        openWindow('confirm', { x: window.innerWidth / 8 + 375 }, {
            message: <span className='t-center'>
                <span className='t-bold'>Unshare folder?</span><br />
                <span className='t-small full-width'>You will lose access to any files owned by {isCampaign ? 'users in' : ''} <span className='t-small t-bold'>{targetName}</span>.</span>
            </span>,
            confirmCallback: () => {
                unshareFolder(auth, targetId, folderId, isCampaign, setLoading, setError)
            }
        })
    }

    return <div className="col t-left FolderEditMode full-width padding-horizontal-half">
        <div className="row flex-1 FolderEditMode--lists rounded full-width">
            <div className='col half-width overflow-y-auto overflow-hidden height-8 padding-left-half padding-top-half'>
                <p className="t-small t-bold c-shade-8"><Book className="size-75 opacity-75" style={{ transform: `scale(0.8)` }} /> Share{isOwner ? '' : 'd'} To Campaigns</p>
                <List className="square padding-0 padding-vertical-1 margin-top-0 margin-bottom-0">
                    {campaigns.map(campaign => {
                        return (
                            <ShareListItem
                                key={campaign._id}
                                id={campaign._id}
                                name={campaign.title}
                                folderId={folderId}
                                item={campaign}
                                sharedList={sharedCampaigns}
                                openUnshareFolderDialog={openUnshareFolderDialog}
                                isOwner={isOwner}
                                ownerId={owner._id}
                                isCampaign
                            />
                        )
                    })}
                </List>
            </div>
            <div className='col half-width overflow-y-auto overflow-hidden height-8 padding-left-half padding-top-half border-left bc-main'>
                <p className="t-small t-bold c-shade-8"><UserIcon className="size-75 opacity-75" /> Share{isOwner ? '' : 'd'} To Users</p>
                <List className="padding-0 padding-vertical-1 margin-top-0 margin-bottom-0">
                    {users.map(user => {
                        return (
                            <ShareListItem
                                key={user._id}
                                id={user._id}
                                name={user.name}
                                folderId={folderId}
                                item={user}
                                sharedList={sharedUsers}
                                openUnshareFolderDialog={openUnshareFolderDialog}
                                isOwner={isOwner}
                                ownerId={owner._id}
                            />
                        )
                    })}
                </List>
            </div>
        </div>
        {isOwner ? (
            <div className="row centered full-width padding-top-half">
                <IconButton
                    className="c-dark-red t-capitalize t-small"
                    onClick={() => openDestroyFolderDialog()}
                >
                    Delete Folder
                </IconButton>
            </div >
        ) : (
            sharedToUser && !sharedToCampaign ? (
                <IconButton
                    className="c-dark-red t-capitalize t-small"
                    onClick={() => {
                        unshareFolder(auth, user._id, folderId, false, setLoading, setError)
                        inventoryDispatch({ type: 'DELETE_FOLDER', value: folderId })
                    }}
                >
                    Remove Self From Folder
                </IconButton>
            ) : (
                <p className='t-small t-center'>Folder is shared to a campaign you are in.</p>
            )
        )}
    </div >;
};

export default FolderEditMode;


const ShareListItem = ({ name, folderId, item, isCampaign, sharedList, isOwner, ownerId, id, openUnshareFolderDialog }) => {
    const { auth } = useAuthState()
    const { setError } = useErrorFunctions()
    const { shareFolder } = useInventoryFunctions()
    const [loading, setLoading] = useState(false)

    const shared = sharedList.find(sharedItem => sharedItem._id === item._id)


    return (isOwner || shared || ownerId === id) ? (
        <ListItem
            className='padding-quarter margin-0'
            disabled={!isOwner}
            onClick={() => isOwner ? (shared ? (
                openUnshareFolderDialog(isCampaign ? item.title : item.name, item._id, isCampaign)
            ) : (
                shareFolder(auth, item._id, folderId, isCampaign, setLoading, setError)
            )) : null}
        >
            {loading ? (
                <Loading className="size-1 min-size-1 margin-right-half" />
            ) : shared ? (
                <CheckCircleFill className="c-green size-1 min-size-1 margin-right-half" />
            ) : (
                isCampaign ? (
                    <Icon value="tome" className="size-1 min-size-1 margin-right-half" />
                ) : ownerId === id ? (
                    <Crown className="c-dark-yellow size-1 min-size-1 margin-right-half" />
                ) : (
                    <UserIcon className="c-main size-1 min-size-1 margin-right-half" />
                )
            )}
            <p className="t-small padding-0 margin-0 c-shade-8 whitespace-nowrap t-overflow-elipses">{name}</p>
        </ListItem>
    ) : null
}