import { attachPhysicsBodyToDice } from "../../functions"
import setRandomDiceValue from "../../functions/setRandomDiceValue"

export default function noAnimationRoll() {
    while (this.rolledDice.length) {
        const die = this.rolledDice[0]
        setRandomDiceValue(die)
        die.diceValue = die.setValue

        // add dice if exploding
        if (die.rollingGroup.explodesOn && die.diceValue >= die.rollingGroup.explodesOn) {
            const newDie = this.createDiceObject(die.type)
            attachPhysicsBodyToDice(newDie, this.diceBodyMaterial)
            newDie.rollingGroup = die.rollingGroup
            newDie.rollingGroupIndex = die.rollingGroup.dice.length
            newDie.position.z = 3

            die.rollingGroup.exploding = true
            die.rollingGroup.explosionCount++

            newDie.explosionParent = die.rollingId
            newDie.explosionAncestor = die.explosionParent ? die.explosionAncestor : die.rollingId

            this.rolledDice.push(newDie)
            die.rollingGroup.dice.push(newDie)
        }

        this.finishDice(die)
    }
}