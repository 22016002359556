import React, { useState } from 'react'
import { CardButton } from '../../Buttons'
import { getIconColors } from '../../../functions/helpers'
import Icon from '../../Icon'
import '../SoundboardViewer.css'
import { Slider } from '../../Slider'
import { Plus } from '@styled-icons/bootstrap/Plus'
import Loading from '../../Loading'
import useMusic from './useMusic'

let hoverTimeout = null

const SoundSelection = ({ sound, onClick }) => {
    const [previewing, setPreviewing] = useState(false)
    const {
        duration,
        time,
        loading,
        setCurrentTime
    } = useMusic(previewing, sound.url, true)

    const onHoverStart = () => {
        clearTimeout(hoverTimeout)
        hoverTimeout = setTimeout(() => { setPreviewing(true) }, 100)
    }

    return (
        <CardButton
            className={`SoundboardIcon ${getIconColors('soundboard')} border`}
            onClick={() => {
                setPreviewing(false)
                onClick({ ...sound, duration })
            }}
            onMouseOver={onHoverStart}
            onMouseLeave={() => {
                setPreviewing(false)
                clearTimeout(hoverTimeout)
            }}
        >
            <div className='relative col-reversed centered-vertically height-4'>
                <div className='absolute-fill centered'>
                    <p className='soundboardCategoryLabel c-main t-center'>{sound.category}</p>
                    <div className='soundSelectionIcon'>{loading ? <Loading /> : previewing ? <Plus className="size-4 c-white padding-none" /> : <Icon value={sound.category} />}</div>
                </div>
                <Slider
                    min={0}
                    max={duration || 1}
                    value={((time / duration) * duration) || 0}
                    className="full-width hideKnob margin-0 square"
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={(e) => {
                        setCurrentTime(e.target.value)
                    }}
                />
            </div>
            <div className="full-width bg-white">
                <p>{sound.title || 'Untitled'}</p>
            </div>
        </CardButton>
    )
}

export default SoundSelection