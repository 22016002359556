import React, { useCallback } from 'react'
import { CardButton, TextButton } from '../../../../components/Buttons'
import Icon from '../../../../components/Icon'
import { useAuthState } from '../../../../context/Auth'
import { useInventoryState } from '../../../../context/Inventory'
import { useWorkspaceFunctions } from '../../../../context/Workspace'
import { getIconColors } from '../../../../functions/helpers'
import useGetDocumentCounts from '../../../../hooks/useGetDocumentCounts'
import useOpenWindow from '../../../../hooks/useOpenWindow'

const NewNote = ({ _index }) => {
    const openWindow = useOpenWindow()
    const { closeWindow } = useWorkspaceFunctions()
    const { allDocuments } = useInventoryState()
    const { user } = useAuthState()

    const openNoteWindow = useCallback((window) => {
        openWindow(window, null, { documentOwnerId: user._id, kind: window })
        closeWindow(_index)
    }, [_index, closeWindow, openWindow, user._id])

    const {
        PDFCount, maxPDFs,
        noteCount, maxNotes,
        mapCount, maxMaps,
        pictureCount, maxPictures,
        soundBoardCount, maxSoundboards
    } = useGetDocumentCounts(allDocuments)

    return (
        <div className="col centered full-height">
            {!user.subscription.active && <TextButton onClick={() => openWindow('premiumSignup')} className="margin-top-1 t-small t-center t-clear-transform t-light"><span className="c-blue c-bold c-hover-dark-blue">Click here</span> to upgrade to premium and get unlimited documents!</TextButton>}
            <div className="row centered">
                <div className="col padding-1 centered">
                    <CardButton
                        disabled={!user.subscription.active && noteCount >= maxNotes}
                        className={`${getIconColors('note')} border-thick size-6 centered padding-0`}
                        onClick={() => openNoteWindow('note')}>
                        <Icon className="size-3" value="ink" />
                    </CardButton>
                    <p className="full-width t-centered">Note</p>
                    {!user.subscription.active && <p className={`t-small t-center ${noteCount >= maxNotes && 'c-red'}`}>{noteCount}/{maxNotes} left</p>}
                </div>

                <div className="col padding-1 centered">
                    <CardButton
                        disabled={!user.subscription.active && PDFCount >= maxPDFs}
                        className={`${getIconColors('PDF')} border-thick size-6 centered padding-0`}
                        onClick={() => openNoteWindow('PDF')}>
                        <Icon className="size-3" value="parchment" />
                    </CardButton>
                    <p className="full-width t-centered">Sheet</p>
                    {!user.subscription.active && <p className={`t-small t-center ${PDFCount >= maxPDFs && 'c-red'}`}>{PDFCount}/{maxPDFs} left</p>}
                </div>

                <div className="col padding-1 centered">
                    <CardButton
                        disabled={!user.subscription.active && mapCount >= maxMaps}
                        className={`${getIconColors('Map')} border-thick size-6 centered padding-0`}
                        onClick={() => openNoteWindow('Map')}>
                        <Icon className="size-3" value="map" />
                    </CardButton>
                    <p className="full-width t-centered">Map</p>
                    {!user.subscription.active && <p className={`t-small t-center ${mapCount >= maxMaps && 'c-red'}`}>{mapCount}/{maxMaps} left</p>}
                </div>
            </div>
            <div className="row centered">
                <div className="col padding-1 centered">
                    <CardButton
                        disabled={!user.subscription.active && pictureCount >= maxPictures}
                        className={`${getIconColors('picture')} border-thick size-6 centered padding-0`}
                        onClick={() => openNoteWindow('picture')}>
                        <Icon className="size-3" value="media" />
                    </CardButton>
                    <p className="full-width t-centered">Picture</p>
                    {!user.subscription.active && <p className={`t-small t-center ${pictureCount >= maxPictures && 'c-red'}`}>{pictureCount}/{maxPictures} left</p>}
                </div>

                <div className="col padding-1 centered">
                    <CardButton
                        disabled={!user.subscription.active && soundBoardCount >= maxSoundboards}
                        className={`${getIconColors('soundboard')} border-thick size-6 centered padding-0`}
                        onClick={() => openNoteWindow('soundboard')}>
                        <Icon className="size-3" value="lute" />
                    </CardButton>
                    <p className="full-width t-centered">Soundboard</p>
                    {!user.subscription.active && <p className={`t-small t-center ${soundBoardCount >= maxSoundboards && 'c-red'}`}>{soundBoardCount}/{maxSoundboards} left</p>}
                </div>
            </div>
        </div>
    )
}

export default NewNote
