import { selectWindow } from './'
export function openWindow(windows, newWindow, settings, props) {
    if (!newWindow) return false
    const uniqueWindowIndex = newWindow.settings.uniqueIndex || settings?.uniqueIndex
    if (uniqueWindowIndex) {
        const duplicateWindow = windows.find(window => {
            return window.settings.uniqueIndex === uniqueWindowIndex
        })
        if (duplicateWindow) return selectWindow.call(this, duplicateWindow.index)
    }

    const value = {
        view: newWindow.view,
        settings: { ...newWindow.settings, ...settings },
        propsPassedFromWindow: props
    }

    value.index = Math.random().toString(36).substr(2, 9)

    this.dispatch({ type: 'OPEN_WINDOW', value })
}