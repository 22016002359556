import React, { useEffect, useState } from 'react'
import { useWorkspaceState } from '../../context/Workspace'
import ShareDB from '../../functions/ShareDB'
import { NoteEditor } from '../NoteEditor'
import { Document, Page } from 'react-pdf'
import { Button } from '../Buttons'
import SoundBoardEntry from '../SoundboardViewer/partials/SoundBoardEntry'
import { getIconColors } from '../../functions/helpers'
import { useAuthState } from '../../context/Auth'

const defaultSoundData = { volume: 0.5, playing: false, repeat: false, time: 0 }
const DocumentPreview = ({ id, kind, ownerId, expanded, onOpen, windowIndex, onDelete, onUnshare, openLabel, className, stopSoundboardScroll }) => {
    const [data, setData] = useState(null)
    const [background, setBackground] = useState(null)
    const [file, setFile] = useState(null)
    const [sounds, setSounds] = useState([])
    const { musicPlaying } = useWorkspaceState()

    const { user } = useAuthState()

    useEffect(() => {
        const connection = ShareDB.connect()
        const doc = connection.get('sharedDocData', id)
        doc.fetch(() => {
            if (!doc?.data?.fogEnabled || ownerId === user?._id)
                setBackground(doc?.data?.picture || doc?.data?.image)

            setFile(doc?.data?.file)
            setSounds(doc?.data?.sounds)
            if (doc)
                setData(doc.data)
            else {
                setData({
                    name: 'Unshared Document',
                })
            }
        })
    }, [id, ownerId, user?._id, user.id])

    const handleOpen = onOpen ? () => onOpen({ id: data?.id, name: data?.name }) : null
    const handleDelete = onDelete ? (e) => {
        onDelete(id)
        e.stopPropagation()
    } : null

    const handleUnshare = onUnshare ? (e) => {
        onUnshare()
        e.stopPropagation()
    } : null

    return expanded && (
        <div
            className={`documentListItem--body position-relative full-width c-shade-6 col centered ${kind === 'Map' ? 'bg-shade-2' : 'bg-shade-2'} rounded ${getIconColors(kind)} ${className}`}
            style={background ? { backgroundImage: `url("${background}")`, backgroundSize: 'cover', backgroundPosition: 'center' } : null}>
            {kind === 'Map' && !background && (
                <div className='centered full-width full-height'>
                    <p className='full-width t-center'>No Preview Available</p>
                </div>
            )}

            {kind === 'note' && (
                <NoteEditor
                    windowIndex={windowIndex}
                    isFullScreen={false}
                    id={id}
                    hideToolbar={true}
                />
            )}

            {kind === 'PDF' && file && (
                <Document
                    className="documentListItem--document"
                    file={file}
                    onLoadError={(err) => console.log(err)}
                    noData={null}
                    loading={null}
                    options={{ verbosity: 0 }}
                >
                    <Page pageNumber={1} width={250} />
                </Document>
            )}

            {(kind === 'soundboard') ? (
                <div className={`full-width height-10 col padding-left-half ${stopSoundboardScroll ? "overflow-hidden" : 'overflow-auto'}`}>
                    <div className='row full-width centered margin-top-1'>
                        <DocumentButtons {...{ openLabel, handleOpen, handleDelete, handleUnshare }} />
                    </div>
                    {sounds?.length ? sounds.map((sound, i) => (
                        <SoundBoardEntry key={`sound-${i}`} sound={{ ...defaultSoundData, ...sound, ...musicPlaying[sound.id] }} minified />
                    )) : <p className='full-height full-width centered t-center'>No Sounds added yet.</p>}
                </div>
            ) : (
                <div className="position-absolute col bottom-0">
                    <div className='row'>
                        <DocumentButtons {...{ openLabel, handleOpen, handleDelete, handleUnshare }} />
                    </div>
                </div>
            )}
        </div>
    )

}

export default DocumentPreview


const DocumentButtons = ({ handleOpen, openLabel, handleDelete, handleUnshare }) => {
    return (handleOpen || handleDelete || handleUnshare) && (
        <div className="margin-bottom-half">
            {handleOpen && <Button className={`width-5 padding-half ${handleDelete || handleUnshare ? 'margin-right-half' : ''}`} onClick={handleOpen}>{openLabel || 'Open'}</Button>}
            {handleDelete ? <Button className="width-5 padding-half red" onClick={handleDelete}>Delete</Button> : (
                handleUnshare && <Button className="width-5 padding-half" onClick={handleUnshare}>Remove</Button>
            )}
        </div>
    )
}