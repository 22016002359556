import { useEffect, useState } from "react";
import { useAuthState } from "../context/Auth/index";

const useGetDocumentCounts = (allDocs) => {
    const { user } = useAuthState()
    const [docsUserOwns, setDocsUserOwns] = useState(allDocs.filter(doc => ownerMatchesId(doc, user._id)))

    useEffect(() => {
        setDocsUserOwns(allDocs.filter(doc => ownerMatchesId(doc, user._id)))
    }, [allDocs, user._id])

    const maxPDFs = 3, maxNotes = 3, maxMaps = 3, maxPictures = 3, maxSoundboards = 0
    const [PDFCount, setPDFCount] = useState(maxPDFs)
    const [noteCount, setNoteCount] = useState(maxNotes)
    const [mapCount, setMapCount] = useState(maxMaps)
    const [pictureCount, setPictureCount] = useState(maxPictures)
    const [soundBoardCount, setSoundBoardCount] = useState(maxSoundboards)

    useEffect(() => {
        let PDFs = 0
        let Notes = 0
        let Maps = 0
        let Pictures = 0
        let Soundboards = 0

        docsUserOwns.forEach(doc => {
            switch (doc.kind) {
                case 'PDF':
                    PDFs++
                    break
                case 'note':
                    Notes++
                    break
                case 'Map':
                    Maps++
                    break
                case 'picture':
                    Pictures++
                    break
                case 'soundboard':
                    Soundboards++
                    break

                default:
                    return null
            }
        })

        setPDFCount(PDFs)
        setNoteCount(Notes)
        setMapCount(Maps)
        setPictureCount(Pictures)
        setSoundBoardCount(Soundboards)
    }, [docsUserOwns])

    return {
        PDFCount, maxPDFs,
        noteCount, maxNotes,
        mapCount, maxMaps,
        pictureCount, maxPictures,
        soundBoardCount, maxSoundboards
    }
};

export default useGetDocumentCounts;


const ownerMatchesId = (doc, userId) => {
    return doc?.owner === userId || doc?.owner?._id === userId
}