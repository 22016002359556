import { useCallback } from 'react'

const useZoom = (setCamera, windowPos, isFullScreen) => {
    const zoom = useCallback((e) => {
        setCamera((camera) => {
            if (e.deltaY === 0 || e.deltaY === -0) return camera
            let factor = 0.9
            if (e.deltaY < 0) factor = 1.1

            let newZoom = camera.zoom

            newZoom *= factor
            newZoom = (Math.min(newZoom, 10))
            newZoom = (Math.max(newZoom, 0.5))

            if (newZoom === 10) return camera
            if (newZoom === 0.5) return camera

            const windowTop = isFullScreen ? 0 : windowPos.top + 74
            const windowLeft = isFullScreen ? 0 : windowPos.left

            const relativeMouseX = e.clientX - windowLeft
            const relativeMouseY = e.clientY - windowTop

            const dx = (relativeMouseX - camera.x) * (factor - 1),
                dy = (relativeMouseY - camera.y) * (factor - 1)

            const newCamera = {
                x: camera.x - dx,
                y: camera.y - dy,
                zoom: newZoom,
            }

            return newCamera
        })
    }, [isFullScreen, setCamera, windowPos.left, windowPos.top])

    return (
        zoom
    )
}

export default useZoom