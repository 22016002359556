import React from 'react'

const Blobs = ({ reverse }) => {
    return (
        <div className={`blobs ${reverse && 'reverse'}`}>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"></div>
        </div>
    )
}

export default Blobs
