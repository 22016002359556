import { useEffect } from 'react'
import { useCampaignFunctions, useCampaignState } from '../context/Campaign'
import { useAuthState } from '../context/Auth'
import { useLocation, useHistory } from 'react-router-dom'
import { useErrorFunctions } from '../context/Error'
import { usePrevious } from './usePrevious'
import { useLoadingFunctions } from '../context/Loading'

export function useCheckForCampaignInUrl() {
    const { auth, user } = useAuthState()

    const { activeCampaign, waitingForInviteId, socket } = useCampaignState()
    const { requestToEnterCampaign, logOutOfCampaign } = useCampaignFunctions()

    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()

    const location = useLocation()
    const history = useHistory()

    const prevLocation = usePrevious(location)

    const checkForCampaign = async () => {
        if (location && location !== prevLocation) {
            if (activeCampaign || waitingForInviteId) {
                const urlId = activeCampaign ? activeCampaign.shortId : waitingForInviteId

                if (!location.pathname.includes(`/campaign/${urlId}`))
                    history.replace({ pathname: `/campaign/${urlId}` })
            } else {
                // if we currently dont have campaign data
                // check if the URL has a campaign id
                // if there is no id in url or we are not signed in, skip
                const campaignInUrl = location.pathname.indexOf('/campaign/')
                if (!auth || campaignInUrl < 0) return false

                // if there is an id in the url then we need to request campaign data
                const shortIdIndex = campaignInUrl + '/campaign/'.length
                const campaignShortId = location.pathname.substr(shortIdIndex)

                try {
                    await requestToEnterCampaign(auth, user, campaignShortId, setLoading, setError)
                } catch (err) {
                    history.replace({ pathname: '/' })
                    logOutOfCampaign(socket)
                }
            }
        }
    }

    useEffect(() => {
        checkForCampaign()
    })
}