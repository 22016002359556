import React, { useEffect, useState } from 'react'
import DiceValueIcon from '../Icon/DiceValueIcon'

const DiceGroup = ({ die, roll, onReRoll }) => {
    const type = die.type
    const [diceInGroup, setDiceInGroup] = useState(roll.dice.filter(filterDie => filterDie.type === type))
    const dieCount = roll.diceCounts[type]
    const skin = roll.skin

    const [open, setOpen] = useState(false)

    const drop = roll.keepBest !== 0 && !die.keep

    useEffect(() => {
        setDiceInGroup(roll.dice.filter(filterDie => filterDie.type === type))
    }, [roll.dice, type])

    return (
        <div className="col width-10dot5">
            <div className='row' onClick={() => setOpen(!open)}>
                <div className={`diceHistoryEntry__diceList row margin-right-1 padding-quarter ${open && 'group'}`}>
                    <DiceValueIcon icon={die.type} value={die.value} skin={roll.skin} drop={drop} failure={die.failed} rerollCount={die.rerollCount} />
                    <span className="diceHistoryEntry__count">x{dieCount}</span>
                </div>
            </div>
            <div className={`diceHistoryEntry__diceContainer row group padding-quarter rounded ${open && 'open'}`}>
                {diceInGroup.map((dieInGroup, i) => {
                    const drop = roll.keepBest !== 0 && !dieInGroup.keep
                    return (
                        <div key={i} className="diceHistoryEntry__diceList">
                            <DiceValueIcon
                                icon={dieInGroup.type}
                                value={dieInGroup.value}
                                skin={skin}
                                drop={drop}
                                failure={dieInGroup.failed}
                                rerollCount={dieInGroup.rerollCount}
                                onReRoll={onReRoll ? () => onReRoll(roll, dieInGroup) : null}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DiceGroup