import API from '../../../functions/API'

export async function sendPasswordResetEmail(email, setLoading) {
    setLoading("password-reset-request")
    const response = await API.sendGraph({
        query: `mutation {
                generatePasswordReset(email: "${email.toLowerCase()}") {
                        success
                    }
                }`
    })

    if (response) {
        setLoading(false)
        return true
    }
}
