import React, { useState } from 'react'
import Loading from '../../../../components/Loading'
import PDFRenderer from '../../../../components/PDFRenderer/PDFRenderer'
import useLoadDocument from '../../../../hooks/useLoadDocument'

const PDF = ({ documentId, documentOwnerId, oldData, _index, _settings, windowScaling, windowDragging, saving, isFullScreen, onCreate }) => {
    const [loading, setLoading] = useState(true)
    const {
        id,
        data,
    } = useLoadDocument(documentId, oldData, 'PDF', _index, setLoading, onCreate)

    return (
        <div className="full-height">
            {loading ? (
                <Loading />
            ) : <PDFRenderer
                windowIndex={_index}
                saving={saving}
                isFullScreen={isFullScreen}
                windowWidth={_settings.width}
                windowScaling={windowScaling}
                windowDragging={windowDragging}
                id={id}
                name={_settings.title}
                initialValue={data}
                broadcasted={_settings.broadcasted}
                broadcasting={_settings.broadcasting}
            />}
        </div>
    )
}

export default PDF