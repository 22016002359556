import { isMobile } from 'react-device-detect'
import GlobalStorage from '../../functions/GlobalStorage'
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_CAMPAIGN': {
            const newState = { ...state }
            newState.selectedCampaign = action.value
            return newState
        }

        case 'OPEN_WINDOW': {
            const newState = { ...state }
            const uniqueWindowIndex = action.value.settings.uniqueIndex
            if (uniqueWindowIndex) {
                const duplicateTab = newState.tabs.find(window => {
                    return window.settings.uniqueIndex === uniqueWindowIndex
                })

                if (duplicateTab) {
                    newState.selectedTab = duplicateTab.index
                    return newState
                }
            }

            action.value.settings = {
                x: action.value.settings.width ? window.innerWidth / 2 - action.value.settings.width / 2 : window.innerWidth / 8,
                y: action.value.settings.height ? window.innerHeight / 2 - action.value.settings.height / 2 : window.innerHeight / 8,
                ...action.value.settings
            }

            const mobileTab = isMobile && !action.value.settings.disableMobileDefault
            if (action.value.settings.broadcasted || (mobileTab)) {
                newState.tabs = [...state.tabs, { ...action.value }]
                if (newState.tabs.length === 1 || mobileTab) newState.selectedTab = action.value.index
            } else {
                newState.windows = [...state.windows, { ...action.value }]
            }
            return newState
        }

        case 'MOVE_WINDOW': {
            const newState = { ...state }
            const { index, x, y, width, height, instance } = action.value
            const windowInstance = newState.windows.find(windowInstance => windowInstance.index === index)
            if (windowInstance) {
                windowInstance.settings.x = x
                windowInstance.settings.y = y
                windowInstance.settings.width = width || windowInstance.settings.width
                windowInstance.settings.height = height || windowInstance.settings.height

                instance.style.left = `${x}px`
                instance.style.top = `${y}px`
                instance.style.width = `${width || windowInstance.settings.width}px`
                instance.style.height = `${height || windowInstance.settings.height}px`
            }
            return newState
        }

        case 'CLOSE_WINDOW': {
            const newState = { ...state, windows: [...state.windows] }
            const windowCloseIndex = newState.windows.findIndex(windowInstance => windowInstance.index === action.value)
            if (windowCloseIndex >= 0) {
                const saving = newState.windows[windowCloseIndex].saving
                if (saving && saving !== 'saved') {
                    newState.windows[windowCloseIndex].closing = true
                } else {
                    newState.windows.splice(windowCloseIndex, 1)
                }
            }

            const tabCloseIndex = newState.tabs.findIndex(tab => tab.index === action.value)
            if (tabCloseIndex >= 0) {
                const saving = newState.tabs[tabCloseIndex].saving
                if (saving && saving !== 'saved') {
                    newState.tabs[tabCloseIndex].closing = true
                } else {
                    newState.tabs.splice(tabCloseIndex, 1)
                }
                if (tabCloseIndex - 1 > 0 && newState.tabs[tabCloseIndex - 1]) newState.selectedTab = newState.tabs[tabCloseIndex - 1].index
                else if (newState.tabs[0]) newState.selectedTab = newState.tabs[0].index
                else newState.selectedTab = null
            }

            return newState
        }

        case 'CLOSE_ALL': {
            return { ...state, windows: [], tabs: [], selectedTab: null }
        }

        case 'CLOSE_WINDOW_FROM_DOC_ID': {
            const newState = { ...state, windows: [...state.windows] }
            const windowCloseIndex = newState.windows.findIndex(w => w?.propsPassedFromWindow?.documentId === action.value)
            if (windowCloseIndex >= 0) {
                const saving = newState.windows[windowCloseIndex].saving
                if (saving && saving !== 'saved') {
                    newState.windows[windowCloseIndex].closing = true
                } else {
                    newState.windows.splice(windowCloseIndex, 1)
                }
            }

            const tabCloseIndex = newState.tabs.findIndex(t => t?.propsPassedFromWindow?.documentId === action.value)
            if (tabCloseIndex >= 0) {
                const saving = newState.tabs[tabCloseIndex].saving
                if (saving && saving !== 'saved') {
                    newState.tabs[tabCloseIndex].closing = true
                } else {
                    newState.tabs.splice(tabCloseIndex, 1)
                }
            }

            if (tabCloseIndex - 1 > 0 && newState.tabs[tabCloseIndex - 1]) newState.selectedTab = newState.tabs[tabCloseIndex - 1].index
            else if (newState.tabs[0]) newState.selectedTab = newState.tabs[0].index
            else newState.selectedTab = null

            return newState
        }

        case 'UNBROADCAST_WINDOW': {
            const newState = { ...state, windows: [...state.windows] }
            const tabCloseIndex = newState.tabs.findIndex(t => t?.propsPassedFromWindow?.documentId === action.value)
            if (tabCloseIndex >= 0) {
                if (newState.tabs[tabCloseIndex]?.settings.wasWindowBeforeBroadcast) {
                    newState.tabs[tabCloseIndex].settings.wasWindowBeforeBroadcast = false
                    newState.tabs[tabCloseIndex].settings.broadcasted = false

                    newState.windows.push(newState.tabs[tabCloseIndex])
                    newState.tabs.splice(tabCloseIndex, 1)

                    if (tabCloseIndex - 1 > 0 && newState.tabs[tabCloseIndex - 1]) newState.selectedTab = newState.tabs[tabCloseIndex - 1].index
                    else if (newState.tabs[0]) newState.selectedTab = newState.tabs[0].index
                    else newState.selectedTab = null

                    return newState
                }

                if (newState.tabs[tabCloseIndex]?.settings.wasTabBeforeBroadcast) {
                    newState.tabs[tabCloseIndex].settings.wasTabBeforeBroadcast = false
                    newState.tabs[tabCloseIndex].settings.broadcasted = false
                    return newState
                }

                const saving = newState.tabs[tabCloseIndex].saving
                if (saving && saving !== 'saved') {
                    newState.tabs[tabCloseIndex].closing = true
                } else {
                    newState.tabs.splice(tabCloseIndex, 1)
                }
            }

            if (tabCloseIndex - 1 > 0 && newState.tabs[tabCloseIndex - 1]) newState.selectedTab = newState.tabs[tabCloseIndex - 1].index
            else if (newState.tabs[0]) newState.selectedTab = newState.tabs[0].index
            else newState.selectedTab = null

            return newState
        }

        case 'UPDATE_VIEW': {
            const newState = { ...state }

            let update = newState.windows.find(windowInstance => windowInstance.index === action.value.index)
            if (!update) update = newState.tabs.find(tab => tab.index === action.value.index)

            update.view = action.value.view
            update.settings = { ...action.value.settings }
            return newState
        }

        case 'UPDATE_WINDOW_SETTINGS': {
            const newState = { ...state }

            let update = newState.windows.find(windowInstance => windowInstance.index === action.value.index)
            if (!update) update = newState.tabs.find(tab => tab.index === action.value.index)

            update.settings = { ...update.settings, ...action.value.values }

            return newState
        }

        case 'MAXIMIZE_WINDOW': {
            const newState = { ...state }
            // find the window index in the collection that you are modifying

            const targetIndex = newState.windows.findIndex(windowInstance => windowInstance.index === action.value.index)
            newState.windows[targetIndex].settings = { ...newState.windows[targetIndex].settings, ...action.value.values }
            newState.tabs.unshift(newState.windows[targetIndex])
            newState.windows.splice(targetIndex, 1)
            return newState
        }

        case 'UNMAXIMIZE_WINDOW': {
            const newState = { ...state }
            // find the tab index in the collection that you are modifying
            const targetIndex = newState.tabs.findIndex(tab => tab.index === action.value)

            newState.windows.push(newState.tabs[targetIndex])
            newState.tabs.splice(targetIndex, 1)

            if (targetIndex - 1 > 0 && newState.tabs[targetIndex - 1]) newState.selectedTab = newState.tabs[targetIndex - 1].index
            else if (newState.tabs[0]) newState.selectedTab = newState.tabs[0].index
            else newState.selectedTab = null

            return newState
        }

        case 'MINIMIZE_WINDOW': {
            const newState = { ...state }
            const targetIndex = newState.windows.findIndex(windowInstance => windowInstance.index === action.value.index)
            if (targetIndex > -1) {
                newState.windows[targetIndex].settings = { ...newState.windows[targetIndex].settings, minimized: true }
                newState.minimized.push(action.value)
            }
            return newState
        }

        case 'UNMINIMIZE_WINDOW': {
            const newState = { ...state }
            const targetIndex = newState.windows.findIndex(windowInstance => windowInstance.index === action.value)
            if (targetIndex > -1) {
                newState.windows[targetIndex].settings = { ...newState.windows[targetIndex].settings, minimized: false }
            }

            const minimizedIndex = newState.minimized.findIndex((minim) => minim.index === action.value)
            if (minimizedIndex > -1) {
                newState.minimized.splice(minimizedIndex, 1)
            }
            return newState
        }

        case 'SELECT_WINDOW': {
            const newState = { ...state }
            newState.windows.sort((a, b) => a.settings.zIndex > b.settings.zIndex ? 1 : -1)

            let windowIndex = 0
            newState.windows.forEach((windowInstance) => {
                if (windowInstance.index === action.value) {
                    windowInstance.settings.zIndex = newState.windows.length
                    windowInstance.settings.selected = true
                } else {
                    windowInstance.settings.zIndex = windowIndex
                    windowInstance.settings.selected = false
                    windowIndex++
                }
            })
            return newState
        }

        case 'SCALE_WINDOW': {
            const newState = { ...state }
            const { x, y, width, height, index } = action.value

            const scalingWindow = newState.windows.find(windowInstance => windowInstance.index === index)
            scalingWindow.settings.x = Math.max(x, 0)
            scalingWindow.settings.y = Math.max(y, 0)
            scalingWindow.settings.width = Math.max(width, 128)
            scalingWindow.settings.height = Math.max(height, 128)
            return newState
        }

        case 'UPDATE_APP_DIMENSIONS': {
            const newState = { ...state }
            newState.appDimensions = { width: action.value.width, height: action.value.height }
            return newState
        }

        case 'SELECT_TAB': {
            const newState = { ...state }
            newState.selectedTab = action.value.index
            if (action.value.values) {
                const target = newState.tabs.find(tab => tab.index === action.value.index)

                if (target) target.settings = { ...target.settings, ...action.value.values }
            }
            return newState
        }

        case 'RENAME_WINDOW': {
            const newState = { ...state }
            let target = newState.windows.find(windowInstance => windowInstance.index === action.value.index)
            if (!target) target = newState.tabs.find(tab => tab.index === action.value.index)
            if (target) target.settings.title = action.value.title
            return newState
        }

        case 'RENAME_WINDOW_FROM_DOC_ID': {
            const newState = { ...state }
            let target = newState.windows.find(w => w?.propsPassedFromWindow?.documentId === action.value.documentId)
            if (!target) target = newState.tabs.find(tab => tab.index === action.value.index)

            if (target) target.settings.title = action.value.title
            return newState
        }

        case 'CHANGE_WINDOW_COLOR': {
            const newState = { ...state }
            let target = newState.windows.find(windowInstance => windowInstance.index === action.value.index)
            if (!target) target = newState.tabs.find(tab => tab.index === action.value.index)

            if (target) target.settings.color = action.value.color
            return newState
        }

        case 'SET_WINDOW_SAVING': {
            const newState = { ...state }
            newState.windows = state.windows.filter(window => {
                const loadingWindow = window.index === action.value.index ? window : null
                if (loadingWindow) {
                    loadingWindow.saving = action.value.saving
                    if (loadingWindow.closing && action.value.saving === 'saved') {
                        return false
                    }
                }

                return true
            })

            newState.tabs = state.tabs.filter(tab => {
                const loadingTab = tab.index === action.value.index ? tab : null
                if (loadingTab) {
                    loadingTab.saving = action.value.saving
                    if (loadingTab.closing && action.value.saving === 'saved') {
                        return false
                    }
                }

                return true
            })

            return newState
        }

        case 'SET_DICE_DRAWER_OPEN': {
            const newState = { ...state }
            newState.diceDrawerOpen = action.value
            return newState
        }

        case 'SET_DICE_OPTIONS': {
            const { key, value } = action.value
            const diceOptions = { ...state.diceOptions, [key]: value }
            return { ...state, diceOptions }
        }

        case 'SET_TEMP_DICE_OPTIONS': {
            return { ...state, tempDiceOptions: { ...state.tempDiceOptions, ...action.value } }
        }

        case 'SET_DICE_IN_HAND': {
            return {
                ...state, diceInHand: action.value, tempDiceOptions: {
                    addOrSub: 0,
                    succeedsOn: 0,
                    succeedsOnGreaterOrLess: 'greater',
                }
            }
        }

        case 'SET_DICE_ROLLING': {
            return { ...state, rolling: action.value }
        }

        case 'UPDATE_WINDOW_PROPS': {
            const newState = { ...state }
            let target = newState.windows.find(windowInstance => windowInstance.index === action.value.index)
            !target && newState.tabs.find(tab => tab.index === action.value.index)
            if (target) target.propsPassedFromWindow = { ...target.propsPassedFromWindow, ...action.value.props }
            return newState
        }

        case 'SET_PASTE_BUCKET': {
            const newState = { ...state }
            newState.paste = action.value
            return newState
        }

        case 'UPDATE_PLAYING_SONG': {
            if (!action.value.id) throw new Error('attempted to update song without id')
            const newState = { ...state }
            newState.musicPlaying[action.value.id] = action.value

            return newState
        }

        case 'REMOVE_PLAYING_SONG': {
            const newState = { ...state }
            delete newState.musicPlaying[action.value.id]
            return newState
        }

        case 'SET_MUSIC_MUTED': {
            const newState = { ...state }
            newState.muteSounds = action.value
            const sd = GlobalStorage.get('SOUND_DATA')
            Object.keys(sd).forEach(key => { if (sd[key]) sd[key].muted = action.value })
            GlobalStorage.set('SOUND_DATA', sd)
            return newState
        }

        case 'SET_GLOBAL_VOLUME': {
            const newState = { ...state }
            newState.globalVolume = action.value
            localStorage.setItem('GLOBAL_VOLUME', action.value)
            const sd = GlobalStorage.get('SOUND_DATA')
            Object.values(newState.musicPlaying).forEach(music => {
                const netVolume = music.volume || 1
                const newVolume = netVolume * action.value
                sd[music.id].volume = newVolume
            })
            return newState
        }

        default: return state
    }
}

export default reducer
