import API from '../../../functions/API'
import { clearForm, setAuth } from './'
import fullUser from '../../../config/graph-request-types/fullUser'

export async function handleGetUserData(authToken, setLoading, setError) {
    setLoading(true)
    try {
        if (!authToken) return
        const response = await API.sendGraphSecure(authToken, {
            query: `query {
                getUser {
                    token
                    expiration
                    user {
                        ${fullUser}
                    }
                }
            }`
        })

        if (response) {
            const { token, user, expiration } = response.data.getUser
            setAuth.call(this, token, user, expiration)
            clearForm.call(this)
            setLoading(false)
        }
    } catch (err) {
        setError('Error Getting user data' + err.message)
        setAuth.call(this, null, null, 'global')
        setLoading(false)
    }
}