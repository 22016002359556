import React from 'react'
import './SelectList.css'

const SelectList = ({ id, items, className, selected, onChange }) => {
    return (
        <select id={id} className={`select-list bg-white c-shade-8 ${className}`} onChange={onChange} value={selected}>
            {items.map((item, i) => {
                const { value, label } = typeof item === 'object' && item !== null ? item : { value: item, label: item }
                return (
                    <option key={i} value={value}>{label}</option>
                )
            })}
        </select>
    )
}

export default SelectList
