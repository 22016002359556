import GlobalStorage from "../../../../functions/GlobalStorage"

export const convertAudioTime = (minutes) => {
    if (minutes) {
        const hrs = minutes / 60
        const hrsSplit = hrs.toString().split('.')
        const minute = hrsSplit[0]
        const percent = hrsSplit[1] ? parseInt(hrsSplit[1]?.slice(0, 2)) : 0
        const sec = Math.ceil((60 * percent) / 100)

        if (parseInt(minute) < 10 && sec < 10) {
            return `0${minute}:0${sec}`;
        }

        if (sec === 60) {
            return `${minute + 1}:00`
        }

        if (parseInt(minute) < 10) {
            return `0${minute}:${sec}`
        }

        if (sec < 10) {
            return `${minute}:0${sec}`
        }

        return `${minute}:${sec}`
    } else {
        return `00:00`
    }
}

export const shouldShowInAdd = (sound, search, currentList) => {
    return (
        (sound.title.includes(search)
            || sound.tags.filter(tag => tag.includes(search)).length > 0)
        && !currentList.find(current => current.id === sound.id)
    )
}

export const getSoundData = (id) => {
    const soundData = GlobalStorage.get('SOUND_DATA') || {}
    return soundData[id]
}

export const setSoundData = (id, sound) => {
    const soundData = GlobalStorage.get('SOUND_DATA') || {}
    soundData[id] = sound
    GlobalStorage.set('SOUND_DATA', soundData)
}