import React from 'react'
import Blobs from '../Blobs'
const Maps = ({ className, clutter }) => {
    return (
        <div className={`landing-feature landing-maps ${className}`} data-scroll>

            <div className="feature-img-container">
                <Blobs reverse />
                <img src="/examples/maps-example@2x.png" alt="example of map designer" />
            </div>

            {clutter.map((item, i) => (
                <img className="clutter-item" key={i} src={item.url} alt='' />
            ))}

            <div className="feature-text-container">
                <div className="feature-text-inner">

                    <h2>Map Designer <span className="coming-soon-span">(coming soon)</span></h2>
                    <p>
                        Design interactive maps for your sessions and build your world from the ground up. Not sure where to start? We can generate a dungeon or world for you to start from or use as is.
                    </p>
                    <a className="landing-cta" href="/login">Join For Free!</a>
                </div>
            </div>
        </div>
    )
}

export default Maps
