import { useCallback, useState } from "react";
import { useAuthState } from "../context/Auth";
import { useCampaignState } from "../context/Campaign";
import { useErrorFunctions } from "../context/Error";
import { useInventoryFunctions } from "../context/Inventory";
import { useWorkspaceFunctions, useWorkspaceState } from "../context/Workspace";
import API from "../functions/API";

const useRenameDocument = (initialText, documentId, kind, setLoading) => {
    const [text, setText] = useState(initialText)

    const { auth } = useAuthState()
    const { socket } = useCampaignState()
    const { windows } = useWorkspaceState()
    const { renameWindow, workspaceDispatch } = useWorkspaceFunctions()
    const { inventoryDispatch } = useInventoryFunctions()
    const { setError } = useErrorFunctions()

    const onChange = useCallback((newText) => {
        const windowIndex = windows.find(window => {
            return window?.propsPassedFromWindow?.documentId === documentId
        })?.index
        if (windowIndex) renameWindow({ index: windowIndex, title: newText })
        if (setLoading) setLoading(true)
        workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
        inventoryDispatch({ type: 'UPDATE', value: { id: documentId, data: { name: newText, kind } } })

        API.sendGraphSecure(auth, {
            query: `mutation {
                    renameDocument(documentId: "${documentId}", name: "${newText}") {
                        name
                    }
                }`
        }).then(() => {
            if (socket) socket.emit("DOCUMENT_CHANGED_NAME", { documentId, name: newText })
            if (windowIndex) workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saved' } })
            if (setLoading) setLoading(false)
        }).catch((err) => {
            console.log(err)
            setError('Error changing document name')
        })

        setText(newText)
    }, [auth, documentId, inventoryDispatch, kind, renameWindow, setError, setLoading, socket, windows, workspaceDispatch])

    return {
        text, setText, onChange
    }
};

export default useRenameDocument;
