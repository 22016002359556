import React from 'react'

const Main = () => {
    return (
        <div className="landing-main">
            <div className="landing-mountain-background"></div>
            <div className="landing-main-inner">
                <div className="col-mobile row full-width centered-vertically">
                    <img className="main-logo" src="/logo.svg" alt="Adventuring Kit Logo" />
                    <div className="col">
                        <h1 className="t-center-mobile t-left">The adventure is only beginning.</h1>
                        <p className="t-center-mobile t-left">
                            Level up your campaigns with with our set of DM tools,
                            play easier with real time dice rolling, syncronized character sheets and encounter maps. <i>Adventuring Kit
                            Helps you play better.</i>
                        </p>
                    </div>
                </div>
                <a className="landing-cta" href="/login">Start Playing Now!</a>
            </div>
        </div>
    )
}

export default Main
