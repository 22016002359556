import API from '../../../functions/API'
import { validateForm, setAuth, clearForm } from './'
import fullUser from '../../../config/graph-request-types/fullUser'

export async function handleLogin(email, password, setLoading, setError) {

    if (validateForm.call(this, { email, password, page: 'login' }, setError)) {
        setLoading("login")
        try {
            const response = await API.sendGraph({
                query: `query {
                        login(email: "${email.toLowerCase()}", password: "${password}") {
                            token
                            expiration
                            user {
                                ${fullUser}
                            }
                        }
                    }`
            })

            if (response) {
                const { token, user, expiration } = response.data.login
                setAuth.call(this, token, user, expiration)
                window.heap.track('USER_LOGIN', { userId: user._id })
                clearForm.call(this)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            setError(err.message)
        }
    }
}