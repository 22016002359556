export function getGeoDataForDiceType(type) {
    switch (type) {
        case "D4": {
            return {
                tab: -0.1,
                af: Math.PI * 7 / 6,
                chamfer: 0.96,
                vertices: [[1, 1, 1], [-1, -1, 1], [-1, 1, -1], [1, -1, -1]],
                faces: [[1, 0, 2, 1], [0, 1, 3, 2], [0, 3, 2, 3], [1, 2, 3, 4]],
                valueMap: [
                    { index: 2, value: 1 },
                    { index: 1, value: 2 },
                    { index: 3, value: 3 },
                    { index: 0, value: 4 },
                ],
                valueRotations: [
                    { value: 1, x: 0.7, y: 0.8, z: -0.05 },
                    { value: 2, x: -2.5, y: -0.77, z: 0 },
                    { value: 3, x: 0.6, y: -0.8, z: -1.6 },
                    { value: 4, x: -1, y: 0, z: -0.8 },
                ],

                finalScale: 0.7,
                scaleFactor: 1.2,
                mass: 300,
                invertUpside: true,

                meshPosXOffset: -0.007,
                meshPosYOffset: 0.015,
                meshPosZOffset: -0.007,

                meshRotXOffset: 0.72,
                meshRotYOffset: 5.5,
                meshRotZOffset: 2.25,

                meshScale: 0.72,
            }
        }

        case "D6": {
            return {
                tab: 0.1,
                af: Math.PI / 4,
                chamfer: 0.96,
                vertices: [[-1, -1, -1], [1, -1, -1], [1, 1, -1], [-1, 1, -1],
                [-1, -1, 1], [1, -1, 1], [1, 1, 1], [-1, 1, 1]],
                faces: [[0, 3, 2, 1, 1], [1, 2, 6, 5, 2], [0, 1, 5, 4, 3],
                [3, 7, 6, 2, 4], [0, 4, 7, 3, 5], [4, 5, 6, 7, 6]],
                valueMap: [
                    { index: 0, value: 1 },
                    { index: 3, value: 2 },
                    { index: 1, value: 3 },
                    { index: 4, value: 4 },
                    { index: 2, value: 5 },
                    { index: 5, value: 6 },
                ],
                valueRotations: [
                    { value: 1, x: -3.15, y: 0, z: 3.15 },
                    { value: 2, x: 0, y: 1.56, z: 1.56 },
                    { value: 3, x: 3, y: 1.55, z: 0.1 },
                    { value: 4, x: -3, y: 4.7, z: 0.15 },
                    { value: 5, x: -1.6, y: 0, z: 0 },
                    { value: 6, x: 0, y: 0, z: -1.58 },
                ],
                finalScale: 0.7,
                scaleFactor: 0.9,
                mass: 300,

                meshPosXOffset: 0,
                meshPosYOffset: 0,
                meshPosZOffset: 0,

                meshRotXOffset: 0,
                meshRotYOffset: 0,
                meshRotZOffset: 0,

                meshScale: 0.53
            }
        }

        case "D8": {
            return {
                tab: 0,
                af: - Math.PI / 4 / 2,
                chamfer: 0.965,
                vertices: [[1, 0, 0], [-1, 0, 0], [0, 1, 0], [0, -1, 0], [0, 0, 1], [0, 0, -1]],
                faces: [[0, 2, 4, 1], [0, 4, 3, 2], [0, 3, 5, 3], [0, 5, 2, 4], [1, 3, 4, 5],
                [1, 4, 2, 6], [1, 2, 5, 7], [1, 5, 3, 8]],
                valueMap: [
                    { index: 7, value: 1 },
                    { index: 3, value: 2 },
                    { index: 6, value: 3 },
                    { index: 2, value: 4 },
                    { index: 5, value: 5 },
                    { index: 1, value: 6 },
                    { index: 4, value: 7 },
                    { index: 0, value: 8 },
                ],
                valueRotations: [
                    { value: 1, x: 3.1, y: -0.8, z: -1.6 },
                    { value: 2, x: 0, y: -2.38, z: -1.6 },
                    { value: 3, x: 0, y: 2.35, z: 1.55 },
                    { value: 4, x: -6.3, y: 2.35, z: -1.55 },
                    { value: 5, x: 0, y: 0.8, z: 1.55 },
                    { value: 6, x: 0, y: 0.8, z: -1.55 },
                    { value: 7, x: 0, y: -0.8, z: 1.55 },
                    { value: 8, x: 0, y: -0.8, z: -1.6 },
                ],
                finalScale: 0.7,
                scaleFactor: 1,
                mass: 340,

                meshPosXOffset: 0,
                meshPosYOffset: 0,
                meshPosZOffset: 0,


                meshRotXOffset: -0.72,
                meshRotYOffset: 0.55,
                meshRotZOffset: -0.22,

                meshScale: 0.59
            }
        }

        case "D10": {
            const vertices = []
            for (let i = 0, b = 0; i < 10; ++i, b += Math.PI * 2 / 10) {
                vertices.push([Math.cos(b), Math.sin(b), 0.105 * (i % 2 ? 1 : -1)]);
            }
            vertices.push([0, 0, -1]);
            vertices.push([0, 0, 1]);

            const data = {
                tab: 0,
                af: Math.PI * 6 / 5,
                chamfer: 0.945,
                vertices,
                faces: [[5, 7, 11, 0], [4, 2, 10, 1], [1, 3, 11, 2], [0, 8, 10, 3], [7, 9, 11, 4],
                [8, 6, 10, 5], [9, 1, 11, 6], [2, 0, 10, 7], [3, 5, 11, 8], [6, 4, 10, 9],
                [1, 0, 2, -1], [1, 2, 3, -1], [3, 2, 4, -1], [3, 4, 5, -1], [5, 4, 6, -1],
                [5, 6, 7, -1], [7, 6, 8, -1], [7, 8, 9, -1], [9, 8, 0, -1], [9, 0, 1, -1]],
                valueMap: [
                    { index: 5, value: 1 },
                    { index: 6, value: 2 },
                    { index: 1, value: 3 },
                    { index: 0, value: 4 },
                    { index: 7, value: 5 },
                    { index: 4, value: 6 },
                    { index: 9, value: 7 },
                    { index: 2, value: 8 },
                    { index: 3, value: 9 },
                    { index: 8, value: 10 },
                    { index: 10, value: 5 },
                ],
                valueRotations: [
                    { value: 1, x: -1.8, y: 0, z: 0.3 },
                    { value: 2, x: 1.2, y: 0, z: 1.66 },
                    { value: 3, x: -1.8, y: 0, z: 2.8 },
                    { value: 4, x: 1.2, y: 0, z: 4.15 },
                    { value: 5, x: -1.8, y: 0, z: 4.05 },
                    { value: 6, x: 1.2, y: 0, z: 2.8 },
                    { value: 7, x: -1.8, y: 0, z: 1.55 },
                    { value: 8, x: 1.2, y: 0, z: 0.35 },
                    { value: 9, x: -1.8, y: 0, z: -0.9 },
                    { value: 10, x: 1.2, y: 0, z: -0.9 },
                ],
                finalScale: 0.7,
                valueMax: 10,
                scaleFactor: 0.9,
                mass: 350,

                meshPosXOffset: 0,
                meshPosYOffset: 0,
                meshPosZOffset: 0,

                meshRotXOffset: 1.27,
                meshRotYOffset: 3.9,
                meshRotZOffset: -0.58,

                meshScale: 0.6,
                calcByPoint: true
            }

            for (let i = 0, b = 0; i < 10; ++i, b += Math.PI * 2 / 10) {
                data.vertices.push([Math.cos(b), Math.sin(b), 0.105 * (i % 2 ? 1 : -1)])
            }

            data.vertices.push([0, 0, -1])
            data.vertices.push([0, 0, 1])

            return data
        }

        case "D100": {
            const vertices = []
            for (let i = 0, b = 0; i < 10; ++i, b += Math.PI * 2 / 10) {
                vertices.push([Math.cos(b), Math.sin(b), 0.105 * (i % 2 ? 1 : -1)]);
            }
            vertices.push([0, 0, -1]);
            vertices.push([0, 0, 1]);

            const data = {
                tab: 0,
                af: Math.PI * 6 / 5,
                chamfer: 0.945,
                vertices,
                faces: [[5, 7, 11, 0], [4, 2, 10, 1], [1, 3, 11, 2], [0, 8, 10, 3], [7, 9, 11, 4],
                [8, 6, 10, 5], [9, 1, 11, 6], [2, 0, 10, 7], [3, 5, 11, 8], [6, 4, 10, 9],
                [1, 0, 2, -1], [1, 2, 3, -1], [3, 2, 4, -1], [3, 4, 5, -1], [5, 4, 6, -1],
                [5, 6, 7, -1], [7, 6, 8, -1], [7, 8, 9, -1], [9, 8, 0, -1], [9, 0, 1, -1]],
                valueMap: [
                    { index: 5, value: 10 },
                    { index: 6, value: 20 },
                    { index: 1, value: 30 },
                    { index: 0, value: 40 },
                    { index: 7, value: 50 },
                    { index: 4, value: 60 },
                    { index: 9, value: 70 },
                    { index: 2, value: 80 },
                    { index: 3, value: 90 },
                    { index: 8, value: 100 },
                    { index: 10, value: 50 },
                ],
                valueRotations: [
                    { value: 10, x: -1.8, y: 0, z: 0.3 },
                    { value: 20, x: 1.2, y: 0, z: 1.66 },
                    { value: 30, x: -1.8, y: 0, z: 2.8 },
                    { value: 40, x: 1.2, y: 0, z: 4.15 },
                    { value: 50, x: -1.8, y: 0, z: 4.05 },
                    { value: 60, x: 1.2, y: 0, z: 2.8 },
                    { value: 70, x: -1.8, y: 0, z: 1.55 },
                    { value: 80, x: 1.2, y: 0, z: 0.35 },
                    { value: 90, x: -1.8, y: 0, z: -0.9 },
                    { value: 100, x: 1.2, y: 0, z: -0.9 },
                ],
                finalScale: 0.7,
                valueMax: 10,
                scaleFactor: 0.9,
                mass: 350,

                meshPosXOffset: 0,
                meshPosYOffset: 0,
                meshPosZOffset: 0,

                meshRotXOffset: 1.27,
                meshRotYOffset: 3.9,
                meshRotZOffset: -0.58,

                meshScale: 0.6,
                calcByPoint: true
            }

            for (let i = 0, b = 0; i < 10; ++i, b += Math.PI * 2 / 10) {
                data.vertices.push([Math.cos(b), Math.sin(b), 0.105 * (i % 2 ? 1 : -1)])
            }

            data.vertices.push([0, 0, -1])
            data.vertices.push([0, 0, 1])

            return data
        }

        case "D12": {
            let p = (1 + Math.sqrt(5)) / 2,
                q = 1 / p

            const data = {
                tab: 0.2,
                af: - Math.PI / 4 / 2,
                chamfer: 0.968,
                vertices: [[0, q, p], [0, q, -p], [0, -q, p], [0, -q, -p], [p, 0, q],
                [p, 0, -q], [-p, 0, q], [-p, 0, -q], [q, p, 0], [q, -p, 0], [-q, p, 0],
                [-q, -p, 0], [1, 1, 1], [1, 1, -1], [1, -1, 1], [1, -1, -1], [-1, 1, 1],
                [-1, 1, -1], [-1, -1, 1], [-1, -1, -1]],
                faces: [[2, 14, 4, 12, 0, 1], [15, 9, 11, 19, 3, 2], [16, 10, 17, 7, 6, 3], [6, 7, 19, 11, 18, 4],
                [6, 18, 2, 0, 16, 5], [18, 11, 9, 14, 2, 6], [1, 17, 10, 8, 13, 7], [1, 13, 5, 15, 3, 8],
                [13, 8, 12, 4, 5, 9], [5, 4, 14, 9, 15, 10], [0, 12, 8, 10, 16, 11], [3, 19, 7, 17, 1, 12]],
                valueMap: [
                    { index: 8, value: 1 },
                    { index: 0, value: 2 },
                    { index: 2, value: 3 },
                    { index: 10, value: 4 },
                    { index: 7, value: 5 },
                    { index: 6, value: 6 },
                    { index: 5, value: 7 },
                    { index: 4, value: 8 },
                    { index: 1, value: 9 },
                    { index: 9, value: 10 },
                    { index: 11, value: 11 },
                    { index: 3, value: 12 },
                ],
                valueRotations: [
                    { value: 1, x: 1.6, y: -2.25, z: 1 },
                    { value: 2, x: 0.5, y: -0.3, z: 1 },
                    { value: 3, x: 2.6, y: -1.6, z: 0 },
                    { value: 4, x: 0.5, y: 0.95, z: 1 },
                    { value: 5, x: 0.5, y: -2.88, z: 1 },
                    { value: 6, x: 0.5, y: -4.1, z: 1 },
                    { value: 7, x: 3.6, y: -4.1, z: 1 },
                    { value: 8, x: 3.6, y: -2.83, z: 1 },
                    { value: 9, x: 3.6, y: 0.95, z: 1 },
                    { value: 10, x: -0.5, y: 4.7, z: 0 },
                    { value: 11, x: -2.6, y: -0.29, z: 1 },
                    { value: 12, x: -1.6, y: 1.55, z: 1 },
                ],
                finalScale: 0.7,
                scaleFactor: 0.9,
                mass: 350,

                meshPosXOffset: 0,
                meshPosYOffset: 0,
                meshPosZOffset: 0,

                meshRotXOffset: 1.18,
                meshRotYOffset: -1.27,
                meshRotZOffset: -0.36,

                meshScale: 0.59
            }

            return data
        }

        case "D20": {
            let t = (1 + Math.sqrt(5)) / 2
            const data = {
                tab: - 0.2,
                af: -Math.PI / 4 / 2,
                chamfer: 0.955,
                vertices: [[-1, t, 0], [1, t, 0], [-1, -t, 0], [1, -t, 0],
                [0, -1, t], [0, 1, t], [0, -1, -t], [0, 1, -t],
                [t, 0, -1], [t, 0, 1], [-t, 0, -1], [-t, 0, 1]],
                faces: [[0, 11, 5, 1], [0, 5, 1, 2], [0, 1, 7, 3], [0, 7, 10, 4], [0, 10, 11, 5],
                [1, 5, 9, 6], [5, 11, 4, 7], [11, 10, 2, 8], [10, 7, 6, 9], [7, 1, 8, 10],
                [3, 9, 4, 11], [3, 4, 2, 12], [3, 2, 6, 13], [3, 6, 8, 14], [3, 8, 9, 15],
                [4, 9, 5, 16], [2, 4, 11, 17], [6, 2, 10, 18], [8, 6, 7, 19], [9, 8, 1, 20]],
                valueMap: [
                    { index: 8, value: 1 },
                    { index: 5, value: 2 },
                    { index: 7, value: 3 },
                    { index: 14, value: 4 },
                    { index: 9, value: 5 },
                    { index: 11, value: 6 },
                    { index: 3, value: 7 },
                    { index: 6, value: 8 },
                    { index: 12, value: 9 },
                    { index: 0, value: 10 },
                    { index: 13, value: 11 },
                    { index: 1, value: 12 },
                    { index: 18, value: 13 },
                    { index: 10, value: 14 },
                    { index: 2, value: 15 },
                    { index: 16, value: 16 },
                    { index: 4, value: 17 },
                    { index: 19, value: 18 },
                    { index: 17, value: 19 },
                    { index: 15, value: 20 },
                ],
                valueRotations: [
                    { value: 1, x: 9, y: 6.25, z: 4.71 },
                    { value: 2, x: 8.5, y: 2.85, z: 6.8 },
                    { value: 3, x: 4.7, y: 3.68, z: 7.5 },
                    { value: 4, x: 1.55, y: 2.6, z: 8.2 },
                    { value: 5, x: 2.2, y: 0.3, z: 6.85 },
                    { value: 6, x: -0.95, y: -0.93, z: 6.85 },
                    { value: 7, x: 2.2, y: 6, z: -0.5 },
                    { value: 8, x: 2.75, y: 3.15, z: -1.6 },
                    { value: 9, x: 2.2, y: 0.95, z: 2.6 },
                    { value: 10, x: 2.2, y: 3.45, z: -0.55 },
                    { value: 11, x: -0.9, y: 3.45, z: -0.55 },
                    { value: 12, x: 8.5, y: 2.2, z: -0.55 },
                    { value: 13, x: -3.5, y: 6.3, z: 1.6 },
                    { value: 14, x: -4, y: 3.45, z: 2.6 },
                    { value: 15, x: 2.25, y: -0.95, z: 0.58 },
                    { value: 16, x: 2.2, y: -3.45, z: -2.6 },
                    { value: 17, x: 1.65, y: 0.5, z: -1.25 },
                    { value: 18, x: -1.5, y: -0.5, z: -1.9 },
                    { value: 19, x: -0.95, y: -3.45, z: 0.55 },
                    { value: 20, x: -3.5, y: 3.15, z: 1.55 },
                ],
                finalScale: 0.75,
                scaleFactor: 1,
                mass: 400,

                meshPosXOffset: 0,
                meshPosYOffset: 0,
                meshPosZOffset: 0,

                meshRotXOffset: 0,
                meshRotYOffset: 0.32,
                meshRotZOffset: 0,

                meshScale: 0.7
            }

            return data
        }

        default:
            return null
    }

}