import { useCampaignState } from "../../context/Campaign"
import { useWorkspaceFunctions } from "../../context/Workspace"
import { getSoundData, setSoundData } from "../../pages/Workspace/partials/SoundControls/functions"

const useSoundboardCommunications = () => {
    const { socket } = useCampaignState()
    const { workspaceDispatch } = useWorkspaceFunctions()

    const soundBoardCommunication = () => {
        socket.on("UPDATE_PLAYING_SONG", newSoundData => {
            const data = JSON.parse(newSoundData)
            workspaceDispatch({ type: 'UPDATE_PLAYING_SONG', value: { ...data } })
        })

        socket.on("UPDATE_SONG_TRACKING", newSoundData => {
            const data = JSON.parse(newSoundData)
            const sd = getSoundData(data.id) || {}
            sd.currentTime = data.currentTime
            setSoundData(data.id, sd)
        })

        socket.on("REMOVE_PLAYING_SONG", newSoundData => {
            const data = JSON.parse(newSoundData)
            workspaceDispatch({ type: 'REMOVE_PLAYING_SONG', value: { ...data } })
            const sd = getSoundData(data.id)
            sd && sd.pause()
            setSoundData(data.id, undefined)
        })
    }

    return soundBoardCommunication
}

export default useSoundboardCommunications