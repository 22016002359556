import { useCallback } from "react"
import { globalTokenPlacer_Atom } from '../../atoms'
import { useAtom } from 'jotai'
import { getPos } from "../../../../functions/CanvasHelpers"
import shortid from "shortid"
import { grid } from "../../../../functions/helpers"
import { useAuthState } from "../../../../context/Auth/index"

const usePlaceToken = (tokens, camera, updateDoc, windowPos, isFullScreen, gridSize, showGrid, gridLeft, gridTop, clearSelection) => {
    const [tokenPlacer, setTokenPlacer] = useAtom(globalTokenPlacer_Atom)
    const { user } = useAuthState()

    const placeToken = useCallback((e) => {
        const windowTop = isFullScreen ? 0 : windowPos.top + 74,
            windowLeft = isFullScreen ? 0 : windowPos.left


        if (tokenPlacer?.length) {
            tokenPlacer.forEach(tokenData => {
                const xx = getPos(e.clientX - windowLeft - (gridLeft * camera.zoom), camera.x, camera.zoom) - gridSize / 2
                const yy = getPos(e.clientY - windowTop - (gridLeft * camera.zoom), camera.y, camera.zoom) - gridSize / 2

                const offsetX = (tokenData?.x ?? 0) - (tokenData?.center?.x ?? 0)
                const offsetY = (tokenData?.y ?? 0) - (tokenData?.center?.y ?? 0)

                const id = shortid.generate()

                let newX = xx + offsetX
                let newY = yy + offsetY

                if (showGrid) {
                    newX = grid(newX + gridSize / 2, gridSize)
                    newY = grid(newY + gridSize / 2, gridSize)
                }

                const newToken = ({
                    ...tokenData,
                    id,
                    ownerId: user._id,
                    x: newX,
                    y: newY
                })

                updateDoc({ p: ['tokens', tokens.length], li: newToken })
                clearSelection()
            })


            setTokenPlacer(null)
        }
    }, [camera.x, camera.y, camera.zoom, clearSelection, gridLeft, gridSize, isFullScreen, setTokenPlacer, showGrid, tokenPlacer, tokens.length, updateDoc, user._id, windowPos.left, windowPos.top])
    return { placeToken, tokenPlacer }
}

export default usePlaceToken