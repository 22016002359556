const getDrawingPos = (points) => {
    const [x1, y1, x2, y2] = points

    const invertX = x1 > x2,
        invertY = y1 > y2

    const left = invertX ? x2 : x1,
        top = invertY ? y2 : y1,
        width = invertX ? x1 - x2 : x2 - x1,
        height = invertY ? y1 - y2 : y2 - y1

    return {
        left, top,
        width, height
    }
}

export default getDrawingPos