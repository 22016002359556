import { useCallback, useState } from 'react'
import { getPos } from '../../../../functions/CanvasHelpers'
import { getRelativeMousePos } from '../getRelativeMousePos'

const useBoundingBox = (camera, windowPos, isFullScreen, gridLeft, gridTop) => {
    const [boundingBoxStart, setBoundingBoxStart] = useState(null)
    const [boundingBox, setBoundingBox] = useState(null)

    const startBB = useCallback((e) => {
        const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
        setBoundingBoxStart({
            x: getPos(xx, camera.x, camera.zoom),
            y: getPos(yy, camera.y, camera.zoom),
        })
    }, [camera.x, camera.y, camera.zoom, isFullScreen, windowPos])

    const updateBB = useCallback((e) => {
        if (boundingBoxStart) {
            const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
            const x1 = boundingBoxStart.x,
                y1 = boundingBoxStart.y,
                x2 = getPos(xx, camera.x, camera.zoom),
                y2 = getPos(yy, camera.y, camera.zoom)

            const invertX = x1 > x2,
                invertY = y1 > y2

            const left = invertX ? x2 : x1,
                top = invertY ? y2 : y1,
                width = invertX ? x1 - x2 : x2 - x1,
                height = invertY ? y1 - y2 : y2 - y1

            if (width > 10 || height > 10) {
                setBoundingBox({
                    left,
                    top,
                    width,
                    height
                })
            } else {
                setBoundingBox(null)
            }
        }
    }, [boundingBoxStart, camera.x, camera.y, camera.zoom, isFullScreen, windowPos])

    const clearBB = useCallback((e) => {
        setBoundingBoxStart(null)
        setBoundingBox(null)
    }, [])

    return ({
        startBB,
        updateBB,
        clearBB,
        boundingBox
    })
}

export default useBoundingBox