import { useEffect, useState } from "react"
import { useWorkspaceFunctions } from "../context/Workspace"
import { getStartingData } from "../functions/helpers"
import ShareDB from "../functions/ShareDB"

const useSaveDocument = (id, initialValue, setData, kind, windowIndex) => {
    const [doc, setDoc] = useState(null)
    const [initialLoadComplete, setInitialLoadComplete] = useState(false)
    const { workspaceDispatch } = useWorkspaceFunctions()

    useEffect(() => {
        const connection = ShareDB.connect()
        const doc = connection.get('sharedDocData', id)
        setDoc(doc)

        const startingData = getStartingData(initialValue, kind)
        const loadOldData = () => {
            if (Object.keys(doc.data)?.length === 0 && startingData) {
                doc.submitOp({ p: [], oi: { ...startingData } })
            } else {
                setInitialLoadComplete(true)
                workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saved' } })
            }
        }
        doc.subscribe((err) => {
            if (err) console.log(err)

            if (!doc.type) {
                doc.create({}, (error) => {
                    if (error) console.error(error)
                    loadOldData()
                })
            } else {
                if (doc.data) {
                    setData(getStartingData(doc.data, kind))
                    setInitialLoadComplete(true)
                    workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saved' } })
                } else {
                    loadOldData()
                }
            }
        })

        const onOp = () => {
            setInitialLoadComplete(true)
            workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saved' } })
            const keys = Object.keys(doc.data)
            const newObj = {}
            keys.forEach(key => {
                const oldVal = doc.data[key]
                if (typeof oldVal === 'object' && oldVal !== null) {
                    if (Array.isArray(oldVal)) {
                        newObj[key] = [...oldVal]
                    } else {
                        newObj[key] = { ...oldVal }
                    }
                } else {
                    newObj[key] = oldVal
                }
            })
            setData(getStartingData(newObj, kind))
        }
        doc.on('op', onOp)


        const onError = (error) => {
            console.log('doc error', error)
        }
        doc.on('error', onError)

        return () => {
            doc.unsubscribe()
            doc.off('op', onOp)
            setDoc(null)
        }

    }, [id, initialValue, kind, setData, windowIndex, workspaceDispatch])

    return {
        doc,
        initialLoadComplete
    }
}

export default useSaveDocument