export default function popSelectedDice(key) {
    if (key) {
        this.selectedDice.reverse()
        const poppingDiceIndex = this.selectedDice.findIndex(dice => dice.type === key)
        if (poppingDiceIndex > -1) {
            const popped = this.selectedDice[poppingDiceIndex]
            this.selectedDice.splice(poppingDiceIndex, 1)
            if (this.selectedDice.length === 0) this.UI.stopRoll()

            this.removeDiceFromWorld(popped)
        }
        this.selectedDice.reverse()
    } else {
        this.selectedDice.forEach(die => {
            this.removeDiceFromWorld(die)
        })
        this.selectedDice = []
        this.UI.stopRoll()
    }

    if (this.selectedDice.length === 0) {
        this.setDiceInHand(false)
    }
}