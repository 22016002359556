import React, { useState, useEffect } from 'react'

const ChatUserDialogue = ({ id, name, x, y, addHovered, removeHovered }) => {
    const [dialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        if (id) setDialogOpen(true)
        else setDialogOpen(false)
    }, [id, setDialogOpen])

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={() => addHovered('dialog')}
            onMouseLeave={() => removeHovered('dialog')}
            className={`chat-user-list-dialogue bg-white bc-main c-shade-7 shadow-1 ${dialogOpen && "open"}`}
            style={{ top: y, left: x + 24 }}
        >
            <div className="row">
                <p>{name}</p>
            </div>
        </div >
    )
}

export default ChatUserDialogue
