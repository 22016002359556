import getUpsideValue from "../../functions/getUpsideValue"

export default function finishDice(die) {
    clearTimeout(die.finishingTimer)
    clearTimeout(die.finishingTimerMax)

    const dieIndex = this.rolledDice.findIndex(searchDie => searchDie.rollingId === die.rollingId)
    this.rolledDice.splice(dieIndex, 1)

    if (!(die.networkDie || die.rollingGroup.disableAnimations)) {
        die.diceValue = getUpsideValue(die, this.camera)
    }

    this.setDiceFinishedPosition(die)
    this.setRolling(false)

    const keepBest = parseInt(die.rollingGroup.keepBest)

    if (die.rollingGroup.disableAnimations && (keepBest === 0 || die.keep)) {
        die.position.x = die.rollingGroup.pos.x + die.finishedPosition.x
        die.position.y = die.rollingGroup.pos.y + die.finishedPosition.y
        die.scale.x = die.finalScale
        die.scale.y = die.finalScale
        die.scale.z = die.finalScale

        if (die.rollingGroup.explodesOn > 0 && die.diceValue >= die.rollingGroup.explodesOn && !die.exploded) {
            this.explodeDiceEffect(die)
            die.exploded = true
        }
    }

    die.rollingGroup.dieFinished += 1
    // All dice in this group are finished, cycle through them all and do any neccesary work for the options selected
    if (die.rollingGroup.dieFinished === die.rollingGroup.dice.length) {
        die.rollingGroup.exploding = false
        if (!die.rollingGroup.disableAnimations && die.rollingGroup.explodesOn > 0 && die.rollingGroup.dice.length < 100) {
            die.rollingGroup.dice.forEach(dieInGroup => {
                if (dieInGroup.diceValue >= die.rollingGroup.explodesOn && !dieInGroup.exploded) {
                    this.explodeDiceEffect(dieInGroup)
                    this.explodeDice(dieInGroup)
                }
            })
        }

        if (die.rollingGroup.exploding) return

        if (keepBest !== 0) {
            const sortValue = keepBest > 0 ? -1 : 1
            die.rollingGroup.dice.sort((a, b) => {
                return a.diceValue > b.diceValue ? sortValue : sortValue * -1
            })
            // copy finished dice to a new array and sort the new one by dice value descending
            let keepDice = [...die.rollingGroup.dice]
            //chop the new array at the keep best value
            keepDice.splice(Math.abs(keepBest))

            // loop threw the chopped array and get the total value of all dice, marking each dice with a "keep" value
            keepDice.forEach(keepDie => {
                keepDie.keep = true
                const succeedsOnDiceOrRoll = die.rollingGroup.succeedsOnDiceOrRoll
                if (die.rollingGroup.succeedsOn !== 0) {
                    const succeedsOnGreaterOrLess = die.rollingGroup.succeedsOnGreaterOrLess
                    if (
                        succeedsOnDiceOrRoll === 'Dice' &&
                        (succeedsOnGreaterOrLess === 'greater' ?
                            keepDie.diceValue >= die.rollingGroup.succeedsOn :
                            keepDie.diceValue <= die.rollingGroup.succeedsOn))
                        die.rollingGroup.totalValue++
                    else if (succeedsOnDiceOrRoll === 'Roll') {
                        die.rollingGroup.totalValue += keepDie.diceValue
                    } else {
                        keepDie.children[0].material.opacity = 0.5
                        keepDie.children[0].material.transparent = true
                    }
                } else {
                    die.rollingGroup.totalValue += keepDie.diceValue
                }
            })

            die.rollingGroup.dice.forEach((die, i) => {
                if (!!die.keep) {
                    die.rollingGroupIndex = i
                } else if (die.rollingGroup.disableAnimations) {
                    this.removeDiceFromWorld(die)
                }
            })
        } else {
            const succeedsOnDiceOrRoll = die.rollingGroup.succeedsOnDiceOrRoll
            if (die.rollingGroup.succeedsOn !== 0) {
                die.rollingGroup.dice.forEach(dieInGroup => {
                    const succeedsOnGreaterOrLess = die.rollingGroup.succeedsOnGreaterOrLess
                    if (
                        succeedsOnDiceOrRoll === 'Dice' &&
                        (succeedsOnGreaterOrLess === 'greater' ?
                            dieInGroup.diceValue >= die.rollingGroup.succeedsOn :
                            dieInGroup.diceValue <= die.rollingGroup.succeedsOn)
                    ) {
                        dieInGroup.children[0].material.opacity = 0.5
                        dieInGroup.children[0].material.transparent = true
                    } else {
                        dieInGroup.rollingGroup.totalValue += dieInGroup.diceValue
                    }
                })
            }
        }

        die.rollingGroup.dice.forEach(die => {
            if (die.body) this.world.remove(die.body)
            this.finishedDice.push(die)
            if (parseInt(keepBest) === 0 && die.rollingGroup.succeedsOn === 0) {
                if (die.rollingGroup.percentileRoll && die.type === 'D10' && die.diceValue === 10) die.rollingGroup.totalValue += 0
                else if (die.rollingGroup.percentileRoll && die.type === 'D100' && die.diceValue === 100) die.rollingGroup.totalValue += 0
                else die.rollingGroup.totalValue += die.diceValue
            }

            const { type, diceValue: value, rollingId: id, keep, exploded, explosionParent, explosionAncestor } = die
            die.rollingGroup.networkDice.push({ type, value, id, keep, exploded, explosionParent, explosionAncestor })
        })

        const plusOrMinus = die.rollingGroup.addOrSub !== 0 ? die.rollingGroup.addOrSub > 0 ? 'After adding ' : 'After subtracting ' : ''
        const addOrSubValue = die.rollingGroup.addOrSub !== 0 ? `${die.rollingGroup.addOrSub} ` : ''
        const successMessage = die.rollingGroup.succeedsOn !== 0 && die.rollingGroup.succeedsOnDiceOrRoll === 'Dice'
            ? die.rollingGroup.totalValue === 1 ? ' success'
                : ' successes'
            : ''
        die.rollingGroup.message = `${plusOrMinus}${addOrSubValue}${die.user || 'You'} rolled ${die.rollingGroup.totalValue}${successMessage}`

        die.rollingGroup.finished = true
        if (die.rollingGroup.percentileRoll && die.rollingGroup.totalValue === 0)
            die.rollingGroup.totalValue = 100

        const dataToSend = {
            ...die.rollingGroup.networkObject,
            message: 'ROLL_DONE',
            name: die.rollingGroup.name || this.user.name,
            label: die.rollingGroup.label || '',
            skin: this.user.diceSkin,
            dice: die.rollingGroup.networkDice,
            explosionCount: die.rollingGroup.explosionCount,
            percentileRoll: die.rollingGroup.percentileRoll,
            totalValue: die.rollingGroup.totalValue,
            privateRoll: die.rollingGroup.privateRoll
        }

        if (!die.networkDie) {
            this.sendDiceRoll(dataToSend)

            dataToSend.localRoll = true
            this.addRollToHistory(dataToSend)
        }

        die.rollingGroup.deathTimer = setTimeout(() => {
            this.removeGroup(die.rollingGroup)
        }, 5000)
    }
}