import React from 'react'
import { IconButton } from '../../components/Buttons'
import SocialIcons from '../../components/SocialIcons/SocialIcons'

const Header = ({ navMenuOpen, setNavMenuOpen }) => {
    return (
        <header className="landing-header">
            <div className="mobile-nav">
                <IconButton className="nav-hamburger" img="/hamgurger.svg" onClick={() => setNavMenuOpen(true)} />
                <div className={`mobile-nav-menu ${navMenuOpen && 'open'}`}>
                    <div className="mobile-nav-header bg-white">
                        <div className="mobile-nav-logo">
                            <img src="/logo.svg" alt="Adventuring Kit Logo" />
                            <p className="c-shade-9">Adventuring Kit</p>
                        </div>
                        <IconButton icon="close" onClick={() => setNavMenuOpen(false)} />
                    </div>
                    <Nav />

                    <div className="nav-mobile-background"></div>
                </div>
            </div>
            <div className="nav desktop-nav-menu">
                <Nav />
            </div>
        </header>
    )
}

export default Header


const Nav = () => (
    <>
        <nav>
            <div className="left">
                <SocialIcons />
            </div>
            <div className="landing-login-nav">
                <ul className="right">
                    <li><a href="/login?login=true" className="c-shade-6">Log Back In</a></li>
                    <li><a className="landing-cta" href="/login">Sign Up</a></li>
                </ul>
            </div>
        </nav>
    </>
)
