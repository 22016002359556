import { useCallback, useState } from 'react'
import { distanceTo } from '../../../../functions/CanvasHelpers'

const usePan = (camera, setCamera) => {
    const [panStartCoords, setPanStartCoords] = useState(null)
    const [cameraOffsetStart, setCameraOffsetStart] = useState(null)

    const pan = useCallback((e) => {
        panFunc(e, panStartCoords, cameraOffsetStart, setCamera)
    }, [cameraOffsetStart, panStartCoords, setCamera])

    const startPan = useCallback((val) => {
        setPanStartCoords(val)
        setCameraOffsetStart({ x: camera.x, y: camera.y })
    }, [camera.x, camera.y])

    const stopPan = useCallback(() => {
        setPanStartCoords(null)
        setCameraOffsetStart(null)
    }, [])

    return ({
        pan,
        startPan,
        stopPan
    })
}

export default usePan


function panFunc(e, dragStartCoords, cameraOffsetStart, setCamera) {
    if (dragStartCoords && cameraOffsetStart) {
        const dist = distanceTo(e.clientX, e.clientY, dragStartCoords.x, dragStartCoords.y)
        const angle = Math.atan2(e.clientY - dragStartCoords.y, e.clientX - dragStartCoords.x)
        const offsetX = cameraOffsetStart.x + Math.cos(angle) * dist
        const offsetY = cameraOffsetStart.y + Math.sin(angle) * dist
        setCamera((camera) => {
            return { ...camera, x: offsetX, y: offsetY }
        })
    }
}