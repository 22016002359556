import { useCallback } from 'react'
import { useWorkspaceFunctions } from '../../../../context/Workspace/index'

const useEditSelectedToken = (data, doc, selectedToken, windowIndex, submitOp, modalState, setModalState) => {
    const { workspaceDispatch } = useWorkspaceFunctions()

    const editSingleToken = useCallback((update, targetToken) => {
        const tokenIndex = data.tokens.findIndex(token => token.id === targetToken.id)
        workspaceDispatch({ type: 'SET_WINDOW_SAVING', value: { index: windowIndex, saving: 'saving' } })
        const updatedToken = { ...data.tokens[tokenIndex], ...update }
        Object.keys(update).forEach(key => {
            submitOp(doc, {
                p: ['tokens', tokenIndex, key],
                od: data.tokens?.[tokenIndex]?.[key],
                oi: update?.[key]
            })
        })

        return updatedToken
    }, [data.tokens, doc, submitOp, windowIndex, workspaceDispatch])


    const editSelectedToken = useCallback((update, multi) => {
        if (multi) {
            const newTokens = selectedToken.map(targetToken => editSingleToken(update, targetToken))
            setModalState({ ...modalState, selectedToken: newTokens })
        } else {
            editSingleToken(update, selectedToken)
            setModalState({ ...modalState, selectedToken: { ...modalState.selectedToken, ...update } })
        }
    }, [editSingleToken, modalState, selectedToken, setModalState])

    return { editSelectedToken, editSingleToken }
}

export default useEditSelectedToken