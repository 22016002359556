import React from 'react'

const Notes = () => (
  <svg id="notes-icon" data-name="notes-icon" xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
    <g>
      <path d="M139.75,10.24H166.2A8.755,8.755,0,0,1,174.954,19V31.156a9.1,9.1,0,0,1-9.088,9.1H139.75Z" transform="translate(-86.274 -6.322)" fill="#ffedd6" />
      <path d="M174.954,19A8.755,8.755,0,0,0,166.2,10.24H139.75v17.1h35.2Z" transform="translate(-86.274 -6.322)" fill="#ead6c1" />
      <path d="M139.75,176.551H166.2a8.751,8.751,0,0,0,8.751-8.755V155.623h0a9.1,9.1,0,0,0-9.088-9.1H139.75Z" transform="translate(-86.274 -90.454)" fill="#ffedd6" />
      <path d="M166.2,197.386H139.75V180.27h35.2v8.346a8.755,8.755,0,0,1-8.751,8.77Z" transform="translate(-86.274 -111.289)" fill="#ead6c1" />
      <path d="M35.82,18.97V83.639a8.755,8.755,0,0,0,8.755,8.755h57.467a8.755,8.755,0,0,1-8.755-8.77V18.955h0a8.755,8.755,0,0,1,8.755-8.755H44.575A8.759,8.759,0,0,0,35.82,18.97Z" transform="translate(-22.113 -6.297)" fill="#ffedd6" />
      <path d="M8.154,73.31a4.427,4.427,0,0,0-1.427,5.017,15.785,15.785,0,0,0,4.232,6.394c3.36,2.8,8.349,7.852,8.349,7.852h5.357S29.445,63.15,9.719,46.7a3.52,3.52,0,0,0-5.74,2.3,77.222,77.222,0,0,0-.314,14.614A4.427,4.427,0,0,0,5.9,67.126l5.927,3.341Z" transform="translate(-2.135 -28.329)" fill="#e67575" />
      <path d="M23.42,134.627c3.356,2.816,8.2,7.73,8.2,7.73h5.357a62.755,62.755,0,0,0,.057-15.827A26.376,26.376,0,0,1,23.42,134.627Z" transform="translate(-14.458 -78.113)" fill="#c66" />
      <path d="M18.846,177.184a10.332,10.332,0,0,0-2.3,6.486v4.592a4.779,4.779,0,0,0,4.764,4.791H38.629a4.779,4.779,0,0,0,4.776-4.779v-4.592a10.332,10.332,0,0,0-2.3-6.486l-3.2-3.945H22.049Z" transform="translate(-10.217 -106.955)" fill="#b4e1e9" />
      <path d="M43.686,193.052h5.87a4.779,4.779,0,0,0,4.776-4.779v-4.592a10.332,10.332,0,0,0-2.3-6.486l-3.2-3.945H34.25v2.809s1.959,5.239,6.654,7.622C44.264,185.376,44.773,189.773,43.686,193.052Z" transform="translate(-21.144 -106.955)" fill="#324856" />
      <path d="M22.48,161.512a4.5,4.5,0,0,1,4.5-4.515h13.3a4.5,4.5,0,1,1,0,9h-13.3A4.5,4.5,0,0,1,22.48,161.512Z" transform="translate(-13.878 -96.92)" fill="#b4e1e9" />
      <path d="M52.934,161.511a4.5,4.5,0,0,1-4.5,4.5H56.3a4.508,4.508,0,0,0,0-9.015H48.43a4.5,4.5,0,0,1,4.5,4.511Z" transform="translate(-29.898 -96.923)" fill="#6eadc6" />
      <path d="M16.55,200.48v4.592h0a4.775,4.775,0,0,0,4.764,4.791H38.625a4.779,4.779,0,0,0,4.779-4.776V200.5Z" transform="translate(-10.217 -123.766)" fill="#6eadc6" />
      <path d="M59.073,46.318h0a2.679,2.679,0,1,1,0-5.319h35.87a2.679,2.679,0,1,1,0,5.319Z" transform="translate(-34.618 -25.299)" fill="#647682" />
      <path d="M60.078,76.153a2.679,2.679,0,0,1,0-5.323h35.87a2.679,2.679,0,0,1,0,5.323Z" transform="translate(-35.622 -43.727)" fill="#647682" />
      <path d="M99.878,105.953a2.679,2.679,0,0,1,0-5.323h20.64a2.679,2.679,0,0,1,0,5.323Z" transform="translate(-60.193 -62.124)" fill="#647682" />
      <path d="M65.067,200.48H51.64c3.356,1.7,3.869,6.1,2.782,9.364h5.866a4.779,4.779,0,0,0,4.779-4.776Z" transform="translate(-31.88 -123.766)" fill="#324856" />
      <path d="M57.412,37.58h-.1a3.98,3.98,0,0,0,.1,7.959H93.286a3.98,3.98,0,0,0,0-7.959Zm0,5.323h-.057a1.343,1.343,0,0,1,.057-2.679H93.343a1.343,1.343,0,0,1-.057,2.679Z" transform="translate(-32.957 -23.2)" fill="#21262c" />
      <path d="M57.5,75.339H93.369a3.98,3.98,0,0,0,0-7.959H57.5a3.98,3.98,0,1,0,0,7.959ZM93.369,72.7H57.5a1.343,1.343,0,0,1,0-2.679H93.369a1.343,1.343,0,0,1,0,2.679Z" transform="translate(-33.04 -41.597)" fill="#21262c" />
      <path d="M97.3,97.18a3.98,3.98,0,1,0,0,7.959h20.64a3.98,3.98,0,1,0,0-7.959Zm-1.347,3.98A1.343,1.343,0,0,1,97.3,99.82h20.64a1.343,1.343,0,0,1,0,2.679H97.3a1.343,1.343,0,0,1-1.343-1.343Z" transform="translate(-57.611 -59.994)" fill="#21262c" />
      <path d="M.218,39.571a5.74,5.74,0,0,0,2.9,4.569L7.328,46.5l-2.1,1.63A5.782,5.782,0,0,0,3.348,54.64c1.63,4.733,6.084,8.216,6.987,9.031a6.165,6.165,0,0,0-.865.574,5.816,5.816,0,0,0-.9,8.173,12.119,12.119,0,0,0-3.547,8.495V85.5A6.122,6.122,0,0,0,11.1,91.593H79.933A10.083,10.083,0,0,0,90,81.521V69.364A10.435,10.435,0,0,0,79.6,58.945H72.5V39.43h7.1A10.427,10.427,0,0,0,90,29.029V16.868A10.083,10.083,0,0,0,79.933,6.8H63.609a1.32,1.32,0,1,0,0,2.636h9.536a10.041,10.041,0,0,0-3.283,7.435V81.537a10.037,10.037,0,0,0,3.283,7.435H33.433a6.069,6.069,0,0,0,1.079-3.444V80.936c0-4.592-3.547-8.495-3.547-8.495a5.816,5.816,0,0,0-4.557-9.478H24.314A65.17,65.17,0,0,0,22.4,44.569a46.324,46.324,0,0,0-7.37-16v-11.7a7.446,7.446,0,0,1,7.435-7.435H58.313a1.32,1.32,0,0,0,0-2.636H22.466A10.087,10.087,0,0,0,12.39,16.868v8.48a39.558,39.558,0,0,0-3.968-3.8,4.753,4.753,0,0,0-4.867-.765A4.848,4.848,0,0,0,.543,24.724,77.63,77.63,0,0,0,.218,39.571Zm72.276-2.763V16.734a7.438,7.438,0,1,1,14.875.134l0,12.161A7.791,7.791,0,0,1,79.6,36.808Zm0,44.713V61.593h7.1a7.791,7.791,0,0,1,7.783,7.783V81.533a7.435,7.435,0,1,1-14.87,0ZM7.657,80.909a9.838,9.838,0,0,1,2.977-6.857,5.812,5.812,0,0,0,2.472.551h13.3a5.8,5.8,0,0,0,2.472-.551,11.1,11.1,0,0,1,2.885,5.525H11.916a1.32,1.32,0,1,0,0,2.636H31.875v3.268a3.467,3.467,0,0,1-3.444,3.444H11.1a3.444,3.444,0,0,1-3.444-3.444ZM9.922,68.783A3.184,3.184,0,0,1,13.106,65.6h13.3a3.184,3.184,0,1,1,0,6.367h-13.3a3.184,3.184,0,0,1-3.184-3.184ZM3.165,25.026a2.181,2.181,0,0,1,1.378-1.8,2.147,2.147,0,0,1,2.2.344C20.862,35.343,22,54.185,21.67,62.963H13.439c-6.406-4.89-7.554-9.042-7.607-9.184a.1.1,0,0,0,0-.031,3.107,3.107,0,0,1,1.006-3.52l3.673-2.847A1.351,1.351,0,0,0,10.852,47a1.316,1.316,0,0,0-.517-1.814L4.412,41.844a3.1,3.1,0,0,1-1.565-2.476,75.16,75.16,0,0,1,.318-14.342Z" transform="translate(-0.004 -4.195)" fill="#21262c" />
      <rect width="90" height="90" fill="none" />
    </g>
  </svg>
)

export default Notes
