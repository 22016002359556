import { useCallback, useState } from "react"
import { useAuthState } from "../../../../context/Auth/index"
import { useInventoryState } from "../../../../context/Inventory/index"
import { getIconColors } from "../../../../functions/helpers"
import useGetDocumentCounts from "../../../../hooks/useGetDocumentCounts"
import useOpenWindow from "../../../../hooks/useOpenWindow"
import { CardButton, TextButton } from "../../../Buttons/index"
import FoldersList from "../../../Chest/FoldersList"
import Icon from "../../../Icon/index"
import Tab from "../../../TabNavigation/Tab"
import TabNavigation from "../../../TabNavigation/TabNavigation"

const AddDocumentView = ({ addDocument }) => {
    const { folders, allDocuments } = useInventoryState()
    const { user } = useAuthState()
    const openWindow = useOpenWindow()

    const {
        PDFCount, maxPDFs,
        noteCount, maxNotes,
        mapCount, maxMaps,
        pictureCount, maxPictures,
        soundBoardCount, maxSoundboards
    } = useGetDocumentCounts(allDocuments)

    const openNoteWindow = useCallback((window) => {
        openWindow(window, null, { documentOwnerId: user._id, onCreate: (doc) => addDocument({ _id: doc._id, kind: doc.kind, documentOwnerId: user._id }) })
    }, [addDocument, openWindow, user._id])

    const [activeTab, setActiveTab] = useState(0)

    return (
        <div>
            <TabNavigation tabs={["Add New", "Use Existing"]} activeTab={activeTab} setTab={setActiveTab} />
            <Tab active={activeTab === 0}>
                <div className="col full-width padding-1">
                    {!user.subscription.active && <TextButton onClick={() => openWindow('premiumSignup')} className="t-small t-center t-clear-transform t-light"><span className="c-blue c-bold c-hover-dark-blue">Click here</span> to upgrade to premium and get unlimited documents</TextButton>}
                    <div className="row wrap centered-horizontally">
                        <div className="col padding-1">
                            <CardButton
                                disabled={!user.subscription.active && noteCount >= maxNotes}
                                className={`padding-0 size-4 ${getIconColors('note')} border-thick centered`}
                                onClick={() => openNoteWindow('note')}>
                                <Icon value="ink" className="size-2" />
                            </CardButton>
                            <p className="t-small full-width t-centered">Create Note</p>
                            {!user.subscription.active && <p className={`t-small t-center ${noteCount >= maxNotes && 'c-red'}`}>{noteCount}/{maxNotes} left</p>}
                        </div>

                        <div className="col padding-1">
                            <CardButton
                                disabled={!user.subscription.active && PDFCount >= maxPDFs}
                                className={`padding-0 size-4 ${getIconColors('PDF')} border-thick centered`}
                                onClick={() => openNoteWindow('PDF')}>
                                <Icon value="parchment" className="size-2" />
                            </CardButton>
                            <p className="t-small full-width t-centered">Create Sheet</p>
                            {!user.subscription.active && <p className={`t-small t-center ${PDFCount >= maxPDFs && 'c-red'}`}>{PDFCount}/{maxPDFs} left</p>}
                        </div>

                        <div className="col padding-1">
                            <CardButton
                                disabled={!user.subscription.active && mapCount >= maxMaps}
                                className={`padding-0 size-4 ${getIconColors('map')} border-thick centered`}
                                onClick={() => openNoteWindow('Map')}>
                                <Icon value="map" className="size-2" />
                            </CardButton>
                            <p className="t-small full-width t-centered">Create Map</p>
                            {!user.subscription.active && <p className={`t-small t-center ${mapCount >= maxMaps && 'c-red'}`}>{mapCount}/{maxMaps} left</p>}
                        </div>
                        <div className="col padding-1">
                            <CardButton
                                disabled={!user.subscription.active && pictureCount >= maxPictures}
                                className={`padding-0 size-4 ${getIconColors('picture')} border-thick centered`}
                                onClick={() => openNoteWindow('picture')}>
                                <Icon value="media" className="size-2" />
                            </CardButton>
                            <p className="t-small full-width t-centered">Save Picture</p>
                            {!user.subscription.active && <p className={`t-small t-center ${pictureCount >= maxPictures && 'c-red'}`}>{pictureCount}/{maxPictures} left</p>}
                        </div>
                        <div className="col padding-1 centered">
                            <CardButton
                                disabled={!user.subscription.active && soundBoardCount >= maxSoundboards}
                                className={`padding-0 size-4 ${getIconColors('soundboard')} border-thick centered`}
                                onClick={() => openNoteWindow('soundboard')}>
                                <Icon value="soundboard" className="size-2" />
                            </CardButton>
                            <p className="t-small full-width t-centered">Create Soundboard</p>
                            {!user.subscription.active && <p className={`t-small t-center ${soundBoardCount >= maxSoundboards && 'c-red'}`}>{soundBoardCount}/{maxSoundboards} left</p>}
                        </div>
                    </div>
                </div>
            </Tab>

            <Tab active={activeTab === 1}>
                <div className="col full-width">
                    <FoldersList
                        openDocumentWindow={(doc) => {
                            addDocument({ _id: doc._id, name: doc.name, kind: doc.kind, documentOwnerId: doc.owner._id })
                        }}
                        folders={folders}
                        draggable={false}
                        minimal
                    />
                </div>
            </Tab>
        </div>
    )
}

export default AddDocumentView