import React from 'react'

const Keyboard = () => {
    return (
        <svg id="keyboard" xmlns="http://www.w3.org/2000/svg" width="49" height="44.215" viewBox="0 0 49 44.215">
            <g transform="translate(-1285.818 -694.277)">
                <g transform="translate(1285.818 694.277)">
                    <path d="M46.129,41.844H28.737a11.021,11.021,0,0,0,3.324-7.752c0-3.392.786-4.456,1.471-5,1.052-.841,2.989-1.217,6.281-1.217a1.436,1.436,0,0,0,0-2.871c-4.1,0-6.436.535-8.075,1.846-2.259,1.807-2.548,4.822-2.548,7.246,0,4.458-3.974,7.11-5.062,7.752H2.871A2.874,2.874,0,0,0,0,44.715V66.344a2.874,2.874,0,0,0,2.871,2.871H46.129A2.874,2.874,0,0,0,49,66.344V44.715A2.874,2.874,0,0,0,46.129,41.844Zm0,24.5H2.871V44.715H46.129C46.131,66.836,46.138,66.344,46.129,66.344Z" transform="translate(0 -25)" fill="#647682" />
                    <path d="M67.967,393H66.436a1.436,1.436,0,1,0,0,2.871h1.531a1.436,1.436,0,1,0,0-2.871Z" transform="translate(-58.779 -357.781)" fill="#647682" />
                    <path d="M109.4,330.436A1.436,1.436,0,0,0,107.967,329h-1.531a1.436,1.436,0,1,0,0,2.871h1.531A1.436,1.436,0,0,0,109.4,330.436Z" transform="translate(-94.951 -299.906)" fill="#647682" />
                    <path d="M185,330.436a1.436,1.436,0,0,0,1.436,1.435h1.531a1.436,1.436,0,1,0,0-2.871h-1.531A1.436,1.436,0,0,0,185,330.436Z" transform="translate(-167.295 -299.906)" fill="#647682" />
                    <path d="M265,330.436a1.436,1.436,0,0,0,1.436,1.435h1.531a1.436,1.436,0,1,0,0-2.871h-1.531A1.436,1.436,0,0,0,265,330.436Z" transform="translate(-239.639 -299.906)" fill="#647682" />
                    <path d="M347.967,329h-1.531a1.436,1.436,0,1,0,0,2.871h1.531a1.436,1.436,0,1,0,0-2.871Z" transform="translate(-311.982 -299.906)" fill="#647682" />
                    <path d="M389.4,266.436A1.436,1.436,0,0,0,387.967,265h-1.531a1.436,1.436,0,0,0,0,2.871h1.531A1.436,1.436,0,0,0,389.4,266.436Z" transform="translate(-348.154 -242.031)" fill="#647682" />
                    <path d="M306.436,267.871h1.531a1.436,1.436,0,0,0,0-2.871h-1.531a1.436,1.436,0,0,0,0,2.871Z" transform="translate(-275.811 -242.031)" fill="#647682" />
                    <path d="M226.436,267.871h1.531a1.436,1.436,0,1,0,0-2.871h-1.531a1.436,1.436,0,1,0,0,2.871Z" transform="translate(-203.467 -242.031)" fill="#647682" />
                    <path d="M146.436,267.871h1.531a1.436,1.436,0,0,0,0-2.871h-1.531a1.436,1.436,0,0,0,0,2.871Z" transform="translate(-131.123 -242.031)" fill="#647682" />
                    <path d="M66.436,267.871h1.531a1.436,1.436,0,0,0,0-2.871H66.436a1.436,1.436,0,0,0,0,2.871Z" transform="translate(-58.779 -242.031)" fill="#647682" />
                    <path d="M403.967,393h-1.531a1.436,1.436,0,1,0,0,2.871h1.531a1.436,1.436,0,1,0,0-2.871Z" transform="translate(-362.623 -357.781)" fill="#647682" />
                    <path d="M164.811,393H146.436a1.436,1.436,0,1,0,0,2.871h18.375a1.436,1.436,0,1,0,0-2.871Z" transform="translate(-131.123 -357.781)" fill="#647682" />
                </g>
            </g>
        </svg>
    )
}

export default Keyboard
