import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useAuthState } from "../../context/Auth"
import { useCampaignFunctions, useCampaignState } from "../../context/Campaign"
import { useErrorFunctions } from "../../context/Error"
import { useInventoryFunctions } from "../../context/Inventory"
import { useLoadingFunctions } from "../../context/Loading"
import { useWorkspaceFunctions } from "../../context/Workspace"
import useOpenWindow from "../useOpenWindow"
import useRecieveBroadcast from "./useRecieveBroadcast"

const useCampaignConnections = () => {
    const { user } = useAuthState()
    const { socket, activeCampaign } = useCampaignState()
    const { workspaceDispatch } = useWorkspaceFunctions()

    const {
        campaignDispatch,
        logOutOfCampaign,
        addConnectedUser,
        removeConnectedUser,
        enterCampaign,
        addWaitingUser,
        cancelWaitingUser
    } = useCampaignFunctions()

    const {
        addCampaignToInventory,
        inventoryDispatch
    } = useInventoryFunctions()

    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()
    const history = useHistory()

    const openWindow = useOpenWindow()
    const openWaitingForInviteWindow = () => openWindow('waitingForInvite')
    const openKickedFromCampaignWindow = () => openWindow('kickedFromCampaign')

    const { setDocToBroadcast, setDocToUnBroadcast } = useRecieveBroadcast()

    const [shouldOpenUAIWindow, setShouldOpenUAIWindow] = useState(false)
    useEffect(() => {
        if (shouldOpenUAIWindow) {
            openWindow('usersAwaitingInvite')
            setShouldOpenUAIWindow(false)
        }
    }, [openWindow, shouldOpenUAIWindow])

    const usersTyping = () => {

        socket.on("CAMPAIGN_DATA", (payload) => {
            const campaign = JSON.parse(payload)
            setLoading(false)
            enterCampaign(campaign, user._id, () => setShouldOpenUAIWindow(true))
            addCampaignToInventory(campaign)
        })

        // campaign owner messages
        socket.on("USER_REQUESTING_INVITE_TO_CAMPAIGN", (payload) => {
            addWaitingUser(payload)
            if (activeCampaign.owner._id === user._id) setShouldOpenUAIWindow(true)
        })

        socket.on("USER_CANCELLED_INVITE_REQUEST", (payload) => {
            cancelWaitingUser(payload)
        })

        // user waiting for invite messages
        socket.on("INVITE_PENDING", (pendingId) => {
            setLoading(false)
            campaignDispatch({ type: "WAITING_FOR_INVITE", value: pendingId })
            openWaitingForInviteWindow()
        })

        socket.on("INVITE_REJECTED", (data) => {
            const { campaignShortId, userId } = JSON.parse(data)
            if (activeCampaign && activeCampaign.shortId === campaignShortId && userId === user._id) {
                logOutOfCampaign(socket)
                workspaceDispatch({ type: 'CLOSE_ALL' })
                openKickedFromCampaignWindow()
                campaignDispatch({ type: "INVITE_REJECTED", value: campaignShortId })
                inventoryDispatch({ type: "REMOVE_CAMPAIGN_FROM_SHORTID", value: campaignShortId })
                history.push("/")
            }
        })


        socket.on("INVITE_ACCEPTED", (userId) => {
            if (userId === user._id)
                campaignDispatch({ type: "INVITE_ACCEPTED" })
        })

        //campaign user messages
        socket.on('USER_CONNECTED_TO_CAMPAIGN', (newUserId) => {
            addConnectedUser(newUserId, activeCampaign, setError, true)
            socket.emit('USER_ONLINE', JSON.stringify({ userId: user._id, shortId: activeCampaign?.shortId }))
        })

        socket.on('USER_ONLINE', (userId) => {
            addConnectedUser(userId, activeCampaign, setError)
        })

        socket.on('USER_DISCONNECTED_FROM_CAMPAIGN', userId => {
            if (userId)
                if (userId === user._id) {
                    logOutOfCampaign(socket)
                    workspaceDispatch({ type: 'CLOSE_ALL' })
                    history.push('/')
                } else {
                    removeConnectedUser(userId, setError)
                }
        })

        socket.on("UPDATE_TO_CAMPAIGN_USERS", (updatedCampaignObject) => {
            campaignDispatch({ type: "UPDATE_CAMPAIGN_USERS", value: JSON.parse(updatedCampaignObject) })
        })

        socket.on('NEW_MESSAGE', (data) => {
            const message = JSON.parse(data)
            campaignDispatch({ type: 'INCOMMING_MESSAGE', value: { message, userId: user._id } })
        })

        socket.on('ERROR_CONNECTING_USER_TO_CAMPAIGN', (err) => {
            setError(err)
            history.replace({ pathname: '/' })
            logOutOfCampaign(socket)
            workspaceDispatch({ type: 'CLOSE_ALL' })
            setLoading(false)
        })

        socket.on('CANNOT_FIND_OTHER_USER_SOCKET', otherUserId => {
            console.log('cannot find other user socket', otherUserId)
            campaignDispatch({ type: 'OTHER_USER_IS_NOT_ONLINE', value: otherUserId })
        })

        socket.on("BROADCAST_WINDOW", (data) => {
            setDocToBroadcast(data)
        })

        socket.on("UNBROADCAST_WINDOW", (data) => {
            setDocToUnBroadcast(data)
        })
    }

    return usersTyping
}

export default useCampaignConnections