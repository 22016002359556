import API from '../../../functions/API'
import { validateForm, setAuth, clearForm } from './'
import TYPES from '../../../config/graph-request-types'

export async function handleUpdateUser(formData, auth, user, setLoading, setError) {

    let { name, email, password } = formData
    if (validateForm.call(this, formData, setError)) {
        setLoading("update user")
        try {
            if (name === user.name) name = ""
            if (email === user.email) email = ""
            if (password === user.password) password = ""
            if (name === "" && email === "" && password === "") {
                clearForm.call(this)
                setLoading(false)
                return
            }

            const response = await API.sendGraphSecure(auth, {
                query: `mutation {
                    updateUser(email: "${email.toLowerCase() || ""}", password: "${password || ""}", name: "${name || ""}") {
                        token
                        expiration
                        user {
                            ${TYPES.fullUser}
                        }
                    }
                }`
            })

            if (response) {
                const { token, user, expiration } = response.data.updateUser
                setAuth.call(this, token, user, expiration)
                clearForm.call(this)
                setLoading(false)
            }
        } catch (err) { setError(err.message) }
    }
}