import React, { useRef } from 'react'
import TokensList from './TokensList'
import TokenProperties from './TokenProperties'
import MidCloseButton from '../../MidCloseButton'
import './MapModal.css'
import GridProperties from './GridProperties'
import DrawSettings from './DrawSettings'
import PingSettings from './PingSettings'

const MapModal = ({ documentId, pingColor, setPingColor, drawToolSettings, setDrawToolSettings, removeTokenImage, modalState, setModalState, data, doc, userImages, windowIndex }) => {
    const scrollRef = useRef(null)
    return (
        <div key={`${windowIndex}-map-modal`} className={`mapModal ${modalState.side} ${modalState.open && 'open'} bg-white border-top-thin`}>
            <div className="full-height full-width overflow-y-auto padding-half" ref={scrollRef}>
                {modalState.type === 'tokens' && (
                    <TokensList
                        scrollRef={scrollRef}
                        windowIndex={windowIndex}
                        removeTokenImage={removeTokenImage}
                        userImages={userImages}
                    />
                )}
                {(modalState.type === 'selectedToken' || modalState.type === 'multipleSelectedTokens') && (
                    <TokenProperties
                        multi={modalState.type === 'multipleSelectedTokens'}
                        selectedToken={modalState.selectedToken}
                        data={data}
                        doc={doc}
                        modalState={modalState}
                        setModalState={setModalState}
                        windowIndex={windowIndex}
                        documentId={documentId}
                    />
                )}
                {modalState.type === 'grid' && (
                    <GridProperties
                        data={data}
                        doc={doc}
                        modalState={modalState}
                        setModalState={setModalState}
                        windowIndex={windowIndex}
                    />
                )}
                {modalState.type === 'draw-settings' && (
                    <DrawSettings
                        drawToolSettings={drawToolSettings}
                        setDrawToolSettings={setDrawToolSettings}
                    />
                )}

                {modalState.type === 'ping-settings' && (
                    <PingSettings
                        pingColor={pingColor}
                        setPingColor={setPingColor}
                    />
                )}
                {modalState.open && (
                    <MidCloseButton
                        bg='bg-white'
                        bc='bc-cyan'
                        side={modalState.side}
                        onClick={() => {
                            setModalState({ ...modalState, open: false })
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default MapModal
