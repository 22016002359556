import userMinimum from './userMinimum'

const allUserTypesAtMinimum = `
    users{
        ${userMinimum}
    }
    usersAwaitingInvite{
        ${userMinimum}
    }
    deniedUsers{
        ${userMinimum}
    }
`
export default allUserTypesAtMinimum