import React from 'react'

const Checklist = () => {
    return (
        <svg data-name="checklist" xmlns="http://www.w3.org/2000/svg" width="95.313" height="128.673" viewBox="0 0 95.313 128.673">
            <path d="M86.508,155.718H20.035A12.535,12.535,0,0,1,7.5,143.183V51.176A12.535,12.535,0,0,1,20.035,38.641H86.508A12.536,12.536,0,0,1,99.044,51.176v92.007A12.535,12.535,0,0,1,86.508,155.718Zm0,0" transform="translate(-5.615 -28.93)" fill="#ffdaaa" />
            <path d="M66.038,38.641h-46A12.535,12.535,0,0,0,7.5,51.176v92.007a12.535,12.535,0,0,0,12.535,12.535H44.723c-13.768-38.918-7.272-54.4,5.385-75.834C57.821,66.817,62.983,50.5,66.038,38.641Zm0,0" transform="translate(-5.615 -28.93)" fill="#ffc477" />
            <path d="M54.775,174.691a9.123,9.123,0,0,1-9.123-9.123V85.913a9.124,9.124,0,0,1,9.123-9.124H108.9a9.124,9.124,0,0,1,9.124,9.124v79.655a9.124,9.124,0,0,1-9.124,9.123Zm0,0" transform="translate(-34.179 -57.491)" fill="#e9fdff" />
            <path d="M91.915,76.789H54.775a9.124,9.124,0,0,0-9.123,9.124v79.655a9.123,9.123,0,0,0,9.123,9.123H70.161c-9.472-32.051-3.052-46.661,8.51-66.246C84.461,98.639,88.813,87,91.915,76.789Zm0,0" transform="translate(-34.179 -57.491)" fill="#ccf8ff" />
            <path d="M143.955,32.564H120.226a7.449,7.449,0,0,1-7.449-7.449V14.949A7.449,7.449,0,0,1,120.226,7.5h23.729a7.449,7.449,0,0,1,7.449,7.449V25.115A7.449,7.449,0,0,1,143.955,32.564Zm0,0" transform="translate(-84.435 -5.615)" fill="#d8d7da" />
            <path d="M129.648,25.115V14.949A7.449,7.449,0,0,1,137.1,7.5H120.226a7.449,7.449,0,0,0-7.449,7.449V25.115a7.449,7.449,0,0,0,7.449,7.449H137.1A7.449,7.449,0,0,1,129.648,25.115Zm0,0" transform="translate(-84.435 -5.615)" fill="#c6c5ca" />
            <path d="M145.029,172.395h36.262a1.885,1.885,0,0,0,0-3.77H145.029a1.885,1.885,0,1,0,0,3.77Zm0,0" transform="translate(-107.17 -126.247)" />
            <path d="M145.029,234.66h36.262a1.885,1.885,0,0,0,0-3.77H145.029a1.885,1.885,0,1,0,0,3.77Zm0,0" transform="translate(-107.17 -172.864)" />
            <path d="M145.029,296.922h36.262a1.885,1.885,0,0,0,0-3.77H145.029a1.885,1.885,0,1,0,0,3.77Zm0,0" transform="translate(-107.17 -219.479)" />
            <path d="M145.029,359.192h36.262a1.885,1.885,0,0,0,0-3.77H145.029a1.885,1.885,0,1,0,0,3.77Zm0,0" transform="translate(-107.17 -266.099)" />
            <path d="M82.2,290.014a5.376,5.376,0,1,0-5.377-5.377A5.383,5.383,0,0,0,82.2,290.014Zm0-6.983a1.607,1.607,0,1,1-1.607,1.607A1.609,1.609,0,0,1,82.2,283.031Zm0,0" transform="translate(-57.514 -209.079)" />
            <path d="M82.2,352.28A5.376,5.376,0,1,0,76.82,346.9,5.382,5.382,0,0,0,82.2,352.28Zm0-6.983A1.607,1.607,0,1,1,80.59,346.9,1.609,1.609,0,0,1,82.2,345.3Zm0,0" transform="translate(-57.514 -255.696)" />
            <path d="M80,226.388l2.83,2.83a1.887,1.887,0,0,0,1.333.552c.045,0,.091,0,.136,0a1.884,1.884,0,0,0,1.375-.754l3.679-4.939a1.885,1.885,0,1,0-3.023-2.252l-2.377,3.191-1.289-1.289A1.885,1.885,0,1,0,80,226.388Zm0,0" transform="translate(-59.484 -165.505)" />
            <path d="M82.836,166.953a1.887,1.887,0,0,0,1.333.552c.045,0,.091,0,.136,0a1.884,1.884,0,0,0,1.375-.754l3.679-4.939a1.885,1.885,0,1,0-3.023-2.252l-2.377,3.191-1.289-1.289a1.885,1.885,0,1,0-2.666,2.665Zm0,0" transform="translate(-59.486 -118.888)" />
            <path d="M80.893,7.826h-5.8a1.885,1.885,0,0,0,0,3.77h5.8a10.663,10.663,0,0,1,10.65,10.65v92.007a10.663,10.663,0,0,1-10.65,10.65H14.42a10.663,10.663,0,0,1-10.65-10.65V22.246A10.663,10.663,0,0,1,14.42,11.6H26.458v5.818H20.6A11.021,11.021,0,0,0,9.588,28.422v66.8a1.885,1.885,0,0,0,3.77,0v-66.8A7.246,7.246,0,0,1,20.6,21.183h6.017a9.348,9.348,0,0,0,9.179,7.65H59.521a9.347,9.347,0,0,0,9.179-7.65h6.017a7.247,7.247,0,0,1,7.239,7.239v79.655a7.247,7.247,0,0,1-7.239,7.238H20.6a7.246,7.246,0,0,1-7.238-7.238v-4.609a1.885,1.885,0,0,0-3.77,0v4.609A11.02,11.02,0,0,0,20.6,119.085h54.12a11.021,11.021,0,0,0,11.009-11.008V28.422A11.022,11.022,0,0,0,74.716,17.413H68.855V9.334A9.344,9.344,0,0,0,59.521,0H35.792a9.348,9.348,0,0,0-9.21,7.826H14.42A14.436,14.436,0,0,0,0,22.246v92.007a14.436,14.436,0,0,0,14.42,14.42H80.893a14.437,14.437,0,0,0,14.42-14.42V22.246A14.437,14.437,0,0,0,80.893,7.826ZM30.228,9.334A5.571,5.571,0,0,1,35.792,3.77H59.521a5.57,5.57,0,0,1,5.564,5.564V19.5a5.57,5.57,0,0,1-5.564,5.564H35.792A5.57,5.57,0,0,1,30.228,19.5Zm0,0" />
        </svg>
    )
}

export default Checklist
