import React from 'react'

import { useCampaignFunctions, useCampaignState } from '../../context/Campaign/index'
import Icon from '../Icon'
import DiceValueIcon from '../Icon/DiceValueIcon'
import DiceGroup from './DiceGroup'

const DiceHistoryEntry = React.forwardRef(({ roll }, ref) => {
    const { socket } = useCampaignState()
    const { handleReRoll } = useCampaignFunctions()
    const onReRoll = (roll, die) => {
        handleReRoll(roll, die, socket)
    }

    const failure = determineFailure(roll)

    return (
        <div
            ref={ref}
            className={`diceHistoryEntry row ${roll.skin} centered-vertically ${roll.localRoll ? 'shadow-dark' : 'remote'} ${failure && 'failure'}`}
        >
            <div className="col full-width">
                <div className="row full-width space-between">
                    <div className={`diceHistoryEntry__left col ${roll.inProgress && 'in-progress'}`}>
                        <p className="diceHistoryEntry__message">
                            {roll.message}
                            {roll.privateRoll && <span className="privateLabel"> - Private</span>}
                        </p>
                        {!roll.inProgress && roll.succeedsOn !== 0 && roll.succeedsOnDiceOrRoll === 'Dice' && (
                            <div className="row">
                                <p className="t-bold margin-right-1">{roll.successCount} Success{roll.successCount > 1 ? 'es' : ''}</p>
                                <p className="t-bold c-main">{roll.failureCount} Failure{roll.failureCount > 1 ? 's' : ''}</p>
                            </div>
                        )}
                        <div className="row centered-vertically">
                            <div className="diceHistoryEntry__diceContainer row">
                                {displayDice(roll, onReRoll)}
                                {explosionsDisplay(roll.explosions, roll.keepBest, roll.skin, onReRoll)}
                            </div>
                            {roll.addOrSub !== 0 && (
                                <div className="diceHistoryEntry__addOrSub">
                                    {roll.addOrSub > 0 && "+"}{roll.addOrSub}
                                </div>
                            )}
                        </div>
                    </div>
                    {roll.inProgress || (roll.succeedsOn !== 0 && roll.succeedsOnDiceOrRoll === 'Dice' && roll.addOrSub === 0) ? null : (
                        <div className="diceHistoryEntry__right col margin-left-1">
                            <p>{roll.succeedsOn !== 0 ? failure ? 'Failure' : 'Success' : 'Total'}</p>
                            <p className="totals centered">{roll.totalValue}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
})

export default DiceHistoryEntry


const displayDice = (roll, onReRoll) => {
    return roll.countedDice.map((die, i) => displayDie(die, i, roll, onReRoll))
}

const explosionsDisplay = (explosions, keepBest, skin) => {
    return explosions.map((die, i) => (
        <div key={`${i}-explosion`} className="explosion row centered-vertically">
            {displayDie(die, `${i}-parent`, { diceCounts: { [die.type]: 1 }, keepBest, skin })}
            <Icon value="arrow-right" />
            {die.predecessors.dice.map((pred, j) => displayDie(pred, j, {
                diceCounts: die.predecessors.diceCounts,
                keepBest,
                skin,
            }))}
        </div>
    ))
}

const displayDie = (die, i, roll, onReRoll) => {
    const dieCount = roll.diceCounts[die.type]
    const drop = roll.keepBest !== 0 && !die.keep
    const skin = roll.skin

    if (dieCount > 10) {
        return <DiceGroup key={i} {...{ die, roll }} onReRoll={onReRoll} />
    } else {
        return <div key={i} className="diceHistoryEntry__diceList">
            <DiceValueIcon icon={die.type} value={die.value} skin={skin} drop={drop} failure={die.failed} onReRoll={onReRoll ? () => onReRoll(roll, die) : null} rerollCount={die.rerollCount} />
        </div>
    }
}

const determineFailure = (roll) => {
    if (roll.succeedsOn !== 0) {
        if (roll.succeedsOnGreaterOrLess === 'greater') {
            return roll.totalValue > roll.succeedsOn ? false : true
        }

        if (roll.succeedsOnGreaterOrLess === 'less') {
            return roll.totalValue < roll.succeedsOn ? false : true
        }
    }

    return false
}