import React from 'react'
import { colorMap } from "../functions/colorMap"
import { IconButton } from '../../Buttons'
import { Circle, Square } from '@styled-icons/boxicons-regular/'
import { Marker } from '@styled-icons/fa-solid/Marker'
import { Slash } from '@styled-icons/fa-solid/Slash'
import { Slider } from '../../Slider'

import { BorderInner } from '@styled-icons/material-sharp/BorderInner'
import { BorderStyle } from '@styled-icons/material-sharp/BorderStyle'
import { SquareFill } from '@styled-icons/bootstrap/SquareFill'

const DrawSettings = ({ drawToolSettings, setDrawToolSettings }) => {
    return (
        <div className="col padding-left-half drawSettings">
            <p className="margin-bottom-1">Tool</p>
            <div className="row space-between margin-bottom-1">
                <IconButton className={`size-2 padding-half grow-hover stroke-none round overflow-visible ${drawToolSettings.tool === 'marker' ? `selected` : 'c-shade-6'}`} onClick={() => setDrawToolSettings({ ...drawToolSettings, tool: 'marker' })}><Marker /></IconButton>
                <IconButton className={`size-2 padding-half grow-hover stroke-none round overflow-visible ${drawToolSettings.tool === 'circle' ? `selected` : 'c-shade-6'}`} onClick={() => setDrawToolSettings({ ...drawToolSettings, tool: 'circle' })}><Circle /></IconButton>
                <IconButton className={`size-2 padding-half grow-hover stroke-none round overflow-visible ${drawToolSettings.tool === 'square' ? `selected` : 'c-shade-6'}`} onClick={() => setDrawToolSettings({ ...drawToolSettings, tool: 'square' })}><Square /></IconButton>
                <IconButton className={`size-2 padding-half grow-hover stroke-none round overflow-visible ${drawToolSettings.tool === 'line' ? `selected` : 'c-shade-6'}`} onClick={() => setDrawToolSettings({ ...drawToolSettings, tool: 'line' })}><Slash /></IconButton>
            </div>

            {drawToolSettings.tool !== 'marker' && (
                <>
                    <p className="margin-bottom-1">Origin</p>
                    <div className="row centered margin-bottom-1">
                        <IconButton className={`padding-half margin-right-1 ${!drawToolSettings.origin || drawToolSettings.origin === 'corner' ? `selected` : 'c-shade-6'}`} onClick={() => setDrawToolSettings({ ...drawToolSettings, origin: 'corner' })}><BorderStyle className="size-1 margin-right-half" />Corner</IconButton>
                        <IconButton className={`padding-half ${drawToolSettings.origin === 'center' ? `selected` : 'c-shade-6'}`} onClick={() => setDrawToolSettings({ ...drawToolSettings, origin: 'center' })}><BorderInner className="size-1 margin-right-half" />Center</IconButton>
                    </div>

                    {drawToolSettings.tool !== 'line' && (
                        <>
                            <p className="margin-bottom-1">Fill</p>
                            <div className="row centered margin-bottom-1">
                                <IconButton className={`padding-half margin-right-1 ${!drawToolSettings.fill || drawToolSettings.fill === 'fill' ? `selected` : 'c-shade-6'}`} onClick={() => setDrawToolSettings({ ...drawToolSettings, fill: 'fill' })}><SquareFill className="size-1 margin-right-half" />Fill</IconButton>
                                <IconButton className={`padding-half ${drawToolSettings.fill === 'outline' ? `selected` : 'c-shade-6'}`} onClick={() => setDrawToolSettings({ ...drawToolSettings, fill: 'outline' })}><Square className="size-1 margin-right-half" />Outline</IconButton>
                            </div>
                        </>
                    )}
                </>
            )}

            <p className="margin-bottom-1">Thickness</p>
            <Slider
                min={1}
                max={16}
                value={drawToolSettings.thickness}
                onChange={(e) => setDrawToolSettings({ ...drawToolSettings, thickness: e.target.value })}
            />
            <p className="margin-bottom-1">Color</p>
            <div className="row centered wrap margin-top-1">
                {Object.keys(colorMap).map((key) => {
                    return (
                        <button
                            key={key}
                            className={`round size-3 margin-quarter bg-${key} ${drawToolSettings.color === key ? 'border-thick bc-main' : 'border-thin bc-main'}`}
                            onClick={() => setDrawToolSettings({ ...drawToolSettings, color: key })}
                        ></button>
                    )
                })}
            </div>
        </div>
    )
}

export default DrawSettings
