import React from 'react'

const Name = ({ name, labels, show, scale }) => {
    const percentScales = [2, 1.5, 1, 0.75, 0.75, 0.6, 0.5, 0.45, 0.4, 0.35, 0.3]
    const adjustedScale = percentScales[scale - 1]

    return name ? <p
        className={`name bg-shade-2 c-shade-8 border-thin bc-shade-6 ${show && 'showNames'}`}
        style={{
            fontSize: 10, zIndex: (labels?.length || 0) + 1,
            transform: `translateX(-50%) translateY(4px) scaleX(${adjustedScale}) scaleY(${adjustedScale})`,
            transformOrigin: 'top middle'
        }}
    >
        {name}
    </p> : null
}

export default Name