import React from 'react'
import Icon from '../Icon'
import './NothingToSee.css'

const NothingToSee = ({ hideDefaultMessage, message, className, titleClassName }) => {
    return (
        <div className={`nothingToSee padding-1 ${className}`}>
            <Icon className="min-size-3" value="clouds" />
            {!hideDefaultMessage && <p className={`nothingToSee__title c-shade-8 ${titleClassName}`}>Nothing to see here.</p>}
            {message && <p className={`${hideDefaultMessage ? titleClassName : ' c-shade-6'}`}>
                {message}
            </p>}
        </div>
    )
}

export default NothingToSee
