import React from 'react'

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26.163" height="26.163" viewBox="0 0 26.163 26.163">
    <g id="Group_47" data-name="Group 47" transform="translate(-5119.418 330.581)">
      <line id="Line_6" data-name="Line 6" y2="34" transform="translate(5120.479 -329.521) rotate(-45)" fill="none" />
      <line id="Line_7" data-name="Line 7" y2="34" transform="translate(5144.521 -329.521) rotate(45)" fill="none" />
    </g>
  </svg>
)

export default Close
