import fullUsersDocuments from "../../../config/graph-request-types/fullUsersDocuments"
import API from "../../../functions/API"
export async function unshareFolder(auth, targetId, folderId, isCampaign, setLoading, setError) {
    try {
        setLoading(true)
        if (isCampaign) {
            const result = await API.sendGraphSecure(auth, {
                query: `mutation unshareFolderWithCampaign($folderId: ID!, $campaignId: String!) {
                        unshareFolderWithCampaign(folderId: $folderId, campaignId: $campaignId) {
                            _id
                            sharedCampaigns {
                                _id
                            }
                            sharedUsers {
                                _id
                            }
                            documents {
                                left {
                                    ${fullUsersDocuments}
                                }
                                right {
                                    ${fullUsersDocuments}
                                }
                            }
                        }
                    }`,
                variables: {
                    "folderId": folderId,
                    "campaignId": targetId,
                }
            })

            this.dispatch({
                type: 'UPDATE_SHARED_FOLDER', value: result.data.unshareFolderWithCampaign
            })
            setLoading(false)
            return result.data.unshareFolderWithCampaign
        } else {
            const result = await API.sendGraphSecure(auth, {
                query: `mutation unshareFolderWithUser($folderId: ID!, $userId: String!) {
                        unshareFolderWithUser(folderId: $folderId, userId: $userId) {
                            _id
                            sharedCampaigns {
                                _id
                            }
                            sharedUsers {
                                _id
                            }
                            documents {
                                left {
                                    ${fullUsersDocuments}
                                }
                                right {
                                    ${fullUsersDocuments}
                                }
                            }
                        }
                    }`,
                variables: {
                    "folderId": folderId,
                    "userId": targetId,
                }
            })

            this.dispatch({
                type: 'UPDATE_SHARED_FOLDER', value: result.data.unshareFolderWithUser
            })

            setLoading(false)
            return result.data.unshareFolderWithUser
        }

    } catch (err) {
        setError(err.message)
    }
}