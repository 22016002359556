import * as THREE from 'three'
import { lerp } from '../../../helpers'
import { isMobile } from 'react-device-detect'


export default function updateSelectedDice() {
    this.selectedDice.forEach((selectedDie, i) => {
        const max4 = i % 4

        let destX, destY, localX = 0, localY = 0
        if (isMobile) {
            destX = this.mobileOrigin.x - (this.offset * max4)
            destY = this.mobileOrigin.y + (this.offset * (i - max4) / 4)

            selectedDie.position.x = destX //lerp(selectedDie.position.x, destX, 0.15)
            selectedDie.position.y = destY //lerp(selectedDie.position.y, destY, 0.15)
        } else if (this.diceTray.active) {
            destX = this.diceTray.worldCoords.x - (this.offset * max4)
            destY = this.diceTray.worldCoords.y + (this.offset * (i - max4) / 4)

            selectedDie.position.x = destX //lerp(selectedDie.position.x, destX, 0.15)
            selectedDie.position.y = destY //lerp(selectedDie.position.y, destY, 0.15)
        } else {
            const dragLocalX = this.dragStartPos.x - this.primaryX - (this.offset * max4)
            const dragLocalY = this.dragStartPos.y + this.primaryY + (this.offset * (i - max4) / 4)

            localX = this.mousePos.x - this.primaryX - (this.offset * max4)
            localY = this.mousePos.y + this.primaryY + (this.offset * (i - max4) / 4)

            destX = this.mouseDown ? dragLocalX : localX
            destY = this.mouseDown ? dragLocalY : localY
            selectedDie.position.x = lerp(selectedDie.position.x, destX, 0.15)
            selectedDie.position.y = lerp(selectedDie.position.y, destY, 0.15)
        }

        const rotationX = lerp(selectedDie.quaternion.x, localX * 5 + selectedDie.rotationOriginX, 0.1)
        const rotationY = lerp(selectedDie.quaternion.y, localY * 5 + selectedDie.rotationOriginY, 0.1)
        const rotationZ = lerp(selectedDie.quaternion.y, localX * 5 + selectedDie.rotationOriginZ, 0.1)
        const newRotation = new THREE.Euler(rotationX, rotationY, rotationZ, 'XYZ')

        selectedDie.setRotationFromEuler(newRotation)
    })
}