import React, { useEffect, useState } from 'react'
import './DocumentListItem.css'
import Icon from '../Icon'
import { getIcon, getIconColors } from '../../functions/helpers'
import { IconButton } from '../Buttons'
import useOpenWindow from '../../hooks/useOpenWindow'
import { VipCrown } from '@styled-icons/remix-fill/VipCrown'
import { useAuthState } from '../../context/Auth'
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'
import { useInventoryState } from '../../context/Inventory/'
import DocumentPreview from '../DocumentPreview/DocumentPreview'

const DocumentListItem = ({ id, kind, onOpen, onDelete, openLabel }) => {
    const [expanded, setExpanded] = useState(false)
    const { user } = useAuthState()
    const { allDocuments, campaigns } = useInventoryState()
    const [name, setName] = useState('')
    const [documentOwnerId, setDocumentOwnerId] = useState('')
    const [thisDocumentExists, setThisDocumentExists] = useState(false)
    const [ownedByUser, setOwnedByUser] = useState(false)

    useEffect(() => {
        const thisDocument = allDocuments.find(document => document._id === id)
        setThisDocumentExists(!!thisDocument)
        const isOwnedByUser = thisDocument?.owner === user._id
        setName(thisDocument?.name || "")
        setDocumentOwnerId(thisDocument?.owner || "")
        setOwnedByUser(isOwnedByUser)
        // } else {
        // onDelete(id)
        // }
    }, [allDocuments, campaigns, id, onDelete, user._id])

    const onOpenFinal = onOpen || (() => {
        if (id)
            openWindow(kind, { title: name, uniqueIndex: `document-${id}` }, {
                documentId: id,
                documentOwnerId,
                documentName: name,
            })
    })

    const openWindow = useOpenWindow()

    return thisDocumentExists ? (
        <div className={`documentListItem col border-none full-width centered-vertically margin-bottom-half`}>
            <div className={`documentListItem--wrapper full-width row space-between full-width card-button padding-0 ${getIconColors(kind)} ${kind}`}>
                <div className="min-width-three-fourths full-width row centered-vertically c-midnight-blue" onClick={id ? () => setExpanded(!expanded) : null}>
                    <Icon className="size-1 min-width-1 margin-half centered" value={getIcon(kind)} />
                    <p className="documentListItem--title t-left">{name || 'Untitled...'}</p>
                </div>
                <div className="row centered-vertically documentListItem--rightContainer" onClick={() => onOpenFinal({ id, name })}>
                    {ownedByUser && (
                        <VipCrown className="size-75 margin-right-half" />
                    )}

                    <IconButton className="documentListItem--openButton c-main">
                        <ArrowRightShort className="size-1" />
                    </IconButton>
                </div>
            </div>

            {
                expanded && <DocumentPreview
                    key={`${id}-doc-prev`}
                    id={id}
                    name={name}
                    kind={kind}
                    ownerId={documentOwnerId}
                    windowIndex={`document-preview-${id}`}
                    expanded={expanded}
                    documentOwnerId={documentOwnerId}
                    onOpen={onOpenFinal}
                    onUnshare={onDelete}
                    openLabel={openLabel}
                    className="padding-top-1 height-10"
                />
            }
        </div >
    ) : null
}

export default DocumentListItem