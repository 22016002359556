import React from 'react'
import { ButtonParent } from '../..'
import Icon from '../../../Icon'
import './IconButton.css'

const IconButton = ({ id, className, draggable, type, icon, img, children, onClick, color, alt, onContextMenu, href, onMouseDown, onMouseOver, onMouseLeave }) => (
  <ButtonParent
    id={id}
    draggable={draggable}
    type={type}
    className={`iconButton centered ${className}`}
    onClick={onClick}
    onMouseDown={onMouseDown}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    onContextMenu={onContextMenu}
    href={href}
    alt={!icon && !img && children ? alt : null}
  >
    {icon && <Icon value={icon} alt={alt} color={color} />}
    {img && <img src={img} alt={alt} />}
    {children}
  </ButtonParent>
)

export default IconButton
