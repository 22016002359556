import { requestToEnterCampaign } from '.'

export async function checkForCampaign(auth, activeCampaign, waitingForInviteId, pathname, history, user, setLoading, setError) {
    if (activeCampaign || waitingForInviteId) {
        const urlId = activeCampaign ? activeCampaign.shortId : waitingForInviteId

        if (!pathname.includes(`/campaign/${urlId}`))
            history.replace({ pathname: `/campaign/${urlId}` })
    } else {
        // if we currently dont have campaign data
        // check if the URL has a campaign id
        // if there is no id in url or we are not signed in, skip
        const campaignInUrl = pathname.indexOf('/campaign/')
        if (!auth || campaignInUrl < 0) return false

        // if there is an id in the url then we need to request campaign data
        const shortIdIndex = campaignInUrl + '/campaign/'.length
        const campaignShortId = pathname.substr(shortIdIndex)

        try {
            await requestToEnterCampaign.call(this, auth, user, campaignShortId, setLoading, setError)
        } catch (err) {
            history.replace({ pathname: '/' })
        }
    }
}