import {
    changeField,
    changePage,
    clearForm,
    getAuthFromLocalStorage,
    getUser,
    // handleFacebookLogin,
    // handleGoogleLogin,
    handleLogin,
    handleLogout,
    handleSignup,
    handleGetUserData,
    handleUpdateUser,
    validateForm,
    sendPasswordResetEmail,
    sendPasswordReset
} from './functions'

export default function buildFunctions(dispatch) {
    return {
        changeField: changeField.bind({ dispatch }),
        changePage: changePage.bind({ dispatch }),
        clearForm: clearForm.bind({ dispatch }),
        getAuthFromLocalStorage: getAuthFromLocalStorage.bind({ dispatch }),
        getUser: getUser.bind({ dispatch }),
        // handleFacebookLogin: handleFacebookLogin.bind({dispatch}),
        // handleGoogleLogin: handleGoogleLogin.bind({dispatch}),
        handleLogin: handleLogin.bind({ dispatch }),
        handleLogout: handleLogout.bind({ dispatch }),
        handleSignup: handleSignup.bind({ dispatch }),
        handleGetUserData: handleGetUserData.bind({ dispatch }),
        handleUpdateUser: handleUpdateUser.bind({ dispatch }),
        validateForm: validateForm.bind({ dispatch }),
        sendPasswordResetEmail: sendPasswordResetEmail.bind({ dispatch }),
        sendPasswordReset: sendPasswordReset.bind({ dispatch }),
        authDispatch: dispatch
    }
}