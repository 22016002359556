import API from "../../../functions/API"
import ImageAPI from "../../../functions/ImageAPI"

export async function removeImagesFromUser(auth, userId, srcList, setError) {
    try {
        await API.sendGraphSecure(auth, {
            query: `mutation($userId: String!, $srcList: String!) {
                    removeImagesFromUser(userId: $userId, srcList: $srcList)
                }`,
            variables: {
                userId,
                srcList: JSON.stringify(srcList)
            }
        })

        await ImageAPI.deleteMany(srcList)
    } catch (err) {
        setError(err.message)
    }
}