import React from 'react'
import { Button } from '../../../components/Buttons'
import { useWorkspaceFunctions } from '../../../context/Workspace'

const Confirm = ({ message, confirmCallback, _index }) => {
    const { closeWindow } = useWorkspaceFunctions()

    const handleConfirm = () => {
        confirmCallback()
        closeWindow(_index)
    }

    return (
        <div className="col padding-1 centered full-height">
            <p className="margin-bottom-1">{message}</p>
            <div className="row">
                <Button className="green margin-right-1" onClick={handleConfirm}>Yes</Button>
                <Button className="red" onClick={() => closeWindow(_index)}>No</Button>
            </div>
        </div>
    )
}

export default Confirm
