import API from '../../../functions/API'
import { validateForm, clearForm, setAuth } from './'
import fullUser from '../../../config/graph-request-types/fullUser'

export async function handleSignup(email, password, passwordConfirm, name, listSignup, setLoading, setError) {

    if (validateForm.call(this, { email, password, passwordConfirm, name, page: 'signup' }, setError)) {
        setLoading("signup")
        try {
            const response = await API.sendGraph({
                query: `mutation {
                    createUser(email: "${email.toLowerCase()}", password: "${password}", name: "${name}", listSignup: ${listSignup}) {
                        token
                        expiration
                        user {
                           ${fullUser}
                        }
                    }
                }`
            })

            if (response) {
                const { token, user, expiration } = response.data.createUser
                setAuth.call(this, token, user, expiration)
                window.heap.track('USER_SIGNUP', { userId: user._id })
                clearForm.call(this)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            setError(err.message)
        }
    }
}