import { useCallback, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import API from '../../functions/API'
import { useAuthState } from '../../context/Auth';
import { useInventoryFunctions } from '../../context/Inventory';
import { useInventoryState } from '../../context/Inventory/index';
import { useAtom, atom } from 'jotai'
import { useErrorFunctions } from '../../context/Error/index';
import GlobalStorage from '../../functions/GlobalStorage';

export const dragSource_atom = atom(null)
export const dragDestination_atom = atom(null)
export const hoveredFolderId_atom = atom(null)
export const inventoryIsDragging = atom(false)

const useOnInventoryDrop = () => {
    const { auth, user } = useAuthState()
    const { folders, batchedFolderUpdates } = useInventoryState()
    const { inventoryDispatch, getFolderData } = useInventoryFunctions()
    const { setError } = useErrorFunctions()

    const [source, setSource] = useAtom(dragSource_atom)
    const [destination, setDestination] = useAtom(dragDestination_atom)
    const [hoveredFolderId, setHoveredFolderId] = useAtom(hoveredFolderId_atom)
    const [isDragging, setIsDragging] = useAtom(inventoryIsDragging)
    useEffect(() => {
        if (source === null || destination === null || source.payload === null) return
        if (source?.removedIndex !== undefined && destination?.addedIndex !== undefined) {
            if (source.type === 'ITEMS') {
                const leftIsSource = source.side === 'left'
                const leftIsDest = destination.side === 'left'
                const newFolders = [...folders]

                const sourceFolderIndex = newFolders.findIndex(folder => folder._id === source.folderId)
                const destFolderIndex = newFolders.findIndex(folder => folder._id === destination.folderId)

                const sourceFolder = newFolders[sourceFolderIndex]
                const destFolder = newFolders[destFolderIndex]

                const documentsOwner = source?.payload?.owner?._id || source?.payload?.owner

                if (batchedFolderUpdates !== null) {
                    if (!isDragging) {
                        setSource(null)
                        setDestination(null)
                        inventoryDispatch({ type: 'APPLY_BATCHED_FOLDER_UPDATE', value: batchedFolderUpdates })
                    }
                } else {
                    if (
                        (user._id === documentsOwner
                            || destFolder.owner._id === documentsOwner
                            || findDocOwnerInSharedUsers(documentsOwner, destFolder.sharedUsers)
                            || findDocOwnerInSharedCampaigns(documentsOwner, destFolder.sharedCampaigns))
                        && !isDragging
                    ) {
                        const [moveTarget] = sourceFolder.documents[leftIsSource ? 'left' : 'right'].splice(source.removedIndex, 1)
                        destFolder.documents[leftIsDest ? 'left' : 'right'].splice(destination.addedIndex, 0, moveTarget)

                        //sorting the destination folder
                        const leftLength = destFolder.documents['left'].length
                        const rightLength = destFolder.documents['right'].length

                        if (leftLength > rightLength + 1) {
                            const moveTarget = destFolder.documents['left'].pop()
                            destFolder.documents['right'].push(moveTarget)
                        }

                        if (rightLength > leftLength + 1) {
                            const moveTarget = destFolder.documents['right'].pop()
                            destFolder.documents['left'].push(moveTarget)
                        }

                        inventoryDispatch({ type: 'SET_FOLDERS', value: newFolders })

                        updateOnServer(auth, {
                            sourceIndex: source.removedIndex,
                            destIndex: destination.addedIndex,
                            sourceFolderId: source.folderId,
                            destFolderId: destination.folderId,
                            leftIsSource,
                            leftIsDest
                        }, getFolderData)
                            .catch((err) => {
                                console.log('error moving items', err)
                                Sentry.captureException('error moving items', err)
                            })
                    } else {
                        setError('Cannot move item to folder that the items owner has no access to.')
                    }
                }
            } else if (source.type === 'FOLDERS') {
                const newFolders = [...folders]
                const [moveTarget] = newFolders.splice(source.removedIndex, 1)
                newFolders.splice(destination.addedIndex, 0, moveTarget)
                inventoryDispatch({ type: 'SET_FOLDERS', value: newFolders })
                updateFoldersOnServer(auth, {
                    sourceIndex: source.removedIndex,
                    destIndex: destination.addedIndex,
                })
                    .catch((err) => {
                        console.log('error moving folders', err)
                        Sentry.captureException('error moving folders', err)
                    })
            }

            setSource(null)
            setDestination(null)
        }
    }, [auth, batchedFolderUpdates, destination, folders, getFolderData, inventoryDispatch, isDragging, setDestination, setError, setSource, source, user._id])

    const onDrop = useCallback((type, payload, folderId, side) => {
        // if not a remove or place payload, skip it
        if (payload.removedIndex === null && payload.addedIndex === null) return

        //if the payload is for an item being removed, set it to the pulled Item state for later use
        if (payload.removedIndex !== null) {
            setSource({ type, ...payload, folderId, side })
        }

        if (payload.addedIndex !== null) {
            setDestination({ type, ...payload, folderId, side })
        }
    }, [setDestination, setSource])


    const onMouseMove = useCallback((e) => {
        const taggedHoveredFolder = e.target.closest('.folder')?.id
        if (taggedHoveredFolder) {
            const folderId = taggedHoveredFolder.substring(7)
            setHoveredFolderId(folderId)
        }
    }, [setHoveredFolderId])

    const onDragStart = (data) => {
        if (!data?.payload?.isFolder) {
            setIsDragging(true);
            GlobalStorage.set('draggingInventoryPayload', data)
            window.addEventListener('mousemove', onMouseMove)
        }
    }

    const onDragEnd = () => {
        window.removeEventListener('mousemove', onMouseMove)
        setHoveredFolderId(null)
        setIsDragging(false);
        GlobalStorage.set('draggingInventoryPayload', null)
    }

    return {
        onDrop,
        onDragStart,
        onDragEnd,
        hoveredFolderId,
        isDragging
    }
}

const updateOnServer = async (auth, payload, getFolderData) => {
    try {
        await API.sendGraphSecure(auth, {
            query: `mutation reorderDocuments($payload: String!) {
            reorderDocuments(payload: $payload) {
                    success
                }
            }`,
            variables: {
                "payload": JSON.stringify(payload)
            }
        })
    } catch (err) {
        console.log(err)

        const { sourceFolderId, destFolderId } = payload
        getFolderData(sourceFolderId)
        if (sourceFolderId !== destFolderId) {
            getFolderData(destFolderId)
        }
    }
}

const updateFoldersOnServer = async (auth, payload) => {
    try {
        await API.sendGraphSecure(auth, {
            query: `mutation reorderFolders($payload: String!) {
            reorderFolders(payload: $payload) {
                    success
                }
            }`,
            variables: {
                "payload": JSON.stringify(payload)
            }
        })
    } catch (err) {
        console.log(err)
    }
}

const findDocOwnerInSharedUsers = (docOwnerId, sharedUsers) => {
    return !!sharedUsers && sharedUsers.find(sharedU => sharedU._id === docOwnerId)
}

const findDocOwnerInSharedCampaigns = (docOwnerId, sharedCampaigns) => {
    return sharedCampaigns && !!sharedCampaigns.find(sharedC => {
        return !!sharedC?.users?.find(sharedCUser => sharedCUser._id === docOwnerId)
    })
}

export default useOnInventoryDrop;



export const useOnDrop = () => {
    const [, setSource] = useAtom(dragSource_atom)
    const [, setDestination] = useAtom(dragDestination_atom)

    const onDrop = useCallback((type, payload, folderId, side) => {
        // if not a remove or place payload, skip it
        if (payload.removedIndex === null && payload.addedIndex === null) return

        //if the payload is for an item being removed, set it to the pulled Item state for later use
        if (payload.removedIndex !== null) {
            setSource({ type, ...payload, folderId, side })
        }

        if (payload.addedIndex !== null) {
            setDestination({ type, ...payload, folderId, side })
        }
    }, [setDestination, setSource])

    return onDrop
}