import * as THREE from 'three'
import * as C from 'cannon'
import { loadGlbMesh, getGeoDataForDiceType } from '../../functions'

export default async function loadDice(textureKey, user) {
    if (user === 'local') this.textureKey = textureKey
    this.dice[user] = {}
    const diceMeshes = await loadGlbMesh('/dice/dice.glb', textureKey)
    diceMeshes.forEach(die => {
        die.castShadow = true
        die.receiveShadow = true
        const geoData = getGeoDataForDiceType(die.name)
        die = assignRelativePositionsToMesh(die, geoData)
        die.textureKey = textureKey
        this.dice[user][die.name] = die
    })

    this.ready = true
}

function assignRelativePositionsToMesh(die, geoData) {
    const container = new THREE.Group()
    container.name = die.name
    die.position.set(geoData.meshPosXOffset, geoData.meshPosYOffset, geoData.meshPosZOffset)

    die.quaternion.setFromAxisAngle(new C.Vec3(geoData.meshRotXOffset, geoData.meshRotYOffset, geoData.meshRotZOffset), Math.PI / 3)
    die.quaternion.normalize()

    die.scale.set(geoData.meshScale, geoData.meshScale, geoData.meshScale)

    die.invertUpside = geoData.invertUpside || false;

    container.add(die)
    return container
}