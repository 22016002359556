
import { useCampaignFunctions, useCampaignState } from "../../context/Campaign"
import { useWorkspaceFunctions } from "../../context/Workspace"

const useDocumentCommunications = () => {
    const { socket } = useCampaignState()
    const { campaignDispatch } = useCampaignFunctions()
    const { workspaceDispatch } = useWorkspaceFunctions()

    const documentCommunications = () => {
        socket.on('USER_OPENED_DOCUMENT', data => {
            campaignDispatch({ type: 'USER_OPENED_DOCUMENT', value: data })
        })

        socket.on('USER_CLOSED_DOCUMENT', data => {
            campaignDispatch({ type: 'USER_CLOSED_DOCUMENT', value: data })
        })

        socket.on('MAP_PING', data => {
            campaignDispatch({ type: 'ADD_MAP_PING', value: data })
        })

        socket.on('DOCUMENT_CHANGED_NAME', data => {
            const { documentId, name } = data
            workspaceDispatch({ type: 'RENAME_WINDOW_FROM_DOC_ID', value: { documentId, title: name } })
        })
    }

    return documentCommunications
}

export default useDocumentCommunications