import React from 'react'
import { TextButton } from '../../../../../../components/Buttons'

const PaymentInfo = ({ info, onUpdatePayment }) => {
    return (
        <div>
            <label>Payment Information</label>
            <div className="card row space-between centered-vertically margin-bottom-1">
                <div className="row margin-left-1">
                    <p className="margin-right-1 t-bold t-uppercase">{info.method}</p>
                    <p className="t-uppercase t-bold">{info.details}</p>
                </div>
                <TextButton className="t-uppercase" onClick={onUpdatePayment}>
                    Update Payment
                </TextButton>
            </div>
        </div>
    )
}

export default PaymentInfo
