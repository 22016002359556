
import React, { useEffect, useState } from 'react'
import './TextInput.css'

/**
 * @param {{
 * id: string, className: string, name: string, label: string, value: string, 
 * type: string, placeholder: string, onChange: function, error: string, onBlur: function
 * style, disabled, defaultValue, readOnly, inputRef, onKeyDown
 * }}
 */

const TextInput = ({
    id, className, inputClassName, name, label, value, type,
    placeholder, onChange, error, onBlur, style, disabled,
    defaultValue, readOnly, inputRef, onKeyDown
}) => {

    const [errorShakeClass, setErrorShakeClass] = useState('')

    useEffect(() => {
        if (error && error !== '') {
            setErrorShakeClass('textInput-shake')
            window.setTimeout(() => setErrorShakeClass(''), 100)
        }
    }, [error])

    return (
        <div className={`textInput-container ${className} ${errorShakeClass}`}>
            {error ? (
                <p className="textInput-error">{error}</p>
            ) : (label && <label className="textInput-label c-shade-6" htmlFor={id}>{label}</label>)}
            <input
                id={id}
                className={`textInput bg-white bc-shade-3 c-shade-7 ${inputClassName}`}
                name={name}
                placeholder={placeholder}
                type={type || ""}
                onChange={onChange}
                value={value}
                defaultValue={defaultValue}
                onBlur={onBlur}
                style={style}
                disabled={disabled}
                readOnly={readOnly}
                unselectable={`readOnly`}
                ref={inputRef}
                onKeyDown={onKeyDown}
            />
        </div>
    )
}

export default TextInput