import { useCallback } from 'react';
import { useAuthState } from '../context/Auth';
import { useInventoryFunctions } from '../context/Inventory';
import API from '../functions/API';

const useCreateFolder = (setLoading = () => null) => {
    const { user, auth } = useAuthState()
    const { inventoryDispatch } = useInventoryFunctions()

    const createFolder = useCallback(() => {
        setLoading(true)
        const abort = new AbortController()
        API.sendGraphSecure(auth, {
            query: `mutation {
                createFolder(ownerId: "${user._id}") {
                    _id
                    name
                    owner {
                        _id
                    }
                    documents {
                        left {
                            _id
                            name
                            kind
                            owner {
                                _id
                            }
                        }
                        right {
                            _id
                            name
                            kind
                            owner {
                                _id
                            }
                        }
                    }
                    sharedCampaigns {
                        _id
                        title
                        owner {
                            _id
                        }
                    }
                    sharedUsers {
                        _id
                        name
                    }
                }
            }`
        }, { abort }).then((response) => {
            const newFolder = response.data.createFolder
            inventoryDispatch({ type: 'ADD_FOLDER', value: newFolder })
            setLoading(false)

            return newFolder
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }, [auth, inventoryDispatch, setLoading, user._id])

    return createFolder
};

export default useCreateFolder;
