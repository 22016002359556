import React, { useReducer, useContext, useMemo } from 'react'
import reducer from './reducer'
import buildFunctions from './buildFunctions'

const InventoryStateContext = React.createContext()
const InventoryFunctionsContext = React.createContext()

function InventoryProvider({ children }) {
    const initialState = {
        folders: [],
        documents: {
            left: [],
            right: []
        },
        campaigns: [],
        sharedFoldersLoading: false,
        batchedFolderUpdates: null
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <InventoryStateContext.Provider value={state}>
            <InventoryFunctionsContext.Provider value={dispatch}>
                {children}
            </InventoryFunctionsContext.Provider>
        </InventoryStateContext.Provider>
    )
}

function useInventoryState() {
    const InventoryState = useContext(InventoryStateContext)
    if (typeof InventoryState === undefined) {
        throw new Error('useInventoryState must be used within an InventoryProvider')
    }

    return InventoryState
}


function useInventoryFunctions() {
    const dispatch = useContext(InventoryFunctionsContext)
    if (typeof dispatch === undefined) {
        throw new Error('useInventoryUpdater must be used within an InventoryProvider')
    }
    const functions = useMemo(() => buildFunctions(dispatch), [dispatch])

    return functions
}

export {
    InventoryProvider,
    useInventoryState,
    useInventoryFunctions
}
