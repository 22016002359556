const getSettings = (settings, countIndex) => {
    const defaultSettings = {
        width: window.innerWidth - window.innerWidth / 4,
        height: window.innerHeight - window.innerHeight / 4,
        x: settings.width ? window.innerWidth / 2 - settings.width / 2 : window.innerWidth / 8,
        y: settings.height ? window.innerHeight / 2 - settings.height / 2 : window.innerHeight / 8,
        color: 'gray',
        minimized: false,
        maximized: false,
        zIndex: countIndex
    }

    return { ...defaultSettings, ...settings }
}

export default getSettings