import React from 'react'

const Send = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="66.351" height="67.593" viewBox="0 0 66.351 67.593">
            <g id="Group_291" data-name="Group 291" transform="translate(-383.654 -369.959)">
                <path id="Path_1329" data-name="Path 1329" d="M394.347,372.081l31.675,31.675-31.675,31.675" transform="translate(22.483 0)" fill="none" stroke="#647682" />
                <line id="Line_92" data-name="Line 92" x1="63.351" transform="translate(385.154 403.756)" fill="none" stroke="#647682" />
            </g>
        </svg>
    )
}

export default Send
