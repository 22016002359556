import React, { useCallback } from 'react'

import { TextButton } from '../../../../components/Buttons'
import Icon from '../../../../components/Icon'
import { useAuthState } from '../../../../context/Auth'
import { useCampaignState, useCampaignFunctions } from '../../../../context/Campaign'
import { useErrorFunctions } from '../../../../context/Error'
import { useWorkspaceFunctions } from '../../../../context/Workspace'

const KickedFromCampaign = ({ _index }) => {
    const { auth, user } = useAuthState()
    const { socket, waitingForInviteId } = useCampaignState()
    const { cancelInviteRequest } = useCampaignFunctions()
    const { setError } = useErrorFunctions()
    const { closeWindow } = useWorkspaceFunctions()

    const acceptKick = useCallback(() => {
        cancelInviteRequest(auth, user._id, waitingForInviteId, socket, setError)
        closeWindow(_index)
    }, [auth, user._id, waitingForInviteId, socket, setError, cancelInviteRequest, closeWindow, _index])

    return (
        <div className="col centered margin-horizontal-1">
            <div className="section row waiting-for-invite-icon fail">
                <Icon value="close" />
            </div>
            <h1 className="waiting-for-invite-title">Kicked</h1>
            <p className="t-centered">It appears the game master has removed you from the campaign.</p>
            <TextButton onClick={acceptKick}>Okay</TextButton>
        </div>
    )
}

export default KickedFromCampaign
