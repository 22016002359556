export default function convertStringIntoDice(string, defaultDiceValue = 'D20', label = '') {
    const defaultDiceNumValues = defaultDiceValue.matchAll(/((\d+|\+|)([-+d<>](\d)+))+/gmi)
    const defaultDiceRollablesArray = [...defaultDiceNumValues].map(val => {
        const string = val[0]
        const keys = [...string.matchAll(/d(4|6|8|100|10|12|20)/gmi)].map(key => key?.[0].toUpperCase())
        const quantities = [...string.matchAll(/(\d+d|d)/gmi)].map(qty => qty?.[0].slice(0, -1))

        if (keys.length === 0) {
            keys.push('D20')
        }
        const dice = keys.map((key, i) => ({
            key: keys[i]?.length ? keys[i] : defaultDiceValue,
            quantity: quantities[i]?.length ? quantities[i] : '1',
        }))

        return dice
    })

    const numValues = string.matchAll(/((\d+|\+|)([-+d<>](\d)+))+/gmi)
    const rollablesArray = [...numValues].map(val => {
        const string = val[0]
        const keys = [...string.matchAll(/d(4|6|8|100|10|12|20)/gmi)].map(key => key?.[0].toUpperCase())
        const quantities = [...string.matchAll(/(\d+d|d)/gmi)].map(qty => qty?.[0].slice(0, -1))
        const adds = [...string.matchAll(/([-+]\d+(?!d)+)/gmi)].map(addOrSub => addOrSub?.[0])
        const compares = [...string.matchAll(/[>|<]+\d+/gmi)].map(compare => compare?.[0])

        let usesDefault = false
        let dice = keys.map((key, i) => ({
            key: keys[i]?.length ? keys[i] : defaultDiceValue,
            quantity: quantities[i]?.length ? quantities[i] : '1',
        }))
        if (keys.length === 0 && (adds.length > 0 || compares.length > 0)) {
            dice = dice.concat(defaultDiceRollablesArray[0])
            usesDefault = true
        }

        const totalAddOrSub = adds.reduce((acc, val) => acc + parseInt(val), 0)
        const finalCompare = compares?.[0]?.substring?.(1) || 0

        let finalValue = ''
        if (!usesDefault) {
            finalValue += dice
                .map(die => (die.quantity || '') + (die.key || ''))
                .join('+')
        }

        if (!isNaN(totalAddOrSub)) {
            if (totalAddOrSub !== 0) {
                finalValue += `${totalAddOrSub > -1 ? '+' : ''}${totalAddOrSub}`
            }
        }

        if (compares.length > 0) {
            finalValue += `${compares[0]}`
        }

        const ret = {
            value: finalValue,
            dice,
            addOrSub: totalAddOrSub,
            succeedsOn: !isNaN(parseInt(finalCompare)) ? parseInt(finalCompare) : 0,
            succeedsOnDiceOrRoll: 'Roll',
            succeedsOnGreaterOrLess: compares?.[0]?.substring?.(0, 1) === ">" ? 'greater' : 'less',
            label,
        }
        return ret
    })

    return rollablesArray
}