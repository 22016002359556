import API from '../../../functions/API'
import TYPES from '../../../config/graph-request-types'

export async function createCampaign(title, auth, items, description, setLoading, setError) {
    setLoading("creating-campaign")
    try {
        const response = await API.sendGraphSecure(auth, {
            query: `mutation($title: String!, $items: String!) {
                        createCampaign(title: $title, items: $items) {
                            campaign {
                                ${TYPES.fullCampaign}
                            }
                            folder {
                                ${TYPES.fullFolder}
                            }
                        }
                    }`,
            variables: {
                title,
                description,
                items: JSON.stringify(items)
            }
        })

        if (response) {
            setLoading(false)
            return response.data.createCampaign
        }
    } catch (err) {
        setError(err.message)
        setLoading(false)
        throw new Error('Error creating campaign')
    }
}