import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAuthState } from '../../../../context/Auth/index'
import { useCampaignState } from '../../../../context/Campaign/index'
import { useWorkspaceState } from '../../../../context/Workspace/index'
import Loading from '../../../Loading/index'
import BoundingBox from '../BoundingBox/index'
import Drawings from '../Drawings/index'
import Fog from '../Fog/index'
import Grid from '../Grid/index'
import Measure from '../Measure/index'
import Pings from '../Pings/index'
import Auras from './Auras'
import './MapTokens.css'
import Token from './Token'
import useBoundingBox from './useBoundingBox'
import useDrawing from './useDrawing'
import useMeasure from './useMeasure'
import usePan from './usePan'
import usePing from './usePing'
import usePlaceToken from './usePlaceToken'
import useSelectTokens from './useSelectTokens'
import useTokenDrag from './useTokenDrag'
import useUpdateSelectedTokens from './useUpdateSelectedTokens'
import useZoom from './useZoom'

const MapTokens = ({
    docId, tool, tokens, updateDoc, backgroundImageUrl,
    showGrid, gridSize, gridLeft, gridTop, pingSettings,
    openTokenModal, onRightClick, windowPos, isFullScreen, showNames,
    fogEnabled, fogCuts, ownerId, windowIndex, paint, paintSettings,
    camera, setCamera, showGridScaler
}) => {
    const wrapperInstance = useRef()
    const instance = useRef()
    const fogCtx = useRef(null)

    const { user } = useAuthState()
    const { pan, startPan, stopPan } = usePan(camera, setCamera)
    const zoom = useZoom(setCamera, windowPos, isFullScreen)
    const [imageBounds, setImageBounds] = useState({ w: 513, h: 513 })

    const { activeCampaign } = useCampaignState()

    const {
        addToSelection,
        removeFromSelection,
        clearSelection,
        currentlySelectedTokens,
        setCurrentlySelectedTokens
    } = useSelectTokens()

    const { placeToken, tokenPlacer } = usePlaceToken(tokens, camera, updateDoc, windowPos, isFullScreen, gridSize, showGrid, gridLeft, gridTop, clearSelection)

    const { startMeasure, updateMeasure, clearMeasure, measure, setMeasure, setMeasureFromToken } = useMeasure(camera, isFullScreen, windowPos, gridSize, gridLeft, gridTop)

    const {
        startTokenDrag,
        onTokenDragging,
        releaseTokenDrag
    } = useTokenDrag(currentlySelectedTokens, setCurrentlySelectedTokens, camera, setCamera, tokens, updateDoc, showGrid, gridSize, gridLeft, gridTop, setMeasure, tool)

    const [tokensNotInFog, setTokensNotInFog] = useState([])

    const { startBB, updateBB, clearBB, boundingBox } = useBoundingBox(camera, windowPos, isFullScreen, gridLeft, gridTop)
    const { addPing, pings, addRemotePing, sendRemotePing, removePing } = usePing(camera, isFullScreen, windowPos, pingSettings, docId)

    const {
        startDrawing, updateDrawing, finishDrawing,
        currentDrawing, setCurrentDrawing, startDrawingDrag,
        onDrawingDrag, releaseDrawingDrag
    } = useDrawing(
        isFullScreen, windowPos, camera, showGrid, gridLeft, gridTop, gridSize,
        updateDoc, paint, paintSettings
    )

    useUpdateSelectedTokens(setCurrentlySelectedTokens, tokens)

    const { diceInHand } = useWorkspaceState()

    useEffect(() => {
        if (backgroundImageUrl) {
            setImageBounds({ w: 500, h: 500 })
            const image = new Image()
            image.src = backgroundImageUrl
            image.onload = () => {
                setImageBounds({ w: image.width, h: image.height })
            }
        }
    }, [backgroundImageUrl])

    const mouseDown = useCallback((e) => {
        if (diceInHand) return
        if (e.button === 0) {
            if (tokenPlacer) {
                placeToken(e)
            } else {
                if (tool === 'pan') {
                    startPan({ x: e.clientX, y: e.clientY })
                } else if (tool === 'select') {
                    const onBackground = e?.target?.className?.includes?.('mapTokensWrapper')
                    if (onBackground && currentlySelectedTokens.length) {
                        clearSelection()
                        openTokenModal([])
                    }
                    if (onBackground) startBB(e)
                } else if (tool === 'ping') {
                    const newPing = addPing(e)
                    sendRemotePing(newPing)
                } else if (tool === 'measure') {
                    startMeasure(e)
                } else if (tool === 'draw') {
                    const onBackground = e?.target?.className?.includes?.('mapTokensWrapper')
                    if (onBackground) startDrawing(e)
                }
            }
        } else if (e.button === 2) {
            startPan({ x: e.clientX, y: e.clientY })
        }
    }, [addPing, clearSelection, currentlySelectedTokens.length, diceInHand, openTokenModal, placeToken, sendRemotePing, startBB, startDrawing, startMeasure, startPan, tokenPlacer, tool])

    const onMouseMove = useCallback((e) => {
        if (diceInHand) return
        onTokenDragging(e, isFullScreen, windowPos)
        pan(e)
        updateBB(e)
        updateDrawing(e)
        if (tool === 'measure') updateMeasure(e)
        onDrawingDrag(e)
    }, [diceInHand, isFullScreen, onDrawingDrag, onTokenDragging, pan, tool, updateBB, updateDrawing, updateMeasure, windowPos])

    const mouseUp = useCallback((e) => {
        if (diceInHand) return
        releaseTokenDrag(e, windowPos, isFullScreen, openTokenModal, instance, showGrid, gridSize, gridLeft, gridTop, tool)
        clearBB(e)
        clearMeasure()
        finishDrawing(e)
        releaseDrawingDrag(e, instance, setCamera)
    }, [clearBB, clearMeasure, diceInHand, finishDrawing, gridLeft, gridSize, gridTop, isFullScreen, openTokenModal, releaseDrawingDrag, releaseTokenDrag, setCamera, showGrid, tool, windowPos])

    useEffect(() => {
        const mapDiv = wrapperInstance.current
        if (mapDiv) {
            mapDiv.addEventListener('mousedown', mouseDown)
            mapDiv.addEventListener('mousemove', onMouseMove)
            mapDiv.addEventListener('mouseup', mouseUp)
        }

        window.addEventListener('mouseup', stopPan)

        return () => {
            if (mapDiv) {
                mapDiv.removeEventListener('mousedown', mouseDown)
                mapDiv.removeEventListener('mousemove', onMouseMove)
                mapDiv.removeEventListener('mouseup', mouseUp)
            }

            window.removeEventListener('mouseup', stopPan)
        }
    }, [mouseDown, mouseUp, onMouseMove, stopPan])

    const tokenData = useMemo(() => {
        const selectable = tool === 'select'
        const clientIsMapOwner = ownerId === user?._id
        return tokens.map((token, index) => {
            const { id, src, x, y, name, rotation, scale, layer, flip, labels, hidden, documents, locked, alignment } = token
            return (
                <Token
                    key={id}
                    {...{
                        selected: currentlySelectedTokens.find(sToken => sToken.id === token.id),
                        selectable,
                        clientIsMapOwner,
                        id, index, src, x, y, name, rotation, scale, flip, layer, labels, hidden, alignment,
                        diceInHand,
                        documents,
                        gridLeft,
                        gridTop,
                        gridSize,
                        locked,
                        onRightClick,
                        showNames,
                        addToSelection,
                        removeFromSelection,
                        startTokenDrag,
                        boundingBox,
                        isFullScreen,
                        windowIndex,
                        showGrid,
                        setMeasureFromToken,
                        tokenOwner: user?._id === token.ownerId || (activeCampaign?.owner && activeCampaign?.owner._id === user?._id),
                        notUnderFog: fogEnabled ? tokensNotInFog.find(fToken => fToken.id === token.id) : true
                    }}
                />
            )
        })
    }, [activeCampaign?.owner, addToSelection, boundingBox, currentlySelectedTokens, diceInHand, fogEnabled, gridLeft, gridSize, gridTop, isFullScreen, onRightClick, ownerId, removeFromSelection, setMeasureFromToken, showGrid, showNames, startTokenDrag, tokens, tokensNotInFog, tool, user?._id, windowIndex])

    const auraData = useMemo(() => {
        return tokens.map((token, i) => {
            const { labels, x, y, scale, alignment, hidden } = token
            const centered = alignment !== 'corner'

            const tokenOwner = user?._id === token.ownerId || (activeCampaign?.owner && activeCampaign?.owner._id === user?._id)

            return <div
                key={`${token.id}-aura-${i}`}
                id={`token-auras-${token.id}`}
                className={`aura-container`}
                style={{
                    left: x,
                    top: y,
                    width: gridSize + 1,
                    height: gridSize + 1,
                    opacity: hidden ? tokenOwner ? 0.5 : 0 : 1,
                }}
            >
                <Auras {...{ scale, centered, labels, ww: gridSize + 1, hh: gridSize + 1, gridSize }} />
            </div>
        })
    }, [activeCampaign?.owner, gridSize, tokens, user?._id])


    return (
        <>
            <div
                ref={wrapperInstance}
                className={`mapTokensWrapper ${backgroundImageUrl ? imageBounds !== null && 'bgLoaded' : 'noBG'}`}
                onContextMenu={(e) => e.preventDefault()}
                onWheel={(e) => {
                    if (e?.target?.className?.includes?.('mapTokensWrapper')
                        || e?.target?.className?.includes?.('token')
                        || e?.target?.className?.includes?.('drawing')) {
                        clearMeasure()
                        zoom(e)
                        stopPan()
                        releaseDrawingDrag(e, instance, setCamera, gridLeft, gridTop, gridSize)
                        releaseTokenDrag(e, windowPos, isFullScreen, openTokenModal, instance, showGrid, gridSize, gridLeft, gridTop, tool, true)
                        instance.current.classList.remove('addPan')
                    }
                }}
            >
                <Measure {...{ measure, camera, gridSize, gridLeft, gridTop }} />
                <div
                    ref={instance}
                    className="MapTokens"
                    style={{
                        left: (camera.x || 0) + 'px',
                        top: (camera.y || 0) + 'px',
                        width: imageBounds?.w + 'px' || '500px',
                        height: imageBounds?.h + 'px' || '500px',
                        backgroundImage: `url("${backgroundImageUrl}")`,
                        transform: `scale(${camera.zoom})`
                    }}
                >
                    <Grid {...{ gridSize, gridLeft, gridTop, showGrid, tool, showGridScaler, camera, updateDoc, windowIndex, zoom }} />
                    {boundingBox && <BoundingBox boundingBox={boundingBox} camera={camera} />}
                    <Pings
                        camera={camera}
                        docId={docId}
                        addRemotePing={addRemotePing}
                        pings={pings}
                        removePing={removePing}
                        windowPos={windowPos}
                    />
                    <Drawings
                        tool={tool}
                        diceInHand={diceInHand}
                        width={imageBounds?.w || 513}
                        height={imageBounds?.h || 513}
                        currentDrawing={currentDrawing}
                        setCurrentDrawing={setCurrentDrawing}
                        paint={paint}
                        showGrid={showGrid}
                        windowPos={windowPos}
                        isFullScreen={isFullScreen}
                        updateDoc={updateDoc}
                        startDrawingDrag={startDrawingDrag}
                        camera={camera}
                    />
                    <div className="gridOffsetContainer" style={{ transform: `translate(${gridLeft}px, ${gridTop}px)` }}>
                        {auraData}
                        {tokenData}
                    </div>
                    {fogEnabled && <Fog
                        tokens={tokens}
                        setTokensNotInFog={setTokensNotInFog}
                        fogCtx={fogCtx}
                        tool={tool}
                        width={imageBounds?.w || 500}
                        height={imageBounds?.h || 500}
                        fogEnabled={fogEnabled}
                        fogCuts={fogCuts}
                        ownerId={ownerId}
                        updateDoc={updateDoc}
                        camera={camera}
                        windowPos={windowPos}
                        isFullScreen={isFullScreen}
                        wrapperInstance={wrapperInstance}
                        gridSize={gridSize}
                        boundingBox={boundingBox}
                    />}
                </div>
            </div>
            {(backgroundImageUrl && imageBounds === null) && <Loading />}
        </>
    )
}

export default MapTokens
