import React, { useEffect, useState } from 'react'
import { D4, D6, D8, D10, D12, D20, D100 } from './DiceIcons'
import { ArrowRotateLeft } from '@styled-icons/fa-solid/ArrowRotateLeft'
import { usePrevious } from '../../hooks/usePrevious'

const DiceValueIcon = ({ icon, value, skin, drop, failure, onReRoll, rerollCount }) => {
    let size = ''
    if (value > 9) size = 'small'
    if (value > 99) size = 'xsmall'

    const [rolling, setRolling] = useState(false)

    const iconExtraIndex = icon.indexOf('-')
    const rawIcon = iconExtraIndex > -1 ? icon.substring(0, iconExtraIndex) : icon

    const classNames = `diceValueIcon ${rolling && 'rolling'} ${rawIcon} ${size} ${drop && 'drop'} ${failure && 'failure'}`
    const skinProp = failure ? 'failure' : skin

    const [hovered, setHovered] = useState(false)
    const reRollIcon = <ArrowRotateLeft className="size-75" />

    const onClick = () => {
        if (onReRoll) {
            onReRoll()
        }
    }

    const prevValue = usePrevious(value)
    useEffect(() => {
        if (prevValue !== value) {
            setRolling(true)
            setHovered(true)
            setTimeout(() => {
                setRolling(false)
                setHovered(false)
            }, 600)
        }
    }, [prevValue, value])

    return (
        <div className={classNames}
            onMouseEnter={() => onReRoll && setHovered(true)}
            onMouseLeave={() => onReRoll && setHovered(false)}
            onClick={onClick}
        >
            <DiceIcon
                {...{ rawIcon, classNames, skinProp, value: hovered ? reRollIcon : value }}
            />
            {rerollCount > 0 && (
                <div className="hasReRollIcon rounded bg-shade-1 c-shade-9">
                    <ArrowRotateLeft />
                    <span>{rerollCount}</span>
                </div>
            )}
        </div >
    )
}


const DiceIcon = ({ rawIcon, classNames, skinProp, value }) => {

    switch (rawIcon) {
        case 'D4':
            return <>
                <D4 skin={skinProp} />
                <p>{value}</p>
            </>

        case 'D6':
            return <>
                <D6 skin={skinProp} />
                <p>{value}</p>
            </>

        case 'D8':
            return <>
                <D8 skin={skinProp} />
                <p>{value}</p>
            </>

        case 'D10':
            return <>
                <D10 skin={skinProp} />
                <p>{value === 10 ? '0' : value}</p>
            </>

        case 'D12':
            return <>
                <D12 skin={skinProp} />
                <p>{value}</p>
            </>

        case 'D20':
            return <>
                <D20 skin={skinProp} />
                <p>{value}</p>
            </>

        case 'D100':
            return <>
                <D100 skin={skinProp} />
                <p>{value === 100 ? '00' : value}</p>
            </>

        default: return null
    }
}

export default DiceValueIcon
