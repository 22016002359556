export function getMarkersBounds(currentDrawing) {
    const points = currentDrawing.points
    if (points) {
        let nbound = null, sbound = null, ebound = null, wbound = null
        for (let i = 0; i < points.length - 1; i++) {
            if (i % 2 === 0) {
                //on an even point (x)
                var coordX = points[i];
                var coordY = points[i + 1];


                if (ebound === null || coordX > ebound) {
                    ebound = coordX;
                }
                else if (wbound === null || coordX < wbound) {
                    wbound = coordX;
                }
                if (nbound === null || coordY < nbound) {
                    nbound = coordY;
                } else if (sbound === null || coordY > sbound) {
                    sbound = coordY;
                }
            } else continue
        }

        nbound -= currentDrawing.thickness / 2
        sbound += currentDrawing.thickness / 2
        wbound -= currentDrawing.thickness / 2
        ebound += currentDrawing.thickness / 2

        return {
            top: nbound,
            height: sbound - nbound,
            left: wbound,
            width: ebound - wbound
        }
    }
}