import GlobalStorage from "../../../functions/GlobalStorage"

export function logOutOfCampaign(socket) {
    if (socket) {
        socket.off()
        socket.disconnect()
    }

    const soundData = GlobalStorage.get('SOUND_DATA') || {}
    Object.values(soundData).forEach(sd => {
        sd && sd.pause()
    })
    GlobalStorage.set('SOUND_DATA', {})

    window._ADVENTURINGKITSOCKET = null
    window.ADVK_LOADED_INITITAL_CHATS = false
    this.dispatch({ type: 'LOGOUT_CAMPAIGN' })
}