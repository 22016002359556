import React from 'react'

const Lute = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="95.191" height="95.203" viewBox="0 0 95.191 95.203">
            <g transform="translate(1.389 7.026)">
                <path d="M381.481,46.781l-4.88-3.676a2.956,2.956,0,0,1,3.557-4.723,3.055,3.055,0,0,1,.583.583l3.676,4.88a2.1,2.1,0,0,1-2.936,2.936Zm0,0" transform="translate(-307.096 -37.787)" fill="#dc6742" />
                <path d="M339.176,83.147l-4.88-3.676a2.956,2.956,0,0,1,3.558-4.723,3.053,3.053,0,0,1,.583.583l3.676,4.88a2.1,2.1,0,0,1-2.936,2.936Zm0,0" transform="translate(-272.658 -67.391)" fill="#dc6742" />
                <path d="M424.466,89.761l3.676,4.88a2.956,2.956,0,0,0,4.723-3.557,3.079,3.079,0,0,0-.583-.583l-4.88-3.676a2.1,2.1,0,0,0-2.936,2.936Zm0,0" transform="translate(-346.678 -77.363)" fill="#dc6742" />
                <path d="M388.1,132.065l3.675,4.88a2.957,2.957,0,0,0,4.723-3.558,3.076,3.076,0,0,0-.583-.583l-4.88-3.676a2.1,2.1,0,0,0-2.935,2.936Zm0,0" transform="translate(-317.074 -111.801)" fill="#dc6742" />
                <path d="M57.043,229.177C70.425,214.285,68.628,187,68.628,187l-9.092-9.092s-27.28-1.8-42.172,11.585c-.4.357-.788.723-1.166,1.1a28.1,28.1,0,0,0,39.745,39.745C56.14,230.146,56.881,229.369,57.043,229.177Zm0,0" transform="translate(-7.967 -151.792)" fill="#dc6742" />
            </g>
            <path d="M216.8,187.006l-9.092-9.092a75.672,75.672,0,0,0-8.711.091l-6.329,5.389a7.629,7.629,0,0,0-.449,11.2l7.9,7.9a7.628,7.628,0,0,0,11.2-.45l5.389-6.329A75.468,75.468,0,0,0,216.8,187.006Zm0,0" transform="translate(-154.753 -144.767)" fill="#b14e2f" />
            <path d="M260.1,193.915l-8.068-2-2-8.069,6.558-5.583,7.581,1.512,1.511,7.58Zm0,0" transform="translate(-203.635 -145.118)" fill="#e7886e" />
            <path d="M328.683,131.919l-7.093-1.023-1.024-7.093,6.557-5.583,5.695,1.448,1.449,5.695Zm0,0" transform="translate(-261.052 -96.237)" fill="#e7886e" />
            <path d="M225.574,208.293l-4.867,4.144a2.051,2.051,0,0,0-.121,3.011l7.9,7.9a2.05,2.05,0,0,0,3.011-.121l4.144-4.866Zm0,0" transform="translate(-179.174 -169.562)" fill="#f3bdae" />
            <path d="M294.4,162.92l-9.092-9.092,6.557-5.582,8.117,8.117Zm0,0" transform="translate(-232.347 -120.681)" fill="#f3bdae" />
            <path d="M120.585,321l-13.178-13.178a2.2,2.2,0,0,0-3.107,0l-1.628,1.628a2.2,2.2,0,0,0,0,3.107l13.177,13.177a2.2,2.2,0,0,0,3.107,0l1.628-1.628A2.2,2.2,0,0,0,120.585,321Zm0,0" transform="translate(-83.15 -250.062)" fill="#e7886e" />
            <path d="M230.547,350.31a1.259,1.259,0,0,0-2.4-.5,35.93,35.93,0,0,1-4.638,7.383,7.854,7.854,0,0,1-3.661,2.554,3.416,3.416,0,1,0,3.508,5.652C227.228,361.522,230.721,355.825,230.547,350.31Zm0,0" transform="translate(-177.185 -284.166)" fill="#b14e2f" />
            <path d="M86.308,224.961a1.259,1.259,0,0,1,.5,2.4A36,36,0,0,0,79.427,232a7.857,7.857,0,0,0-2.554,3.661,3.416,3.416,0,1,1-5.652-3.508C75.1,228.28,80.793,224.787,86.308,224.961Zm0,0" transform="translate(-57.256 -183.126)" fill="#b14e2f" />
            <path d="M362.976,66.854l-7.144-7.143,14.419-12.277,6.089-1.087-1.088,6.088Zm0,0" transform="translate(-289.76 -37.73)" fill="#f3bdae" />
            <path d="M442.48,13.862l-5.453-5.453a3.1,3.1,0,0,0-4.761.453l-2.5,3.689a2.572,2.572,0,0,0,.311,3.261l5,5a2.571,2.571,0,0,0,3.26.311l3.689-2.5A3.1,3.1,0,0,0,442.48,13.862Zm0,0" transform="translate(-349.587 -6.106)" fill="#dc6742" />
            <path d="M29.986,243.114a1.451,1.451,0,0,0,1.61-1.406,1.4,1.4,0,0,0-1.4-1.384h-.01a26.715,26.715,0,0,1-19.09-45.6c.331-.331.7-.675,1.112-1.05,4.681-4.207,11.157-7.309,19.246-9.22a1.395,1.395,0,0,0-.641-2.715c-8.549,2.02-15.436,5.337-20.469,9.86-.454.408-.853.785-1.22,1.152a29.5,29.5,0,0,0,20.858,50.368Zm0,0" transform="translate(-0.499 -147.91)" />
            <path d="M102.279,300.747a3.626,3.626,0,0,0-5.08,0l-1.629,1.629a3.6,3.6,0,0,0,0,5.08l13.182,13.181a3.626,3.626,0,0,0,5.08,0l1.629-1.629a3.6,3.6,0,0,0,0-5.08Zm11.209,16.289-1.629,1.628a.809.809,0,0,1-1.135,0L97.543,305.484a.8.8,0,0,1,0-1.135l1.628-1.629a.807.807,0,0,1,1.135,0L113.488,315.9A.8.8,0,0,1,113.488,317.036Zm0,0" transform="translate(-77.037 -243.98)" />
            <path d="M211.375,360.06a4.86,4.86,0,0,0,6.985.189c4.925-4.925,7.767-10.952,7.6-16.123a2.661,2.661,0,0,0-2.674-2.587,2.609,2.609,0,0,0-2.393,1.551,34.746,34.746,0,0,1-4.461,7.1,6.445,6.445,0,0,1-3.013,2.1A4.885,4.885,0,0,0,211.375,360.06Zm1.663-3.723a1.993,1.993,0,0,1,1.269-1.406,9.225,9.225,0,0,0,4.312-3.01,38.22,38.22,0,0,0,4.558-7.125c-.089,4.29-2.592,9.281-6.791,13.479A2.014,2.014,0,0,1,213.039,356.338Zm0,0" transform="translate(-171.201 -278.032)" />
            <path d="M72.264,229.93a6.443,6.443,0,0,1,2.1-3.012,34.744,34.744,0,0,1,7.1-4.462,2.6,2.6,0,0,0,1.5-2.926,2.789,2.789,0,0,0-2.976-2.147c-5.054,0-10.915,2.845-15.681,7.611a4.807,4.807,0,1,0,7.963,4.937Zm-6.573-1.48a2.017,2.017,0,0,1,.583-1.485c4.179-4.179,9.2-6.7,13.479-6.791a38.27,38.27,0,0,0-7.125,4.559,9.236,9.236,0,0,0-3.01,4.312A2.03,2.03,0,0,1,65.691,228.451Zm0,0" transform="translate(-51.327 -176.962)" />
            <path d="M238.054,23.114a4.4,4.4,0,0,0-1.691-4.083L233.8,17.1l.493-.579a3.969,3.969,0,0,0,3.773-.369l3.69-2.5a4.541,4.541,0,0,0,.658-6.9l-5.456-5.456a4.541,4.541,0,0,0-6.9.658l-2.5,3.69a3.97,3.97,0,0,0-.365,3.769l-.583.5L224.67,7.337a4.411,4.411,0,0,0-6.947.007,4.357,4.357,0,0,0,.858,6.095l2.058,1.55-1.931,1.644L216.8,14.1a4.352,4.352,0,1,0-6.09,6.1l2.019,1.52L201,31.711a87.35,87.35,0,0,0-16.667,1.033,1.395,1.395,0,1,0,.438,2.755,87.55,87.55,0,0,1,13.01-1.047L189.148,41.8a3.491,3.491,0,0,0-.2,5.06l7.9,7.9a3.475,3.475,0,0,0,5.06-.2l7.35-8.633a87.954,87.954,0,0,1-.846,11.669c-1.092,7.712-3.794,18.463-10.462,25.883l-.027.031c-.115.136-.814.872-1.022,1.081a26.606,26.606,0,0,1-13.461,7.269,1.4,1.4,0,0,0-1.084,1.648A1.417,1.417,0,0,0,184,94.59a29.383,29.383,0,0,0,14.869-8.028c.176-.176.932-.965,1.164-1.233,7.141-7.956,10-19.292,11.146-27.4A87.033,87.033,0,0,0,212,42.708l9.989-11.732,1.52,2.019A4.352,4.352,0,1,0,229.6,26.9L227.075,25l1.644-1.931,1.55,2.058a4.4,4.4,0,0,0,6.095.858A4.324,4.324,0,0,0,238.054,23.114ZM221.66,27.053l-5.007-5.007,12.291-10.464,3.181,3.181Zm8.2-19.847,2.5-3.69a1.725,1.725,0,0,1,2.621-.25l5.456,5.456a1.726,1.726,0,0,1-.25,2.621l-3.69,2.5a1.188,1.188,0,0,1-1.492-.142l-5-5A1.177,1.177,0,0,1,229.864,7.206Zm-7.049,5.929-2.556-1.926a1.562,1.562,0,1,1,2.187-2.188l2.032,2.7Zm-10.732,2.65a1.578,1.578,0,0,1,2.5,0l2,2.658-1.674,1.424-2.516-1.9A1.565,1.565,0,0,1,212.082,15.786ZM201.4,35.029l7.274,7.273-3.771,4.429L196.975,38.8ZM199.781,52.75a.663.663,0,0,1-.963.038l-7.9-7.9a.661.661,0,0,1,.038-.963l3.887-3.31,8.248,8.248Zm10.71-12.579-6.957-6.956,4.427-3.769,6.3,6.3Zm5.583-6.558-5.982-5.981,4.429-3.771,5.324,5.324Zm12.15-4.174a1.56,1.56,0,1,1-2.493,1.878l-1.9-2.517,1.425-1.673,2.652,2A1.555,1.555,0,0,1,228.225,29.439Zm7.057-6.714a1.539,1.539,0,0,1-.6,1.031,1.576,1.576,0,0,1-2.188-.308l-1.926-2.557,1.416-1.663,2.692,2.027A1.572,1.572,0,0,1,235.282,22.724Zm0,0" transform="translate(-148.515 0)" />
        </svg>
    )
}

export default Lute
