import React, { useEffect, useState } from 'react'
import { TabNavigation, Tab } from '../../../components/TabNavigation'
import UserSettings from './settings/UserSettings'
import { SubscriptionSettings } from './settings/SubscriptionSettings'
import './settings.css'
import { useWorkspaceFunctions } from '../../../context/Workspace'
import General from './settings/General'

const Settings = ({ _index, startingTab }) => {
  const [GENERAL_TAB_INDEX, USER_TAB_INDEX, SUBS_TAB_INDEX] = [0, 1, 2]

  const [activeTab, setActiveTab] = useState(startingTab || 0)
  const { changeWindowColor } = useWorkspaceFunctions()
  useEffect(() => {
    if (activeTab === SUBS_TAB_INDEX) {
      changeWindowColor({ index: _index, color: 'secure' })
    } else {
      changeWindowColor({ index: _index, color: 'white' })
    }
  }, [activeTab, changeWindowColor, SUBS_TAB_INDEX, _index])

  return (
    <div className="window-inner settings">
      <TabNavigation tabs={["General", "Account", "Subscription"]} activeTab={activeTab} setTab={setActiveTab} />
      <Tab active={activeTab === GENERAL_TAB_INDEX}><General /></Tab>
      <Tab active={activeTab === USER_TAB_INDEX}><UserSettings /></Tab>
      <Tab active={activeTab === SUBS_TAB_INDEX}><SubscriptionSettings windowIndex={_index} /></Tab>
    </div>
  )
}

export default Settings
