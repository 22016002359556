import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-smooth-dnd'
import { useInventoryFunctions } from '../../context/Inventory/index'
import FolderInner from './partials/ItemIcon/FolderInner'

const Folder = ({
    index, kind, id, name, owner,
    documents, setLoading, sharedCampaigns,
    sharedUsers, draggable, openDocumentWindow,
    minimal, defaultFolder
}) => {
    const [open, setOpen] = useState(defaultFolder)
    const [loadingDocs, setLoadingDocs] = useState(false)
    const { getFolderData } = useInventoryFunctions()
    useEffect(() => {
        if (open && !documents && !loadingDocs) {
            getFolderData(id).then(() => setLoadingDocs(false))
            setLoadingDocs(true)
        }
    }, [documents, getFolderData, id, loadingDocs, open])

    return draggable ? (
        <Draggable key={`chest-item-draggable-${id}`}>
            <div
                id={`folder-${id}`}
                className={`folder folder-${index} ${defaultFolder ? 'default-folder' : 'padding-horizontal-half'} margin-bottom-half col ${(open || defaultFolder) && 'open'}`}
            >
                <FolderInner {...{ id, index, kind, name, documents, owner, open, setOpen, setLoading, openDocumentWindow, draggable, minimal, defaultFolder, sharedCampaigns, sharedUsers }} />
            </div>
        </Draggable>
    ) : (
        <div
            id={`folder-${id}`}
            className={`folder ${defaultFolder && 'default-folder'} col ${(open || defaultFolder) && 'open'}`}
        >
            <FolderInner {...{ id, kind, name, documents, owner, open, setOpen, setLoading, openDocumentWindow, draggable, minimal, defaultFolder, sharedCampaigns, sharedUsers }} />
        </div>
    )
}

export default Folder
