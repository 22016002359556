export function addMarkerPoint(xx, yy, brushCycleReady, currentDrawing, setCurrentDrawing) {
    if (brushCycleReady.current) {
        if (currentDrawing && currentDrawing.tool === 'marker') {
            const newPoints = [...currentDrawing.points]
            newPoints.push(xx, yy)

            setCurrentDrawing((cd) => {
                return ({
                    ...cd,
                    points: newPoints
                })
            })
        }

        brushCycleReady.current = false
        return currentDrawing
    }
}