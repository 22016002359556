import React from 'react'
import Icon from '../Icon'
import Loading from '../Loading'
import './Chat.css'
import '../UserIcon/UserIcon.css'

const ChatUserIcon = ({ id, color, name, className, isCampaignOwner, isCurrentUser, setDialog, loading }) => {

    const initialsSplit = name.split(' ')
    const initials = initialsSplit.length > 1 ? initialsSplit[0][0] + initialsSplit[1][0] : name[0]

    return (
        <div
            onMouseEnter={(e) => {
                const boundingRect = e.target.getBoundingClientRect()
                setDialog && setDialog('user-icon', id, isCurrentUser ? "You" : name, isCurrentUser, boundingRect.left, boundingRect.top)
            }}
            className={`${className} user-icon-circle ${color} chat-user-list-icon ${isCurrentUser ? 'border-thick' : 'border-2'} bc-shade-2 ${loading && 'loading'}`}
        >
            {loading ? <Loading fill /> : initials}
            {isCampaignOwner && <Icon className="chat-user-icon-crown" value="crown" />}
        </div>
    )
}

export default ChatUserIcon