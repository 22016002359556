import { useCallback, useState } from 'react'
import { getDrawPos, getPos } from '../../../../functions/CanvasHelpers'
import { grid } from '../../../../functions/helpers'


const useTokenDrag = (currentlySelectedTokens, setCurrentlySelectedTokens, camera, setCamera, tokens, updateDoc, showGrid, gridSize, gridLeft, gridTop, setMeasure, tool) => {
    const [dragStartCoords, setDragStartCoords] = useState(null)
    const [dragCoords, setDragCoords] = useState(null)

    const setTokens = useCallback((token) => {
        const newTokenIndex = tokens.findIndex(oldToken => oldToken.id === token.id)
        if (newTokenIndex > -1) {
            updateDoc({ p: ['tokens', newTokenIndex], ld: tokens[newTokenIndex], li: token })
        }
    }, [updateDoc, tokens])

    const startTokenDrag = useCallback((e) => {
        if (tool === 'select')
            setDragStartCoords({ x: e.clientX, y: e.clientY })
    }, [tool])

    const onTokenDragging = useCallback((e) => {
        if (dragStartCoords) {
            setDragCoords({ x: e.clientX, y: e.clientY })

            if (dragCoords) {
                currentlySelectedTokens.forEach(token => {
                    if (token.locked) return

                    const centered = token.alignment !== 'corner'
                    const scale = token.scale || 1
                    const diffFromGridSize = (((scale / 4) * gridSize) - gridSize) || 0

                    const startX = getPos(dragStartCoords.x, camera.x, camera.zoom)
                    const startY = getPos(dragStartCoords.y, camera.y, camera.zoom)
                    const shiftX = token.x - startX,
                        shiftY = token.y - startY


                    const x = getPos(dragCoords.x, camera.x, camera.zoom) + shiftX,
                        y = getPos(dragCoords.y, camera.y, camera.zoom) + shiftY


                    if (token?.instance?.current?.style) {
                        token.instance.current.style.left = x + 'px'
                        token.instance.current.style.top = y + 'px'
                        token.instance?.current?.classList?.add?.('dragging')
                    }

                    if (token?.auraInstance?.style) {
                        token.auraInstance.style.left = x + 'px'
                        token.auraInstance.style.top = y + 'px'
                        token.auraInstance?.classList?.add?.('dragging')
                    }

                    token.newX = x
                    token.newY = y

                    if (showGrid) {
                        if (currentlySelectedTokens.length === 1) {
                            const measureX = getDrawPos(x + gridSize / 2 + (centered ? 0 : diffFromGridSize / 2), camera.x, camera.zoom) + gridLeft * camera.zoom,
                                measureY = getDrawPos(y + gridSize / 2 + (centered ? 0 : diffFromGridSize / 2), camera.y, camera.zoom) + gridTop * camera.zoom

                            setMeasure((measure) => ({ ...measure, x2: measureX, y2: measureY }))
                        }
                    }
                })
            }
        }
    }, [dragStartCoords, dragCoords, currentlySelectedTokens, camera.x, camera.zoom, camera.y, showGrid, gridSize, gridLeft, gridTop, setMeasure])

    const releaseTokenDrag = useCallback((e, windowPos, isFullScreen, openTokenModal, instance, showGrid, gridSize, gridLeft, gridTop, tool, disableCamera) => {
        const onBackground = e?.target?.className?.includes?.('mapTokensWrapper')
        const onToken = e?.target?.className?.includes?.('token')
        if (e.button === 0 && (onBackground || onToken)) {
            let hasChanges = false
            const newSelectedTokens = currentlySelectedTokens.map((token) => {
                if (token.newX && token.newY) {
                    hasChanges = true
                    const oldToken = tokens[token.index]

                    const snapToGrid = showGrid && token.alignment !== 'nogrid'

                    token.newX = snapToGrid ? grid(token.newX + gridSize / 2, gridSize) : token.newX
                    token.newY = snapToGrid ? grid(token.newY + gridSize / 2, gridSize) : token.newY

                    if (token?.instance?.current?.style) {
                        token.instance.current.style.left = token.newX + 'px'
                        token.instance.current.style.top = token.newY + 'px'
                        token.instance?.current?.classList?.remove?.('dragging')
                    }

                    if (token?.auraInstance?.style) {
                        token.auraInstance.style.left = token.newX + 'px'
                        token.auraInstance.style.top = token.newY + 'px'
                        token.auraInstance?.classList?.remove?.('dragging')
                    }

                    setTokens({ ...oldToken, x: token.newX, y: token.newY, newX: null, newY: null })
                    return ({ ...token, x: token.newX, y: token.newY, newX: null, newY: null })
                }

                return ({ ...token })
            })

            setDragStartCoords(null)
            setDragCoords(null)

            if (!hasChanges && tool === 'select') {
                openTokenModal(newSelectedTokens)
            }

            if (hasChanges) {
                setCurrentlySelectedTokens(newSelectedTokens)
            }

        }
    }, [currentlySelectedTokens, tokens, setTokens, setCurrentlySelectedTokens])

    return ({
        startTokenDrag,
        onTokenDragging,
        releaseTokenDrag
    })
}

export default useTokenDrag