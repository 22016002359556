import { iRandom } from "../../helpers"

export default function setRandomDiceValue(die) {
    switch (die.type) {
        case 'D4': return die.setValue = iRandom(4) + 1
        case 'D6': return die.setValue = iRandom(6) + 1
        case 'D8': return die.setValue = iRandom(8) + 1
        case 'D12': return die.setValue = iRandom(12) + 1
        case 'D100': return die.setValue = (iRandom(10) + 1) * 10
        case 'D10': return die.setValue = iRandom(10) + 1
        case 'D20': return die.setValue = iRandom(20) + 1

        default: return
    }
}


export function getRandomDiceValue(dieType) {
    switch (dieType) {
        case 'D4': return iRandom(4) + 1
        case 'D6': return iRandom(6) + 1
        case 'D8': return iRandom(8) + 1
        case 'D12': return iRandom(12) + 1
        case 'D100': return (iRandom(10) + 1) * 10
        case 'D10': return iRandom(10) + 1
        case 'D20': return iRandom(20) + 1

        default: return
    }
}