import React from 'react'

const Arrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g transform="translate(-384.154 -370.666)">
                <path d="M394.347,372.081l5,5-5,5" transform="translate(-4.192 0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                <line x1="10" transform="translate(385.154 377.081)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
        </svg>
    )
}

export default Arrow
