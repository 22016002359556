const reducer = (state, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'SET_LOADING': {
            newState.loading = action.value
            return newState
        }

        default: return state
    }
}

export default reducer
