import React, { useEffect } from 'react';

import Breadknife from 'breadknife'
import abTestConfig from './config/abTestConfig'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import { ErrorProvider } from './context/Error'
import { AuthProvider, useAuthFunctions, useAuthState } from './context/Auth'
import { WorkspaceProvider } from './context/Workspace'
import { CampaignProvider } from './context/Campaign'
import { InventoryProvider } from './context/Inventory'

import Error from './components/Error'
import Auth from './pages/Auth'
import Landing from './pages/Landing'
import Workspace from './pages/Workspace'
import { LoadingProvider } from './context/Loading';
import VersionDisplay from './components/VersionDisplay/VersionDisplay';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./styles/globals.css"
import "./styles/shadows.css"
import "./styles/bg-colors.css"
import "./styles/border-colors.css"
import "./styles/colors.css"
import "./styles/fills.css"
import "./styles/hover-colors.css"
import "./styles/spacing.css"
import "./styles/text.css"
import "./styles/breakpoints.css"
import "./styles/size.css"

import GS from './functions/GlobalStorage'
import ErrorPage from './pages/ErrorPage';
import { storeAttemptedCouponCode } from './functions/storeAttemptedCouponCode';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: "https://e6a62ef905954380b7de71ac3f798168@o404633.ingest.sentry.io/5840112",
  integrations: [new Integrations.BrowserTracing(), new Sentry.Integrations.Breadcrumbs({
    console: false
  })],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

const darkModeSetting = localStorage.getItem('darkModeSetting')
const tokenMissingIcon = new Image()
tokenMissingIcon.src = '/MapTokens/question-mark.svg'
tokenMissingIcon.onload = GS.set('TOKEN_ICON_MISSING', tokenMissingIcon)

Breadknife.init(abTestConfig)
let tests = Breadknife.getTests()
tests = tests.map(test => {
  const testObject = {}
  testObject[test.id] = test.slice
  return testObject
})
window.heap.addUserProperties(tests)

const attemptedCouponCode = storeAttemptedCouponCode()

function App() {
  return (
    <div id="App" className={`App ${darkModeSetting === 'On' ? 'force-dark-mode' : darkModeSetting === 'Off' ? 'force-light-mode' : ''}`}>
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <ErrorProvider>
          <LoadingProvider>
            <>
              <Error />
              <Router>
                <AuthProvider>
                  <Switch>
                    <Route path="/login">
                      <Auth />
                    </Route>

                    <Route path="/new">
                      <Landing />
                    </Route>

                    <PrivateRoute path="/">
                      <InventoryProvider>
                        <CampaignProvider>
                          <WorkspaceProvider>
                            <Workspace />
                          </WorkspaceProvider>
                        </CampaignProvider>
                      </InventoryProvider>
                    </PrivateRoute>
                  </Switch>
                </AuthProvider>
              </Router>
            </>
          </LoadingProvider>
        </ErrorProvider>
        <VersionDisplay />
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App

const PrivateRoute = ({ children, ...rest }) => {
  const { auth, user } = useAuthState()
  const { authDispatch } = useAuthFunctions()

  useEffect(() => {
    if (user) {
      window.heap.identify(user.id)
    }
  }, [user])

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return (
          auth ? (
            children
          ) : (
            nonAuthedRouting(location, authDispatch, attemptedCouponCode)
          )
        )
      }}
    />
  )
}

const checkIfNewUser = () => {
  const check = localStorage.getItem("notNew")
  return !check
}

const nonAuthedRouting = (location, authDispatch, attemptedCouponCode) => {
  if (location.pathname.includes('/reset-password')) {
    authDispatch({ type: 'SET_RESET_TOKEN', value: location?.search?.substring(7) || "" })
    return <Redirect to={{ pathname: "/login" }} />
  }
  if (!location.pathname.includes('/campaign') && checkIfNewUser() && !attemptedCouponCode) {
    return <Redirect to={{ pathname: "/new", state: { from: location } }} />
  } else {
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />
  }
}

const ogRemoveChild = Node.prototype.removeChild
function newRemoveChild(child) {
  try {
    ogRemoveChild.apply(this, arguments)
  } catch (err) {
    console.log(err);
  }
}
Node.prototype.removeChild = newRemoveChild