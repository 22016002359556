import { loadStripe } from "@stripe/stripe-js"
import stripeConfig from "../config/stripe"
import { useAuthState } from "../context/Auth"
import { useErrorFunctions } from "../context/Error"
import { useLoadingFunctions } from "../context/Loading"
import API from "../functions/API"
const stripeEnv = (window.location.hostname.includes('qa.') || window.location.hostname.includes('localhost')) ? 'qa' : 'production'
const stripePromise = loadStripe(stripeConfig.pk[stripeEnv])

const useSendToCheckout = (interval) => {
    const { auth, user } = useAuthState()
    const { setError } = useErrorFunctions()

    const { setLoading } = useLoadingFunctions()

    return async () => {
        setLoading(true)
        const stripe = await stripePromise

        const attemptedCoupon = localStorage.getItem('attemptedCoupon')
        const response = await API.sendGraphSecure(auth, {
            query: `mutation {
                    createCheckout(interval: "${interval}", subPath: "${window.location.pathname}", couponCode: "${attemptedCoupon}")
                }`
        })


        const sessionId = response.data.createCheckout
        window.heap.track('USER_STARTED_CHECKOUT', { userId: user._id, email: user.email, attemptedCoupon })
        localStorage.removeItem('attemptedCoupon')
        const result = await stripe.redirectToCheckout({
            sessionId: sessionId
        })

        if (result.error) {
            setLoading(false)
            setError(result.error.message)
        }
    }
}

export default useSendToCheckout
