import { useEffect } from 'react'
import { useAuthState } from '../context/Auth'
import { useCampaignFunctions, useCampaignState } from '../context/Campaign'
import { useInventoryState } from '../context/Inventory'
import { useWorkspaceState } from '../context/Workspace'
import { getSoundData } from '../pages/Workspace/partials/SoundControls/functions'
import { usePrevious } from './usePrevious'

const useOpenDocumentsCommunications = () => {
    const { windows, tabs, musicPlaying } = useWorkspaceState()
    const prevWindows = usePrevious(windows)
    const prevTabs = usePrevious(tabs)
    const { user } = useAuthState()
    const { activeCampaign, connectedUsers, socket, usersInDocuments, recentlyDisconnectedUsers } = useCampaignState()
    const { campaignDispatch, addNotification } = useCampaignFunctions()
    const { allDocuments } = useInventoryState()

    useEffect(() => {
        if (activeCampaign) {
            if ((windows !== undefined && prevWindows !== undefined) && (tabs !== undefined && prevTabs !== undefined)) {
                recentlyClosedWindows(windows, prevWindows, allDocuments, socket, user, campaignDispatch)
                recentlyClosedWindows(tabs, prevTabs, allDocuments, socket, user, campaignDispatch)
            }
        }
    }, [activeCampaign, campaignDispatch, allDocuments, prevTabs, prevWindows, socket, tabs, user, windows])

    const prevConnectedUsersLength = usePrevious(connectedUsers.length)
    useEffect(() => {
        if (connectedUsers?.length > prevConnectedUsersLength) {
            recentlyOpenedWindows(windows, allDocuments, socket, user)
            recentlyOpenedWindows(tabs, allDocuments, socket, user)
            soundDataUpdate(musicPlaying, socket)
        }
    }, [activeCampaign, connectedUsers, allDocuments, musicPlaying, prevConnectedUsersLength, socket, tabs, user, windows])

    useEffect(() => {
        if (recentlyDisconnectedUsers.length) {
            //close all docs that the removed user had open
            recentlyDisconnectedUsers.forEach(lostUser => {
                addNotification.call(this,
                    `${lostUser.name} has disappeared!`,
                    `${lostUser._id}-has-left`
                )
                Object.keys(usersInDocuments).forEach(openDoc => {
                    const data = { documentId: openDoc, userId: lostUser._id }
                    campaignDispatch({ type: 'USER_CLOSED_DOCUMENT', value: data })
                })
            })

            campaignDispatch({ type: 'CLEAR_RECENTLY_DISCONNECTED_USERS' })
        }
    }, [addNotification, campaignDispatch, recentlyDisconnectedUsers, usersInDocuments])
}

export default useOpenDocumentsCommunications

const soundDataUpdate = (soundDataList, socket) => {
    soundDataList && Object.values(soundDataList).forEach(data => {
        const sd = getSoundData(data.id) || { id: data.id, currentTime: 0 }
        socket.emit('UPDATE_PLAYING_SONG', JSON.stringify({ ...data, currentTime: sd.currentTime }))
    })
}

const recentlyOpenedWindows = (list, allDocuments, socket, user) => {
    list.forEach(item => {
        if (item.propsPassedFromWindow) {
            const { documentId } = item.propsPassedFromWindow
            const { broadcasting } = item.settings
            if (allDocuments.find(doc => doc?._id === documentId)) {
                const data = { documentId, userId: user._id }
                socket.emit('USER_OPENED_DOCUMENT', data)
            }

            if (broadcasting) {
                socket.emit('BROADCAST_WINDOW', documentId)
            }
        }
    })
}

const recentlyClosedWindows = (list, prevList, allDocuments, socket, user, campaignDispatch) => {
    if (list.length < prevList.length) {
        const closedWindows = prevList.filter(prevWindow => !list.find(window => window.index === prevWindow.index))
        closedWindows.forEach(closedWindow => {
            if (closedWindow.propsPassedFromWindow) {
                const { documentId } = closedWindow.propsPassedFromWindow
                const { broadcasted, broadcasting } = closedWindow.settings
                if (broadcasting || broadcasted) {
                    const data = { documentId, userId: user._id }
                    socket.emit('USER_CLOSED_DOCUMENT', data)
                    campaignDispatch({ type: 'USER_CLOSED_DOCUMENT', value: data })
                }
            }
        })
    } else if (list.length > prevList.length) {
        const openedWindows = list.filter(window => !prevList.find(prevWindow => window.index === prevWindow.index))
        openedWindows.forEach(openedWindow => {
            if (openedWindow.propsPassedFromWindow) {
                const { documentId } = openedWindow.propsPassedFromWindow
                const { broadcasting, broadcasted } = openedWindow.settings
                if (broadcasting || broadcasted) {
                    const data = { documentId, userId: user._id }
                    socket.emit('USER_OPENED_DOCUMENT', data)
                    campaignDispatch({ type: 'USER_OPENED_DOCUMENT', value: data })
                }
            }
        })
    }
}
