import { useEffect, useState } from "react"
import { useAuthState } from "../context/Auth"
import { useInventoryFunctions } from "../context/Inventory"
import { useWorkspaceFunctions } from "../context/Workspace"
import API from "../functions/API"
const useLoadDocument = (documentId, oldData, kind, windowIndex, setLoading, onCreate) => {
    const [data, setData] = useState(null)
    const [id, setId] = useState(documentId || null)
    const { workspaceDispatch } = useWorkspaceFunctions()
    const { inventoryDispatch } = useInventoryFunctions()
    const { user } = useAuthState()

    useEffect(() => {
        setLoading(true)
        const abort = new AbortController()
        if (documentId) {
            if (oldData) {
                let newData = JSON.parse(oldData)
                if (newData) {
                    if (kind === 'note') {
                        newData = newData.blocks.map(block => {
                            return { insert: block.text + '\n' }
                        })
                    }
                    setData(newData)
                }
            }
            setLoading(false)
        } else {
            API.sendGraph({
                query: `mutation {
                    createDocument(documentOwnerId: "${user._id}", kind: "${kind}") {
                        _id
                    }
                }`
            }, { abort }).then((response) => {
                const newId = response.data.createDocument._id
                setId(newId)

                inventoryDispatch({ type: 'UPDATE', value: { id: newId, data: { name: '', kind, owner: { _id: user._id } } } })
                workspaceDispatch({ type: 'UPDATE_WINDOW_PROPS', value: { index: windowIndex, props: { documentId: newId } } })
                if (onCreate) onCreate({ _id: newId, kind })
                setLoading(false)

                return newId
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
        }

        return () => {
            abort.abort()
        }
    }, [documentId, inventoryDispatch, kind, oldData, onCreate, setLoading, user._id, windowIndex, workspaceDispatch])

    return {
        data,
        id
    }
}

export default useLoadDocument