import React from 'react'

import { useAuthState } from '../../context/Auth'

import Icon from '../Icon'
import NothingToSee from '../NothingToSee'

import { List, ListItem } from '../List'
import { useInventoryState } from '../../context/Inventory'

const Campaigns = ({ openChestWindow, setActiveTab }) => {
    const { campaigns } = useInventoryState()
    const { user } = useAuthState()

    if (campaigns && campaigns.length) return (
        <div className="chest-inner-content chest-inner-list">
            <List>
                {campaigns.map((campaign, i) => (
                    <ListItem
                        key={i}
                        onClick={() => {
                            setActiveTab(0)
                            openChestWindow('viewCampaign', null, { campaignShortId: campaign.shortId })
                        }}
                    >
                        <Icon className="list-item-icon margin-quarter" value="tome" />
                        <div className="col">
                            <div className="row full-width t-left">
                                <h3 className="c-shade-9">{campaign.title}</h3>
                            </div>
                            <div className="row c-shade-6 t-small">
                                {campaign.users.length > 1 && campaign.users.slice(0, 3).map((campaignUser, i) => (
                                    <span key={i} className="row">
                                        {i > 0 && ", "}
                                        {user._id === campaignUser._id ? 'You' : campaignUser.name}
                                        {campaignUser._id === campaign.owner._id && <Icon value="crown" className="margin-left-quarter" />}
                                    </span>
                                ))}
                                {campaign.users.length > 3 && <span>&nbsp;and more.</span>}
                            </div>
                        </div>
                    </ListItem>
                ))}
            </List>
        </div>
    )

    return (
        <NothingToSee message="Make your first campaign and you will see it here." />
    )
}

export default Campaigns
