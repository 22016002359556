import React from 'react'
import { getIconImg } from '../../../../functions/helpers'
import useOpenWindow from '../../../../hooks/useOpenWindow'
import DocumentPreview from '../../../DocumentPreview/DocumentPreview'
import './MapTokens.css'


const DocumentIcons = ({ src, documents, hovered, windowIndex, scale }) => {
    const size = 12
    const openWindow = useOpenWindow()
    const percentScales = [2, 1.5, 1, 1, 1, 0.75, 0.75]
    const adjustedScale = percentScales[scale - 1]

    return (
        <div className="position-absolute" style={{ right: size, top: -size / 4, }}>
            {(src && documents.length > 0) || (!src && documents.length > 1) ? <div
                className="documentIcon bg-white rounded centered"
                style={{
                    width: size + (documents.length > 1 ? 12 : 0),
                    height: size,
                    padding: size / 6,
                    transform: `scaleX(${adjustedScale}) scaleY(${adjustedScale})`
                }}>
                {documents?.length ? (
                    <>
                        {src && <DocIcon kind={documents?.[0]?.kind} />}
                        {documents.length > 1 && (<p style={{ fontSize: 8, lineHeight: 1.2 }}>+{documents.length - 1}</p>)}
                    </>
                ) : null}
            </div> : null}
            {<div className={`docPreviewList bg-shade-1 rounded ${hovered && 'hovered'}`} style={{ transform: `scaleX(${adjustedScale}) scaleY(${adjustedScale})` }}>
                {documents.map((document, i) => {
                    return <div className="docPreview rounded" key={i}>
                        <DocumentPreview
                            className={'padding-top-0 full-height'}
                            id={document._id}
                            kind={document.kind}
                            ownerId={document.ownerId}
                            windowIndex={windowIndex}
                            expanded
                            onOpen={() => openWindow(document.kind, { title: document.name, uniqueIndex: `document-${document._id}` }, {
                                documentId: document._id,
                                documentOwnerId: document.ownerId,
                                documentName: document.name,
                            })}
                            stopSoundboardScroll
                        />
                    </div>
                })}
            </div>}
        </div>
    )
}

export default DocumentIcons

const DocIcon = ({ kind }) => {
    const firstIcon = getIconImg(kind)
    return <img height="100%" src={firstIcon} alt="document icon" />
}