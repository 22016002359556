import React from 'react'

import { useAuthState } from '../../context/Auth'

import NothingToSee from '../NothingToSee'

import Icon from '../Icon'

import { List, ListItem } from '../List'
import { Button, IconButton } from '../Buttons'
import { useCampaignFunctions, useCampaignState } from '../../context/Campaign'
import { useErrorFunctions } from '../../context/Error'
import useOpenWindow from '../../hooks/useOpenWindow'
import HiddenList from '../List/HiddenList'
import UserCount from './partials/UserCount/UserCount'
import { isMobile } from 'react-device-detect'

const Users = ({ openChestWindow }) => {
    const { auth, user } = useAuthState()
    const { setError } = useErrorFunctions()
    const { socket, activeCampaign } = useCampaignState()
    const { addUserToCampaign, removeUserFromCampaign, removeUserFromDeniedList } = useCampaignFunctions()

    const openWindow = useOpenWindow()

    const openPremiumSignup = () => openWindow('premiumSignup')
    const usersAreCapped = user._id === activeCampaign.owner._id && user?.subscription?.active === false

    const handleRemoveUserButton = (campaignUser) => {
        const confirmOptions = {
            message: `Are you sure you want to remove ${campaignUser.name} from the campaign?`,
            confirmCallback: () => {
                removeUserFromCampaign(auth, campaignUser._id, activeCampaign.shortId, socket, setError)
            }
        }

        if (isMobile)
            openChestWindow('confirm', null, confirmOptions)
        else {
            openWindow('confirm', null, confirmOptions)
        }
    }

    if (activeCampaign) return (
        <div className="chest-inner-content chest-inner-list">
            {(usersAreCapped) ? (
                <UserCount userCount={activeCampaign?.users?.length} openPremiumSignup={openPremiumSignup} />
            ) : null}
            <List className="margin-top-0">
                {activeCampaign?.users?.map((campaignUser, i) => (
                    <ListItem className="chest-list-item" key={i}>
                        <div className="chest-list-item-row space-between">
                            <span key={i}>
                                <span className="margin-right-1">{campaignUser._id === activeCampaign.owner._id && <Icon value="crown" />}</span>
                                <span>{user._id === campaignUser._id ? 'You' : campaignUser.name}</span>
                            </span>
                            {user._id === activeCampaign.owner._id && campaignUser._id !== user._id ? (
                                <IconButton className="list-item-remove-button" onClick={() => handleRemoveUserButton(campaignUser)} icon="minimize" />
                            ) : null}
                        </div>
                    </ListItem>
                ))}
            </List>

            <HiddenList titleClassName="padding-horizontal-1" title={`Users awaiting Invites (${activeCampaign?.usersAwaitingInvite?.length || 0})`} defaultOpen={false}>
                <List>
                    {activeCampaign?.usersAwaitingInvite?.map((waitingUser, i) => (
                        <ListItem className="chest-list-item" key={i}>
                            <div className="chest-list-item-row full-width row centered-vertically space-between">
                                <p key={i}>{waitingUser.name}</p>
                                <div className="row centered-vertically">
                                    {user._id === activeCampaign.owner._id ? (
                                        <>
                                            {!usersAreCapped || (usersAreCapped && activeCampaign.users.length < 5) ? (
                                                <Button className="margin-horizontal-1 green circleButton" onClick={() => addUserToCampaign(auth, waitingUser._id, activeCampaign.shortId, socket, setError)}>
                                                    <Icon value="check" />
                                                </Button>
                                            ) : null}
                                            <Button className="circleButton red" onClick={() => removeUserFromCampaign(auth, waitingUser._id, activeCampaign.shortId, socket, setError)}>
                                                <Icon value="close" />
                                            </Button>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </List>
            </HiddenList>

            <HiddenList titleClassName="padding-horizontal-1" title={`Denied Users (${activeCampaign?.deniedUsers?.length || 0})`} defaultOpen={false}>
                <List>
                    {activeCampaign?.deniedUsers?.map((deniedUser, i) => (
                        <ListItem className="chest-list-item" key={i}>
                            <div className="chest-list-item-row full-width row centered-vertically space-between">
                                <p key={i}>{deniedUser.name}</p>
                                <div className="row centered-vertically">
                                    {user._id === activeCampaign.owner._id ? (
                                        <IconButton className="list-item-remove-button" onClick={() => removeUserFromDeniedList(auth, deniedUser._id, activeCampaign.shortId, socket, setError)} icon="minimize" />
                                    ) : null}
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </List>
            </HiddenList>
        </div>
    )

    return (
        <NothingToSee message="Invite users to see them here." />
    )
}

export default Users
