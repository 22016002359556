import fullFolder from "../../../config/graph-request-types/fullFolder"
import API from "../../../functions/API"

export async function getFolderData(folderId) {
    try {
        const result = await API.sendGraph({
            query: `query fullFolder($folderId: ID!) {
                fullFolder(folderId: $folderId) {
                        ${fullFolder}
                    }
                }`,
            variables: {
                "folderId": folderId
            }
        })

        this.dispatch({
            type: 'UPDATE_SHARED_FOLDER', value: result.data.fullFolder
        })
    } catch (err) {
        console.log(err)
    }
}