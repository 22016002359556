import React from 'react'

const Clouds = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="338.485" height="220.078" viewBox="0 0 338.485 220.078">
    <g id="cloud-computing" transform="translate(0 -0.475)" opacity="0.303">
      <path id="Path_291" data-name="Path 291" d="M211.109,104.448a16.925,16.925,0,0,0-16.926-16.926,3.108,3.108,0,0,0-.733.055,23.53,23.53,0,0,0,.733-5.7,22.567,22.567,0,0,0-22.568-22.568,20.536,20.536,0,0,0-5.642.733v-.733a50.546,50.546,0,0,0-13.367-34.248l-.135-.146a50.907,50.907,0,0,0-13.766-10.59l-.485-.231a47.267,47.267,0,0,0-4.515-2.019c-.466-.187-.948-.355-1.427-.526-1.267-.457-2.554-.857-3.871-1.212-.562-.157-1.168-.317-1.76-.452a40,40,0,0,0-3.986-.738c-.565-.085-1.129-.2-1.694-.259A50.567,50.567,0,0,0,64.645,54.459a22.216,22.216,0,0,0-5.868-.788A22.567,22.567,0,0,0,36.209,76.239a3.274,3.274,0,0,0,.055.733,23.377,23.377,0,0,0-5.7-.733A22.359,22.359,0,0,0,8.413,102.878c.072.408.174.8.27,1.2a21.985,21.985,0,0,0,.846,2.727c.152.4.281.793.457,1.179a21.6,21.6,0,0,0,1.749,3.226c.253.383.565.722.835,1.088a20.759,20.759,0,0,0,1.507,1.824c.421.457.868.884,1.331,1.309s.942.8,1.433,1.185,1.044.818,1.6,1.179a22.769,22.769,0,0,0,12.124,3.584H194.183A16.927,16.927,0,0,0,211.109,104.448Zm0,0" transform="translate(-2.358 -2.376)" fill="#a5d0f2" />
      <path id="Path_292" data-name="Path 292" d="M393.325,227.013a28.179,28.179,0,0,0-35.43-22.116,33.723,33.723,0,0,0-9.777-8.063c-.226-.124-.441-.248-.667-.366a33.465,33.465,0,0,0-4.672-2.061h-.08a34.3,34.3,0,0,0-4.686-1.207c-.4-.072-.8-.146-1.2-.209A33.474,33.474,0,0,0,299.5,216.068a28.085,28.085,0,0,0-34.923,32.554c.113.6.309,1.168.463,1.755a27.414,27.414,0,0,0,.992,3.182c.27.694.6,1.347.92,2.014.413.835.84,1.653,1.325,2.444s.9,1.386,1.394,2.052.981,1.242,1.512,1.835c.614.694,1.229,1.375,1.912,2.014.457.416.953.788,1.433,1.179a27.778,27.778,0,0,0,2.584,1.956c.074.047.154.074.226.118a27.986,27.986,0,0,0,14.884,4.358h95.913a22.568,22.568,0,0,0,5.19-44.516Zm0,0" transform="translate(-77.829 -56.618)" fill="#a5d0f2" />
      <g id="Group_35" data-name="Group 35" transform="translate(0 0.475)">
        <path id="Path_293" data-name="Path 293" d="M191.825,124.64a22.568,22.568,0,0,0,5.642-44.425V79.5a28.241,28.241,0,0,0-28.21-28.21h-.281A56.419,56.419,0,0,0,57.549,45.653,28.206,28.206,0,0,0,28.774,68.22H28.21a28.21,28.21,0,0,0,0,56.419ZM11.284,96.43A16.925,16.925,0,0,1,28.21,79.5a18.071,18.071,0,0,1,4.328.562A5.641,5.641,0,0,0,39.493,73.8,16.923,16.923,0,0,1,56.419,56.937,16.654,16.654,0,0,1,60.8,57.5a5.639,5.639,0,0,0,7.11-4.906,45.135,45.135,0,0,1,90.067,4.344v.733a5.641,5.641,0,0,0,7.171,5.433,14.809,14.809,0,0,1,4.113-.523A16.925,16.925,0,0,1,186.184,79.5a17.625,17.625,0,0,1-.565,4.325,5.644,5.644,0,0,0,6.262,6.959,11.284,11.284,0,1,1-.055,22.568H28.21A16.927,16.927,0,0,1,11.284,96.43Zm0,0" transform="translate(0 -0.475)" fill="#4b7493" />
        <path id="Path_294" data-name="Path 294" d="M395.707,220.2a33.985,33.985,0,0,0-38.207-23.9,39.495,39.495,0,0,0-63.923,11,32.345,32.345,0,0,0-3.725-.207,33.852,33.852,0,1,0,0,67.7h95.913a28.211,28.211,0,0,0,9.942-54.6Zm-9.942,43.314H289.852a22.568,22.568,0,1,1,5.815-44.37,5.644,5.644,0,0,0,6.835-3.736,28.211,28.211,0,0,1,48.673-9.292,5.643,5.643,0,0,0,5.862,1.84A22.646,22.646,0,0,1,385.4,225.642a5.644,5.644,0,0,0,4.248,4.515,16.926,16.926,0,0,1-3.887,33.361Zm0,0" transform="translate(-75.458 -54.724)" fill="#4b7493" />
        <path id="Path_295" data-name="Path 295" d="M100.606,255.113h-.565a28.21,28.21,0,0,0-51.714-9.028,22.568,22.568,0,1,0-9.782,42.879h62.061a16.926,16.926,0,1,0,0-33.852Zm0,22.568H38.545a11.284,11.284,0,1,1,0-22.568,10.919,10.919,0,0,1,8,3.383,5.642,5.642,0,0,0,9.592-2.331,16.925,16.925,0,0,1,33.185,4.59,5.642,5.642,0,0,0,5.642,5.642h5.642a5.642,5.642,0,1,1,0,11.284Zm0,0" transform="translate(-4.693 -68.887)" fill="#4b7493" />
      </g>
    </g>
  </svg>
)

export default Clouds
