import API from "../../../functions/API"
export async function unshareDocument(auth, folderId, documentId, setLoading, setError) {
    try {
        setLoading(true)
        const result = await API.sendGraphSecure(auth, {
            query: `mutation unshareDocument($folderId: ID!, $documentId: ID!) {
                        unshareDocument(folderId: $folderId, documentId: $documentId)
                    }`,
            variables: {
                "folderId": folderId,
                "documentId": documentId,
            }
        })

        this.dispatch({
            type: 'UNSHARE_DOCUMENT_FROM_FOLDER', value: { folderId, documentId }
        })

        setLoading(false)
        return result.data.unshareFolderWithCampaign
    } catch (err) {
        setError(err.message)
    }
}