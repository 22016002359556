import React, { useReducer, useContext, useMemo } from 'react'
import reducer from './reducer'
import buildFunctions from './buildFunctions'
// import { useErrorFunctions } from '../Error'

const AuthStateContext = React.createContext()
const AuthFunctionsContext = React.createContext()

const AuthProvider = ({ children }) => {
    const initialState = {
        auth: null,
        user: null,
        page: "signup", // Page switches between login, signup and profile, for each stage of profile setup and auth.
        email: "", // Holds input for email fields.
        password: "", // Holds input for password fields.
        passwordConfirm: "", // Holds input for the password confirm field.
        name: "", // Holds input for the name field.
        image: "", // Holds URL of profile image once set.
        loginEmail: "",
        loginPassword: "",
        initialLoad: true,
        passwordResetToken: ""
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <AuthStateContext.Provider value={state}>
            <AuthFunctionsContext.Provider value={dispatch}>
                {children}
            </AuthFunctionsContext.Provider>
        </AuthStateContext.Provider>
    )
}

function useAuthState() {
    const state = useContext(AuthStateContext)
    if (typeof state === undefined) {
        throw new Error('useAuthState must be used within an AuthProvider')
    }

    return state
}


function useAuthFunctions() {
    const dispatch = useContext(AuthFunctionsContext)
    if (typeof dispatch === undefined) {
        throw new Error('useAuthFunctions must be used within an AuthProvider')
    }
    const functions = useMemo(() => buildFunctions(dispatch), [dispatch])

    return functions
}

export {
    AuthProvider,
    useAuthState,
    useAuthFunctions
}