import { useCallback, useState } from 'react'
import shortid from 'shortid'
import { useAuthState } from '../../../../context/Auth/index'
import { distanceTo, getPos } from '../../../../functions/CanvasHelpers'
import { getHexFromMapColor } from '../../../../functions/helpers'
import { getRelativeMousePos } from '../getRelativeMousePos'
import { formatCurrentDrawingLine } from './formatCurrentDrawingLine'

import getDrawingPos from './getDrawingPos'
import { getMeasurePoint } from './useMeasure'

// let addPanTimeout

const useDrawing = (isFullScreen, windowPos, camera, showGrid, gridLeft, gridTop, gridSize, updateDoc, paints, paintSettings) => {

    const [currentDrawing, setCurrentDrawing] = useState(null)
    const { user } = useAuthState()
    const [dragStartCoords, setDragStartCoords] = useState(null)
    const [dragCoords, setDragCoords] = useState(null)

    const [drawingDragTarget, setDrawingDragTarget] = useState(null)

    const startDrawing = useCallback((e) => {
        let x1, y1
        if (showGrid) {
            const centered = paintSettings.origin === 'center'

            const { x, y } = getMeasurePoint(e, isFullScreen, windowPos, gridSize, gridLeft, gridTop, camera, centered)
            x1 = getPos(x, camera.x, camera.zoom)
            y1 = getPos(y, camera.y, camera.zoom)
        } else {
            const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
            x1 = getPos(xx, camera.x, camera.zoom)
            y1 = getPos(yy, camera.y, camera.zoom)
        }

        setCurrentDrawing({
            ...paintSettings,
            userId: user._id,
            points: [x1, y1],
            color: getHexFromMapColor(paintSettings.color),
        })
    }, [camera, gridLeft, gridSize, gridTop, isFullScreen, paintSettings, showGrid, user._id, windowPos])

    const updateDrawing = useCallback((e) => {
        if (currentDrawing && currentDrawing.tool !== 'marker' && currentDrawing.points.length >= 2) {
            const centered = paintSettings.origin === 'center'
            setCurrentDrawing((cd) => {
                const x1 = cd.points[0]
                const y1 = cd.points[1]
                const halvedGridSize = (centered ? gridSize / 2 : gridSize)
                const zoomedGridSize = halvedGridSize * camera.zoom
                let x2, y2
                if (showGrid) {
                    let { x, y } = getMeasurePoint(e, isFullScreen, windowPos, halvedGridSize, gridLeft, gridTop, camera, false)
                    x2 = getPos(x + (centered ? 0 : zoomedGridSize), camera.x, camera.zoom)
                    y2 = getPos(y + (centered ? 0 : zoomedGridSize), camera.y, camera.zoom)
                } else {
                    const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
                    x2 = getPos(xx, camera.x, camera.zoom)
                    y2 = getPos(yy, camera.y, camera.zoom)
                }

                const dist = distanceTo(x1, y1, x2, y2) / (centered ? 1 : 2) || 0
                const displayDist = (Math.round(((dist * (centered ? 4 : 2)) / camera.zoom) / gridSize)) * 5

                const distX = distanceTo(x1, y1, x2, y1) / (centered ? 1 : 2) || 0
                const displayDistX = (Math.round(distX * 2) / gridSize) * 5

                const distY = distanceTo(x1, y1, x1, y2) / (centered ? 1 : 2) || 0
                const displayDistY = (Math.round(distY * 2) / gridSize) * 5

                return {
                    ...cd,
                    points: [x1, y1, x2, y2],
                    dist,
                    displayDist,
                    displayDistX,
                    displayDistY
                }
            })
        }
    }, [camera, currentDrawing, gridLeft, gridSize, gridTop, isFullScreen, paintSettings.origin, showGrid, windowPos])

    const finishDrawing = useCallback((e) => {
        if (currentDrawing && currentDrawing.tool !== 'marker') {
            let x2, y2

            if (showGrid) {
                const centered = paintSettings.origin === 'center'
                const halvedGridSize = (centered ? gridSize / 2 : gridSize)
                const zoomedGridSize = halvedGridSize * camera.zoom


                let { x, y } = getMeasurePoint(e, isFullScreen, windowPos, halvedGridSize, gridLeft, gridTop, camera, false)
                x2 = getPos(x + (centered ? 0 : zoomedGridSize), camera.x, camera.zoom)
                y2 = getPos(y + (centered ? 0 : zoomedGridSize), camera.y, camera.zoom)
            } else {
                const { xx, yy } = getRelativeMousePos(e, isFullScreen, windowPos)
                x2 = getPos(xx, camera.x, camera.zoom)
                y2 = getPos(yy, camera.y, camera.zoom)
            }

            const id = shortid.generate()

            const lineFormatedCurrentDrawing = formatCurrentDrawingLine(currentDrawing, x2, y2)

            updateDoc({
                p: ['paint', paints.length], li: {
                    id,
                    dist: currentDrawing.dist,
                    points: [
                        lineFormatedCurrentDrawing.points[0],
                        lineFormatedCurrentDrawing.points[1],
                        lineFormatedCurrentDrawing.points[2],
                        lineFormatedCurrentDrawing.points[3],
                    ],
                    ...paintSettings,
                    color: getHexFromMapColor(paintSettings.color),
                }
            })

            setCurrentDrawing(null)
        }
    }, [camera, currentDrawing, gridLeft, gridSize, gridTop, isFullScreen, paintSettings, paints.length, showGrid, updateDoc, windowPos])

    const startDrawingDrag = useCallback((e, drawing, instance) => {
        setDragStartCoords({ x: e.clientX, y: e.clientY })
        setDrawingDragTarget({ ...drawing, instance })
        if (instance?.current) instance?.current?.classList?.add('dragging')
    }, [])

    const onDrawingDrag = useCallback((e) => {
        if (dragStartCoords && drawingDragTarget) {
            setDragCoords({ x: e.clientX, y: e.clientY })

            if (dragCoords && drawingDragTarget) {
                const startX = getPos(dragStartCoords.x, camera.x, camera.zoom)
                const startY = getPos(dragStartCoords.y, camera.y, camera.zoom)

                const { left, top, width, height } = getDrawingPos(drawingDragTarget.points)

                const drawingX = (
                    drawingDragTarget.tool === 'marker' ?
                        drawingDragTarget?.markerBounds?.left :
                        drawingDragTarget.origin === 'center' ? left - (width / 2) * 2 : left
                ) || 0

                const drawingY = (
                    drawingDragTarget.tool === 'marker' ?
                        drawingDragTarget?.markerBounds?.top :
                        drawingDragTarget.origin === 'center' ? top - (height / 2) * 2 : top
                ) || 0

                const shiftX = drawingX - startX,
                    shiftY = drawingY - startY

                const x = getPos(dragCoords.x, camera.x, camera.zoom) + shiftX,
                    y = getPos(dragCoords.y, camera.y, camera.zoom) + shiftY

                if (drawingDragTarget?.instance?.current?.style) {
                    drawingDragTarget.instance.current.style.left = x + 'px'
                    drawingDragTarget.instance.current.style.top = y + 'px'
                }

                drawingDragTarget.newX = x + (drawingDragTarget.origin === 'center' ? (width / 2) * 2 : 0)
                drawingDragTarget.newY = y + (drawingDragTarget.origin === 'center' ? (height / 2) * 2 : 0)
            }
        }
    }, [dragStartCoords, drawingDragTarget, dragCoords, camera.x, camera.zoom, camera.y])

    const releaseDrawingDrag = useCallback((e, instance, setCamera) => {
        const onBackground = e?.target?.className?.includes?.('mapTokensWrapper')
        const onDrawing = e?.target?.className?.includes?.('drawing')
        if (e.button === 0 && (onBackground || onDrawing) && drawingDragTarget?.newX && drawingDragTarget?.points?.length > 2) {

            // const centerOffsetW = (isFullScreen ? window.innerWidth : windowPos.width) / 2
            // const centerOffsetH = (isFullScreen ? window.innerHeight : windowPos.height) / 2

            // const xx = centerOffsetW - drawingDragTarget.newX * camera.zoom
            // const yy = centerOffsetH - drawingDragTarget.newY * camera.zoom
            // const ww = (drawingDragTarget.tool === 'marker' ? drawingDragTarget.markerBounds.width : getDrawingPos(drawingDragTarget.points).width) * camera.zoom
            // const hh = (drawingDragTarget.tool === 'marker' ? drawingDragTarget.markerBounds.height : getDrawingPos(drawingDragTarget.points).height) * camera.zoom

            // instance?.current?.classList?.add?.('addPan')

            // clearTimeout(addPanTimeout)
            // addPanTimeout = setTimeout(() => {
            //     instance?.current?.classList?.remove?.('addPan')
            // }, 600)

            // setCamera((camera) => ({ x: xx - ww / 2, y: yy - hh / 2, zoom: camera.zoom }))

            const paintIndex = paints.findIndex(paint => paint.id === drawingDragTarget.id)

            drawingDragTarget.instance = undefined
            if (drawingDragTarget.tool === 'marker') {
                updateDoc({
                    p: ['paint', paintIndex],
                    ld: paints[paintIndex],
                    li: {
                        ...drawingDragTarget,
                        markerBounds: {
                            ...drawingDragTarget.markerBounds,
                            left: drawingDragTarget.newX,
                            top: drawingDragTarget.newY,
                        }
                    }
                })
            } else if (drawingDragTarget?.points?.length === 4) {
                const { width, height } = getDrawingPos(drawingDragTarget.points)

                updateDoc({
                    p: ['paint', paintIndex],
                    ld: paints[paintIndex],
                    li: {
                        ...drawingDragTarget,
                        points: [
                            drawingDragTarget.newX,
                            drawingDragTarget.newY,
                            drawingDragTarget.newX + width,
                            drawingDragTarget.newY + height,
                        ]
                    }
                })
            }

            setDragStartCoords(null)
            setDragCoords(null)
        }
        if (drawingDragTarget !== null) {
            if (drawingDragTarget.instance?.current) drawingDragTarget.instance?.current?.classList?.remove?.('dragging')
            setDrawingDragTarget(null)
        }
    }, [drawingDragTarget, paints, updateDoc])



    return {
        startDrawing,
        updateDrawing,
        finishDrawing,
        currentDrawing,
        setCurrentDrawing,
        startDrawingDrag,
        onDrawingDrag,
        releaseDrawingDrag
    }
}

export default useDrawing