import React from 'react'

import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
} from '@styled-icons/material'

import DocumentToolbar, { ToolBarSeparator, TextTypeList } from '../../DocumentToolbar'
import {
    IconButton,
} from '../../Buttons'

const TopToolBar = ({ toolbarId, id, saving, windowIndex, isFullScreen, broadcasted, broadcasting }) => {
    return (
        <DocumentToolbar
            toolbarId={toolbarId}
            documentId={id}
            windowIndex={windowIndex}
            saving={saving}
            isFullScreen={isFullScreen}
            broadcasted={broadcasted}
            broadcasting={broadcasting}
        >
            <div className="row full-width height-2 hidden-phone">
                <TextTypeList />
                <ToolBarSeparator />

                <IconButton
                    className='c-main rounded-0 ql-bold'
                    alt="Bold"
                >
                    <FormatBold className="size-1" />
                </IconButton>

                <IconButton
                    className='c-main rounded-0 ql-italic'
                    alt="Italic"
                >
                    <FormatItalic className="size-1" />
                </IconButton>

                <IconButton
                    className='c-main rounded-0 ql-underline'
                    alt="Underline"
                >
                    <FormatUnderlined className="size-1" />
                </IconButton>
            </div>
        </DocumentToolbar>
    )
}

export default TopToolBar