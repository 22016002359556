import React from 'react'
import './Card.css'

const Card = ({ className, emphasis, style, children }) => {
    if (emphasis === true) emphasis = 1
    return (
        <div className={`card bg-white ${className} ${emphasis && ('emphasis-' + emphasis)}`} style={style}>
            {children}
        </div>
    )
}

export default Card
