import React from 'react'
import './VersionDisplay.css'

import version from '../../config/version'

const VersionDisplay = () => {
    return (
        <p className="version-display">
            {version}
        </p>
    )
}

export default VersionDisplay
