import React, { useRef } from 'react'
import { useWorkspaceFunctions } from '../../context/Workspace'
import { IconButton } from '../Buttons'
import { Close } from '@styled-icons/evaicons-solid/Close'
import Loading from '../Loading'
import getSettings from './functions/getSettings'
import Inner from './Inner'

import './tabs.css'

const Tab = ({ index, selected, view: ViewElement, settings, windowDragging, propsPassedFromWindow, countIndex, closing, saving }) => {
    const newSettings = getSettings(settings, countIndex)
    const { color, innerProps } = newSettings
    const instance = useRef(null)
    const windowInnerRef = useRef(null)
    const viewProps = { _index: index, _settings: newSettings, windowInnerRef, saving, windowDragging, ...propsPassedFromWindow }
    const { closeWindow } = useWorkspaceFunctions()
    return (
        <div
            className={`tab ${selected && 'selected'} bg-white ${color}`}
            ref={instance}
        >
            {closing && <Loading overlay label="Saving and Closing" />}
            {settings.showCloseOnTab && <IconButton className="tabCloseButton" onClick={() => closeWindow(index)}>
                <Close className="size-3 c-shade-9 stroke-none" />
            </IconButton>}
            <Inner ref={windowInnerRef} {...innerProps} id={index}>
                {ViewElement && <ViewElement {...viewProps} isFullScreen={true} />}
            </Inner>
        </div >
    )
}

export default Tab
