import shortid from "shortid"
import { circle, dashedLine, distanceTo, getPos, line } from "../../../../functions/CanvasHelpers"

const main = '#647682',
    green = '#9bd095',
    white = '#FBFBFB',
    red = '#e17e7e'

function addFogCutPoint(xx, yy, tool, fogCuts, updateDoc, currentFogCuts, setCurrentFogCuts, camera) {
    if (tool === 'cut-fog') {
        const newX = getPos(xx, camera.x, camera.zoom)
        const newY = getPos(yy, camera.y, camera.zoom)

        if (currentFogCuts) {
            if (currentFogCuts.length > 1) {
                const firstX = currentFogCuts[0].x
                const firstY = currentFogCuts[0].y
                const dist = distanceTo(newX, newY, firstX, firstY)
                if (dist < 12) {
                    finishFogCut(fogCuts, currentFogCuts, setCurrentFogCuts, updateDoc)
                } else {
                    currentFogCuts.push({
                        x: newX,
                        y: newY
                    })
                    setCurrentFogCuts(currentFogCuts)
                }
            } else {
                currentFogCuts.push({
                    x: newX,
                    y: newY
                })
                setCurrentFogCuts(currentFogCuts)
            }
        } else {
            currentFogCuts = [{
                x: newX,
                y: newY
            }]
            setCurrentFogCuts(currentFogCuts)
        }
    }
}

function finishFogCut(fogCuts, currentFogCuts, setCurrentFogCuts, updateDoc) {
    const newFogCuts = [...fogCuts]
    if (currentFogCuts.length > 2) {
        newFogCuts.push({
            id: shortid.generate(),
            points: currentFogCuts
        })
        updateDoc({ p: ['fogCuts'], od: fogCuts, oi: newFogCuts })
        setCurrentFogCuts(null)
    } else {
        setCurrentFogCuts(null)
    }
}

function drawFogCutTool(xx, yy, ctx, currentFogCuts) {
    if (currentFogCuts) {
        ctx.lineWidth = 3
        ctx.strokeStyle = white
        ctx.fillStyle = currentFogCuts.length > 2 ? green : main

        const startX = currentFogCuts[0].x
        const startY = currentFogCuts[0].y

        for (let i = 0; i < currentFogCuts.length - 1; i++) {
            const point = currentFogCuts[i]
            const nextPoint = currentFogCuts[i + 1]
            dashedLine(
                ctx,
                point.x,
                point.y,
                nextPoint.x,
                nextPoint.y,
            )
        }

        dashedLine(
            ctx,
            currentFogCuts[currentFogCuts.length - 1].x,
            currentFogCuts[currentFogCuts.length - 1].y,
            xx,
            yy,
            [5, 15]
        )

        circle(
            ctx,
            startX,
            startY,
            12,
            true
        )

        ctx.strokeStyle = white
        if (currentFogCuts.length > 2) {
            line(ctx, startX - 3, startY - 1, startX, startY + 3)
            line(ctx, startX + 5, startY - 5, startX, startY + 3)
        } else {
            line(ctx, startX - 5, startY - 5, startX + 5, startY + 5)
            line(ctx, startX - 5, startY + 5, startX + 5, startY - 5)
        }
    }
}

function detectPointOnFogCut(xx, yy, ctx, tool, fogCuts, setSelectedFogCut) {
    let selectedFogCut = fogCuts.find(cut => {
        ctx.beginPath()
        ctx.moveTo(
            cut.points[0].x,
            cut.points[0].y
        )
        cut.points.forEach(point => {
            ctx.lineTo(
                point.x,
                point.y,
            )
        })

        if (ctx.isPointInPath(xx, yy)) {
            return cut
        }

        if (tool === 'fog-fill') {
            ctx.strokeStyle = red
            ctx.lineWidth = 3
            ctx.closePath()
            ctx.stroke()
        }

        return null
    })
    if (setSelectedFogCut) setSelectedFogCut(selectedFogCut)
    return selectedFogCut
}

function drawFillTool(ctx, tool, selectedFogCut) {
    if (
        tool === 'fog-fill' &&
        selectedFogCut
    ) {
        ctx.fillStyle = red
        ctx.globalAlpha = 0.5
        ctx.beginPath()
        ctx.moveTo(
            selectedFogCut.points[0].x,
            selectedFogCut.points[0].y,
        )

        selectedFogCut.points.forEach(point => {
            ctx.lineTo(
                point.x,
                point.y,
            )
        })

        ctx.closePath()
        ctx.fill()

        ctx.globalAlpha = 1
    }
}

function removeFogCut(tool, fogCuts, selectedFogCut, setSelectedFogCut, updateDoc) {
    if (tool === 'fog-fill' && selectedFogCut) {
        const newFogCuts = [...fogCuts]
        const cutIndex = newFogCuts.findIndex(cut => selectedFogCut.id === cut.id)
        newFogCuts.splice(cutIndex, 1)
        updateDoc({ p: ['fogCuts'], od: fogCuts, oi: newFogCuts })

        setSelectedFogCut(null)
    }
}

function drawFog(ctx, fogCuts, isOwner, width, height) {
    ctx.clearRect(0, 0, width, height)

    ctx.globalAlpha = isOwner ? 0.5 : 1
    ctx.fillStyle = '#21262c'

    ctx.fillRect(
        0,
        0,
        width,
        height
    )


    ctx.globalAlpha = 1

    if (fogCuts.length) {
        ctx.globalCompositeOperation = "destination-out"
        ctx.beginPath()
        fogCuts.forEach(cut => {
            ctx.moveTo(
                cut.points[0].x,
                cut.points[0].y,
            )

            cut.points.forEach(point => {
                ctx.lineTo(
                    point.x,
                    point.y,
                )
            })
            ctx.closePath()
            ctx.fill()
        })
        ctx.globalCompositeOperation = "source-over"
    }
}

export {
    drawFog, addFogCutPoint, finishFogCut,
    drawFogCutTool, detectPointOnFogCut,
    drawFillTool, removeFogCut
}