import shortid from 'shortid'
import { removeNotification } from './removeNotification'

export function addNotification(message, setId, color) {
    const id = setId || shortid.generate()

    setTimeout(() => {
        removeNotification.call(this, id)
    }, 5000)

    this.dispatch({ type: 'ADD_NOTIFICATION', value: { message, id, color } })
}