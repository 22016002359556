const reducer = (state, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'SET_ERROR': {
            newState.error = { ...action.value }
            newState.errorCount++
            return newState
        }

        default: return state
    }
}

export default reducer
