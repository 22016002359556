import React from 'react'
import Plus from './Plus'
import ChestOpen from './ChestOpen'
import ChestClosed from './ChestClosed'
import Clouds from './Clouds'
import Tome from './Tome'
import TomeOpen from './TomeOpen'
import Map from './Map'
import Notes from './Notes'
import Lute from './Lute'
import Drum from './Drum'
import River from './River'
import Minimize from './Minimize'
import Maximize from './Maximize'
import Close from './Close'
import Scale from './Scale'
import Send from './Send'

import Parchment from './Parchment'
import Checklist from './Checklist'
import Ink from './Ink'

import CheckIcon from './CheckIcon'
import Chat from './Chat'

import ExpandListToggle from './ExpandListToggle'
import Volume from './Volume'

import Settings from './Settings'

import Keyboard from './Keyboard'

import Arrow from './Arrow'

import './styles.css'
import Media from './Media'
import Dots from './Dots'
import LogoOutline from './LogoOutline'

import Discord from './Discord'
import Facebook from './Facebook'
import Instagram from './Instagram'
import Twitter from './Twitter'
import King from './King'
import Character from './Character'

const Icon = ({ value, color, className }) => (
    <div className={`Icon ${value} ${className}`}>{selectIcon(value, color)}</div>
)

export default Icon

const selectIcon = (value, color) => {
    switch (value) {
        case 'arrow-left': return <Arrow className="arrow-left" />
        case 'arrow-right': return <Arrow />
        case 'plus': return <Plus color={color} />
        case 'chest-open': return <ChestOpen color={color} />
        case 'chest-closed': return <ChestClosed color={color} />
        case 'tome': return <Tome color={color} />
        case 'tome-open': return <TomeOpen color={color} />

        case 'chat-icon': return <Chat color={color} />
        case 'check': return <CheckIcon color={color} />
        case 'clouds': return <Clouds color={color} />
        case 'expand-list': return <ExpandListToggle color={color} />

        case 'map': return <Map color={color} />
        case 'note': return <Notes color={color} />
        case 'media': return <Media />

        case 'king': return <King />
        case 'character': return <Character />

        case 'lute':
        case 'soundboard':
        case 'music':
            return <Lute />

        case 'drum':
        case 'sound-effect':
            return <Drum />

        case 'river':
        case 'ambience':
            return <River />

        case 'minimize': return <Minimize color={color} />
        case 'maximize': return <Maximize color={color} />
        case 'close': return <Close color={color} />
        case 'scale': return <Scale color={color} />

        case 'send-icon': return <Send color={color} />
        case 'crown': return <img alt="crown icon" src="/crown.svg" color={color} />
        case 'parchment': return <Parchment />
        case 'checklist': return <Checklist />
        case 'ink': return <Ink />

        case 'volume': return <Volume color={color} />

        case 'settings-icon': return <Settings color={color} />

        case 'keyboard': return <Keyboard color={color} />

        case 'dots': return <Dots color={color} />

        case 'logo-outline': return <LogoOutline color={color} />

        case 'discord': return <Discord color={color} />
        case 'facebook': return <Facebook color={color} />
        case 'instagram': return <Instagram color={color} />
        case 'twitter': return <Twitter color={color} />

        default: return <span>?</span>
    }
}

export { default as DiceValueIcon } from './DiceValueIcon'