import fullUser from '../../../config/graph-request-types/fullUser'
import API from '../../../functions/API'
import { importInventoryData } from './importInventoryData'
export async function leaveCampaign(authToken, user, shortId, setLoading, setError, closeWindow, windowIndex) {
    setLoading(true)
    try {
        const result = await API.sendGraphSecure(authToken, {
            query: `mutation {
                leaveCampaign(shortId: "${shortId}", userId: "${user._id}") {
                    ${fullUser}
                }
            }`
        })

        closeWindow(windowIndex)
        importInventoryData.call(this, result.data.leaveCampaign)
        setLoading(false)
    } catch (err) {
        setError('Error leaving campaign')
        console.log(err)
        setLoading(false)
    }
}