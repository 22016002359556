import { useCampaignFunctions, useCampaignState } from "../../context/Campaign"

const useUsersTypingCommunications = () => {
    const { socket } = useCampaignState()
    const { campaignDispatch } = useCampaignFunctions()

    const usersTyping = () => {
        socket.on('user-typing', userId => {
            campaignDispatch({ type: 'ADD_USER_TYPING', value: userId })
        })

        socket.on('user-done-typing', userId => {
            campaignDispatch({ type: 'REMOVE_USER_TYPING', value: userId })
        })
    }

    return usersTyping
}

export default useUsersTypingCommunications