import React from 'react'
import DocumentToolbar from '../../DocumentToolbar'
import { PageFit, AlignBottom, AlignTop, AlignLeft, AlignRight } from '@styled-icons/fluentui-system-filled/'
import { PhotoSizeSelectSmall } from '@styled-icons/material-rounded/PhotoSizeSelectSmall'
import { IconButton } from '../../Buttons'
import { ToolBarSeparator } from '../../DocumentToolbar'

const TopToolBar = ({ id, windowIndex, scaleMode, setScaleMode, picturePosition, setPicturePosition, saving, isFullScreen, broadcasted, broadcasting }) => {
    return (
        <DocumentToolbar
            windowIndex={windowIndex}
            saving={saving}
            isFullScreen={isFullScreen}
            documentId={id}
            broadcasted={broadcasted}
            broadcasting={broadcasting}
        >
            <div className="row full-width height-2 hidden-phone">
                <IconButton onClick={() => setScaleMode("contain")}>
                    <PhotoSizeSelectSmall className={`size-1 ${scaleMode === 'contain' ? "c-blue" : "c-shade-6"}`} />
                </IconButton>
                <IconButton onClick={() => setScaleMode("cover")}>
                    <PageFit className={`size-1 ${scaleMode === 'cover' ? "c-blue" : "c-shade-6"}`} />
                </IconButton>
                <ToolBarSeparator />
                <IconButton onClick={() => setPicturePosition("top")}>
                    <AlignTop className={`size-1 ${picturePosition === 'top' ? "c-blue" : "c-shade-6"}`} />
                </IconButton>
                <IconButton onClick={() => setPicturePosition("bottom")}>
                    <AlignBottom className={`size-1 ${picturePosition === 'bottom' ? "c-blue" : "c-shade-6"}`} />
                </IconButton>
                <IconButton onClick={() => setPicturePosition("left")}>
                    <AlignLeft className={`size-1 ${picturePosition === 'left' ? "c-blue" : "c-shade-6"}`} />
                </IconButton>
                <IconButton onClick={() => setPicturePosition("right")}>
                    <AlignRight className={`size-1 ${picturePosition === 'right' ? "c-blue" : "c-shade-6"}`} />
                </IconButton>
            </div>
        </DocumentToolbar>
    )
}

export default TopToolBar
