import React from 'react'
import { Button, TextButton } from '../../../../components/Buttons'
import { List, ListItem } from '../../../../components/List'
import Icon from '../../../../components/Icon'

import { useWorkspaceFunctions } from '../../../../context/Workspace'
import Loading from '../../../../components/Loading'
import { useAuthState } from '../../../../context/Auth'

import { useHistory } from 'react-router-dom'
import InviteToggle from '../../../../components/InviteToggle'
import { useLoadingFunctions, useLoadingState } from '../../../../context/Loading'
import { useInventoryFunctions, useInventoryState } from '../../../../context/Inventory'
import useOpenWindow from '../../../../hooks/useOpenWindow'
import { useErrorFunctions } from '../../../../context/Error'

import './campaign.css'

const ViewCampaign = ({ campaignShortId, _index }) => {

    const { user, auth } = useAuthState()
    const { campaigns, documents } = useInventoryState()
    const { destroyCampaign, leaveCampaign } = useInventoryFunctions()
    const { closeWindow } = useWorkspaceFunctions()
    const { loading } = useLoadingState()
    const { setLoading } = useLoadingFunctions()
    const { setError } = useErrorFunctions()
    const history = useHistory()
    const campaign = campaigns.find(campaign => campaign.shortId === campaignShortId)
    const { title, shortId, owner, users, items: campaignItems } = campaign

    const openWindow = useOpenWindow()

    const enterCampaignAndCloseWindow = () => {
        history.push(`/campaign/${campaignShortId}`)
        closeWindow(_index)
    }

    const openDestroyCampaignDialog = () => {
        openWindow('confirm', null, {
            message: `Are you sure you want to destroy the "${title}" campaign?
            This is irreversible!`,
            confirmCallback: () => {
                destroyCampaign(auth, user, campaignShortId, setLoading, setError, closeWindow, _index)
            }
        })
    }

    const openLeaveCampaignDialog = () => {
        openWindow('confirm', null, {
            message: `Are you sure you want to Leave the "${title}" campaign?`,
            confirmCallback: () => {
                leaveCampaign(auth, user, campaignShortId, setLoading, setError, closeWindow, _index)
            }
        })
    }

    if (loading === 'view-campaign') return <Loading />
    return (
        <div className="view-campaign padding-horizontal-2 padding-bottom-2">
            <div className="section row centered-vertically space-between">
                <h1 className="c-shade-6">{title}</h1>
            </div>

            <div className="row centered-vertically space-between">
                <p className="c-shade-5">Items</p>
                {/* {documents > 0 && <TextButton class="padding-0">+ Add Items To Campaign</TextButton>} */}
            </div>
            <List className="bg-shade-1 c-shade-8" emptyMessage={documents > 0 ? "There are no Items added yet." : "You havent made any items yet."}>
                {campaignItems && campaignItems.map((item, i) => (
                    <ListItem key={i}>
                        Item
                    </ListItem>
                ))}
            </List>

            <div className="row centered-vertically space-between">
                <p className="full-width c-shade-5">Users</p>
                <InviteToggle className="window-section-right" inputClassName="invite-link-small" text={'+ Get Campaign Invite Link'} url={`https://adventuring-kit.com/campaign/${shortId}`} />
            </div>
            <List className="view-campaign-user-list bg-shade-1 c-shade-8">
                {users.map((campaignUser, i) => (
                    <ListItem key={i}>
                        <div className="full-width row space-between" key={i}>
                            <div className="row">
                                <span>
                                    {user._id === campaignUser._id ? 'You' : campaignUser.name}
                                </span>
                                &nbsp;
                                {campaignUser._id === owner._id && <Icon value="crown" />}
                            </div>
                        </div>
                    </ListItem>
                ))}
            </List>

            <div className="view-campaign-bottom-ui push-off-window no-pointer-events space-between centered-vertically">
                <Button className="shadow-dark" onClick={enterCampaignAndCloseWindow}>Enter Campaign</Button>
                {user._id === owner._id ? (
                    <TextButton onClick={openDestroyCampaignDialog}>Destroy Campaign</TextButton>
                ) : (
                    <TextButton onClick={openLeaveCampaignDialog}>Leave Campaign</TextButton>
                )}
            </div>
        </div >
    )
}

export default ViewCampaign
