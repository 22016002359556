import React from 'react'

const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55.266" height="55.266" viewBox="0 0 55.266 55.266">
    <g id="Group_25" data-name="Group 25" transform="translate(-1769.867 -938.367)">
      <line id="Line_1" data-name="Line 1" y2="46.266" transform="translate(1797.5 942.867)" fill="none" strokeLinecap="round" strokeWidth="9" />
      <line id="Line_2" data-name="Line 2" y2="46.266" transform="translate(1820.633 966) rotate(90)" fill="none" strokeLinecap="round" strokeWidth="9" />
    </g>
  </svg>
)

export default PlusIcon
