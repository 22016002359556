import React from 'react'
import './Slider.css'

const Slider = ({ min, max, className, value, onChange, onMouseUp, onClick }) => {

    const inDarkMode = getInDarkMode()
    const bgColor = inDarkMode ? '#647682' : '#DEE1E8'
    min = min || 0
    max = max || 100
    value = (value === undefined ? 50 : value)

    const gradientValue = (value - min) / (max - min) * 100

    return (
        <input
            style={{ background: `linear-gradient(to right, #B4E1E9 0%, #B4E1E9 ${gradientValue}%, ${bgColor} ${gradientValue}%, ${bgColor} 100%)` }}
            type="range"
            min={min}
            max={max}
            value={value}
            className={`Slider ${className}`}
            onChange={onChange}
            onMouseUp={onMouseUp}
            onClick={onClick}
        ></input>
    )
}

export default Slider

const getInDarkMode = () => {
    return localStorage.getItem('darkModeSetting') === 'On' || (localStorage.getItem('darkModeSetting') === 'Auto' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
}