import shortid from 'shortid'
import { getScreenPosIn3dSpace } from '../../functions'

export default function createDiceGroup(diceSelected) {
    const groupId = shortid.generate()
    const groupCount = this.rollingGroups.length
    const prevGroup = groupCount > 0 ? this.rollingGroups[groupCount - 1] : null
    const prevGroupY = prevGroup ? prevGroup.groupScreenY : this.notificationCount * this.notificationsHeight
    const prevGroupHeight = prevGroup ? prevGroup.height : 0
    const groupHeight = (Math.ceil(diceSelected / 4) * this.finishedDiceOffset)

    const max4 = diceSelected < 5 ? diceSelected : 4

    const groupWidth = (max4 * this.finishedDiceOffset)

    const groupScreenX = this.WIDTH - this.groupRightStandOff - groupWidth
    const groupScreenY = prevGroupY + prevGroupHeight + this.groupTopMargin
    const groupPos = getScreenPosIn3dSpace(this.camera, groupScreenX, groupScreenY, this.WIDTH, this.HEIGHT)

    const addOrSubValue = this.addOrSub !== '-' ? parseInt(this.addOrSub) : 0
    const tempAddOrSubValue = this.tempDiceOptions.addOrSub !== '-' ? parseInt(this.tempDiceOptions.addOrSub) : 0

    const tempSucceedsOn = this.tempDiceOptions.succeedsOn !== 0 ? parseInt(this.tempDiceOptions.succeedsOn) : 0
    const tempSuceedsOnGreaterOrLess = this.tempDiceOptions.succeedsOnGreaterOrLess

    const group = {
        id: groupId,
        name: this.tempDiceOptions.name || this.user.name,
        label: this.tempDiceOptions.label || '',
        prevGroupHeight,
        prevGroup,
        dice: [],
        dieFinished: 0,
        pos: groupPos,
        width: groupWidth,
        height: groupHeight,
        groupScreenX,
        groupScreenY,
        screenX: this.WIDTH,
        screenY: groupScreenY,
        totalValue: addOrSubValue + tempAddOrSubValue,
        networkDice: [],
        exploding: false,
        explosionCount: 0,
        diceTrayActive: this.diceTray.active,
        privateRoll: this.rollingPrivately,
        disableAnimations: this.disableAnimations,
        rollingPrivately: this.rollingPrivately,
        keepBest: this.keepBest,
        addOrSub: addOrSubValue + tempAddOrSubValue,
        succeedsOn: tempSucceedsOn || this.succeedsOn,
        succeedsOnDiceOrRoll: tempSucceedsOn !== 0 ? 'Roll' : this.succeedsOnDiceOrRoll,
        succeedsOnGreaterOrLess: tempSucceedsOn !== 0 ? tempSuceedsOnGreaterOrLess : this.succeedsOnGreaterOrLess,
        explodesOn: this.explodesOn,
        skin: this.user.diceSkin,
    }

    group.networkObject = {
        groupId,
        keepBest: this.keepBest,
        addOrSub: addOrSubValue + tempAddOrSubValue,
        succeedsOn: tempSucceedsOn || this.succeedsOn,
        succeedsOnDiceOrRoll: tempSucceedsOn !== 0 ? 'Roll' : this.succeedsOnDiceOrRoll,
        succeedsOnGreaterOrLess: tempSucceedsOn !== 0 ? tempSuceedsOnGreaterOrLess : this.succeedsOnGreaterOrLess,
        explodesOn: this.explodesOn,
        disableAnimations: this.disableAnimations,
        skin: this.user.diceSkin,
    }

    if (prevGroup) prevGroup.nextGroup = group
    this.rollingGroups.push(group)

    return group
}