import React from 'react'

const Tab = ({ active, children }) => {
    return (
        <div className={`tab-nav-tab ${active && 'active'}`}>
            {children}
        </div>
    )
}

export default Tab
