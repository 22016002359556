import React from 'react'

const ListItem = ({ children, onClick, selected, className, disabled }) => {
    return (
        <li className={`list-item ${selected && 'selected'} ${disabled && 'disabled'} ${className}`} onClick={onClick}>
            {children}
        </li>
    )
}

export default ListItem
