import React from 'react'
import { useAuthState } from '../../../../context/Auth/index'
import { useCampaignState } from '../../../../context/Campaign/index'
import { LockFill, UnlockFill } from '@styled-icons/bootstrap/'
import { EyeFill, EyeSlashFill } from '@styled-icons/bootstrap/'
import { TrashBin } from '@styled-icons/ionicons-sharp/TrashBin'
import { Button } from '../../../Buttons/index'

const TokenPropertiesHeader = ({ multi, tokenOwnedByUser, selectedToken, editSelectedToken, removeSelectedToken }) => {
    const { activeCampaign } = useCampaignState()
    const { user } = useAuthState()

    const { hidden, locked } = selectedToken.length ? selectedToken[0] : selectedToken
    return (
        <div className="row centered space-between margin-top-1 margin-bottom-1">
            {tokenOwnedByUser || activeCampaign?.owner?._id === user._id ? <Button className={`col centered size-4 ${locked ? 'blue' : 'bg-shade-5 c-shade-1'} margin-right-1`} onClick={() => editSelectedToken({ locked: !locked }, multi)}>
                {
                    locked ? (
                        <>
                            <UnlockFill className="size-1 margin-bottom-half" /> Unlock
                        </>
                    ) : (
                        <>
                            <LockFill className="size-1 margin-bottom-half" /> Lock
                        </>
                    )}
            </Button> : null}

            {
                tokenOwnedByUser || activeCampaign?.owner?._id === user._id ? (
                    <Button className={`col centered size-4 ${hidden ? 'blue' : 'black'} margin-right-1`} onClick={() => editSelectedToken({ hidden: !hidden }, multi)}>
                        {hidden ? (
                            <EyeFill className="size-1 margin-bottom-half" />
                        ) : (
                            <EyeSlashFill className="size-1 margin-bottom-half" />
                        )}
                        {hidden ? "Show" : "Hide"}
                    </Button>
                ) : null
            }

            <Button className="col centered size-4 red" onClick={() => removeSelectedToken(multi)}>
                <TrashBin className="size-1 margin-bottom-half" />
                Delete
            </Button>
        </div >
    )
}

export default TokenPropertiesHeader