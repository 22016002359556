import { useEffect } from "react"
import useSendToCheckout from './useSendToCheckout'
const useAttemptedCoupon = () => {
    const sendToCheckout = useSendToCheckout('monthly')
    useEffect(() => {
        const attemptedCoupon = localStorage.getItem('attemptedCoupon')
        if (attemptedCoupon) {
            sendToCheckout()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export default useAttemptedCoupon
