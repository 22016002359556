import { useEffect, useState } from 'react'
import { usePrevious } from '../../../hooks/usePrevious'
import { useCampaignFunctions, useCampaignState } from '../../../context/Campaign'
import { useAuthState } from '../../../context/Auth'
import { useErrorFunctions } from '../../../context/Error'
import { useLoadingState, useLoadingFunctions } from '../../../context/Loading'

let typingTimer

export function useMessaging(message, setMessage) {
    const [typing, setTyping] = useState(false)
    let previousTyping = usePrevious(typing)

    const { user, auth } = useAuthState()

    const { connectedUsers, activeCampaign, socket } = useCampaignState()
    const { addMessage } = useCampaignFunctions()

    const { setError } = useErrorFunctions()

    const { loading } = useLoadingState()
    const { setLoading } = useLoadingFunctions()

    const chatLoading = loading === 'chat'

    const createMessage = () => {
        if (message.replace(' ', '').length > 0) {
            const messageObject = addMessage(message, user, activeCampaign.shortId)
            if (!chatLoading) {
                sendMessage(socket, auth, messageObject, setLoading, setError)
                setMessage('')
            }
        }
    }

    const previousMessage = usePrevious(message)
    useEffect(() => {
        if (message.length > 0 && message !== previousMessage && previousMessage !== undefined) {
            clearTimeout(typingTimer)
            setTyping(true)
            typingTimer = setTimeout(() => {
                setTyping(false)
            }, 3000)
        }

        if (message.length === 0) {
            setTyping(false)
        }
    }, [message, previousMessage, connectedUsers, user])

    useEffect(() => {
        if (previousTyping !== undefined) {
            if (typing && typing !== previousTyping) {
                socket.emit('user-typing', user.name)
            }

            if ((!typing && typing !== previousTyping)) {
                socket.emit('user-done-typing', user.name)
            }
        }
    }, [activeCampaign, previousTyping, socket, typing, user])

    return createMessage
}


async function sendMessage(socket, auth, message, setError) {
    try {
        socket.emit('NEW_MESSAGE', JSON.stringify({ auth, message }))
    } catch (err) {
        setError(err.message)
    }
}