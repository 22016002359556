import React from 'react'
import './boundingBox.css'

const BoundingBox = ({ boundingBox, camera }) => {
    return (
        <div className="boundingBox" style={{
            left: boundingBox.left,
            top: boundingBox.top,
            width: boundingBox.width,
            height: boundingBox.height,
        }}></div>
    )
}

export default BoundingBox