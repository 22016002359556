import { useEffect, useRef, useState } from 'react'
import { usePrevious } from '../../../hooks/usePrevious'

const useMusic = (playing, url, resetOnPlay) => {
    const [time, setTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [repeat, setRepeat] = useState(false)
    const soundRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [volume, setVolume] = useState(0.5)
    let currentTimeTimeout = useRef(null)

    const previousPlaying = usePrevious(playing)
    useEffect(() => {
        const setCurrentDuration = () => {
            setDuration(soundRef.current.duration)
        }

        if (previousPlaying !== playing) {
            if (soundRef.current) {
                if (playing) {
                    playSong(soundRef, resetOnPlay, repeat, setLoading, setTime, currentTimeTimeout)
                } else {
                    soundRef.current.pause()
                    setLoading(false)
                    clearInterval(currentTimeTimeout.current)
                }
            } else {
                if (playing) {
                    soundRef.current = new Audio()
                    soundRef.current.src = url
                    soundRef.current.load()
                    soundRef.current.loop = repeat
                    soundRef.current.addEventListener('durationchange', setCurrentDuration)
                    playSong(soundRef, resetOnPlay, repeat, setLoading, setTime, currentTimeTimeout)
                }
            }
        }


        return () => {
            if (soundRef.current) {
                soundRef.current.removeEventListener('durationChange', setCurrentDuration)
            }
        }
    }, [currentTimeTimeout, playing, previousPlaying, repeat, resetOnPlay, url, volume])

    const setCurrentTime = (newTime) => {
        if (soundRef.current) {
            soundRef.current.currentTime = newTime
            setTime(newTime)
            if (playing)
                soundRef.current.play().catch(e => console.log(e))
        }
    }

    useEffect(() => {
        if (soundRef.current) {
            soundRef.current.volume = volume
        }
    }, [volume])

    return {
        volume,
        setVolume,
        duration,
        time,
        loading,
        setLoading,
        repeat,
        setRepeat,
        setCurrentTime,
        soundRef
    }
}

export default useMusic


const playSong = (soundRef, resetOnPlay, repeat, setLoading, setTime, currentTimeTimeout) => {
    if (resetOnPlay) {
        soundRef.current.currentTime = 0
        soundRef.current.load()
    }
    soundRef.current.loop = repeat
    soundRef.current.play().then(() => {
        setLoading(false)
    }).catch(e => console.log(e))
    setLoading(true)
    currentTimeTimeout.current = setInterval(() => setTime(soundRef.current.currentTime), 200)
}