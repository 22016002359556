import React from 'react'
import { Copy } from '@styled-icons/boxicons-regular/Copy'
import { BoxArrowInLeft } from '@styled-icons/bootstrap/BoxArrowInLeft'
import { GridOff } from '@styled-icons/material/'
import Slider from '../../../Slider/Slider'
import Checkbox from '../../../Checkbox/Checkbox'
import { Button } from '../../../Buttons/index'

import { BorderStyle, BorderInner } from '@styled-icons/material-rounded'
import Toggle from '../../../Toggle/Toggle'


const Controls = ({ selectedToken, editSelectedToken, copyTokens, removeSelectedToken, gridSize, multi }) => {
    const { scale, rotation, flip, alignment, layer } = selectedToken.length ? selectedToken[0] : selectedToken

    const gridAlignmentValues = ['center', 'corner', 'nogrid']
    const gridIndex = gridAlignmentValues.findIndex((val) => val === (alignment || 'center'))

    const gridAlignmentIcons = [
        <div className="col centered padding-quarter">
            <BorderInner className="size-1dot5 margin-bottom-quarter" />
            <p className='t-small'>Center</p>
        </div>,
        <div className="col centered padding-quarter">
            <BorderStyle className="size-1dot5 margin-bottom-quarter" />
            <p className='t-small'>Corner</p>
        </div>,
        <div className="col centered padding-quarter">
            <GridOff className="size-1dot5 margin-bottom-quarter" />
            <p className='t-small'>No Grid</p>
        </div>
    ]

    return <div className='col padding-horizontal-half'>
        <div className="margin-top-2">
            <div className="col space-between">
                <div className="row space-between">
                    <p className="t-small">Scale</p>
                    <p className="t-small margin-left-1">{scale === undefined ? 1 : scale / 4}</p>
                </div>
                <Slider
                    className="full-width margin-top-half"
                    min={1}
                    max={24}
                    value={scale === undefined ? 4 : scale}
                    onChange={(e) => editSelectedToken({ scale: e.target.value }, multi)}
                />
            </div>
        </div>

        <div className="row margin-top-2">
            <div className="col">
                <p className="t-small margin-bottom-half">Flip</p>
                <Checkbox checked={flip || false} onChange={(e) => {
                    editSelectedToken({ flip: e.target.checked }, multi)
                }} />
            </div>
            <div className="col space-between full-width">
                <p className="t-small margin-bottom-1 t-right">Rotate</p>
                <Slider
                    className="full-width"
                    min={0}
                    max={360}
                    value={rotation === undefined ? 0 : rotation}
                    onChange={(e) => editSelectedToken({ rotation: e.target.value }, multi)}
                />
            </div>
        </div>

        <div className='col space-between margin-top-2'>
            <div className="row space-between">
                <p className="t-small">View Layer</p>
                <p className="t-small margin-left-1">{layer === undefined ? 0 : layer}</p>
            </div>
            <Slider
                className="full-width margin-top-half"
                min={0}
                max={6}
                value={layer === undefined ? 0 : layer}
                onChange={(e) => editSelectedToken({ layer: e.target.value }, multi)}
            />
        </div>

        <div className='margin-top-2'>
            <p className='t-small margin-bottom-quarter'>Grid Alignment</p>
            <Toggle
                fullWidthButtons
                entries={gridAlignmentValues}
                entryIcons={gridAlignmentIcons}
                value={gridIndex}
                setValue={(val) => editSelectedToken({ alignment: val }, multi)}
            />
        </div>

        <div className="row margin-top-2 full-width">
            <Button className="row centered half-width blue margin-right-half" onClick={() => copyTokens(selectedToken, gridSize)}>
                <Copy className="size-1 margin-right-half" /> Copy
            </Button>
            <Button className="row centered half-width black" onClick={() => {
                copyTokens(selectedToken, gridSize)
                removeSelectedToken()
            }}>
                <BoxArrowInLeft className="size-1 margin-right-half" /> Move
            </Button>
        </div>
    </div>
}

export default Controls