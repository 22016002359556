export function changePage(page) {
    this.dispatch({
        type: 'CHANGE_PAGE',
        value: (
            page === "login"
            || page === "signup"
            || page === "password-reset-request-form"
            || page === "password-reset-form"
        ) ? page : "signup"
    })
}