import adjustHostnameForDev from "./adjustHostnameForDev"
const urlPrefix = (window.location.hostname.includes('qa.')) ? 'image-qa' : 'image'
const baseUrl = process.env.NODE_ENV === "production" ? `https://${urlPrefix}.adventuring-kit.com` : adjustHostnameForDev(process.env.REACT_APP_IMAGE_PROCCESSOR_URL)

class ImageAPI {
    static async upload(e, files, opts) {
        try {
            const file = files ? files[0] : e.target.files[0]
            const processedFile = await this.process(file, { width: opts?.maxWidth || 4 * 1024, height: opts?.maxHeight || 4 * 1024 })
            const formData = await new FormData()
            await formData.append('file', processedFile, file?.name?.replace ? file.name.replace(' ', '_') : undefined)
            return fetch(baseUrl + '/image-upload', {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
        } catch (e) {
            throw new Error(e)
        }
    }

    static async uploadMany(files) {
        try {
            const formData = await new FormData()
            files.forEach(async file => await formData.append('files', file, file?.name?.replace ? file.name.replace(' ', '_') : undefined))
            return fetch(baseUrl + '/image-upload-many', {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
        } catch (e) {
            throw new Error(e)
        }
    }

    static async uploadPDF(files) {
        try {
            const file = files[0]
            const formData = await new FormData()
            await formData.append('file', file)
            return fetch(baseUrl + '/image-upload', {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
        } catch (e) {
            throw new Error('error uploading PDF', e)
        }
    }

    static async delete(url) {
        try {
            return fetch(baseUrl + '/delete-image/' + url).then(res => res.json())
        } catch (e) {
            throw new Error('error uploading image', e)
        }
    }

    static async deleteMany(urls) {
        try {
            return fetch(baseUrl + '/delete-images', {
                ...postData,
                body: JSON.stringify(urls)
            })
                .then(res => res.json())
        } catch (e) {
            throw new Error('error uploading image', e)
        }
    }

    static process(file, maxDimensions) {
        return new Promise((resolve, reject) => {
            const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp']
            if (types.every(type => file.type !== type))
                reject(`File type not supported`)

            if (file.size > 5000000)
                reject(`Image too large`)

            this.resize(file, maxDimensions, (file, processed) => {
                resolve(file)
            })
        })
    }

    static resize(file, maxDimensions, callback) {
        if (typeof maxDimensions === 'function') {
            callback = maxDimensions;
            maxDimensions = {
                width: 120,
                height: 120
            };
        }

        if (!this.isSupported() || !file.type.match(/image.*/)) {
            callback(file, false);
            return false;
        }


        if (file.type.match(/image\/gif/)) {
            // Not attempting, could be an animated gif
            callback(file, false);
            // TODO: use https://github.com/antimatter15/whammy to convert gif to webm
            return false;
        }

        let image = document.createElement('img');

        image.onload = (imgEvt) => {
            let width = image.width;
            let height = image.height;
            let isTooLarge = false;

            if (width >= height && width > maxDimensions.width) {
                // width is the largest dimension, and it's too big.
                height *= maxDimensions.width / width;
                width = maxDimensions.width;
                isTooLarge = true;
            } else if (height > maxDimensions.height) {
                // either width wasn't over-size or height is the largest dimension
                // and the height is over-size
                width *= maxDimensions.height / height;
                height = maxDimensions.height;
                isTooLarge = true;
            }

            if (!isTooLarge) {
                // early exit; no need to resize
                callback(file, false);
                return;
            }

            let canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            let ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            if (hasToBlobSupport) {
                canvas.toBlob((blob) => {
                    callback(blob, true);
                }, file.type);
            } else {
                let blob = this._toBlob(canvas, file.type);
                callback(blob, true);
            }
        };
        this._loadImage(image, file);

        return true;
    }

    static _toBlob(canvas, type) {
        let dataURI = canvas.toDataURL(type);
        let dataURIParts = dataURI.split(',');
        let byteString;
        if (dataURIParts[0].indexOf('base64') >= 0) {
            // Convert base64 to raw binary data held in a string:
            byteString = atob(dataURIParts[1]);
        } else {
            // Convert base64/URLEncoded data component to raw binary data:
            byteString = decodeURIComponent(dataURIParts[1]);
        }
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let intArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i += 1) {
            intArray[i] = byteString.charCodeAt(i);
        }

        let mimeString = dataURIParts[0].split(':')[1].split(';')[0];
        let blob = null;

        if (hasBlobConstructor) {
            blob = new Blob(
                [hasArrayBufferViewSupport ? intArray : arrayBuffer],
                { type: mimeString }
            );
        } else {
            let bb = new Blob();
            bb.append(arrayBuffer);
            blob = bb.getBlob(mimeString);
        }

        return blob;
    }

    static _loadImage(image, file, callback) {
        if (typeof (URL) === 'undefined') {
            let reader = new FileReader();
            reader.onload = function (evt) {
                image.src = evt.target.result;
                if (callback) { callback(); }
            }
            reader.readAsDataURL(file);
        } else {
            image.src = URL.createObjectURL(file);
            if (callback) { callback(); }
        }
    };

    static isSupported() {
        return (
            (typeof (HTMLCanvasElement) !== 'undefined')
            && hasBlobSupport
            && hasReaderSupport
        );
    }
}

export default ImageAPI


const hasBlobConstructor = typeof (Blob) !== 'undefined' && (() => {
    try {
        return Boolean(new Blob())
    } catch (e) {
        return false
    }
})()

const hasArrayBufferViewSupport = hasBlobConstructor && typeof (Uint8Array) !== 'undefined' && (() => {
    try {
        return new Blob([new Uint8Array(100)]).size === 100
    } catch (e) {
        return false
    }
})()

let hasToBlobSupport = (typeof HTMLCanvasElement !== "undefined" ? HTMLCanvasElement.prototype.toBlob : false)
let hasBlobSupport = (hasToBlobSupport || (typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined'))
let hasReaderSupport = (typeof FileReader !== 'undefined' || typeof URL !== 'undefined')

const postData = {
    method: "POST",
    headers: {
        accept: "application/json",
        "Content-type": "application/json",
    }
}