import Socket from '../../../functions/Socket'
export async function requestToEnterCampaign(auth, user, shortId, setLoading, setError) {
    try {
        setLoading(true)

        const socket = await Socket.assignSocket(user, shortId)
        this.dispatch({ type: "SET_SOCKET", value: socket })

        const connectionData = { auth, campaignShortId: shortId, userId: user._id }
        socket.emit("USER_REQUESTING_TO_ENTER_CAMPAIGN", JSON.stringify(connectionData))
    } catch (err) {
        setError(err.message)
        setLoading(false)
    }
}