import React from 'react'
import './ResizeBumpers.css'
const ResizeBumpers = ({ bumperActive }) => {
    return (
        <>
            <div className={`bumper bg-shade-9 left-bumper ${bumperActive === 'left' && 'active'}`}></div>
            <div className={`bumper bg-shade-9 right-bumper ${bumperActive === 'right' && 'active'}`}></div>
        </>
    )
}

export default ResizeBumpers
