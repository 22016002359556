export default function countDieTypes(dice, inProgress) {
    const diceCounts = {}, diceTotals = {}
    dice.forEach(die => {
        const keep = die.keep ? '-keep' : ''
        const success = die.success ? '-success' : ''
        const extras = keep + success
        die.rawType = die.type
        die.type = die.type + extras

        if (diceCounts[die.type]) diceCounts[die.type] += 1
        else diceCounts[die.type] = 1

        if (diceTotals[die.type]) diceTotals[die.type] += inProgress ? 0 : die.value
        else diceTotals[die.type] = inProgress ? 0 : die.value

    })

    const diceCountKeys = Object.keys(diceCounts)
    diceCountKeys.forEach(key => {
        if (diceCounts[key] > 10) {

            dice = dice.filter(die => (die.type !== key))

            const keep = key.indexOf('-keep') > -1
            const success = key.indexOf('-success') > -1
            dice.unshift({ type: key, value: diceTotals[key], keep, success })
        }
    })

    return { diceCounts, dice }
}


